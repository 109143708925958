import { montharr, weekday } from "./../../../arrays/weekandmonth";
import { v4 as uuidv4 } from "uuid";
const crypto = require("crypto");

function generateHash(inputString) {
  return crypto.createHash("sha256").update(inputString).digest("hex");
}
export const mt4 = (
  readerEvent,
  userId,
  portfolio,
  settrades,
  el,
  setinvalidFile,
  pnlmethod,
  entries // currently logged entries
) => {
  var content = readerEvent.target.result; // =====> this is the content!
  el.innerHTML = content;
  let tbody = "";
  try {
    tbody = el.children[1].children[0].children[2].childNodes[1];
  } catch {}
  const verified = 0; // could be 1 in the future
  // Check if the file is from the correct broker
  // If it is then continue
  if (tbody === "" || !tbody) {
    setinvalidFile(true);
  } else {
    setinvalidFile(false);
    var data = [];
    var headers = [
      "ticket",
      "opentime",
      "type",
      "size",
      "item",
      "entryprice",
      "s/l",
      "t/p",
      "closetime",
      "exitprice",
      "commission",
      "taxes",
      "swap",
      "profit",
    ];
    let numberOfTrades = 0;
    for (var ii = 3; ii < Number.MAX_VALUE; ii++) {
      var tableRow = tbody.rows[ii];
      if (tableRow.children[1].title === "Commission + Swap + Profit + Taxes") {
        numberOfTrades = ii - 1;
        break;
      }
    }
    // ------ parse data based on headers only ------
    for (var iii = 3; iii < numberOfTrades; iii++) {
      var tableRow2 = tbody.rows[iii];
      var rowData = {};
      for (var jj = 0; jj < tableRow2.cells.length; jj++) {
        const value =
          tableRow2.cells[jj].innerHTML === ""
            ? null
            : tableRow2.cells[jj].innerHTML;
        if (!value) {
        } else {
          rowData[headers[jj]] = tableRow2.cells[jj].innerHTML;
        }
      }

      data = data.concat(rowData);
    }
    // -------- CREATE TRADES --------
    // add parsed data correct trade props to temporary trade objects
    // then push to the trades to the trades state
    let tradesInner = [...entries];
    for (var ik = 0; ik < data.length; ik++) {
      let trade = "";
      var startDateTime = new Date(data[ik].opentime);
      if (String(data[ik].commission).toLowerCase() === "cancelled") {
        // if order was cancelled, do not enter the trade
      } else {
        const myString = String(data[ik].ticket);

        const hashValue = generateHash(myString);
        let mutableTrades = entries.slice(); // array of user trades that can be edited

        const foundTrade = mutableTrades.find((trade) =>
          trade.entry.hashes?.includes(hashValue)
        );
        if (String(data[ik].type).toLowerCase() === "balance") {
          trade = {
            entryId: uuidv4(),
            entry: {
              pictures: ["N/A"],
              symbol: {
                symbols: [],
                pointValue: 0,
              },
              strategy: "",
              selectedConfidence: "",
              selectedEmotion: "",
              selectedPhysical: "",
              selectedMarket: "",
              selectedTimeframe: "",
              selectedMistake: "",
              selectedPortfolio: portfolio.name,
              selectedPortfolioType: "",
              orderType:
                Number(data[ik].item.replace(/\s/g, "")) > 0
                  ? "Deposit"
                  : "Withdrawal",
              orderNumber: data[ik].ticket,
              dayOfWeek: weekday[startDateTime.getDay()],
              monthOfYear: montharr[startDateTime.getMonth()],
              stopLoss: "",
              takeProfit: "",
              profitLoss: Number(data[ik].item.replace(/\s/g, "")),
              commissions: "",
              fees: "",
              maxAdEx: "",
              maxFavEx: "",
              comments: "",
              multiExecution: [],
              exitExecution: [],
              startDateTime: startDateTime,
              endDateTime: startDateTime,
              tags: "",
              hashes: [hashValue],
              verifiedLevel: verified, // for now,
              idLinks: [],
            },
          };
          if (foundTrade) {
            // do nothing
          } else {
            tradesInner = tradesInner.concat(trade);
          }
        } else {
          var endDateTime = new Date(data[ik].closetime);
          let entryexecution = {
            id: uuidv4(),
            lotSize: Number(data[ik].size),
            entryPrice: Number(data[ik].entryprice),
            startDateTime: startDateTime,
            expectedEntry: "",
            strikePrice: "",
            expirationDate: "",
            legType: "",
          };
          let exitexecution = {
            id: uuidv4(),
            exitLotSize: Number(data[ik].size),
            exitPrice: Number(data[ik].exitprice),
            endDateTime: endDateTime,
            expectedExit: "",
            exitstrikePrice: "",
            exitexpirationDate: "",
            exitlegType: "",
            equityComponents: [],
            exercised: "",
          };
          trade = {
            entryId: uuidv4(),
            entry: {
              pictures: ["N/A"],
              symbol: {
                symbols: [data[ik].item.toUpperCase()],
                pointValue: 0,
              },
              strategy: "",
              selectedConfidence: "",
              selectedEmotion: "",
              selectedPhysical: "",
              selectedMarket: "",
              selectedTimeframe: "",
              selectedMistake: "",
              selectedPortfolio: portfolio.name,
              selectedPortfolioType: "Forex",
              orderType: data[ik].type === "buy" ? "Long" : "Short",
              orderNumber: data[ik].ticket,
              dayOfWeek: weekday[startDateTime.getDay()],
              monthOfYear: montharr[startDateTime.getMonth()],
              stopLoss: data[ik]["s/l"],
              takeProfit: data[ik]["t/p"],
              profitLoss: Number(data[ik].profit.replace(/\s/g, "")),
              commissions: Number(data[ik].commission.replace(/\s/g, "")),
              fees: Number(data[ik].swap.replace(/\s/g, "")),
              maxAdEx: "",
              maxFavEx: "",
              comments: "",
              multiExecution: [entryexecution],
              exitExecution: [exitexecution],
              startDateTime: startDateTime,
              endDateTime: endDateTime,
              tags: "",
              hashes: [hashValue],
              verifiedLevel: verified, // for now,
              idLinks: [],
            },
          };
          if (foundTrade) {
            // do nothing
          } else {
            tradesInner = tradesInner.concat(trade);
          }
        }
      }
    }
    settrades(tradesInner);
  }
};
export default mt4;
