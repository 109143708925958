import { v4 as uuidv4 } from "uuid";
import longShortDelineation from "./longShortDelineation";
import { countExerciseAssignIsEqual } from "./countExerciseAssign";

// Just a helper you had
const fixRound = (num) => {
  return Math.round(num * 1e10) / 1e10;
};

// -----------------------------------------
// FIFO / LIFO calculation helpers
// -----------------------------------------

/**
 * Sorts executions by date/time ascending (FIFO) or descending (LIFO).
 * `execs` is an array of { lotSize, entryPrice, startDateTime, legType, ... }
 * This returns a *copy* sorted by startDateTime in ascending or descending order.
 */
function sortEntriesForCostMethod(execs, costBasisMethod) {
  const sorted = [...execs].sort((a, b) => {
    const dateA = new Date(a.startDateTime).getTime();
    const dateB = new Date(b.startDateTime).getTime();
    // FIFO => earliest first; LIFO => latest first
    return costBasisMethod === "fifo" ? dateA - dateB : dateB - dateA;
  });
  return sorted;
}

/**
 * Matches an exit execution (with a certain exitLotSize) to
 * one or more entry executions (which each have some remaining lotSize).
 *
 * @param {object[]} entryPool - array of entry objects, each with a `remainingSize`, `entryPrice`, etc.
 * @param {object} exitExec - object with `exitLotSize`, `exitPrice`, etc.
 * @param {string} exitType - e.g. "Long Call", "Short Call", ...
 * @returns {number} realizedProfit for this exitExec
 */
function matchExitToEntries(entryPool, exitExec, exitType) {
  let exitSizeRemaining = exitExec.exitLotSize;
  let realizedPL = 0;

  // We'll keep matching until exitSizeRemaining is 0 or we run out of entries
  for (let i = 0; i < entryPool.length && exitSizeRemaining > 0; i++) {
    const ent = entryPool[i];
    if (ent.remainingSize <= 0) continue; // skip depleted entry

    // The matching size is the minimum of what's left in the exit
    // and what's left in this entry
    const matchSize = Math.min(exitSizeRemaining, ent.remainingSize);

    // If the exit is closing a "Long" position, P/L = (exitPrice - entryPrice) * matchSize
    // If the exit is closing a "Short" position, P/L = (entryPrice - exitPrice) * matchSize
    // Because for a short, the cost basis is effectively the credited premium
    let plPerContract = 0;
    if (exitType === "Long Call" || exitType === "Long Put") {
      // "sell to close" => profit = exitPrice - entryPrice
      plPerContract = Number(exitExec.exitPrice) - Number(ent.entryPrice);
    } else if (exitType === "Short Call" || exitType === "Short Put") {
      // "buy to close" => profit = entryPrice - exitPrice
      plPerContract = Number(ent.entryPrice) - Number(exitExec.exitPrice);
    } else {
      // fallback for single futures: "Long" => exit - entry, "Short" => entry - exit
      // This is basically the same as your longShortDelineation logic, but in a match-based approach
      // We'll do a naive approach here if there's no explicit leg type
      plPerContract = Number(exitExec.exitPrice) - Number(ent.entryPrice);
      if (exitType.includes("Short")) {
        plPerContract = Number(ent.entryPrice) - Number(exitExec.exitPrice);
      }
    }

    // Add partial realized P/L
    realizedPL += matchSize * plPerContract;

    // Deplete the sizes
    exitSizeRemaining -= matchSize;
    ent.remainingSize -= matchSize;
  }

  return realizedPL;
}

/**
 * Performs FIFO/LIFO cost-basis matching for a single "leg type" (or single type).
 * `entries` is an array of entry executions for that leg (or that single type).
 * `exits` is an array of exit executions for that leg (or that single type).
 * We return total realized P/L for these entries/exits.
 */
function calculateRealizedPLForLegTypeFIFOorLIFO(entries, exits, costBasisMethod) {
  // Sort entries by FIFO or LIFO
  const sortedEntries = sortEntriesForCostMethod(entries, costBasisMethod).map(e => {
    return {
      ...e,
      remainingSize: Number(e.lotSize) // track how many are still un-closed
    };
  });

  let totalRealizedPL = 0;

  for (let x of exits) {
    const exercised = x.exercised;
    // If "Exercised"/"Expired"/"Assigned", you might have special logic, but we'll do
    // partial only if you have explicit equity component logic. We'll handle that below:
    if (
      exercised === "Expired" ||
      exercised === "Exercised" ||
      exercised === "Assigned"
    ) {
      // Similar to your original approach. If `countExerciseAssignIsEqual` returns true,
      // we glean from `x.equityComponents`.
      if (countExerciseAssignIsEqual([x])) {
        // If we have an "equityComponents" array, each side may alter cost basis differently
        if (x.equityComponents && x.equityComponents.length) {
          for (let comp of x.equityComponents) {
            // comp.side === 'buy' => one side of the cost basis, etc.
            // Typically you'd handle the share quantity as it relates to the option lot.
            // We'll do a simplistic approach to incorporate it as a realized event:
            // We treat 'buy' as a cost (like negative P/L) and 'sell' as positive.
            // This is how your original code effectively did it:
            let realizedDelta =
              (Number(comp.quantity) * Number(comp.price)) / 100;
            if (comp.side === "buy") {
              realizedDelta = realizedDelta; // cost
            } else {
              // 'sell'
              realizedDelta = realizedDelta; // credit
            }
            totalRealizedPL += realizedDelta;
          }
        }
        // We also want to "close out" the matched number of entries for the same quantity
        // if needed. This can be quite tricky to handle exactly as the original. We'll do a
        // partial approach if x.exitLotSize > 0.
        // But often "exercised" or "assigned" means the entire position is closed out,
        // so let's attempt to match the quantity:
        if (x.exitLotSize > 0) {
          // run matchExitToEntries to 0 out that portion
          totalRealizedPL += matchExitToEntries(sortedEntries, x, x.exitlegType);
        }
      } else {
        // If not all assigned or partial logic, we do nothing or partial. We'll keep it minimal.
        if (x.exitLotSize > 0) {
          totalRealizedPL += matchExitToEntries(sortedEntries, x, x.exitlegType);
        }
      }
    } else {
      // Normal close
      totalRealizedPL += matchExitToEntries(sortedEntries, x, x.exitlegType);
    }
  }

  return totalRealizedPL;
}

/**
 * Calculates the total Realized P/L using FIFO or LIFO for a Multi-Leg Strategy.
 * We group entries/exits by their legType (e.g. "Long Call", "Short Call", etc.)
 */
function calculateRealizedPLMultiLegFIFOorLIFO(multiexec, exitexec, costBasisMethod) {
  // Group the entries by legType
  const entriesByLegType = {};
  for (let e of multiexec) {
    const lt = e.legType || "UNKNOWN_LEG";
    if (!entriesByLegType[lt]) entriesByLegType[lt] = [];
    entriesByLegType[lt].push(e);
  }
  // Group the exits by exitlegType
  const exitsByLegType = {};
  for (let x of exitexec) {
    const lt = x.exitlegType || "UNKNOWN_LEG";
    if (!exitsByLegType[lt]) exitsByLegType[lt] = [];
    exitsByLegType[lt].push(x);
  }

  let totalPL = 0;
  // For each legType we found in entries
  for (let legType of Object.keys(entriesByLegType)) {
    const legEntries = entriesByLegType[legType] || [];
    const legExits = exitsByLegType[legType] || [];
    // Calculate realized P/L for that leg
    const legPL = calculateRealizedPLForLegTypeFIFOorLIFO(
      legEntries,
      legExits,
      costBasisMethod
    );
    totalPL += legPL;
  }

  // If there's any leg in exits that didn't appear in entries, handle them:
  for (let legType of Object.keys(exitsByLegType)) {
    if (!entriesByLegType[legType]) {
      // no matching entries; normally zero or error. We'll skip or do minimal:
      // Possibly assigned from a separate trade, etc.
      // We'll ignore or treat as 0
    }
  }

  return totalPL;
}

/**
 * Calculates the total Realized P/L using FIFO or LIFO for single (non Multi-Leg).
 */
function calculateRealizedPLSingleFIFOorLIFO(multiexec, exitexec, type, costBasisMethod) {
  // In single trades, we typically have one "Long" or "Short" type.
  // We'll treat them as one group.
  const entries = [...multiexec];
  const exits = [...exitexec];

  // We'll unify them under a pseudo "legType" that lines up with 'type'?
  // Or we can just call our generic function directly:
  return calculateRealizedPLForLegTypeFIFOorLIFO(entries, exits, costBasisMethod);
}
// -----------------------------------------

export const profitLossCalculation = (
  multiexec,
  exitexec,
  type,
  assetClass,
  pointValue, // e.g. 100 for options, or anything for futures
  init, // if true, force profitLoss to calculate as if there were full exitexec
  costBasisMethod // or "FIFO" or "LIFO"
) => {
  /**
   * If the user chooses FIFO or LIFO, do the new matching approach (realized P/L).
   * Else, do your original code (summing up cost basis).
   */
  if (costBasisMethod === "fifo" || costBasisMethod === "lifo") {
    // -- NEW FIFO/LIFO path --

    let realizedPL = 0;
    if (type === "Multi-Leg Strategy") {
      realizedPL = calculateRealizedPLMultiLegFIFOorLIFO(
        multiexec,
        exitexec,
        costBasisMethod
      );
    } else {
      realizedPL = calculateRealizedPLSingleFIFOorLIFO(
        multiexec,
        exitexec,
        type,
        costBasisMethod
      );
    }

    // Now multiply by appropriate factor for Options or Futures
    if (assetClass === "Options") {
      realizedPL = realizedPL * 100;
    } else if (assetClass === "Futures") {
      realizedPL = realizedPL * pointValue;
    }
    // else (Exercise Stock, Assigned Stock, or other), do nothing special

    // Round it
    realizedPL = Math.round(realizedPL * 1e10) / 1e10;
    return realizedPL;
  }

  // -- ORIGINAL (average cost basis) path --
  let entryCost = 0;
  let exitCost = 0;
  let tempbuysum = 0;
  let tempsellsum = 0;
  let exittempbuysum = 0;
  let exittempsellsum = 0;

  const entryExecs = multiexec;
  let entryLots = 0;
  entryExecs.forEach((exec) => {
    entryLots += Number(exec.lotSize);
  });
  const exitExecs = exitexec;
  let exitLots = 0;
  exitExecs.forEach((exec) => {
    exitLots += Number(exec.exitLotSize);
  });
  entryLots = fixRound(entryLots); // fix rounding
  exitLots = fixRound(exitLots);

  if (type === "Multi-Leg Strategy") {
    for (let i in multiexec) {
      const entrylegtype = multiexec[i].legType;
      if (entrylegtype === "Long Call" || entrylegtype === "Long Put") {
        tempbuysum +=
          Number(multiexec[i].lotSize) * Number(multiexec[i].entryPrice);
      } else if (
        entrylegtype === "Short Call" ||
        entrylegtype === "Short Put"
      ) {
        tempsellsum +=
          Number(multiexec[i].lotSize) * Number(multiexec[i].entryPrice);
      }
    }
    for (let i in exitexec) {
      const exitlegtype = exitexec[i].exitlegType;
      const exercised = exitexec[i].exercised;
      if (
        exercised === "Expired" ||
        exercised === "Exercised" ||
        exercised === "Assigned"
      ) {
        if (countExerciseAssignIsEqual(exitexec)) {
          let equityComponents = exitexec[i].equityComponents;
          if (equityComponents) {
            for (let k in equityComponents) {
              const comp = equityComponents[k];
              if (comp.side === "buy") {
                tempbuysum +=
                  (Number(comp.quantity) * Number(comp.price)) / 100;
              } else {
                exittempbuysum +=
                  (Number(comp.quantity) * Number(comp.price)) / 100;
              }
            }
          }
        } else {
          // else do nothing or custom partial logic if needed
        }
      } else {
        if (exitlegtype === "Long Call" || exitlegtype === "Long Put") {
          exittempbuysum +=
            Number(exitexec[i].exitLotSize) * Number(exitexec[i].exitPrice);
        } else if (
          exitlegtype === "Short Call" ||
          exitlegtype === "Short Put"
        ) {
          exittempsellsum +=
            Number(exitexec[i].exitLotSize) * Number(exitexec[i].exitPrice);
        }
      }
    }
    entryCost = tempbuysum - tempsellsum;
    exitCost = exittempbuysum - exittempsellsum;
  } else {
    for (let i in multiexec) {
      entryCost += multiexec[i].lotSize * multiexec[i].entryPrice;
    }
    for (let ie in exitexec) {
      const exercised = exitexec[ie].exercised;
      if (
        exercised === "Expired" ||
        exercised === "Exercised" ||
        exercised === "Assigned"
      ) {
        if (countExerciseAssignIsEqual(exitexec)) {
          let equityComponents = exitexec[ie].equityComponents;
          if (equityComponents) {
            for (let k in equityComponents) {
              const comp = equityComponents[k];
              if (comp.side === "buy") {
                tempbuysum +=
                  (Number(comp.quantity) * Number(comp.price)) / 100;
              } else {
                exittempbuysum +=
                  (Number(comp.quantity) * Number(comp.price)) / 100;
              }
            }
          }
        } else {
          // else partial logic if needed
        }
      } else {
        exitCost += exitexec[ie].exitLotSize * exitexec[ie].exitPrice;
      }
    }
  }

  let profitLoss = 0;

  let lotsRatio = 1;
  if (!init) {
    // fraction of lots closed
    lotsRatio = exitLots / entryLots || 0;
  }

  const difference =
    type === "Multi-Leg Strategy"
      ? exitCost - entryCost
      : longShortDelineation(type, entryExecs) === "Long"
      ? exitCost - entryCost * lotsRatio
      : entryCost * lotsRatio - exitCost;

  if (assetClass === "Options") {
    profitLoss = difference * 100;
  } else if (assetClass === "Futures") {
    profitLoss = difference * pointValue;
  } else if (
    assetClass === "Exercise Stock" ||
    assetClass === "Assigned Stock"
  ) {
    profitLoss = difference;
  } else {
    profitLoss = difference;
  }
  profitLoss = Math.round(profitLoss * 1e10) / 1e10;

  return profitLoss;
};

/**
 * Returns net or gross P/L depending on `d`.
 */
export const returnNetPLDollar = (a, b, c, d) => {
  let newa = 0;
  let newb = 0;
  let newc = 0;
  if (a !== "") {
    newa = Number(a);
  }
  if (b !== "") {
    newb = Number(b);
  }
  if (c !== "") {
    newc = Number(c);
  }
  return d === "Gross" ? newa : newa + newb + newc;
};

export default profitLossCalculation;

