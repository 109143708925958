import {
  getRobinhoodInstrument,
  getRobinhoodOptionInstrument,
  getRobinhoodOptionPosition,
} from "../../robinhood/utils";
import { v4 as uuidv4 } from "uuid";
import { getEndDateTime, getStartDateTime } from "../../getStartorEndDateTime";
import profitLossCalculation from "../../profitLossCalculation";
import { montharr, weekday } from "../../../arrays/weekandmonth";
import { filterByID, filterByIDRHOption } from "./../../filterByID";
import rhOptionsStrategyParser from "./../../robinhood/strategyParser";
import getLegType from "./../../robinhood/getLegType";
import { countExerciseAssignIsEqual } from "../../countExerciseAssign";

import {
  mergeDuplicateExecutions,
  mergeDuplicateExitExecutions,
} from "./../../mergeDuplicateExecutions";
const fixRound = (num) => {
  return Math.round(num * 1e10) / 1e10;
};
const filterByStrikeandExpiration = (leg, opentrademultiExecution) => {
  let check = false;
  const legTypeInner = getLegType(
    leg["side"] === "buy" ? "sell" : "buy",
    leg["option_type"]
  );
  const strikePriceInner = leg["strike_price"];
  const expirationDateInnerPre = new Date(leg["expiration_date"]);
  const expirationDateInner = new Date(
    expirationDateInnerPre.getTime() +
      expirationDateInnerPre.getTimezoneOffset() * 60000
  ); //this converts the "YYYY-MM-DD" string to the correct date time object

  opentrademultiExecution.forEach((exec) => {
    if (
      String(exec.expirationDate) === String(expirationDateInner) &&
      Number(exec.strikePrice) === Number(strikePriceInner) &&
      String(exec.legType) === String(legTypeInner)
    ) {
      check = true;
    } else {
    }
  });
  return check;
};
export const parseRobinhoodOrders = async (
  tradesIn, // trades already imported
  idsIn, // arrays if ids already imported
  portfolioIn, // visible portfolio (selected portfolio or default)
  tradedata, // incoming entries from robinhood api
  userId,
  filtercryptoByID,
  verified, // trade verification level
  accessToken,
  importType,
  pnlmethod
) => {
  let trades = tradesIn;
  let ids = idsIn;
  let changedIds = [];
  let portfolio = portfolioIn;

  for (let i = 0, j = tradedata.length; i < j; i++) {
    const currentIteration = i + 1;
    if (importType === "initialLink") {
      localStorage.setItem(
        "autoiterationMessage",
        `Importing ${currentIteration}/${tradedata.length} executions...`
      );
    }
    let trade = {};
    const statePre = tradedata[i]["state"];

    let openTradesPre = [...trades];
    let openTradesPre2 = [...trades];
    /*           const side = tradedata[i]["side"]; */
    const direction = tradedata[i]["direction"];
    const recieivingaccount = tradedata[i]["receiving_account_type"];
    const originaccount = tradedata[i]["originating_account_type"];
    const rhType = tradedata[i]["rhType"];
    const instrumenturl = tradedata[i]["instrument"];
    const initialDeposit = tradedata[i]["initial_deposit"];
    const executionsPre = tradedata[i]["executions"];

    //commented out on 12/07/24 due to updated rh apis
    /*     const amount = !tradedata[i]["amount"]
      ? initialDeposit
      : rhType === "ach received"
      ? Number(tradedata[i]["amount"]["amount"])
      : Number(tradedata[i]["amount"]);
 */

    const amount = tradedata[i]["amount"];
    // options instrument
    const optionId = tradedata[i]["option"];
    const extractedOptionId =
      optionId &&
      optionId
        .toString()
        .replace("https://api.robinhood.com/options/instruments/", "")
        .replace("/", "");
    // options position

    const optionResponse =
      optionId && (await getRobinhoodOptionInstrument(extractedOptionId));

    const optionInstrument =
      rhType === "optionevent"
        ? optionResponse &&
          optionResponse.gotRobinhoodOptionInstruments.instrument.chain_symbol.replace(
            /\d+$/,
            ""
          ) // just in case there are numbers at the end. Maybe look into why RH appends
        : tradedata[i]["chain_symbol"];
    // ------------------------------

    const instrumentResponse =
      rhType === "stock" &&
      executionsPre.length &&
      (await getRobinhoodInstrument(instrumenturl, null));
    const cryptoInstrument =
      rhType === "crypto" &&
      String(filtercryptoByID(tradedata[i]["currency_pair_id"])).replace(
        "-",
        ""
      );

    const instrument =
      rhType === "stock"
        ? instrumentResponse &&
          instrumentResponse.gotRobinhoodInstruments.instrument.symbol
        : rhType === "crypto"
        ? cryptoInstrument
        : optionInstrument;
    /*           const label = tradedata[i]["label"];

    const optionlegs = tradedata[i]["legs"]; */
    const notFromorToInvestingAccount =
      recieivingaccount === "rhs_account" || originaccount === "rhs_account"
        ? true
        : false;

    const checkDirection =
      direction === "pull"
        ? true
        : recieivingaccount === "rhs_account"
        ? true
        : false;

    const isTradeOrder =
      rhType === "stock" ||
      rhType === "option" ||
      rhType === "optionevent" ||
      rhType === "crypto"
        ? true
        : false;

    if (statePre === "unconfirmed" || statePre === "new") {
      // we only don't add unconfirmed or new orders because they have identical ids to confirmed ones
      return { trades: tradesIn, ids: idsIn, changedIds: [] };
    }

    if (!isTradeOrder) {
      if (notFromorToInvestingAccount) {
        if (statePre === "cancelled") {
          // the order was cancelled, so skip
          // still add the id anyways
          if (!ids.includes(String(tradedata[i]["id"]))) {
            ids.push(String(tradedata[i]["id"]));
          }
        } else {
          if (!ids.includes(String(tradedata[i]["id"]))) {
            changedIds.push(String(tradedata[i]["id"]));
            var startDateTime =
              tradedata[i]["updated_at"] &&
              new Date(tradedata[i]["updated_at"]);
            var startDateTime2 =
              tradedata[i]["initiated_at"] &&
              new Date(tradedata[i]["initiated_at"]);
            var startDateTime3 =
              tradedata[i]["created_at"] &&
              new Date(tradedata[i]["created_at"]);
            const startdateTimeActual = startDateTime3
              ? startDateTime3
              : startDateTime2
              ? startDateTime2
              : startDateTime;

            trade = {
              entryId: String(tradedata[i]["id"]),
              entry: {
                pictures: ["N/A"],
                symbol: {
                  symbols: [],
                  pointValue: 0,
                },
                strategy: "",
                selectedConfidence: "",
                selectedEmotion: "",
                selectedPhysical: "",
                selectedMarket: "",
                selectedTimeframe: "",
                selectedMistake: "",
                selectedPortfolio: portfolio.name,
                selectedPortfolioType: "",
                orderType: checkDirection ? "Deposit" : "Withdrawal",
                orderNumber: "",
                dayOfWeek: weekday[startdateTimeActual.getDay()],
                monthOfYear: montharr[startdateTimeActual.getMonth()],
                stopLoss: "",
                takeProfit: "",
                profitLoss: checkDirection ? amount : -amount,
                commissions: 0,
                fees: !Number(tradedata[i]["service_fee"])
                  ? 0
                  : -Number(tradedata[i]["service_fee"]),
                maxAdEx: "",
                maxFavEx: "",
                comments: "",
                multiExecution: [],
                exitExecution: [],
                startDateTime: startdateTimeActual,
                endDateTime: startdateTimeActual,
                tags: "",
                verifiedLevel: verified, // because it was a fully automated trade,
                idLinks: [],
                hashes: [],
              },
            };
            trades.push(trade);
          } else {
          }
        }
      } else {
      }
      // push all transfers to also push the ones not dealing ith the rhs account
      // so the polling algorithm doesnt do unecessary calls
      ids.push(String(tradedata[i]["id"]));
    } else if (rhType === "stock" || rhType === "crypto" /* skip for now */) {
      if (
        !executionsPre.length ||
        Number(tradedata[i]["cumulative_quantity"]) === 0
      ) {
        // if there are no executions, and a couple other checks, the order was cancelled, so skip
        // still add the id anyways
        if (!ids.includes(String(tradedata[i]["id"]))) {
          ids.push(String(tradedata[i]["id"]));
        }
      } else {
        if (!ids.includes(String(tradedata[i]["id"]))) {
          let openTradesMatch = openTradesPre.filter((trade) =>
            filterByID(trade, false, instrument)
          );
          let openTradesNOTMatch = openTradesPre2.filter((trade) =>
            filterByID(trade, true, instrument)
          );

          //let timestamp = new Date(fullTradearray[i]["Timestamp"]);
          let orderType = tradedata[i]["side"] === "buy" ? "Long" : "Short";
          //let delta = fullTradearray[i]["Delta"];

          let newExecutionsArray = [];
          let executions = tradedata[i]["executions"];
          executions.forEach((execution) => {
            let startDateTime = new Date(execution["timestamp"]);
            let entryexecution = {
              id: uuidv4(),
              lotSize: fixRound(Number(execution["quantity"])),
              entryPrice:
                rhType === "stock"
                  ? execution["price"]
                  : execution["effective_price"],
              startDateTime: startDateTime,
              expectedEntry: "",
              strikePrice: "",
              expirationDate: "",
              legType: "",
            };
            newExecutionsArray.push(entryexecution);
          });

          let startDateTimezz = new Date(getStartDateTime(newExecutionsArray));

          const symbol = instrument.toString().replace("-", "");

          // As of 8/30/2022, Robinhood does not having shorting capabilities.
          // This will need to be added in when they add support for shorting stocks

          // Need to fix rounding issue of lotSizes by doing :
          // Math.round(Number(tradedata[i]["size"]) * 1e10) / 1e10,
          const fees = Number(tradedata[i]["fees"]);
          const checkedfees = fees ? -fees : 0;
          if (!openTradesMatch.length) {
            changedIds.push(String(tradedata[i]["id"]));
            // if we don't find open trade, open new one
            // If Robinhood ever supports shorting this will change unfortunately
            if (orderType === "Short") {
              // treat the entry execs as a 0 entry price
              let newExecutionsArrayRECEIVE = [];

              let executionsRECEIVE = tradedata[i]["executions"];
              executionsRECEIVE.forEach((execution) => {
                let startDateTime = new Date(execution["timestamp"]);
                let entryexecution = {
                  id: uuidv4(),
                  lotSize: fixRound(Number(execution["quantity"])),
                  entryPrice: 0,
                  startDateTime: startDateTime,
                  expectedEntry: "",
                  strikePrice: "",
                  expirationDate: "",
                  legType: "",
                };
                newExecutionsArrayRECEIVE.push(entryexecution);
              });
              let startDateTimezz2 = new Date(
                getStartDateTime(newExecutionsArrayRECEIVE)
              );

              // then sell the shares at the same datetime
              let newExecutionsArrayRECEIVEexit = [];

              let executionsRECEIVEexit = tradedata[i]["executions"];
              executionsRECEIVEexit.forEach((execution) => {
                let endDateTime = new Date(execution["timestamp"]);
                let exitexecution = {
                  id: uuidv4(),
                  exitLotSize: fixRound(Number(execution["quantity"])),
                  exitPrice: execution["price"],
                  endDateTime: endDateTime,
                  expectedExit: "",
                  exitstrikePrice: "",
                  exitexpirationDate: "",
                  exitlegType: "",
                  equityComponents: [],
                  exercised: "",
                };
                newExecutionsArrayRECEIVEexit.push(exitexecution);
              });
              let endDateTimez2 = new Date(
                getEndDateTime(newExecutionsArrayRECEIVEexit)
              );

              trade = {
                entryId: String(tradedata[i]["id"]),
                entry: {
                  pictures: ["N/A"],
                  symbol: {
                    symbols: [symbol],
                    pointValue: 0,
                  },
                  strategy: "",
                  selectedConfidence: "",
                  selectedEmotion: "",
                  selectedPhysical: "",
                  selectedMarket: "",
                  selectedTimeframe: "",
                  selectedMistake: "",
                  selectedPortfolio: portfolio.name,
                  selectedPortfolioType:
                    rhType === "stock" ? "Stocks" : "Crypto",
                  orderType: "Long",
                  orderNumber: "",
                  dayOfWeek: weekday[startDateTimezz.getDay()],
                  monthOfYear: montharr[startDateTimezz.getMonth()],
                  stopLoss: "",
                  takeProfit: "",
                  profitLoss: Number(
                    profitLossCalculation(
                      newExecutionsArrayRECEIVE,
                      newExecutionsArrayRECEIVEexit,
                      "Long",
                      "Stocks",
                      0,
                      false,
                      pnlmethod
                    )
                  ),
                  commissions: 0,
                  fees: checkedfees, // fees appear to be always negative
                  maxAdEx: "",
                  maxFavEx: "",
                  comments: "",
                  multiExecution: newExecutionsArrayRECEIVE,
                  exitExecution: newExecutionsArrayRECEIVEexit,
                  startDateTime: startDateTimezz2,
                  endDateTime: endDateTimez2,
                  tags: ["Possible Received Stock"],
                  verifiedLevel: verified, // because it was a fully automated trade,
                  idLinks: [],
                  hashes: [],
                },
              };
            } else {
              trade = {
                entryId: String(tradedata[i]["id"]),
                entry: {
                  pictures: ["N/A"],
                  symbol: {
                    symbols: [symbol],
                    pointValue: 0,
                  },
                  strategy: "",
                  selectedConfidence: "",
                  selectedEmotion: "",
                  selectedPhysical: "",
                  selectedMarket: "",
                  selectedTimeframe: "",
                  selectedMistake: "",
                  selectedPortfolio: portfolio.name,
                  selectedPortfolioType:
                    rhType === "stock" ? "Stocks" : "Crypto",
                  orderType: orderType,
                  orderNumber: "",
                  dayOfWeek: weekday[startDateTimezz.getDay()],
                  monthOfYear: montharr[startDateTimezz.getMonth()],
                  stopLoss: "",
                  takeProfit: "",
                  profitLoss: 0,
                  commissions: 0,
                  fees: checkedfees, // fees appear to be always negative
                  maxAdEx: "",
                  maxFavEx: "",
                  comments: "",
                  multiExecution: newExecutionsArray,
                  exitExecution: [],
                  startDateTime: startDateTimezz,
                  endDateTime: "",
                  tags: "",
                  verifiedLevel: verified, // because it was a fully automated trade,
                  idLinks: [],
                  hashes: [],
                },
              };
            }
            trades.push(trade);
            openTradesPre = trades;
            openTradesPre2 = trades;
          } else {
            const openTrade = openTradesMatch[0];
            changedIds.push(openTrade?.entryId);
            const openTradeEntry = openTrade?.entry;
            const opentradeOrderType = openTrade?.entry.orderType;
            const opentradeSymbol = openTrade?.entry.symbol;
            const opentradeFees = openTrade?.entry.fees;
            const opentradesType = openTrade?.entry.selectedPortfolioType;
            const opentrademultiExecution = openTrade?.entry.multiExecution;
            const opentradeexitExecution = openTrade?.entry.exitExecution;
            // if the sides do not match then it's a close
            if (orderType !== opentradeOrderType) {
              // -------- Add the exit execution, and calculate profit/loss, fees, and start & end date time stuff -------
              let exitnewExecutionsArray = [];
              let exitexecutions = tradedata[i]["executions"];
              exitexecutions.forEach((execution) => {
                var endDateTimePRE = new Date(execution["timestamp"]);
                let exitexecutionPRE = {
                  id: uuidv4(),
                  exitLotSize: fixRound(Number(execution["quantity"])),
                  exitPrice:
                    rhType === "stock"
                      ? execution["price"]
                      : execution["effective_price"],
                  endDateTime: endDateTimePRE,
                  expectedExit: "",
                  exitstrikePrice: "",
                  exitexpirationDate: "",
                  exitlegType: "",
                  equityComponents: [],
                  exercised: "",
                };
                exitnewExecutionsArray.push(exitexecutionPRE);
              });

              let entryLots = 0;
              opentrademultiExecution.forEach((exec) => {
                entryLots += Number(exec.lotSize);
              });
              let exitLots = 0;
              /*                 const newExitExecutionPRE = [
              ...openTrade?.entry.exitExecution,
              exitexecutionPRE,
            ];*/
              const newExitExecutionPRE = [
                ...opentradeexitExecution,
                ...exitnewExecutionsArray,
              ];

              newExitExecutionPRE.forEach((exec) => {
                exitLots += Number(exec.exitLotSize);
              });
              let exitLotsOLD = 0;
              opentradeexitExecution.forEach((exec) => {
                exitLotsOLD += Number(exec.exitLotSize);
              });

              entryLots = fixRound(entryLots); // fix rounding issues
              exitLots = fixRound(exitLots);
              exitLotsOLD = fixRound(exitLotsOLD);

              let startDateTimez = new Date(
                getStartDateTime(opentrademultiExecution)
              );
              let endDateTimez = new Date(getEndDateTime(newExitExecutionPRE));

              let finalFees = opentradeFees + checkedfees;

              const entry = Object.assign({}, openTradeEntry, {
                exitExecution: newExitExecutionPRE,
                profitLoss: Number(
                  profitLossCalculation(
                    opentrademultiExecution,
                    newExitExecutionPRE,
                    opentradeOrderType,
                    opentradesType,
                    opentradeSymbol.pointValue,
                    false,
                    pnlmethod
                  )
                ),
                endDateTime: endDateTimez,
                startDateTime: startDateTimez,
                fees: finalFees, //add funding payments at the end
              });

              const closedTrade = Object.assign({}, openTrade, {
                entry: entry,
              });
              openTradesNOTMatch.push(closedTrade);
              trades = openTradesNOTMatch;
              openTradesPre = openTradesNOTMatch;
              openTradesPre2 = openTradesNOTMatch;

              // calculate absolute start date time

              // -------------------
            } else {
              // Add new opening executions
              const opentradeFeesin = openTrade?.entry.fees;
              const opentrademultiExecutionNew = [
                ...opentrademultiExecution,
                ...newExecutionsArray,
              ];
              const entry = Object.assign({}, openTradeEntry, {
                multiExecution: opentrademultiExecutionNew,
                fees: opentradeFeesin - checkedfees,
              });
              const closedTrade = Object.assign({}, openTrade, {
                entry: entry,
              });
              openTradesNOTMatch.push(closedTrade);
              trades = openTradesNOTMatch;
              openTradesPre = openTradesNOTMatch;
              openTradesPre2 = openTradesNOTMatch;
            }
          }
          ids.push(String(tradedata[i]["id"]));
        } else {
        }
      }
    } else if (rhType === "option" || rhType === "optionevent") {
      if (
        (rhType === "option" &&
          Number(tradedata[i]["processed_quantity"]) === 0) ||
        (rhType === "optionevent" &&
          (statePre !== "confirmed" ||
            tradedata[i]["underlying_price"] === null))
      ) {
        // the order was totally cancelled, so skip
        // still add the id anyways
        if (!ids.includes(String(tradedata[i]["id"]))) {
          ids.push(String(tradedata[i]["id"]));
        }
      } else {
        if (!ids.includes(String(tradedata[i]["id"]))) {
          let positionId = tradedata[i]["position"];
          if (
            positionId &&
            positionId.toString().charAt(positionId.length - 1) === "/"
          )
            positionId = positionId.substr(0, positionId.length - 1);

          const positionOptionId = positionId && positionId.split("/").pop();
          const optionPositionResponse =
            rhType === "optionevent" &&
            positionId &&
            (await getRobinhoodOptionPosition(positionOptionId, accessToken));
          const legs = tradedata[i]["legs"];
          const symbol = instrument.toString().replace("-", "");

          const strikePrice =
            optionResponse &&
            optionResponse.gotRobinhoodOptionInstruments.instrument
              .strike_price;
          const expirationDate =
            optionResponse &&
            optionResponse.gotRobinhoodOptionInstruments.instrument
              .expiration_date;

          // Only if its an options event do we initialize leg type
          // Check in the optionevent handler
          let optionType = "";
          let optionSide = "";
          let optionTypeF = "";
          if (rhType === "optionevent") {
            optionType =
              optionResponse &&
              optionResponse.gotRobinhoodOptionInstruments.instrument.type;
            optionSide =
              optionPositionResponse &&
              optionPositionResponse.gotRobinhoodOptionPosition.position.type;
            if (optionType === "call") {
              optionTypeF = optionSide === "long" ? "Long Call" : "Short Call";
            } else {
              optionTypeF = optionSide === "long" ? "Long Put" : "Short Put";
            }
          }
          // ---------------

          const lastVarIn =
            rhType === "option"
              ? legs
              : {
                  strikePrice: strikePrice,
                  expirationDate: expirationDate,
                  legType: optionTypeF,
                };
          let openTradesMatch = openTradesPre.filter((trade) =>
            filterByIDRHOption(trade, false, symbol, rhType, lastVarIn)
          );
          let openTradesNOTMatch = openTradesPre2.filter((trade) =>
            filterByIDRHOption(trade, true, symbol, rhType, lastVarIn)
          );

          //let timestamp = new Date(fullTradearray[i]["Timestamp"]);
          let orderTypeOpenPre = tradedata[i]["opening_strategy"];
          let orderTypeClosePre = tradedata[i]["closing_strategy"];
          let orderTypeOpenPreOTHER = !tradedata[i]["opening_strategy"]
            ? tradedata[i]["closing_strategy"]
            : tradedata[i]["opening_strategy"];
          //let orderTypeOpen = rhOptionsStrategyParser(orderTypeOpenPre);
          let orderTypeOpenOTHER = rhOptionsStrategyParser(
            orderTypeOpenPreOTHER
          );
          //let orderTypeClose = rhOptionsStrategyParser(orderTypeClosePre);
          let orderTypeActual =
            orderTypeOpenOTHER === "Long Call" ||
            orderTypeOpenOTHER === "Long Put" ||
            orderTypeOpenOTHER === "Short Call" ||
            orderTypeOpenOTHER === "Short Put"
              ? orderTypeOpenOTHER
              : "Multi-Leg Strategy";

          //let delta = fullTradearray[i]["Delta"];

          let newExecutionsArrayPre = [];
          rhType === "option" &&
            legs.forEach((leg) => {
              let executions = leg["executions"];
              executions.forEach((execution) => {
                /*               let startDateTime = convertUTCDateToLocalDate(
                new Date(executions["timestamp"])
              );
              let expirationDate = convertUTCDateToLocalDate(
                new Date(leg["expiration_date"])
              ); */
                let startDateTime = new Date(execution["timestamp"]);
                let expirationDatePre = new Date(leg["expiration_date"]);
                const expirationDate = new Date(
                  expirationDatePre.getTime() +
                    expirationDatePre.getTimezoneOffset() * 60000
                ); //this converts the "YYYY-MM-DD" string to the correct date time object
                let entryexecution = {
                  id: uuidv4(),
                  lotSize: fixRound(Number(execution["quantity"])),
                  entryPrice: Number(execution["price"]),
                  startDateTime: startDateTime,
                  expectedEntry: "",
                  strikePrice: Number(leg["strike_price"]),
                  expirationDate: expirationDate,
                  legType: getLegType(leg["side"], leg["option_type"]),
                };
                newExecutionsArrayPre.push(entryexecution);
              });
            });
          const newExecutionsArray = mergeDuplicateExecutions(
            newExecutionsArrayPre
          );
          let startDateTimezz = new Date(getStartDateTime(newExecutionsArray));

          // Need to fix rounding issue of lotSizes by doing :
          // Math.round(Number(tradedata[i]["size"]) * 1e10) / 1e10,
          if (!openTradesMatch.length) {
            changedIds.push(String(tradedata[i]["id"]));

            // if we don't find open trade, open new one
            trade = {
              entryId: String(tradedata[i]["id"]),
              entry: {
                pictures: ["N/A"],
                symbol: {
                  symbols: [symbol],
                  pointValue: 0,
                },
                strategy: "",
                selectedConfidence: "",
                selectedEmotion: "",
                selectedPhysical: "",
                selectedMarket: "",
                selectedTimeframe: "",
                selectedMistake: "",
                selectedPortfolio: portfolio.name,
                selectedPortfolioType: "Options",
                orderType: orderTypeActual,
                orderNumber: "",
                dayOfWeek: weekday[startDateTimezz.getDay()],
                monthOfYear: montharr[startDateTimezz.getMonth()],
                stopLoss: "",
                takeProfit: "",
                profitLoss: 0,
                commissions: 0,
                fees: 0, // Robinhood does not currently have options fees
                maxAdEx: "",
                maxFavEx: "",
                comments: "",
                multiExecution: newExecutionsArray,
                exitExecution: [],
                startDateTime: startDateTimezz,
                endDateTime: "",
                tags: "",
                verifiedLevel: verified, // because it was a fully automated trade,
                idLinks: [],
                hashes: [],
              },
            };
            trades.push(trade);
            openTradesPre = trades;
            openTradesPre2 = trades;
          } else {
            // sometimes there are multiple trades that match the open trades criteria
            // only happens with options
            for (var ie = 0, je = openTradesMatch.length; ie < je; ie++) {
              const openTrade = openTradesMatch[ie];
              changedIds.push(openTrade?.entryId);

              const openTradeEntry = openTrade?.entry;
              const opentrademultiExecution = openTrade?.entry.multiExecution;
              const opentradeexitExecution = openTrade?.entry.exitExecution;

              const opentradeOrderType = openTrade?.entry.orderType;
              const opentradeSymbol = openTrade?.entry.symbol;
              // if orderTypeOpen does not exist, it's strictly a close
              if (orderTypeOpenPre === null || !orderTypeOpenPre) {
                // -------- Add the exit execution, and calculate profit/loss, fees, and start & end date time stuff -------

                // Create new exit execution
                // this step doesn't actually add the execution to the final execution array
                // just a create step
                let exitnewExecutionsArray = [];
                let equityComponentArray = [];
                if (rhType === "optionevent") {
                  // Add expired/exercised/assigned options
                  let exitexpirationDatePre = new Date(
                    tradedata[i]["event_date"]
                  );
                  const exitexpirationDate = new Date(
                    exitexpirationDatePre.getTime() +
                      exitexpirationDatePre.getTimezoneOffset() * 60000
                  ); //this converts the "YYYY-MM-DD" string to the correct date time object

                  let equityComponents = tradedata[i]["equity_components"];
                  equityComponents.forEach((comp) => {
                    equityComponentArray.push({
                      id: uuidv4(),
                      price: comp.price,
                      quantity: comp.quantity,
                      side: comp.side,
                    });
                  });
                  let exitDateTime = new Date(tradedata[i]["created_at"]);
                  let exitexecutionPRE = {
                    id: uuidv4(),
                    exitLotSize: fixRound(Number(tradedata[i]["quantity"])),
                    exitPrice: "",
                    endDateTime: exitDateTime,
                    expectedExit: "",
                    exitstrikePrice: Number(strikePrice),
                    exitexpirationDate: exitexpirationDate,
                    exitlegType: optionTypeF,
                    equityComponents: equityComponentArray,
                    exercised:
                      tradedata[i]["type"] === "expiration"
                        ? "Expired"
                        : tradedata[i]["type"] === "exercise"
                        ? "Exercised"
                        : "Assigned",
                  };
                  exitnewExecutionsArray.push(exitexecutionPRE);
                  let entryLots = 0;
                  opentrademultiExecution.forEach((exec) => {
                    entryLots += Number(exec.lotSize);
                  });
                  let exitLots = 0;
                  exitnewExecutionsArray.forEach((exec) => {
                    exitLots += Number(exec.exitLotSize);
                  });

                  entryLots = fixRound(entryLots); // fix rounding issues
                  exitLots = fixRound(exitLots);
                  if (entryLots !== exitLots) {
                    // if it's an open trade, prompt the user if they want to add the exercise/assign stock
                  } else {
                    // If the position is fully closed
                    // and the exits have the same number of exercise/assignments, calculate the pnl between them
                    // then add to the pnl
                    if (countExerciseAssignIsEqual(exitnewExecutionsArray)) {
                    } else {
                      for (
                        let i = 0, j = exitnewExecutionsArray.length;
                        i < j;
                        i++
                      ) {
                        const orderType = exitnewExecutionsArray[i].exitlegType;
                        const exercisedAssigned =
                          exitnewExecutionsArray[i].exercised;
                        const predate = exitnewExecutionsArray[i].endDateTime;
                        const optionStartDate = new Date(predate);
                        if (exercisedAssigned === "Exercised") {
                          if (orderType === "Long Call") {
                            let optionISODate = optionStartDate;
                            let entryexecution = {
                              id: uuidv4(),
                              lotSize:
                                Number(exitnewExecutionsArray[i].exitLotSize) *
                                100,
                              entryPrice:
                                exitnewExecutionsArray[i].exitstrikePrice,
                              startDateTime: optionISODate,
                              expectedEntry: "",
                              strikePrice: "",
                              expirationDate: "",
                              legType: "",
                            };
                            const newdata = {
                              entryId: uuidv4(),
                              entry: {
                                pictures: ["N/A"],
                                symbol: {
                                  symbols: [symbol],
                                  pointValue: "",
                                },
                                strategy: "",
                                selectedConfidence: "",
                                selectedEmotion: "",
                                selectedPhysical: "",
                                selectedMarket: "",
                                selectedTimeframe: "",
                                selectedMistake: "",
                                selectedPortfolio: portfolio.name,
                                selectedPortfolioType: "Exercise Stock",
                                orderType: "Long",
                                orderNumber: "",
                                dayOfWeek: weekday[optionStartDate.getDay()],
                                monthOfYear:
                                  montharr[optionStartDate.getMonth()],
                                stopLoss: "",
                                takeProfit: "",
                                profitLoss: "",
                                commissions: 0,
                                fees: 0,
                                maxAdEx: "",
                                maxFavEx: "",
                                comments: "",
                                multiExecution: [entryexecution],
                                exitExecution: [],
                                startDateTime: optionISODate,
                                endDateTime: "",
                                tags: [],
                                verifiedLevel: verified,
                                idLinks: [],
                                hashes: [],
                              },
                            };
                            openTradesNOTMatch.push(newdata);
                          } else if (orderType === "Long Put") {
                            let optionISODate = optionStartDate;
                            let entryexecution = {
                              id: uuidv4(),
                              lotSize:
                                Number(exitnewExecutionsArray[i].exitLotSize) *
                                100,
                              entryPrice:
                                exitnewExecutionsArray[i].exitstrikePrice,
                              startDateTime: optionISODate,
                              expectedEntry: "",
                              strikePrice: "",
                              expirationDate: "",
                              legType: "",
                            };
                            const newdata = {
                              entryId: uuidv4(),
                              entry: {
                                pictures: ["N/A"],
                                symbol: {
                                  symbols: [symbol],
                                  pointValue: "",
                                },
                                strategy: "",
                                selectedConfidence: "",
                                selectedEmotion: "",
                                selectedPhysical: "",
                                selectedMarket: "",
                                selectedTimeframe: "",
                                selectedMistake: "",
                                selectedPortfolio: portfolio.name,
                                selectedPortfolioType: "Exercise Stock",
                                orderType: "Short",
                                orderNumber: "",
                                dayOfWeek: weekday[optionStartDate.getDay()],
                                monthOfYear:
                                  montharr[optionStartDate.getMonth()],
                                stopLoss: "",
                                takeProfit: "",
                                profitLoss: "",
                                commissions: 0,
                                fees: 0,
                                maxAdEx: "",
                                maxFavEx: "",
                                comments: "",
                                multiExecution: [entryexecution],
                                exitExecution: [],
                                startDateTime: optionISODate,
                                endDateTime: "",
                                tags: [],
                                verifiedLevel: verified,
                                idLinks: [],
                                hashes: [],
                              },
                            };
                            openTradesNOTMatch.push(newdata);
                          }
                        } else if (exercisedAssigned === "Assigned") {
                          if (orderType === "Short Call") {
                            let optionISODate = optionStartDate;
                            let entryexecution = {
                              id: uuidv4(),
                              lotSize:
                                Number(exitnewExecutionsArray[i].exitLotSize) *
                                100,
                              entryPrice:
                                exitnewExecutionsArray[i].exitstrikePrice,
                              startDateTime: optionISODate,
                              expectedEntry: "",
                              strikePrice: "",
                              expirationDate: "",
                              legType: "",
                            };
                            const newdata = {
                              entryId: uuidv4(),
                              entry: {
                                pictures: ["N/A"],
                                symbol: {
                                  symbols: [symbol],
                                  pointValue: "",
                                },
                                strategy: "",
                                selectedConfidence: "",
                                selectedEmotion: "",
                                selectedPhysical: "",
                                selectedMarket: "",
                                selectedTimeframe: "",
                                selectedMistake: "",
                                selectedPortfolio: portfolio.name,
                                selectedPortfolioType: "Assign Stock",
                                orderType: "Short",
                                orderNumber: "",
                                dayOfWeek: weekday[optionStartDate.getDay()],
                                monthOfYear:
                                  montharr[optionStartDate.getMonth()],
                                stopLoss: "",
                                takeProfit: "",
                                profitLoss: "",
                                commissions: 0,
                                fees: 0,
                                maxAdEx: "",
                                maxFavEx: "",
                                comments: "",
                                multiExecution: [entryexecution],
                                exitExecution: [],
                                startDateTime: optionISODate,
                                endDateTime: "",
                                tags: [],
                                verifiedLevel: verified,
                                idLinks: [],
                                hashes: [],
                              },
                            };
                            openTradesNOTMatch.push(newdata);
                          } else if (orderType === "Short Put") {
                            let optionISODate = optionStartDate;
                            let entryexecution = {
                              id: uuidv4(),
                              lotSize:
                                Number(exitnewExecutionsArray[i].exitLotSize) *
                                100,
                              entryPrice:
                                exitnewExecutionsArray[i].exitstrikePrice,
                              startDateTime: optionISODate,
                              expectedEntry: "",
                              strikePrice: "",
                              expirationDate: "",
                              legType: "",
                            };
                            const newdata = {
                              entryId: uuidv4(),
                              entry: {
                                pictures: ["N/A"],
                                symbol: {
                                  symbols: [symbol],
                                  pointValue: "",
                                },
                                strategy: "",
                                selectedConfidence: "",
                                selectedEmotion: "",
                                selectedPhysical: "",
                                selectedMarket: "",
                                selectedTimeframe: "",
                                selectedMistake: "",
                                selectedPortfolio: portfolio.name,
                                selectedPortfolioType: "Assign Stock",
                                orderType: "Long",
                                orderNumber: "",
                                dayOfWeek: weekday[optionStartDate.getDay()],
                                monthOfYear:
                                  montharr[optionStartDate.getMonth()],
                                stopLoss: "",
                                takeProfit: "",
                                profitLoss: "",
                                commissions: 0,
                                fees: 0,
                                maxAdEx: "",
                                maxFavEx: "",
                                comments: "",
                                multiExecution: [entryexecution],
                                exitExecution: [],
                                startDateTime: optionISODate,
                                endDateTime: endDateTimez,
                                tags: [],
                                verifiedLevel: verified,
                                idLinks: [],
                                hashes: [],
                              },
                            };
                            openTradesNOTMatch.push(newdata);
                          }
                        }
                      }
                    }
                  }
                } else {
                  // Look for option with same strike, leg type, and expiration date

                  let exitlegsUnfiltered = tradedata[i]["legs"];

                  // Filter the legs so that we are only dealing with ones
                  // that exist in the current openTradesMatch[ie]
                  let exitlegs = exitlegsUnfiltered.filter((exec) =>
                    filterByStrikeandExpiration(exec, opentrademultiExecution)
                  );
                  exitlegs.forEach((leg) => {
                    let exitexecutions = leg["executions"];
                    exitexecutions.forEach((execution) => {
                      var endDateTimePRE = new Date(execution["timestamp"]);
                      let exitexpirationDatePre = new Date(
                        leg["expiration_date"]
                      );
                      const exitexpirationDate = new Date(
                        exitexpirationDatePre.getTime() +
                          exitexpirationDatePre.getTimezoneOffset() * 60000
                      ); //this converts the "YYYY-MM-DD" string to the correct date time object

                      let exitexecutionPRE = {
                        id: uuidv4(),
                        exitLotSize: fixRound(Number(execution["quantity"])),
                        exitPrice: Number(execution["price"]),
                        endDateTime: endDateTimePRE,
                        expectedExit: "",
                        exitstrikePrice: Number(leg["strike_price"]),
                        exitexpirationDate: exitexpirationDate,
                        exitlegType: getLegType(
                          leg["side"] === "buy" ? "sell" : "buy",
                          leg["option_type"]
                        ),
                        equityComponents: [],
                        exercised: "",
                      };
                      exitnewExecutionsArray.push(exitexecutionPRE);
                    });
                  });
                }
                const newexitExecutionsArray = mergeDuplicateExitExecutions(
                  exitnewExecutionsArray
                );
                const newExitExecutionPRE = [
                  ...opentradeexitExecution,
                  ...newexitExecutionsArray,
                ];
                let startDateTimez = new Date(
                  getStartDateTime(opentrademultiExecution)
                );
                let endDateTimez = new Date(
                  getEndDateTime(newExitExecutionPRE)
                );

                const entry = Object.assign({}, openTradeEntry, {
                  exitExecution: newExitExecutionPRE,
                  profitLoss: Number(
                    profitLossCalculation(
                      opentrademultiExecution,
                      newExitExecutionPRE,
                      opentradeOrderType,
                      "Options",
                      opentradeSymbol.pointValue,
                      false,
                      pnlmethod
                    )
                  ),
                  endDateTime: endDateTimez,
                  startDateTime: startDateTimez,
                });
                const closedTrade = Object.assign({}, openTrade, {
                  entry: entry,
                });
                openTradesNOTMatch.push(closedTrade);
                trades = openTradesNOTMatch;
                openTradesPre = openTradesNOTMatch;
                openTradesPre2 = openTradesNOTMatch;

                // calculate absolute start date time

                // -------------------
              } else {
                let expirationDatezy = "";

                // get latest expiration date
                for (
                  let ih = 0, jh = opentrademultiExecution.length;
                  ih < jh;
                  ih++
                ) {
                  if (ih === 0) {
                    expirationDatezy =
                      opentrademultiExecution[ih].expirationDate;
                  } else {
                    expirationDatezy =
                      opentrademultiExecution[ih].expirationDate >
                      expirationDatezy
                        ? opentrademultiExecution[ih].expirationDate
                        : expirationDatezy;
                  }
                }
                if (
                  orderTypeClosePre &&
                  orderTypeOpenPre &&
                  orderTypeClosePre === orderTypeOpenPre
                ) {
                  // Option is a rolled option
                  let legs = tradedata[i]["legs"];
                  let entryExecutionsPre = [];
                  let exitExecutionsPre = [...opentradeexitExecution];

                  legs.forEach((leg) => {
                    let executions = leg["executions"];
                    executions.forEach((execution) => {
                      let dateTime = new Date(execution["timestamp"]);
                      let expirationDatePre = new Date(leg["expiration_date"]);
                      const expirationDate = new Date(
                        expirationDatePre.getTime() +
                          expirationDatePre.getTimezoneOffset() * 60000
                      ); //this converts the "YYYY-MM-DD" string to the correct date time object

                      if (leg["position_effect"] === "open") {
                        let entryexecution = {
                          id: uuidv4(),
                          lotSize: fixRound(Number(execution["quantity"])),
                          entryPrice: Number(execution["price"]),
                          startDateTime: dateTime,
                          expectedEntry: "",
                          strikePrice: Number(leg["strike_price"]),
                          expirationDate: expirationDate,
                          legType: getLegType(leg["side"], leg["option_type"]),
                        };
                        entryExecutionsPre.push(entryexecution);
                      } else {
                        let exitexecution = {
                          id: uuidv4(),
                          exitLotSize: fixRound(Number(execution["quantity"])),
                          exitPrice: Number(execution["price"]),
                          endDateTime: dateTime,
                          expectedExit: "",
                          exitstrikePrice: Number(leg["strike_price"]),
                          exitexpirationDate: expirationDate,
                          exitlegType: getLegType(
                            leg["side"] === "buy" ? "sell" : "buy",
                            leg["option_type"]
                          ),
                          equityComponents: [],
                          exercised: "",
                        };
                        exitExecutionsPre.push(exitexecution);
                      }
                    });
                  });
                  const entryExecutions =
                    mergeDuplicateExecutions(entryExecutionsPre);
                  const exitExecutions =
                    mergeDuplicateExitExecutions(exitExecutionsPre);
                  let endDateTimezyz = new Date(getEndDateTime(exitExecutions));
                  let startDateTimezyz = new Date(
                    getStartDateTime(entryExecutions)
                  );
                  // Add closing executions from the rolled option order's opening executions
                  const entry = Object.assign({}, openTradeEntry, {
                    multiExecution: opentrademultiExecution,
                    exitExecution: exitExecutions,
                    endDateTime: endDateTimezyz,
                    profitLoss: Number(
                      profitLossCalculation(
                        opentrademultiExecution,
                        exitExecutions,
                        opentradeOrderType,
                        "Options",
                        opentradeSymbol.pointValue,
                        false,
                        pnlmethod
                      )
                    ),
                  });
                  const rolledTrade = Object.assign({}, openTrade, {
                    entry: entry,
                  });
                  // Now add a new trade
                  let orderTypeActualx = "";
                  if (entryExecutions.length === 1) {
                    orderTypeActualx = entryExecutions[0].legType;
                  } else {
                    orderTypeActualx = "Multi-Leg Strategy";
                  }
                  trade = {
                    entryId: String(tradedata[i]["id"]),
                    entry: {
                      pictures: ["N/A"],
                      symbol: {
                        symbols: [symbol],
                        pointValue: 0,
                      },
                      strategy: "",
                      selectedConfidence: "",
                      selectedEmotion: "",
                      selectedPhysical: "",
                      selectedMarket: "",
                      selectedTimeframe: "",
                      selectedMistake: "",
                      selectedPortfolio: portfolio.name,
                      selectedPortfolioType: "Options",
                      orderType: orderTypeActualx,
                      orderNumber: "",
                      dayOfWeek: weekday[startDateTimezyz.getDay()],
                      monthOfYear: montharr[startDateTimezyz.getMonth()],
                      stopLoss: "",
                      takeProfit: "",
                      profitLoss: 0,
                      commissions: 0,
                      fees: 0, // Ronbihood does not currently have options fees
                      maxAdEx: "",
                      maxFavEx: "",
                      comments: "",
                      multiExecution: entryExecutions,
                      exitExecution: [],
                      startDateTime: startDateTimezyz,
                      endDateTime: "",
                      tags: "",
                      verifiedLevel: verified, // because it was a fully automated trade,
                      idLinks: [],
                      hashes: [],
                    },
                  };
                  openTradesNOTMatch.push(rolledTrade, trade);
                  trades = openTradesNOTMatch;
                  openTradesPre = openTradesNOTMatch;
                  openTradesPre2 = openTradesNOTMatch;
                } else {
                  // Add new opening executions
                  const opentrademultiExecutionNew = [
                    ...opentrademultiExecution,
                    ...newExecutionsArray,
                  ];
                  const entry = Object.assign({}, openTradeEntry, {
                    multiExecution: opentrademultiExecutionNew,
                  });
                  const closedTrade = Object.assign({}, openTrade, {
                    entry: entry,
                  });
                  openTradesNOTMatch.push(closedTrade);
                  trades = openTradesNOTMatch;
                  openTradesPre = openTradesNOTMatch;
                  openTradesPre2 = openTradesNOTMatch;
                }
              }
            }
          }
          ids.push(String(tradedata[i]["id"]));
        } else {
        }
      }
    }
  }

  return { trades: trades, ids: ids, changedIds: changedIds };
};
export default parseRobinhoodOrders;
