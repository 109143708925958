import initialSettingsState from "./initialStates";
// ****** Make sure to also add the setting in
// intialStates.js when adding a new setting!!
export const createSettings = (settingsRef, propstochange) => {
  const settingsProps = [
    "currencySymbol",
    "historyColumnDisplay",
    "colorTheme",
    "calculationType",
    "performanceMetricCalculationType",
    "performanceMetricCalculationAriGeo",
    "performanceMetricGraphs",
    "linkedBrokerInfo",
    "dateFilterBy",
    "comparisons",
    "feesStructure",
    "filters",
    "savefilters",
    "sidepanelprops",
    "drawdownCalculationType",
    "startingBalance",
    "drawdownToggle",
    "dashboardElements",
    "comparisonObjs",
    "comparerKPIbundles",
    "demoDataLoaded",
    "dashboardFrequencyToggle",
    "pnlcalculationmethod",
    "calendarNotes",
    "streakCalcToggle",
    "verified",
    "verifiedDate",
    "portfolioColor",
    "quantumConversations",
    // <= NEW SETTING PROPS GO HERE
  ];
  let settingsData = {};
  settingsProps.forEach((prop) => {
    if (propstochange.hasOwnProperty(prop)) {
      settingsData[prop] = propstochange[prop];
    } else {
      settingsData[prop] =
        !settingsRef[prop] || settingsRef[prop] === undefined
          ? initialSettingsState[prop]
          : settingsRef[prop];
      // need to also do a custom one for dashboard settings
    }
  });
  return settingsData;
};
export default createSettings;
