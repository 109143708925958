import React, { useState } from "react";

const MFAInputBox = ({
  connectionsArray,
  brokerNameMapper,
  mfaCodeError,
  onCodesChange,
}) => {
  const [mfaCodes, setMfaCodes] = useState(() => {
    // Initialize state with empty values or fetch from an initial data source
    const initialState = {};
    connectionsArray.forEach((connection) => {
      initialState[connection.broker] = ""; // Initialize with an empty string
    });
    return initialState;
  });

  const handleInputChange = (broker, value) => {
    setMfaCodes((prevCodes) => {
      const updatedCodes = { ...prevCodes, [broker]: value };
      onCodesChange(updatedCodes); // Call parent callback
      return updatedCodes;
    });
  };

  return (
    <div className="mfcode-text-input-boxes-superwrapper">
      {connectionsArray.map((connection, index) => {
        const valueIn = mfaCodes[connection.broker] || ""; // Get value from state
        return (
          connection.broker === "robinhood" && ( // and other brokers with mfa codes!!
            <div className="mfcode-text-input-boxes-wrapper" key={index}>
              {brokerNameMapper(connection.broker) + " code"}
              {mfaCodeError && (
                <span className="mandatory4">{"Invalid Code"}</span>
              )}
              <input
                id="logbook-text-input-boxes-stopLoss"
                name="stopLoss"
                style={{ paddingLeft: "10px", marginTop: 0 }}
                value={valueIn}
                onChange={(e) =>
                  handleInputChange(connection.broker, e.target.value)
                }
                className="logbook-text-input-boxes"
                autoComplete="off"
                type="password"
                pattern="[+-]?([0-9]*[.])?[0-9]+"
              />
            </div>
          )
        );
      })}
    </div>
  );
};

export default MFAInputBox;
