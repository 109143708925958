import React, {
  useEffect,
  useState,
  useRef,
  createContext,
  useCallback,
  useContext,
} from "react";
import { Background } from "../src/components/PageTemplate/TemplateDivs.js";
import blank from "../src/images/blank2.png";
import { Navbar, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, useLocation, useHistory } from "react-router-dom";
import logo from "../src/images/TradeFuse_trans.png";
import { Auth0Context } from "@auth0/auth0-react";
import $ from "jquery";
import fusezap from "./images/FuseZap.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import createStripeSession from "./utils/stripe/createStripeSession";
import newcreateStripeSession from "./utils/stripe/newcreateStripeSession";
import getFormattedDateTime from "./functions/getDateTimeStripeFormat";
import PopoverStickOnHover from "./components/PopoverStickOnHover";
import { proddev } from "./PROD-DEV-TOGGLE.js";
import getCustomer from "./utils/stripe/getStripeCustomer";

import store from "./store";
import {
  faQuestionCircle,
  faPowerOff,
  faCheckCircle,
  faCaretLeft,
  faCaretRight,
} from "@fortawesome/free-solid-svg-icons";
import putUserData from "./utils/AWS/putS3UserObject";
import { initialSettingsStateNew } from "./pages/Settings/initialStates";
import createDirectStripeSession from "./utils/stripe/createDirectSession";
import { setLoaderState /* getFilteredList */ } from "./actions/actionCreators";

function getQueryParam(url, param) {
  const queryParams = new URLSearchParams(url.search);
  return queryParams.get(param);
}
export const Paywall = (props) => {
  const auth0Instance = useContext(Auth0Context);
  const [paycardState, setpaycardState] = useState("Monthly");
  const [masterAutoImportCount, setmasterAutoImportCount] = useState(2);
  const [ultimateAutoImportCount, setultimateAutoImportCount] = useState(6);
  const customer = props.customer;
  const payWallState = props.paywallState;
  const customerId = customer?.stripeId;
  const history = useHistory();

  // promo banner toggler
  const promoBannerOn = false; //false if off.
  const promoColor = "#00B32C";

  useEffect(() => {
    $(".profile-icon").click(function () {
      const dropdown = $(".logout-dropdown");
      if (dropdown.css("display") === "flex") {
        dropdown.css({
          display: "none",
        });
      } else {
        dropdown.css({
          display: "flex",
        });
      }
    });
    $(document).mouseup(function (e) {
      let accountv = $(".logout-dropdown");
      let accountv2 = $(".profile-icon");
      if (
        !accountv.is(e.target) &&
        accountv.has(e.target).length === 0 &&
        accountv2.has(e.target).length === 0
      ) {
        if (
          e.target.id !== "addassetClassButton" &&
          e.target.id !== "noclick"
        ) {
          accountv.css({ display: "none" });
        }
      }
    });

    // Set the end date for the promotion (e.g., November 30th, 2024)
    const endDate = new Date("2024-12-25T23:59:59").getTime();

    function updateCountdown() {
      const now = new Date().getTime();
      const timeLeft = endDate - now;
      // Calculate days, hours, minutes, and seconds left
      const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

      // Display the countdown in the <span> with id="countdown"
      const countdownElement = document.getElementById("countdown");
      if (countdownElement) {
        const countDownValue = `${days}d ${hours}h ${minutes}m ${seconds}s`;
        countdownElement.innerHTML = countDownValue;
      }

      // If the countdown is over, display a message
      if (timeLeft < 0) {
        document.addEventListener("DOMContentLoaded", () => {
          document.getElementById("countdown").innerHTML = "Promo Ended";
        });
      }
    }

    // Update countdown every second
    setInterval(updateCountdown, 1000);
  }, []);
  const user = props.user;
  const userAvatar = user?.picture;
  function get_ga_clientid() {
    var cookie = {};
    document.cookie.split(";").forEach(function (el) {
      var splitCookie = el.split("=");
      var key = splitCookie[0].trim();
      var value = splitCookie[1];
      cookie[key] = value;
    });
    return cookie["_ga"]?.substring(6);
  }

  const clientId = get_ga_clientid();

  const runInitialStripeFunction = async (priceId) => {
    {
      store.dispatch(setLoaderState({ loading: true }));
      const currentURL = window.location;

      const customerEmail = auth0Instance.user.email;
      const customerName = auth0Instance.user.name;
      const auth0Id = auth0Instance.user.sub;

      // Function to append UTM parameters to a URL and prepare it for additional parameters
      function appendUtmParameters(baseUrl, utmSource, utmCampaign) {
        let url = new URL(baseUrl);
        if (utmSource) url.searchParams.append("utm_source", utmSource);
        if (utmCampaign) url.searchParams.append("utm_campaign", utmCampaign);

        // Append an "&" at the end if there are already parameters in the URL
        let finalUrl = url.href;
        if (url.search) finalUrl += "&";

        return finalUrl;
      }

      const currentUrl = new URL(window.location.href);

      // Extract the utm_source and utm_campaign parameters
      /*       const utmSource = getQueryParam(currentUrl, "utm_source");
      const utmCampaign = getQueryParam(currentUrl, "utm_campaign"); */
      const getCookie = (name) =>
        decodeURIComponent(
          document.cookie
            .split("; ")
            .find((row) => row.startsWith(name + "="))
            ?.split("=")[1] || ""
        );

      const utmSource = getCookie("tradefuseutmsource");
      const utmCampaign = getCookie("tradefuseutmcampaign");
      // Append UTM parameters to the base URLs
      const successUrlWithUtm = appendUtmParameters(
        currentUrl.origin + "/Dashboard",
        utmSource,
        utmCampaign
      );
      const cancelUrlWithUtm = appendUtmParameters(
        currentUrl.href,
        utmSource,
        utmCampaign
      );

      // Use these URLs for Stripe
      const success_url = successUrlWithUtm;
      const cancel_url = cancelUrlWithUtm;

      async function getInfo() {
        try {
          // get user agent
          const userAgent = navigator.userAgent;

          // get IP address
          const response = await fetch("https://api.ipify.org/?format=json");
          const data = await response.json();
          const ipAddress = data.ip;
          return { ipAddress: ipAddress, userAgent: userAgent };
          // use the values of userAgent and ipAddress here
          // ...
        } catch (error) {
          console.error(error);
        }
      }
      const dateTimeString = String(getFormattedDateTime());
      const userAgentIpAddy = await getInfo();
      const metadata = {
        "Last Login": dateTimeString,
        "Last Session": dateTimeString,
        Channel: utmSource || "",
        IPv4Address: userAgentIpAddy.ipAddress || "",
        UserAgent: userAgentIpAddy.userAgent || "",
        Campaign: utmCampaign || "",
        auth0id: auth0Id,
        proddev: proddev,
        clientId: clientId,
      };
      const rewardful = window.Rewardful;
      const referral = rewardful?.referral;
      const session = await newcreateStripeSession(
        priceId,
        success_url,
        cancel_url,
        customerEmail,
        customerName,
        metadata,
        referral,
        history
      );
      store.dispatch(setLoaderState({ loading: false }));
      window.location.replace(session.url);
    }
  };

  const runExpiredStripeFunction = async (priceId) => {
    {
      store.dispatch(setLoaderState({ loading: true }));
      const currentURL = window.location;
      const success_url = currentURL.origin + "/Dashboard";
      const cancel_url = currentURL.href;
      const customer =
        customerId &&
        customerId !== "" &&
        (await getCustomer(customerId, history)); // stripe customer obj
      const customermetadata = customer.metadata;
      const logins =
        customermetadata && customermetadata.Logins
          ? customermetadata.Logins
          : 0;
      const sessions =
        customermetadata && customermetadata.Sessions
          ? customermetadata.Sessions
          : 0;
      const channel = customermetadata && customermetadata.Channel;
      const ipv4 = customermetadata && customermetadata.IPv4Address;
      const useragent = customermetadata && customermetadata.UserAgent;
      const campaign = customermetadata && customermetadata.Campaign;
      const auth0id = customermetadata && customermetadata.auth0id;
      const storage = customermetadata && customermetadata["Storage Used"];
      const sharedtrades =
        customermetadata && customermetadata["Shared Trades"];
      const totalTrades = customermetadata && customermetadata.totalTrades;

      const dateTimeString = String(getFormattedDateTime());

      const metadata = {
        Logins: Number(logins),
        "Last Login": dateTimeString,
        "Last Session": dateTimeString,
        Trades: totalTrades,
        "Shared Trades": sharedtrades,
        Sessions: Number(sessions),
        "Storage Used": storage,
        Channel: channel,
        IPv4Address: ipv4,
        UserAgent: useragent,
        Campaign: campaign,
        auth0id: auth0id,
        proddev: proddev,
        clientId: clientId,
      };
      const session = await createDirectStripeSession(
        customerId,
        priceId,
        success_url,
        cancel_url,
        history,
        metadata
      );
      store.dispatch(setLoaderState({ loading: false }));
      window.location.replace(session.url);
    }
  };

  const proCountDisplay =
    paycardState === "Monthly" ? (
      promoBannerOn ? (
/*         <p>
          <s>$19</s>
          <strong style={{ margin: "0px 0px 0px 16px", color: promoColor }}>
            $10
          </strong>
        </p> */"$19"
      ) : (
        "$19"
      )
    ) : paycardState === "3-Month" ? (
      promoBannerOn ? (
/*         <p>
          <s>$17</s>
          <strong style={{ margin: "0px 0px 0px 16px", color: promoColor }}>
            $9
          </strong>
        </p> */"$17"
      ) : (
        "$17"
      )
    ) : promoBannerOn ? (
/*       <p>
        <s>$14</s>
        <strong style={{ margin: "0px 0px 0px 16px", color: promoColor }}>
          $7
        </strong>
      </p> */"$14"
    ) : (
      "$14"
    );

  const mastercountdisplay =
    paycardState === "Monthly" ? (
      promoBannerOn ? (
/*         <p>
          <s>$39</s>
          <strong style={{ margin: "0px 0px 0px 16px", color: promoColor }}>
            $20
          </strong>
        </p> */"$39"
      ) : (
        "$39"
      )
    ) : paycardState === "3-Month" ? (
      promoBannerOn ? (
/*         <p>
          <s>$35</s>
          <strong style={{ margin: "0px 0px 0px 16px", color: promoColor }}>
            $18
          </strong>
        </p> */"$35"
      ) : (
        "$35"
      )
    ) : paycardState === "Yearly" ? (
      promoBannerOn ? (
/*         <p>
          <s>$29</s>
          <strong style={{ margin: "0px 0px 0px 16px", color: promoColor }}>
            $15
          </strong>
        </p> */"$29"
      ) : (
        "$29"
      )
    ) : (
      "$0"
    );

  const ultimatepricedisplay =
    paycardState === "Monthly" ? (
      promoBannerOn ? (
/*         <p>
          <s>$69</s>
          <strong style={{ margin: "0px 0px 0px 16px", color: promoColor }}>
            $35
          </strong>
        </p> */"$69"
      ) : (
        "$69"
      )
    ) : paycardState === "3-Month" ? (
      promoBannerOn ? (
/*         <p>
          <s>$62</s>
          <strong style={{ margin: "0px 0px 0px 16px", color: promoColor }}>
            $31
          </strong>
        </p> */"$62"
      ) : (
        "$62"
      )
    ) : paycardState === "Yearly" ? (
      promoBannerOn ? (
/*         <p>
          <s>$51</s>
          <strong style={{ margin: "0px 0px 0px 16px", color: promoColor }}>
            $26
          </strong>
        </p> */"$51"
      ) : (
        "$51"
      )
    ) : (
      "$0"
    );

  const proPriceId =
    paycardState === "Monthly"
      ? "price_1NrZ9gJEnF6qjMZiGZBT2yOK"
      : paycardState === "3-Month"
      ? "price_1NrZ9XJEnF6qjMZilJdClA9a"
      : "price_1NrZ9HJEnF6qjMZicUdwt4Az";

  const masterPriceId =
    paycardState === "Monthly"
      ? "price_1NrZ3SJEnF6qjMZifdKYlDFo"
      : paycardState === "3-Month"
      ? "price_1NrZ3BJEnF6qjMZiC0mbaEzN"
      : paycardState === "Yearly"
      ? "price_1NrZ2cJEnF6qjMZiREJtN0bg"
      : "";

  const ultimatePriceId =
    paycardState === "Monthly"
      ? "price_1NrcgQJEnF6qjMZiyuU53gwV"
      : paycardState === "3-Month"
      ? "price_1NrcgBJEnF6qjMZiHHyqL7zj"
      : paycardState === "Yearly"
      ? "price_1NrcfhJEnF6qjMZiefJh6cra"
      : "";

  const promoBanner = () => {
    return promoBannerOn ? (
      <div className="promo-banner">
        <p
          style={{
            fontSize: "18px",
            color: "#E40010",
            fontWeight: "bold",
          }}
        >
          🎄 12 Days Of Christmas Sale! 🎁 Enjoy{" "}
          <span style={{ color: "#1FD537" }}>50% OFF Forever</span> with Code:{" "}
          <strong
            style={{
              background: "linear-gradient(45deg, #FFFFFF, #00BFFF)", // Diagonal gradient from white to icy blue
              WebkitBackgroundClip: "text", // Clips the background to the text
              WebkitTextFillColor: "transparent", // Makes the text fill transparent to show the background
              display: "inline-block", // Ensures the gradient is applied correctly
            }}
          >
            SNOWY40
          </strong>{" "}
          🎄
        </p>
        <p>
          ⌛ Time left:{" "}
          <span
            id="countdown"
            style={{
              background: "linear-gradient(45deg, #FFFFFF, #00BFFF)", // Diagonal gradient from white to icy blue
              WebkitBackgroundClip: "text", // Clips the background to the text
              WebkitTextFillColor: "transparent", // Makes the text fill transparent to show the background
              display: "inline-block", // Ensures the gradient is applied correctly
            }}
          ></span>
        </p>
      </div>
    ) : (
      ""
    );
  };
  return (
    <div className="paywallcontainer">
      <Background />
      <div className="navbar2">
        <Link to="./">
          <Image
            src={logo}
            className="tradersIQlogo"
            style={{
              width: 200,
              marginLeft: 8,
              marginTop: 10,
            }}
            alt="Traders IQ Home"
          ></Image>
        </Link>
        <a href={"https://tradefuse.app/support"} id="payqallquesion-icon-icon">
          {" "}
          <FontAwesomeIcon icon={faQuestionCircle} />
        </a>
        <div className="profile-icon" id={"profile-icon"}>
          {userAvatar === "" || userAvatar === null || !userAvatar ? (
            <img
              alt=""
              src={blank}
              style={{
                overflow: "hidden",
                maxWidth: "100%",
                maxHeight: "100%",
                borderRadius: 100,
                zIndex: 1000000000,
              }}
            />
          ) : (
            <img
              alt=""
              src={userAvatar}
              style={{
                overflow: "hidden",
                maxWidth: "100%",
                maxHeight: "100%",
                borderRadius: 100,
                zIndex: 1000000000,
              }}
            />
          )}
        </div>
        <div className="logout-dropdown">
          <button
            onClick={async () => {
              //window.location.reload();
              localStorage.clear();
              auth0Instance.logout({
                returnTo: window.location.origin,
              });
            }}
            className="logout-link"
          >
            <div className="small-icons-OC5">
              <FontAwesomeIcon icon={faPowerOff} />
            </div>
            Log Out
          </button>
        </div>
      </div>
      <div className="newpricingtablecardsuperwrapper">
        <div
          className={
            promoBannerOn
              ? "newpricingtablecardwrapperON"
              : "newpricingtablecardwrapper"
          }
        >
          <div className="newpricingtableHeader">
            {promoBanner()}

            <div className="newpricingtableHeader1">
              <div className="newpricingtableHeader1inner">
                TRADEFUSE PRICING DETAILS
              </div>
            </div>
            {payWallState === "expired" ? (
              <span className="newpricingtableHeader2">
                Your trial may be over, but your&nbsp;
                <span className="newpricingtableHeader2inner">
                  journal entries
                </span>
                &nbsp;&&nbsp;
                <span className="newpricingtableHeader2inner">
                  trading metrics
                </span>
                &nbsp;are still accessible.
              </span>
            ) : (
              <span className="newpricingtableHeader2">
                Try&nbsp;
                <span className="newpricingtableHeader2inner">
                  automating&nbsp;
                </span>{" "}
                your journaling & access&nbsp;
                <span className="newpricingtableHeader2inner">AI-powered</span>
                &nbsp;insights with a 5-day free trial.{" "}
              </span>
            )}
            {payWallState === "expired" ? (
              <div className="newpricingtableHeader3">
                Regain access to our next-generation trading journal, still
                personalized for you. All of your portfolios, journal entries,
                settings, trading metrics, etc., everything is still available
                for you after upgrading.
              </div>
            ) : (
              <div className="newpricingtableHeader3">
                Gain immediate access to our next-generation trading journal.
                Capable of automatically importing trades in real-time. Insights
                & customizable reports, powered by AI.
              </div>
            )}
            {/*             <div className="newpricingtableUseTheFuse">
              <div className="newpricingtableUseTheFuseinner">Use the </div>
              &nbsp;
              <span className="newpricingtableUseTheFuseinner2">Fuse</span>
              <img
                className="newpricingtableUseTheFuseinnerzap"
                alt="0"
                src={fusezap}
              />
            </div> */}
            {payWallState === "expired" ? (
              <div className="newpricingtablechooseplanheader">
                Choose the plan right for you
              </div>
            ) : (
              <div className="newpricingtablechooseplanheader">
                Choose the plan right for you
              </div>
            )}
          </div>
          <div className="newpricingtableToggleComponent">
            <div className="newpricingtableToggleinner">
              <div
                className={
                  paycardState === "Monthly"
                    ? "newpricingtableToggleinnersubhighlighted"
                    : "newpricingtableToggleinnersub"
                }
                onClick={() => setpaycardState("Monthly")}
              >
                Monthly
              </div>
              <div
                className={
                  paycardState === "3-Month"
                    ? "newpricingtableToggleinnersubhighlighted"
                    : "newpricingtableToggleinnersub"
                }
                onClick={() => setpaycardState("3-Month")}
              >
                3-Month
                <div className="newpricingtableToggleinnersub2">
                  <div className="newpricingtableToggleinnersubsparkle">✨</div>
                  <div className="newpricingtableToggleinnersub22">
                    Save 10%
                  </div>
                </div>
              </div>
              <div
                className={
                  paycardState === "Yearly"
                    ? "newpricingtableToggleinnersubhighlighted"
                    : "newpricingtableToggleinnersub"
                }
                onClick={() => setpaycardState("Yearly")}
              >
                Yearly
                <div className="newpricingtableToggleinnersub2">
                  <div className="newpricingtableToggleinnersubsparkle">✨</div>
                  <div className="newpricingtableToggleinnersub22">
                    Save 25%
                  </div>
                </div>{" "}
              </div>
            </div>
          </div>
          <div className="newpricingtablecardWrapperMain">
            <div className="newpricingtablecardCard">
              <div className="procardheader">Pro Plan</div>
              <div className="pricewrapperpromobanner">
                <div className="newpricingPricetext">{proCountDisplay}</div>
                <div
                  className={
                    promoBannerOn
                      ? /* "newpricingPricepermonthtextON" */"newpricingPricepermonthtext"
                      : "newpricingPricepermonthtext"
                  }
                >
                  /MONTH
                </div>
              </div>
              <div className="newpricingPricepermonthsubtext">
                Essential tools for traders
              </div>
              <div className="newpricingPricecardfeaturepro">
                <div className="newpricingPricecardfeature">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  &nbsp;&nbsp; 2 portfolios
                </div>
                <div className="newpricingPricecardfeature">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  &nbsp;&nbsp; Unlimited file imports
                </div>
                <div className="newpricingPricecardfeature">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  &nbsp;&nbsp; Unlimited entries
                </div>
                <div className="newpricingPricecardfeature">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  &nbsp;&nbsp; Advanced reports
                </div>
                <div className="newpricingPricecardfeature">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  &nbsp;&nbsp; Calendar
                </div>
                <div className="newpricingPricecardfeature">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  &nbsp;&nbsp; 5 pictures per trade
                </div>
                <div className="newpricingPricecardfeature">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  &nbsp;&nbsp; Customizable dashboard
                </div>
                <div className="newpricingPricecardfeature">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  &nbsp;&nbsp; Track open positions
                </div>
                <div className="newpricingPricecardfeature">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  &nbsp;&nbsp; Save global filters
                </div>
                <div className="newpricingPricecardfeature">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  &nbsp;&nbsp; Commissions/fees
                </div>
              </div>
              <div
                id="pricing-tier-button-pro2"
                onClick={async () => {
                  payWallState === "expired"
                    ? runExpiredStripeFunction(proPriceId)
                    : runInitialStripeFunction(proPriceId);
                }}
              >
                {payWallState === "expired"
                  ? "Regain Pro Access"
                  : "Start Pro Trial"}
              </div>
            </div>
            <div className="newpricingtablecardCardM">
              <div className="mastercardheader">Master Plan</div>
              <div>
                <div className="newpricingPricetext">{mastercountdisplay}</div>
                <div
                  className={
                    promoBannerOn
                      ? /* "newpricingPricepermonthtextON" */"newpricingPricepermonthtext"
                      : "newpricingPricepermonthtext"
                  }
                >
                  /MONTH
                </div>
              </div>
              <div className="newpricingPricepermonthsubtext">
                Unlock your full trading potential
              </div>{" "}
              <div className="newpricingPricepermonthsubtext2">
                Everything in Pro, plus:
              </div>
              <div className="newpricingPricecardfeaturemaster">
                <div className="newpricingPricecardfeature">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  &nbsp;&nbsp; 5 portfolios
                </div>
                <div className="newpricingPricecardfeature">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <span className="newpricingPricecardfeaturecool">
                    &nbsp;&nbsp; Automated importing{" "}
                  </span>
                  {/*                   <div className="newpricingPricecardfeature2">
                    {masterAutoImportCount} portfolios
                  </div> */}{" "}
                  <PopoverStickOnHover
                    component={
                      <div
                        style={{
                          color: "#fff7cb",
                          padding: "2px",
                          width: 260,
                          textAlign: "left",
                        }}
                      >
                        Connect your broker accounts with just a few easy steps.
                        Past trades are imported for you once connected, and new
                        trades are imported in <b>real-time</b>. No waiting. No
                        manual sync.
                      </div>
                    }
                    placement="bottom"
                    onMouseEnter={() => {}}
                    delay={300}
                    setClass="accountNumberSuperWrapper"
                    xAdjust={27}
                    yAdjust={0}
                    keepOpen={false}
                  >
                    <span className="tooptip-i-style80">&#x1D48A;</span>
                  </PopoverStickOnHover>
                </div>{" "}
                <div className="newpricingPricecardfeature">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <span className="newpricingPricecardfeaturecool">
                    &nbsp;&nbsp; QuantumQuery
                  </span>
                  <PopoverStickOnHover
                    component={
                      <div
                        style={{
                          color: "#fff7cb",
                          padding: "2px",
                          width: 220,
                          textAlign: "left",
                        }}
                      >
                        A comprehensive trading journal analysis AI designed to
                        answer any question related to your trading activities.{" "}
                      </div>
                    }
                    placement="bottom"
                    onMouseEnter={() => {}}
                    delay={300}
                    setClass="accountNumberSuperWrapper"
                    xAdjust={27}
                    yAdjust={0}
                    keepOpen={false}
                  >
                    <span className="tooptip-i-style80">&#x1D48A;</span>
                  </PopoverStickOnHover>
                </div>
                {/*                 <div className="newpricingPricecardfeature">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <span className="newpricingPricecardfeaturecool">
                    &nbsp;&nbsp; AI-powered reports{" "}
                  </span>
                  <PopoverStickOnHover
                    component={
                      <div
                        style={{
                          color: "#fff7cb",
                          padding: "2px",
                          width: 260,
                          textAlign: "left",
                        }}
                      >
                        Fully customizable reports powered by AI. Select metrics
                        & categories to compare, then an AI will give feedback
                        and score your performance.
                      </div>
                    }
                    placement="bottom"
                    onMouseEnter={() => {}}
                    delay={300}
                    setClass="accountNumberSuperWrapper"
                    xAdjust={27}
                    yAdjust={0}
                    keepOpen={false}
                  >
                    <span className="tooptip-i-style80">&#x1D48A;</span>
                  </PopoverStickOnHover>
                </div> */}{" "}
                <div className="newpricingPricecardfeature">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  &nbsp;&nbsp; AI-powered reports
                </div>
                <div className="newpricingPricecardfeature">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  &nbsp;&nbsp; Advanced tools
                </div>
                <div className="newpricingPricecardfeature">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  &nbsp;&nbsp; Portfolio & trade verification
                </div>
                <div className="newpricingPricecardfeature">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  &nbsp;&nbsp; 10 pictures per trade
                </div>
                <div className="newpricingPricecardfeature">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  &nbsp;&nbsp; Colored portfolios
                </div>
                <div className="newpricingPricecardfeature">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  &nbsp;&nbsp; Slippage metrics
                </div>
                <div className="newpricingPricecardfeature">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  &nbsp;&nbsp; Enhanced customer support
                </div>
              </div>
              <div
                id="pricing-tier-button-master2"
                onClick={async () => {
                  payWallState === "expired"
                    ? runExpiredStripeFunction(masterPriceId)
                    : runInitialStripeFunction(masterPriceId);
                }}
              >
                {payWallState === "expired"
                  ? "Regain Master Access"
                  : "Start Master Trial"}
              </div>
            </div>
            <div className="newpricingtablecardCardE">
              <div className="ultimatecardheader">Ultimate Plan</div>
              <div>
                <div className="newpricingPricetexte">
                  {ultimatepricedisplay}
                </div>
                <div
                  className={
                    promoBannerOn
                      ? /* "newpricingPricepermonthtexteON" */"newpricingPricepermonthtexte"
                      : "newpricingPricepermonthtexte"
                  }
                >
                  /MONTH
                </div>
              </div>
              <div className="newpricingPricepermonthsubtexte">
                Achieve trading supremacy
              </div>{" "}
              <div className="newpricingPricepermonthsubtext22">
                Everything in Master, plus:
              </div>
              <div className="newpricingPricecardfeatureultimate">
                <div className="newpricingPricecardfeaturee">
                  {" "}
                  <FontAwesomeIcon icon={faCheckCircle} />
                  &nbsp;&nbsp; 10 portfolios
                </div>
                <div className="newpricingPricecardfeaturee">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <span className="newpricingPricecardfeaturecool">
                    &nbsp;&nbsp; QuantumQuery Advanced
                  </span>
                  <PopoverStickOnHover
                    component={
                      <div
                        style={{
                          color: "#fff7cb",
                          padding: "2px",
                          width: 214,
                          textAlign: "left",
                        }}
                      >
                        Our most advanced model for QuantumQuery. Results are
                        more consistent and intelligent.{" "}
                      </div>
                    }
                    placement="bottom"
                    onMouseEnter={() => {}}
                    delay={300}
                    setClass="accountNumberSuperWrapper"
                    xAdjust={27}
                    yAdjust={0}
                    keepOpen={false}
                  >
                    <span className="tooptip-i-style80">&#x1D48A;</span>
                  </PopoverStickOnHover>
                </div>
                <div className="newpricingPricecardfeaturee">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  &nbsp;&nbsp; Additional portfolios with &nbsp;&nbsp;
                  &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  automated importing
                  {/*                   <div className="newpricingPricecardfeature2">
                    {masterAutoImportCount} portfolios
                  </div> */}
                </div>{" "}
                <div className="newpricingPricecardfeaturee">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  &nbsp;&nbsp; 25 pictures per trade
                </div>
                <div className="newpricingPricecardfeaturee">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  &nbsp;&nbsp; Integration request priority
                </div>
                <div className="newpricingPricecardfeaturee">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  &nbsp;&nbsp; 24/7 priority customer support
                </div>
              </div>
              <div
                id="pricing-tier-button-master2"
                onClick={async () => {
                  payWallState === "expired"
                    ? runExpiredStripeFunction(ultimatePriceId)
                    : runInitialStripeFunction(ultimatePriceId);
                }}
              >
                {payWallState === "expired"
                  ? "Regain Ultimate Access"
                  : "Start Ultimate Trial"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Paywall;
