import convertUTCDateToLocalDate from "../../convertUTCDateToLocalTime";
import { v4 as uuidv4 } from "uuid";
import { getEndDateTime, getStartDateTime } from "../../getStartorEndDateTime";
import profitLossCalculation from "../../profitLossCalculation";
import { montharr, weekday } from "../../../arrays/weekandmonth";
import sha256 from "./../../sha256";
import { filterByID, filterByIDandType } from "./../../filterByID";

const fixRound = (num) => {
  return Math.round(num * 1e10) / 1e10;
};
export const parseMetaTraderOrders = (
  tradesIn, // trades already imported
  idsIn, // arrays if ids already imported
  portfolioIn, // visible portfolio (selected portfolio or default)
  tradedata, // incoming entries from dydx
  userId,
  verified, // trade verification level
  marginMode,
  importType,
  pnlmethod
) => {
  let trades = tradesIn;
  let ids = idsIn;
  let portfolio = portfolioIn;
  let changedIds = [];
  const hedgenet = !marginMode
    ? "hedging"
    : marginMode == "ACCOUNT_MARGIN_MODE_RETAIL_HEDGING"
    ? "hedging"
    : marginMode == "ACCOUNT_MARGIN_MODE_RETAIL_NETTING"
    ? "netting"
    : "hedging";

  for (let i = 0, j = tradedata.length; i < j; i++) {
    const currentIteration = i + 1;
    if (importType === "initialLink") {
      localStorage.setItem(
        "autoiterationMessage",
        `Importing ${currentIteration}/${tradedata.length} executions...`
      );
    }
    let trade = {};
    let openTradesPre = [...trades];
    let openTradesPre2 = [...trades];
    const type = tradedata[i]["type"];
    const inorout = tradedata[i]["entryType"];
    const amount = tradedata[i]["profit"];
    let tempData = tradedata[i];
    let tempDataForId = Object.assign({}, tradedata[i]);

    const newId =
      tempDataForId["entryType"] +
      tempDataForId["id"] +
      tempDataForId["symbol"] +
      tempDataForId["time"] +
      tempDataForId["type"] +
      tempDataForId["volume"];
    const stringForId = JSON.stringify(newId);

    const id = sha256(String(stringForId));
    /*     console.log(stringForId);
    console.log(ids);
    console.log(tempDataForId);
    console.log(id); */
    if (type === "DEAL_TYPE_BALANCE") {
      if (!ids.includes(String(id))) {
        var startDateTime = new Date(tradedata[i]["time"]);
        trade = {
          entryId: String(id),
          entry: {
            pictures: ["N/A"],
            symbol: {
              symbols: [],
              pointValue: 0,
            },
            strategy: "",
            selectedConfidence: "",
            selectedEmotion: "",
            selectedPhysical: "",
            selectedMarket: "",
            selectedTimeframe: "",
            selectedMistake: "",
            selectedPortfolio: portfolio.name,
            selectedPortfolioType: "Forex",
            orderType: amount > 0 ? "Deposit" : "Withdrawal",
            orderNumber: String(tradedata[i]["id"]),
            dayOfWeek: weekday[startDateTime.getDay()],
            monthOfYear: montharr[startDateTime.getMonth()],
            stopLoss: "",
            takeProfit: "",
            profitLoss: Number(amount),
            commissions: 0,
            fees: 0,
            maxAdEx: "",
            maxFavEx: "",
            comments: "",
            multiExecution: [],
            exitExecution: [],
            startDateTime: startDateTime,
            endDateTime: startDateTime,
            tags: "",
            verifiedLevel: verified, // because it was a fully automated trade,
            idLinks: [],
            hashes: [],
          },
        };
        trades.push(trade);
        ids.push(String(id));
        changedIds.push(String(id));
      } else {
      }
    } else {
      if (!ids.includes(String(id))) {
        // Side matters with metatrader because you can have a long and short position open simultaneously
        // Need to fix rounding issue of lotSizes by doing :
        // Math.round(Number(tradedata[i]["size"]) * 1e10) / 1e10,

        if (hedgenet === "netting") {
          let orderType =
            tradedata[i]["type"] === "DEAL_TYPE_SELL" ? "Short" : "Long";
          const symbol = tradedata[i]?.["symbol"]?.toString()?.replace("-", "");
          let openTradesMatch = openTradesPre.filter((trade) =>
            filterByID(trade, false, symbol)
          );
          let openTradesNOTMatch = openTradesPre2.filter((trade) =>
            filterByID(trade, true, symbol)
          );

          let startDateTime = new Date(tradedata[i]["time"]);
          //let timestamp = new Date(fullTradearray[i]["Timestamp"]);
          //let delta = fullTradearray[i]["Delta"];
          let entryexecution = {
            id: uuidv4(),
            lotSize: fixRound(Number(tradedata[i]["volume"])),
            entryPrice: tradedata[i]["price"],
            startDateTime: startDateTime,
            expectedEntry: "",
            strikePrice: "",
            expirationDate: "",
            legType: "",
          };
          const stoploss = tradedata[i]["stopLoss"];
          const takeProfit = tradedata[i]["takeProfit"];
          const openTrade = openTradesMatch[0];
          changedIds.push(openTrade?.entryId);
          const openTradeEntry = openTrade?.entry;
          const opentrademultiExecution = openTrade?.entry.multiExecution;
          const opentradeOrderType = openTrade?.entry.orderType;
          const opentradeSymbol = openTrade?.entry.symbol;
          const opentradeFees = openTrade?.entry.fees;
          const opentradeCommissions = openTrade?.entry.commissions;

          const opentradeProfit = openTrade?.entry.profitLoss;

          const opentradesType = openTrade?.entry.selectedPortfolioType;
          // NETTING ACCOUNTS
          if (!openTradesMatch.length) {
            trade = {
              entryId: String(id),
              entry: {
                pictures: ["N/A"],
                symbol: {
                  symbols: [symbol],
                  pointValue: 0,
                },
                strategy: "",
                selectedConfidence: "",
                selectedEmotion: "",
                selectedPhysical: "",
                selectedMarket: "",
                selectedTimeframe: "",
                selectedMistake: "",
                selectedPortfolio: portfolio.name,
                selectedPortfolioType: "Forex",
                orderType: orderType,
                orderNumber: "",
                dayOfWeek: weekday[startDateTime.getDay()],
                monthOfYear: montharr[startDateTime.getMonth()],
                stopLoss: !stoploss ? "" : stoploss,
                takeProfit: !takeProfit ? "" : takeProfit,
                profitLoss: 0,
                commissions: Number(tradedata[i]["commission"]),
                fees: Number(tradedata[i]["swap"]),
                maxAdEx: "",
                maxFavEx: "",
                comments: "",
                multiExecution: [entryexecution],
                exitExecution: [],
                startDateTime: startDateTime,
                endDateTime: "",
                tags: "",
                verifiedLevel: verified, // because it was a fully automated trade,
                idLinks: [],
                hashes: [],
              },
            };
            trades.push(trade);
            changedIds.push(String(id));
            openTradesPre = trades;
            openTradesPre2 = trades;
          } else {
            // -------- Add the exit execution, and calculate profit/loss, fees, and start & end date time stuff -------
            var endDateTimePRE = new Date(tradedata[i]["time"]);
            let exitexecutionPRE = {
              id: uuidv4(),
              exitLotSize: fixRound(Number(tradedata[i]["volume"])),
              exitPrice: tradedata[i]["price"],
              endDateTime: endDateTimePRE,
              expectedExit: "",
              exitstrikePrice: "",
              exitexpirationDate: "",
              exitlegType: "",
              equityComponents: [],
              exercised: "",
            };
            let entryLots = 0;
            opentrademultiExecution.forEach((exec) => {
              entryLots += Number(exec.lotSize);
            });
            let exitLots = 0;
            const newExitExecutionOLD = openTrade?.entry.exitExecution;

            const newExitExecutionPRE = [
              ...openTrade?.entry.exitExecution,
              exitexecutionPRE,
            ];
            newExitExecutionPRE.forEach((exec) => {
              exitLots += Number(exec.exitLotSize);
            });
            let exitLotsOLD = 0;

            newExitExecutionOLD.forEach((exec) => {
              exitLotsOLD += Number(exec.exitLotSize);
            });

            entryLots = fixRound(entryLots); // fix rounding issues
            exitLots = fixRound(exitLots);
            exitLotsOLD = fixRound(exitLotsOLD);
            const differenceIn = Number(entryLots) - Number(exitLotsOLD);
            const differenceNew = Number(exitLots) - Number(differenceIn);

            if (orderType !== openTradeEntry.orderType) {
              if (exitLots > entryLots) {
                // We still close the existing trade
                let exitexecution = {
                  id: uuidv4(),
                  exitLotSize: fixRound(Number(differenceIn)),
                  exitPrice: tradedata[i]["price"],
                  endDateTime: endDateTimePRE,
                  expectedExit: "",
                  exitstrikePrice: "",
                  exitexpirationDate: "",
                  exitlegType: "",
                  equityComponents: [],
                  exercised: "",
                };
                const newExitExecutionPREinner = [
                  ...openTrade?.entry.exitExecution,
                  exitexecution,
                ];
                let startDateTimez = getStartDateTime(opentrademultiExecution);
                let endDateTimez = getEndDateTime(newExitExecutionPREinner);
                let finalFees = opentradeFees + Number(tradedata[i]["swap"]);
                let finalCommissions =
                  opentradeCommissions + Number(tradedata[i]["commission"]);
                let finalProfit =
                  opentradeProfit + Number(tradedata[i]["profit"]);
                const stoploss = tradedata[i]["stopLoss"];
                const takeProfit = tradedata[i]["takeProfit"];

                const entry = Object.assign({}, openTradeEntry, {
                  exitExecution: newExitExecutionPREinner,
                  profitLoss: Number(finalProfit),
                  endDateTime: endDateTimez,
                  startDateTime: startDateTimez,
                  commissions: Number(finalCommissions), //add funding payments at the end
                  fees: Number(finalFees),
                });

                const closedTrade = Object.assign({}, openTrade, {
                  entry: entry,
                });
                openTradesNOTMatch.push(closedTrade);

                // If the exit Execution makes the summed exit lots sizes more than the entry
                // We add a new trade to the other side
                const newLotSize = fixRound(Number(differenceNew));
                let orderTypeinner =
                  tradedata[i]["type"] === "DEAL_TYPE_SELL" ? "Short" : "Long";

                let entryexecutioninner = {
                  id: uuidv4(),
                  lotSize: newLotSize,
                  entryPrice: tradedata[i]["price"],
                  startDateTime: endDateTimePRE,
                  expectedEntry: "",
                  strikePrice: "",
                  expirationDate: "",
                  legType: "",
                };

                const tradeNew = {
                  entryId: String(tradedata[i]["id"]),
                  entry: {
                    pictures: ["N/A"],
                    symbol: {
                      symbols: [symbol],
                      pointValue: 0,
                    },
                    strategy: "",
                    selectedConfidence: "",
                    selectedEmotion: "",
                    selectedPhysical: "",
                    selectedMarket: "",
                    selectedTimeframe: "",
                    selectedMistake: "",
                    selectedPortfolio: portfolio.name,
                    selectedPortfolioType: "Crypto",
                    orderType: orderTypeinner,
                    orderNumber: "",
                    dayOfWeek: weekday[endDateTimePRE.getDay()],
                    monthOfYear: montharr[endDateTimePRE.getMonth()],
                    stopLoss: !stoploss ? "" : stoploss,
                    takeProfit: !takeProfit ? "" : takeProfit,
                    profitLoss: 0,
                    commissions: Number(tradedata[i]["commission"]),
                    fees: Number(tradedata[i]["swap"]),
                    maxAdEx: "",
                    maxFavEx: "",
                    comments: "",
                    multiExecution: [entryexecutioninner],
                    exitExecution: [],
                    startDateTime: endDateTimePRE,
                    endDateTime: "",
                    tags: "",
                    verifiedLevel: verified, // because it was a fully automated trade,
                    idLinks: [],
                    hashes: [],
                  },
                };
                openTradesNOTMatch.push(tradeNew);
                changedIds.push(String(tradedata[i]["id"]));

                // Then set to trades
                trades = openTradesNOTMatch;
                openTradesPre = openTradesNOTMatch;
                openTradesPre2 = openTradesNOTMatch;
              } else {
                // Add new opening executions

                let startDateTimez = getStartDateTime(opentrademultiExecution);
                let endDateTimez = getEndDateTime(newExitExecutionPRE);
                let finalCommissions =
                  opentradeCommissions + Number(tradedata[i]["commission"]);
                let finalFees = opentradeFees + Number(tradedata[i]["swap"]);
                let finalProfit =
                  opentradeProfit + Number(tradedata[i]["profit"]);
                const stoploss = tradedata[i]["stopLoss"];
                const takeProfit = tradedata[i]["takeProfit"];
                const entry = Object.assign({}, openTradeEntry, {
                  exitExecution: newExitExecutionPRE,
                  profitLoss: Number(finalProfit),
                  endDateTime: endDateTimez,
                  startDateTime: startDateTimez,
                  stopLoss: !stoploss ? "" : stoploss,
                  takeProfit: !takeProfit ? "" : takeProfit,
                  commissions: Number(finalCommissions), //add funding payments at the end
                  fees: Number(finalFees),
                });

                const closedTrade = Object.assign({}, openTrade, {
                  entry: entry,
                });
                openTradesNOTMatch.push(closedTrade);
                trades = openTradesNOTMatch;
                openTradesPre = openTradesNOTMatch;
                openTradesPre2 = openTradesNOTMatch;
              }
              // Check if the user took an exiting position
              // whose lot size made the summed exit lot size
              // greater than the entry summed lot size
            } else {
              // Add new opening executions
              const opentrademultiExecutionNew = [
                ...opentrademultiExecution,
                entryexecution,
              ];
              const entry = Object.assign({}, openTradeEntry, {
                multiExecution: opentrademultiExecutionNew,
                commissions:
                  opentradeCommissions + Number(tradedata[i]["commission"]),
                fees: opentradeFees + Number(tradedata[i]["swap"]),
              });
              const closedTrade = Object.assign({}, openTrade, {
                entry: entry,
              });
              openTradesNOTMatch.push(closedTrade);
              trades = openTradesNOTMatch;
              openTradesPre = openTradesNOTMatch;
              openTradesPre2 = openTradesNOTMatch;
            }
          }
        } else {
          // HEDGING ACCOUNTS
          let orderType =
            tradedata[i]["type"] === "DEAL_TYPE_SELL" ? "Short" : "Long";

          const symbol = tradedata[i]?.["symbol"]?.toString()?.replace("-", "");
          let openTradesMatch = openTradesPre.filter((trade) =>
            filterByIDandType(
              trade,
              false,
              symbol,
              orderType,
              tradedata[i]["entryType"]
            )
          );
          let openTradesNOTMatch = openTradesPre2.filter((trade) =>
            filterByIDandType(
              trade,
              true,
              symbol,
              orderType,
              tradedata[i]["entryType"]
            )
          );

          let startDateTime = new Date(tradedata[i]["time"]);
          //let timestamp = new Date(fullTradearray[i]["Timestamp"]);
          //let delta = fullTradearray[i]["Delta"];
          let entryexecution = {
            id: uuidv4(),
            lotSize: fixRound(Number(tradedata[i]["volume"])),
            entryPrice: tradedata[i]["price"],
            startDateTime: startDateTime,
            expectedEntry: "",
            strikePrice: "",
            expirationDate: "",
            legType: "",
          };
          const stoploss = tradedata[i]["stopLoss"];
          const takeProfit = tradedata[i]["takeProfit"];

          const openTrade = openTradesMatch[0];
          changedIds.push(openTrade?.entryId);

          const openTradeEntry = openTrade?.entry;
          const opentrademultiExecution = openTrade?.entry.multiExecution;
          const opentradeOrderType = openTrade?.entry.orderType;
          const opentradeSymbol = openTrade?.entry.symbol;
          const opentradeFees = openTrade?.entry.fees;
          const opentradeCommissions = openTrade?.entry.commissions;

          const opentradeProfit = openTrade?.entry.profitLoss;

          const opentradesType = openTrade?.entry.selectedPortfolioType;
          if (!openTrade) {
            trade = {
              entryId: String(id),
              entry: {
                pictures: ["N/A"],
                symbol: {
                  symbols: [symbol],
                  pointValue: 0,
                },
                strategy: "",
                selectedConfidence: "",
                selectedEmotion: "",
                selectedPhysical: "",
                selectedMarket: "",
                selectedTimeframe: "",
                selectedMistake: "",
                selectedPortfolio: portfolio.name,
                selectedPortfolioType: "Forex",
                orderType: orderType,
                orderNumber: "",
                dayOfWeek: weekday[startDateTime.getDay()],
                monthOfYear: montharr[startDateTime.getMonth()],
                stopLoss: !stoploss ? "" : stoploss,
                takeProfit: !takeProfit ? "" : takeProfit,
                profitLoss: 0,
                commissions: Number(tradedata[i]["commission"]),
                fees: Number(tradedata[i]["swap"]),
                maxAdEx: "",
                maxFavEx: "",
                comments: "",
                multiExecution: [entryexecution],
                exitExecution: [],
                startDateTime: startDateTime,
                endDateTime: "",
                tags: "",
                verifiedLevel: verified, // because it was a fully automated trade,
                idLinks: [],
                hashes: [],
              },
            };
            trades.push(trade);
            changedIds.push(String(id));
            openTradesPre = trades;
            openTradesPre2 = trades;
          } else {
            if (orderType !== opentradeOrderType) {
              // -------- Add the exit execution, and calculate profit/loss, fees, and start & end date time stuff -------
              var endDateTimePRE = new Date(tradedata[i]["time"]);
              let exitexecutionPRE = {
                id: uuidv4(),
                exitLotSize: fixRound(Number(tradedata[i]["volume"])),
                exitPrice: tradedata[i]["price"],
                endDateTime: endDateTimePRE,
                expectedExit: "",
                exitstrikePrice: "",
                exitexpirationDate: "",
                exitlegType: "",
                equityComponents: [],
                exercised: "",
              };
              let entryLots = 0;
              opentrademultiExecution &&
                opentrademultiExecution.forEach((exec) => {
                  entryLots += Number(exec.lotSize);
                });
              let exitLots = 0;
              const newExitExecutionOLD = openTrade
                ? openTrade?.entry.exitExecution
                : [];
              const newExitExecutionPRE = [
                ...newExitExecutionOLD,
                exitexecutionPRE,
              ];
              newExitExecutionPRE.forEach((exec) => {
                exitLots += Number(exec.exitLotSize);
              });
              let exitLotsOLD = 0;

              newExitExecutionOLD.forEach((exec) => {
                exitLotsOLD += Number(exec.exitLotSize);
              });

              entryLots = fixRound(entryLots); // fix rounding issues
              exitLots = fixRound(exitLots);
              exitLotsOLD = fixRound(exitLotsOLD);

              // Check if the user took an exiting position
              // whose lot size made the summed exit lot size
              // greater than the entry summed lot size
              let startDateTimez = getStartDateTime(opentrademultiExecution);
              let endDateTimez = getEndDateTime(newExitExecutionPRE);
              let finalCommissions =
                opentradeCommissions + Number(tradedata[i]["commission"]);
              let finalFees = opentradeFees + Number(tradedata[i]["swap"]);
              let finalProfit =
                opentradeProfit + Number(tradedata[i]["profit"]);
              const stoploss = tradedata[i]["stopLoss"];
              const takeProfit = tradedata[i]["takeProfit"];
              const entry = Object.assign({}, openTradeEntry, {
                exitExecution: newExitExecutionPRE,
                profitLoss: Number(finalProfit),
                endDateTime: endDateTimez,
                startDateTime: startDateTimez,
                stopLoss: !stoploss ? "" : stoploss,
                takeProfit: !takeProfit ? "" : takeProfit,
                commissions: Number(finalCommissions), //add funding payments at the end
                fees: Number(finalFees),
              });

              const closedTrade = Object.assign({}, openTrade, {
                entry: entry,
              });
              openTradesNOTMatch.push(closedTrade);
              trades = openTradesNOTMatch;
              openTradesPre = openTradesNOTMatch;
              openTradesPre2 = openTradesNOTMatch;
            } else {
              // Add new opening executions
              const opentrademultiExecutionNew = [
                ...opentrademultiExecution,
                entryexecution,
              ];
              const entry = Object.assign({}, openTradeEntry, {
                multiExecution: opentrademultiExecutionNew,
                commissions:
                  opentradeCommissions + Number(tradedata[i]["commission"]),
                fees: opentradeFees + Number(tradedata[i]["swap"]),
              });
              const closedTrade = Object.assign({}, openTrade, {
                entry: entry,
              });
              openTradesNOTMatch.push(closedTrade);
              trades = openTradesNOTMatch;
              openTradesPre = openTradesNOTMatch;
              openTradesPre2 = openTradesNOTMatch;
            }
            changedIds.push(openTrade?.entryId);
          }
        }
        ids.push(String(id));
      }
    }
  }

  return { trades: trades, ids: ids, changedIds: changedIds };
};

export default parseMetaTraderOrders;
