import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  useRowSelect,
  useCallback,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTable, usePagination, useSortBy } from "react-table";
import Select from "react-select";
import { theme } from "../../components/selectTheme";
import moment from "moment/moment";
import { CheckBoxContainer } from "../Manual-Trade-Entry/CardandParent";
import dollarCostAverage from "../../functions/dollarCostAvg";
import getRiskedPercentColor from "../../functions/getRiskedPercentColor";
import getOptionsStrategy from "../../functions/getOptionsStrategy";
import longShortDelineation from "../../functions/longShortDelineation";
import { handleNANGOOD } from "../../functions/handleUndefined";
import { Navbar, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import PopoverStickOnHover from "./../../components/PopoverStickOnHover";

import EfficiencyBar from "./efficiencyBar";
import { v4 as uuidv4 } from "uuid";
import {
  faTrashAlt,
  faTag,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faCaretDown,
  faCaretUp,
  faPrint,
  faColumns,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { entryDataIDs, columnIds } from "./arrays";
import {
  preCheckColumns,
  handleHeaderCheckBoxClick,
  handleAccessorCheckBoxClick,
  handleColumnChoosing,
  handleDeleteTrades,
  handleTagTrades,
  numberWithCommas,
} from "./functions";
import { useReactToPrint } from "react-to-print";
import getDateDifference from "../../functions/getDateDifference";
import { returnNetPLDollar } from "../../functions/profitLossCalculation";
import { profitLossCalculation } from "../../functions/profitLossCalculation";
import supportedCryptos from "../../arrays/isCurrencyCrypto";
import slippageCalculation from "../../functions/slippageCalculation";
import showConfirmedActionButton from "../../functions/showConfirmedActionButton";

let he = require("he");
var montharr = new Array(12);
montharr[0] = "January";
montharr[1] = "February";
montharr[2] = "March";
montharr[3] = "April";
montharr[4] = "May";
montharr[5] = "June";
montharr[6] = "July";
montharr[7] = "August";
montharr[8] = "September";
montharr[9] = "October";
montharr[10] = "November";
montharr[11] = "December";

var weekday = new Array(7);
weekday[0] = "Sunday";
weekday[1] = "Monday";
weekday[2] = "Tuesday";
weekday[3] = "Wednesday";
weekday[4] = "Thursday";
weekday[5] = "Friday";
weekday[6] = "Saturday";

const EntryTable = (props) => {
  const tier = props.tier;
  const active = props.active;
  const portfolio = props.userPortfolio;
  const data = portfolio.entries;
  const settings = portfolio.settings;
  const thedefaultSymbol = settings.currencySymbol;
  const supportedCryptosCheck = supportedCryptos.includes(thedefaultSymbol);
  const chosenPortType = portfolio.assetClasses;
  const pnlmethod = settings.pnlcalculationmethod;
  const chosenPortTypeLength = chosenPortType.length;
  const location = useLocation();
  const locationState = location.state;
  const locationSortBy = locationState && locationState.sortBy;
  let sortBy = !locationSortBy ? [] : locationSortBy;
  const history = useHistory();
  const [tableheight, setTableHeight] = useState([window.innerHeight]);
  const [width, setWidth] = useState([window.innerWidth]);
  const [height, setHeight] = useState([window.innerHeight]);
  const allData = props.allData;
  const createdPortfolios = allData?.createdPortfolios;
  const chosenPortfolios = allData?.chosenPortfolios;

  const integrationsInfo = allData?.globalSettings.integrationsInfo;

  const firstUpdate = useRef(true);
  const toComponentB = (row) => {
    // NEED TO REMOVE EDIT TRADE HERE TO ALLOW FOR EDIT TRADES
    // TO MERGE WITH INCOMING WEBSOCKET MSGS
    localStorage.removeItem("editTrade");

    localStorage.removeItem("editTradeId");
    history.push({
      pathname: "/Trade-Details",
      state: row.original,
    });
  };
  const settingsListFunction = useCallback(() => {
    const newsettings =
      settings.historyColumnDisplay === undefined
        ? []
        : settings.historyColumnDisplay;
    let emptyColumns = [];
    for (var i = 0, j = Object.keys(newsettings).length; i < j; i++) {
      if (newsettings[columnIds[i]] === false) {
        emptyColumns = emptyColumns.concat(entryDataIDs[i]);
      } else {
        continue;
      }
    }
    return emptyColumns;
  }, [settings]);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  function hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }
  useEffect(() => {
    if (firstUpdate.current) {
      preCheckColumns(settings, tier, active);
      window.addEventListener("resize", () => {
        setTableHeight(window.innerHeight);
      });
      var addedType2 = localStorage.getItem("loadDemoDataAction");
      if (addedType2 === "yes") {
        showConfirmedActionButton("Demo Data Added!");
        localStorage.removeItem("loadDemoDataAction");
      } else if (addedType2 === "no") {
        showConfirmedActionButton("Demo Data Deleted!");
        localStorage.removeItem("loadDemoDataAction");
      }
    }
    const handleWindowResize = () => {
      setTableHeight(window.innerHeight);
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };
    window.addEventListener("resize", () => {
      setTableHeight(window.innerHeight);
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    });
    return () => {
      window.removeEventListener("resize", handleWindowResize);
      firstUpdate.current = false;
    };
  }, [settings, props, firstUpdate, tier, active, width]);

  const handleRowClick = (row, b) => {
    toComponentB(row);
  };

  let tierShow = false;
  let ismaster = false;

  if (tier === "free") {
    tierShow = true;
  } else if (tier === "pro" && active) {
  } else if (tier === "oldpro" && active) {
  } else if (tier === "master" && active) {
    ismaster = true;
  } else if (tier === "ultimate" && active) {
    ismaster = true;
  } else if (tier === "admin" && active) {
    ismaster = true;
  } else {
    tierShow = true;
  }

  // show the missing trades prompt when users have more than 50 trades, and have imported/connected a broker
  let missingTradesCheck = false;
  function checkPortMatch(obj, portNames) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key) && portNames.includes(obj[key].port)) {
        return true;
      }
    }
    return false;
  }

  const importinfocheck = checkPortMatch(integrationsInfo, chosenPortfolios);
  let autoImportConnectionsArray = [];
  createdPortfolios &&
    createdPortfolios.forEach((portfolio) => {
      const linkedBroker = portfolio.settings.linkedBrokerInfo;
      linkedBroker.broker !== "" &&
        autoImportConnectionsArray.push({
          ...portfolio.settings.linkedBrokerInfo,
          port: portfolio.name,
        });
    });
  if (
    (autoImportConnectionsArray.length || importinfocheck) &&
    data.length >= 30
  ) {
    missingTradesCheck = true;
  } else {
  }
  // ----------------------
  const columns = useMemo(
    () => [
      {
        Header: () => {
          return (
            <CheckBoxContainer>
              <label className="container checkheader-2">
                <input
                  type="checkbox"
                  id="boxHeaderChecks"
                  onClick={handleHeaderCheckBoxClick}
                />
                <span className="checkmarkvh checkmark-col3 "></span>
              </label>
            </CheckBoxContainer>
          );
        },
        id: "checkboxcolumn",
        disableSortBy: true,
        accessor: () => {
          return (
            <CheckBoxContainer>
              <label className="container checkheader-2">
                <input
                  type="checkbox"
                  id="boxTradeChecks"
                  onClick={(e) => {
                    handleAccessorCheckBoxClick(e);
                    e.stopPropagation();
                  }}
                />
                <span className="checkmark checkmark-col1-2"></span>
              </label>
            </CheckBoxContainer>
          );
        },
      },
      {
        Header: "entryId",
        accessor: "entryId",
        Cell: (props) => {
          if (props.value === "") {
            return <div id="entryidvalue">{""}</div>;
          } else {
            return <div id="entryidvalue">{props.value}</div>;
          }
        },
      },
      {
        Header: (header) => {
          const sorted = header.state.sortBy;
          const id = sorted.length && sorted[0].id;
          return !sorted.length || id !== "entry.startDateTime" ? (
            "Date & Time Opened"
          ) : (
            <span style={{ color: "#fbd665" }}>{"Date & Time Opened"}</span>
          );
        },
        id: "entry.startDateTime",
        accessor: (row) => new Date(row.entry.startDateTime),
        sortType: "basic",
        Cell: (props) => {
          if (props.value === "") {
            return "";
          } else {
            return moment(props.value).format("MM/DD/YYYY HH:mm:ss");
          }
        },
      },
      {
        Header: (header) => {
          const sorted = header.state.sortBy;
          const id = sorted.length && sorted[0].id;
          return !sorted.length || id !== "entry.endDateTime" ? (
            "Date & Time Closed"
          ) : (
            <span style={{ color: "#fbd665" }}>{"Date & Time Closed"}</span>
          );
        },
        id: "entry.endDateTime",
        accessor: (row) => {
          const entryExecs = row.entry.multiExecution;
          let entryLots = 0;
          entryExecs.forEach((exec) => {
            entryLots += Number(exec.lotSize);
          });
          let exitLots = 0;
          const exitExecs = row.entry.exitExecution;
          exitExecs.forEach((exec) => {
            exitLots += Number(exec.exitLotSize);
          });
          entryLots = Math.round(entryLots * 1e10) / 1e10; // fix rounding issue
          exitLots = Math.round(exitLots * 1e10) / 1e10;
          if (
            row.entry.orderType.toLowerCase().replace(/ /gi, "") ===
              "deposit" ||
            row.entry.orderType.toLowerCase().replace(/ /gi, "") ===
              "withdrawal" ||
            row.entry.orderType.toLowerCase().replace(/ /gi, "") ===
              "fundingpayment" ||
            row.entry.orderType.toLowerCase().replace(/ /gi, "") ===
              "approval" ||
            row.entry.orderType.toLowerCase().replace(/ /gi, "") === "commit" ||
            row.entry.orderType.toLowerCase().replace(/ /gi, "") === "self"
          ) {
            return new Date(-8639999999999999);
          } else {
            if (exitLots !== entryLots) {
              return new Date(-8640000000000000);
            } else {
              return new Date(row.entry.endDateTime);
            }
          }
        },
        sortType: "basic",
        Cell: ({ row }) => {
          const entryExecs = row.original.entry.multiExecution;
          let entryLots = 0;
          entryExecs.forEach((exec) => {
            entryLots += Number(exec.lotSize);
          });
          let exitLots = 0;
          const exitExecs = row.original.entry.exitExecution;
          exitExecs.forEach((exec) => {
            exitLots += Number(exec.exitLotSize);
          });
          entryLots = Math.round(entryLots * 1e10) / 1e10; // fix rounding issue
          exitLots = Math.round(exitLots * 1e10) / 1e10;
          if (
            row.original.entry.orderType.toLowerCase().replace(/ /gi, "") ===
              "deposit" ||
            row.original.entry.orderType.toLowerCase().replace(/ /gi, "") ===
              "withdrawal" ||
            row.original.entry.orderType.toLowerCase().replace(/ /gi, "") ===
              "fundingpayment" ||
            row.original.entry.orderType.toLowerCase().replace(/ /gi, "") ===
              "approval" ||
            row.original.entry.orderType.toLowerCase().replace(/ /gi, "") ===
              "commit" ||
            row.original.entry.orderType.toLowerCase().replace(/ /gi, "") ===
              "nft" ||
            row.original.entry.orderType.toLowerCase().replace(/ /gi, "") ===
              "wrap" ||
            row.original.entry.orderType.toLowerCase().replace(/ /gi, "") ===
              "self"
          ) {
            return "";
          } else {
            if (exitLots !== entryLots) {
              return (
                <span className="open-trade">
                  {/* this.setstate(open = true) ---- maybe make a fucntion that reutrns the class and also activates functions that change open boolean state */}
                  Open
                  {/* To use props open = true -> Then we create a JS variable element and assign value 
              <Welcome name="Mary" /> to it. Here React automatically updates the 
              props object giving it a new property called name with the value Mary. */}
                </span>
              );
            } else {
              return moment(row.original.entry.endDateTime).format(
                "MM/DD/YYYY HH:mm:ss"
              );
            }
          }
        },
      },
      {
        Header: (header) => {
          const sorted = header.state.sortBy;
          const id = sorted.length && sorted[0].id;
          return !sorted.length || id !== "holdingtime" ? (
            "Holding Time"
          ) : (
            <span style={{ color: "#fbd665" }}>{"Holding Time"}</span>
          );
        },
        id: "holdingtime",
        accessor: (row) => {
          const datenow = new Date();
          const startDate = new Date(row.entry.startDateTime);
          const endDate = new Date(row.entry.endDateTime);
          const entryExecs = row.entry.multiExecution;
          let entryLots = 0;
          entryExecs.forEach((exec) => {
            entryLots += Number(exec.lotSize);
          });
          let exitLots = 0;
          const exitExecs = row.entry.exitExecution;
          exitExecs.forEach((exec) => {
            exitLots += Number(exec.exitLotSize);
          });
          entryLots = Math.round(entryLots * 1e10) / 1e10; // fix rounding issue
          exitLots = Math.round(exitLots * 1e10) / 1e10;
          if (exitLots !== entryLots) {
            return (datenow.getTime() - startDate.getTime()) / 1000;
          } else {
            return (endDate.getTime() - startDate.getTime()) / 1000;
          }
        },
        sortType: "number",
        Cell: ({ row }) => {
          if (
            row.original.entry.orderType.toLowerCase().replace(/ /gi, "") ===
              "deposit" ||
            row.original.entry.orderType.toLowerCase().replace(/ /gi, "") ===
              "withdrawal" ||
            row.original.entry.orderType.toLowerCase().replace(/ /gi, "") ===
              "fundingpayment" ||
            row.original.entry.orderType.toLowerCase().replace(/ /gi, "") ===
              "approval" ||
            row.original.entry.orderType.toLowerCase().replace(/ /gi, "") ===
              "commit" ||
            row.original.entry.orderType.toLowerCase().replace(/ /gi, "") ===
              "nft" ||
            row.original.entry.orderType.toLowerCase().replace(/ /gi, "") ===
              "wrap" ||
            row.original.entry.orderType.toLowerCase().replace(/ /gi, "") ===
              "self"
          ) {
            return "";
          } else {
            const datenow = new Date();
            const entryExecs = row.original.entry.multiExecution;
            let entryLots = 0;
            entryExecs.forEach((exec) => {
              entryLots += Number(exec.lotSize);
            });
            let exitLots = 0;
            const exitExecs = row.original.entry.exitExecution;
            exitExecs.forEach((exec) => {
              exitLots += Number(exec.exitLotSize);
            });
            entryLots = Math.round(entryLots * 1e10) / 1e10; // fix rounding issue
            exitLots = Math.round(exitLots * 1e10) / 1e10;
            if (exitLots !== entryLots) {
              return getDateDifference(
                datenow,
                row.original.entry.startDateTime
              );
            } else {
              return getDateDifference(
                row.original.entry.endDateTime,
                row.original.entry.startDateTime
              );
            }
          }
        },
      },
      {
        Header: (header) => {
          const sorted = header.state.sortBy;
          const id = sorted.length && sorted[0].id;
          return !sorted.length || id !== "entry.profitLoss" ? (
            "Gross P/L (Amount)"
          ) : (
            <span style={{ color: "#fbd665" }}>{"Gross P/L (Amount)"}</span>
          );
        },
        id: "entry.profitLoss",
        accessor: "entry.profitLoss",
        sortType: "basic",
        Cell: ({ row }) => {
          const displaytype =
            row.original.entry.orderType.toLowerCase().replace(/ /gi, "") ===
            "fundingpayment"
              ? numberWithCommas(
                  parseFloat(Number(row.original.entry.profitLoss)).toFixed(3)
                )
              : numberWithCommas(
                  parseFloat(Number(row.original.entry.profitLoss)).toFixed(2)
                );
          const displaytypecrypto =
            row.original.entry.orderType.toLowerCase().replace(/ /gi, "") ===
            "fundingpayment"
              ? numberWithCommas(
                  parseFloat(Number(row.original.entry.profitLoss)).toFixed(3)
                )
              : parseFloat(Number(row.original.entry.profitLoss).toFixed(6));
          const grossPLFinal = supportedCryptosCheck
            ? displaytypecrypto + " " + String(thedefaultSymbol)
            : he.decode(String(thedefaultSymbol)) + displaytype;
          if (row.original.entry.profitLoss === "") {
            return "";
          } else {
            if (Number(row.original.entry.profitLoss) > 0) {
              return <span className="orderOutcome-Win">{grossPLFinal}</span>;
            } else if (Number(row.original.entry.profitLoss) < 0) {
              return <span className="orderOutcome-Loss">{grossPLFinal}</span>;
            } else if (Number(row.original.entry.profitLoss) === 0) {
              return <span className="orderOutcome-BE">{grossPLFinal}</span>;
            } else {
              return "";
            }
          }
        },
      },
      {
        Header: (header) => {
          const sorted = header.state.sortBy;
          const id = sorted.length && sorted[0].id;
          return !sorted.length || id !== "netPLdollar" ? (
            "Net P/L (Amount)"
          ) : (
            <span style={{ color: "#fbd665" }}>{"Net P/L (Amount)"}</span>
          );
        },
        id: "netPLdollar",
        accessor: (row) =>
          returnNetPLDollar(
            row.entry.profitLoss,
            row.entry.commissions,
            row.entry.fees,
            settings.calculationType
          ),
        sortType: "basic",
        Cell: ({ row }) => {
          if (
            row.original.entry.profitLoss === "" &&
            row.original.entry.commissions === "" &&
            row.original.entry.fees === ""
          ) {
            return "";
          } else {
            let netPLwCommas = numberWithCommas(
              parseFloat(
                returnNetPLDollar(
                  row.original.entry.profitLoss,
                  row.original.entry.commissions,
                  row.original.entry.fees,
                  settings.calculationType
                )
              ).toFixed(2)
            );
            let netPLCrypto = parseFloat(
              returnNetPLDollar(
                row.original.entry.profitLoss,
                row.original.entry.commissions,
                row.original.entry.fees,
                settings.calculationType
              ).toFixed(6)
            ); // parsefloat is encompassing here b/c we want the trailing zeros to be chopped off
            const netPL = parseFloat(
              returnNetPLDollar(
                row.original.entry.profitLoss,
                row.original.entry.commissions,
                row.original.entry.fees,
                settings.calculationType
              ) // here we do not want trailing 0s chopped
            );
            if (netPL === "") {
              return "";
            } else {
              const netPLFinal = supportedCryptosCheck
                ? netPLCrypto + " " + String(thedefaultSymbol)
                : he.decode(String(thedefaultSymbol)) + netPLwCommas;
              if (Number(netPL) > 0) {
                return <span className="orderOutcome-Win">{netPLFinal}</span>;
              } else if (Number(netPL) < 0) {
                return <span className="orderOutcome-Loss">{netPLFinal}</span>;
              } else if (Number(netPL) === 0) {
                return <span className="orderOutcome-BE">{netPLFinal}</span>;
              } else {
                return "";
              }
            }
          }
        },
      },
      {
        Header: (header) => {
          const sorted = header.state.sortBy;
          const id = sorted.length && sorted[0].id;
          return !sorted.length || id !== "netPLpercent" ? (
            "Net P/L %"
          ) : (
            <span style={{ color: "#fbd665" }}>{"Net P/L %"}</span>
          );
        },
        id: "netPLpercent",
        accessor: (row) => {
          const PL = parseFloat(
            returnNetPLDollar(
              row.entry.profitLoss,
              row.entry.commissions,
              row.entry.fees,
              settings.calculationType
            )
          );
          const accountBalance = Number(row.balance) - Number(PL);

          const percent = Number(PL / accountBalance) * 100;
          return percent;
        },
        sortType: "basic",
        Cell: ({ row }) => {
          const PL = parseFloat(
            returnNetPLDollar(
              row.original.entry.profitLoss,
              row.original.entry.commissions,
              row.original.entry.fees,
              settings.calculationType
            )
          );
          const accountBalance = Number(row.original.balance) - Number(PL);

          const percent = handleNANGOOD(Number(PL / accountBalance) * 100, "-");
          if (
            percent === "" ||
            row.original.entry.orderType.toLowerCase().replace(/ /gi, "") ===
              "withdrawal" ||
            row.original.entry.orderType.toLowerCase().replace(/ /gi, "") ===
              "deposit"
          ) {
            return "";
          } else {
            if (Number(percent) > 0) {
              return (
                <span className="orderOutcome-Win">
                  {percent.toFixed(3) + " %"}
                </span>
              );
            } else if (Number(percent) < 0) {
              return (
                <span className="orderOutcome-Loss">
                  {percent.toFixed(3) + " %"}
                </span>
              );
            } else if (Number(percent) === 0) {
              return (
                <span className="orderOutcome-BE">
                  {percent.toFixed(3) + " %"}
                </span>
              );
            } else {
              return "";
            }
          }
        },
      },
      {
        Header: (header) => {
          const sorted = header.state.sortBy;
          const id = sorted.length && sorted[0].id;
          return !sorted.length || id !== "entry.orderType" ? (
            "Order Type"
          ) : (
            <span style={{ color: "#fbd665" }}>{"Order Type"}</span>
          );
        },
        id: "entry.orderType",
        accessor: (row) => {
          const entryExecutioninner = row.entry.multiExecution;
          const orderTypeString =
            row.entry.orderType === "Multi-Leg Strategy"
              ? row.entry.orderType +
                getOptionsStrategy(entryExecutioninner, "", row.entry.orderType)
              : row.entry.orderType;
          return orderTypeString;
        },
        sortType: "basic",
        Cell: ({ row }) => {
          const orderType = row.original.entry.orderType.toLowerCase(); // make the value lower case first
          const display =
            orderType && orderType[0].toUpperCase() + orderType.substring(1); // then capitalize the first letter
          if (
            orderType.toLowerCase().replace(/ /gi, "") === "long" ||
            orderType.toLowerCase().replace(/ /gi, "") === "longcall" ||
            orderType.toLowerCase().replace(/ /gi, "") === "longput" ||
            orderType.toLowerCase().replace(/ /gi, "") === "buylimit" ||
            orderType.toLowerCase().replace(/ /gi, "") === "buystop"
          ) {
            return (
              <span className="orderType-cell-component orderType-Long">
                {display}
              </span>
            );
          } else if (
            orderType.toLowerCase().replace(/ /gi, "") === "multi-legstrategy"
          ) {
            return longShortDelineation(
              row.original.entry.orderType,
              row.original.entry.multiExecution
            ) === "Long" ? (
              <div className="orderType-cell-component-multi orderType-Long">
                {display}
                <span className="orderType-cell-component-text">
                  {getOptionsStrategy(
                    row.original.entry.multiExecution,
                    "",
                    row.original.entry.orderType
                  )}
                </span>
              </div>
            ) : (
              <div className="orderType-cell-component-multi orderType-Short">
                {display}
                <span className="orderType-cell-component-text">
                  {getOptionsStrategy(
                    row.original.entry.multiExecution,
                    "",
                    row.original.entry.orderType
                  )}
                </span>
              </div>
            );
          } else if (
            orderType.toLowerCase().replace(/ /gi, "") === "short" ||
            orderType.toLowerCase().replace(/ /gi, "") === "shortcall" ||
            orderType.toLowerCase().replace(/ /gi, "") === "shortput" ||
            orderType.toLowerCase().replace(/ /gi, "") === "selllimit" ||
            orderType.toLowerCase().replace(/ /gi, "") === "sellstop"
          ) {
            return (
              <span className="orderType-cell-component orderType-Short">
                {display}
              </span>
            );
          } else if (orderType.toLowerCase().replace(/ /gi, "") === "deposit") {
            return (
              <span className="orderType-cell-component orderType-Deposit">
                {display}
              </span>
            );
          } else if (
            orderType.toLowerCase().replace(/ /gi, "") === "withdrawal"
          ) {
            return (
              <span className="orderType-cell-component orderType-Withdrawal">
                {display}
              </span>
            );
          } else if (orderType.toLowerCase().replace(/ /gi, "") === "open") {
            return (
              <span className="orderType-cell-component orderType-Open">
                {display}
              </span>
            );
          } else if (
            orderType.toLowerCase().replace(/ /gi, "") === "fundingpayment"
          ) {
            return (
              <span className="orderType-cell-component orderType-FundingPayment">
                {row.original.entry.orderType}
              </span>
            );
          } else if (
            orderType.toLowerCase().replace(/ /gi, "") === "approval"
          ) {
            return (
              <span className="orderType-cell-component orderType-Approval">
                {row.original.entry.orderType}
              </span>
            );
          } else if (orderType.toLowerCase().replace(/ /gi, "") === "commit") {
            return (
              <span className="orderType-cell-component orderType-Commit">
                {row.original.entry.orderType}
              </span>
            );
          } else if (orderType.toLowerCase().replace(/ /gi, "") === "nft") {
            return (
              <span className="orderType-cell-component orderType-NFT">
                {row.original.entry.orderType}
              </span>
            );
          } else if (orderType.toLowerCase().replace(/ /gi, "") === "wrap") {
            return (
              <span className="orderType-cell-component orderType-Self">
                {row.original.entry.orderType}
              </span>
            );
          } else if (orderType.toLowerCase().replace(/ /gi, "") === "self") {
            return (
              <span className="orderType-cell-component orderType-Self">
                {row.original.entry.orderType}
              </span>
            );
          } else {
            return "";
          }
        },
      },
      {
        Header: (header) => {
          const headerTo =
            chosenPortTypeLength !== 1
              ? "Symbol"
              : chosenPortType[0] === "Stocks" ||
                chosenPortType[0] === "Options"
              ? "Ticker Symbol"
              : chosenPortType[0] === "Crypto"
              ? "Pair"
              : "Symbol";
          const sorted = header.state.sortBy;
          const id = sorted.length && sorted[0].id;
          return !sorted.length || id !== "entry.symbol.symbols" ? (
            headerTo
          ) : (
            <span style={{ color: "#fbd665" }}>{headerTo}</span>
          );
        },
        accessor: "entry.symbol.symbols",
        id: "entry.symbol.symbols",
        sortType: "basic",
        Cell: (props) => {
          if (props.value === "") {
            return "";
          } else {
            return <span className="symbolcolstyles">{props.value}</span>;
          }
        },
      },
      {
        Header: (header) => {
          const headerTo =
            chosenPortTypeLength !== 1
              ? "Executions"
              : chosenPortType[0] === "Options"
              ? "Legs"
              : "Executions";
          const sorted = header.state.sortBy;
          const id = sorted.length && sorted[0].id;
          return !sorted.length || id !== "executions" ? (
            headerTo
          ) : (
            <span style={{ color: "#fbd665" }}>{headerTo}</span>
          );
        },
        id: "executions",
        sortType: "number",
        accessor: (row) => {
          const entryExecutioninner = row.entry.multiExecution;
          const exitExecutioninner = row.entry.exitExecution;
          let number = entryExecutioninner.length + exitExecutioninner.length;
          return number;
        },
        Cell: ({ row }) => {
          const entryExecutioninner = row.original.entry.multiExecution;
          const exitExecutioninner = row.original.entry.exitExecution;
          let number = entryExecutioninner.length + exitExecutioninner.length;
          if (
            row.original.entry.orderType === "Deposit" ||
            row.original.entry.orderType === "Withdrawal" ||
            row.original.entry.orderType === "Funding Payment" ||
            row.original.entry.orderType === "Commit" ||
            row.original.entry.orderType === "Approval" ||
            row.original.entry.orderType === "Wrap" ||
            row.original.entry.orderType === "Self" ||
            isNaN(number)
          ) {
            return "";
          } else {
            return <span>{number.toFixed(0)}</span>;
          }
        },
      },
      {
        Header: (header) => {
          const headerTo =
            chosenPortTypeLength !== 1
              ? "Units"
              : chosenPortType[0] === "Stocks"
              ? "Shares"
              : chosenPortType[0] === "Options" ||
                chosenPortType[0] === "Futures"
              ? "Contracts"
              : chosenPortType[0] === "Forex"
              ? "Lots"
              : chosenPortType[0] === "Crypto"
              ? "Coins/Tokens"
              : chosenPortType[0] === "CFD"
              ? "Quantity"
              : "Units";
          const sorted = header.state.sortBy;
          const id = sorted.length && sorted[0].id;
          return !sorted.length || id !== "entry.lotSize" ? (
            headerTo
          ) : (
            <span style={{ color: "#fbd665" }}>{headerTo}</span>
          );
        },
        id: "entry.lotSize",
        accessor: (row) => {
          let multiExecutioninner = row.entry.multiExecution;
          let lots = 0;
          multiExecutioninner.forEach((execution) => {
            lots += Number(execution.lotSize);
          });
          return lots;
        },
        sortType: "number",
        Cell: ({ row }) => {
          const multiExecution = row.original.entry.multiExecution;
          let lots = 0;
          multiExecution.forEach((execution) => {
            lots += Number(execution.lotSize);
          });
          if (
            lots === "" ||
            row.original.entry.orderType === "Deposit" ||
            row.original.entry.orderType === "Withdrawal" ||
            row.original.entry.orderType === "Funding Payment" ||
            row.original.entry.orderType === "Commit" ||
            row.original.entry.orderType === "Approval" ||
            row.original.entry.orderType === "Wrap" ||
            row.original.entry.orderType === "Self"
          ) {
            return "";
          } else {
            return (
              <span>
                {lots.toLocaleString("en-US", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 5,
                })}
              </span>
            );
          }
        },
      },
      {
        Header: (header) => {
          const headerTo =
            chosenPortTypeLength !== 1
              ? "Avg. Entry Price"
              : chosenPortType[0] === "Options" ||
                chosenPortType[0] === "Futures"
              ? "Avg. Entry Spot Price"
              : "Avg. Entry Price";
          const sorted = header.state.sortBy;
          const id = sorted.length && sorted[0].id;
          return !sorted.length || id !== "entry.entryPrice" ? (
            headerTo
          ) : (
            <span style={{ color: "#fbd665" }}>{headerTo}</span>
          );
        },
        id: "entry.entryPrice",
        sortType: "number",
        accessor: (row) => {
          const multiExecutioninner = row.entry.multiExecution;
          const orderType = row.entry.orderType;
          let avgEntry = dollarCostAverage(
            multiExecutioninner,
            "entry",
            orderType
          );
          return avgEntry;
        },
        Cell: ({ row }) => {
          const orderType = row.original.entry.orderType;
          const multiExecutioninner = row.original.entry.multiExecution;
          let avg = dollarCostAverage(multiExecutioninner, "entry", orderType);
          if (
            avg === "" ||
            row.original.entry.orderType === "Deposit" ||
            row.original.entry.orderType === "Withdrawal" ||
            row.original.entry.orderType === "Funding Payment" ||
            row.original.entry.orderType === "Commit" ||
            row.original.entry.orderType === "Approval" ||
            row.original.entry.orderType === "Wrap" ||
            row.original.entry.orderType === "Self" ||
            isNaN(avg)
          ) {
            return "";
          } else {
            return <span>{parseFloat(avg)}</span>;
          }
        },
      },
      {
        Header: (header) => {
          const headerTo = "Expected Entry";
          const sorted = header.state.sortBy;
          const id = sorted.length && sorted[0].id;
          return !sorted.length || id !== "entry.expectedEntry" ? (
            headerTo
          ) : (
            <span style={{ color: "#fbd665" }}>{headerTo}</span>
          );
        },
        id: "entry.expectedEntry",
        accessor: "entry.expectedEntry",
        sortType: "number",
      },
      {
        Header: (header) => {
          const headerTo = "Stop Loss";
          const sorted = header.state.sortBy;
          const id = sorted.length && sorted[0].id;
          return !sorted.length || id !== "entry.stopLoss" ? (
            headerTo
          ) : (
            <span style={{ color: "#fbd665" }}>{headerTo}</span>
          );
        },
        id: "entry.stopLoss",
        accessor: "entry.stopLoss",
        sortType: "number",
      },
      {
        Header: (header) => {
          const headerTo = "Take Profit";
          const sorted = header.state.sortBy;
          const id = sorted.length && sorted[0].id;
          return !sorted.length || id !== "entry.takeProfit" ? (
            headerTo
          ) : (
            <span style={{ color: "#fbd665" }}>{headerTo}</span>
          );
        },
        id: "entry.takeProfit",
        accessor: "entry.takeProfit",
        sortType: "number",
      },
      {
        Header: (header) => {
          const headerTo =
            chosenPortTypeLength !== 1
              ? "Avg. Exit Price"
              : chosenPortType[0] === "Options" ||
                chosenPortType[0] === "Futures"
              ? "Avg. Exit Spot Price"
              : "Avg. Exit Price";
          const sorted = header.state.sortBy;
          const id = sorted.length && sorted[0].id;
          return !sorted.length || id !== "entry.exitPrice" ? (
            headerTo
          ) : (
            <span style={{ color: "#fbd665" }}>{headerTo}</span>
          );
        },
        id: "entry.exitPrice",
        sortType: "number",
        accessor: (row) => {
          const exitExecutioninner = row.entry.exitExecution;
          const orderType = row.entry.orderType;
          let avgExit = dollarCostAverage(
            exitExecutioninner,
            "exit",
            orderType
          );
          return avgExit;
        },
        Cell: ({ row }) => {
          const orderType = row.original.entry.orderType;
          const exitExecutioninner = row.original.entry.exitExecution;
          let avg = dollarCostAverage(exitExecutioninner, "exit", orderType);
          if (
            avg === "" ||
            row.original.entry.orderType === "Deposit" ||
            row.original.entry.orderType === "Withdrawal" ||
            row.original.entry.orderType === "Funding Payment" ||
            row.original.entry.orderType === "Commit" ||
            row.original.entry.orderType === "Approval" ||
            row.original.entry.orderType === "Wrap" ||
            row.original.entry.orderType === "Self" ||
            isNaN(avg)
          ) {
            return "";
          } else {
            return <span>{parseFloat(avg)}</span>;
          }
        },
      },
      {
        Header: (header) => {
          const headerTo = "Expected Exit";
          const sorted = header.state.sortBy;
          const id = sorted.length && sorted[0].id;
          return !sorted.length || id !== "entry.expectedExit" ? (
            headerTo
          ) : (
            <span style={{ color: "#fbd665" }}>{headerTo}</span>
          );
        },
        id: "entry.expectedExit",
        accessor: "entry.expectedExit",
        sortType: "number",
      },
      {
        Header: (header) => {
          const headerTo = "Slippage";
          const sorted = header.state.sortBy;
          const id = sorted.length && sorted[0].id;
          return !sorted.length || id !== "slippage" ? (
            headerTo
          ) : (
            <span style={{ color: "#fbd665" }}>{headerTo}</span>
          );
        },
        accessor: (row) => {
          const orderType = row.entry.orderType;
          const multiExecutioninner = row.entry.multiExecution;
          const exitExecutioninner = row.entry.exitExecution;
          const slippage = slippageCalculation(
            multiExecutioninner,
            exitExecutioninner,
            orderType,
            row.entry.selectedPortfolioType,
            row.entry.symbol.pointValue,
            false,
            row.entry.profitLoss
          );
          if (!slippage) {
            return "";
          } else {
            const slippageFinal = supportedCryptosCheck
              ? parseFloat(slippage.toFixed(6)) + " " + String(thedefaultSymbol)
              : he.decode(String(thedefaultSymbol)) +
                numberWithCommas(parseFloat(slippage).toFixed(2));
            return slippageFinal;
          }
        },
        id: "slippage",
        sortType: "number",
        Cell: (props) => {
          const row = props.row;
          const orderType = row.original.entry.orderType;
          const multiExecutioninner = row.original.entry.multiExecution;
          const exitExecutioninner = row.original.entry.exitExecution;

          if (props.value === "") {
            return "";
          } else {
            const slippage = slippageCalculation(
              multiExecutioninner,
              exitExecutioninner,
              orderType,
              row.original.entry.selectedPortfolioType,
              row.original.entry.symbol.pointValue,
              false,
              row.original.entry.profitLoss
            );
            const slippageFinal = supportedCryptosCheck
              ? parseFloat(slippage.toFixed(6)) + " " + String(thedefaultSymbol)
              : he.decode(String(thedefaultSymbol)) +
                numberWithCommas(parseFloat(slippage).toFixed(2));
            if (Number(slippage) > 0) {
              return (
                <span className="orderOutcome-Win-notbold">
                  {slippageFinal}
                </span>
              );
            } else if (Number(slippage) < 0) {
              return (
                <span className="orderOutcome-Loss-notbold">
                  {slippageFinal}
                </span>
              );
            } else if (Number(slippage) === 0) {
              return (
                <span className="orderOutcome-BE-notbold">{slippageFinal}</span>
              );
            } else {
              return "";
            }
          }
        },
      },
      {
        Header: (header) => {
          const headerTo = "Commisions";
          const sorted = header.state.sortBy;
          const id = sorted.length && sorted[0].id;
          return !sorted.length || id !== "entry.commissions" ? (
            headerTo
          ) : (
            <span style={{ color: "#fbd665" }}>{headerTo}</span>
          );
        },
        id: "entry.commissions",
        accessor: "entry.commissions",
        sortType: "number",
        Cell: (props) => {
          const commissionsFinal = supportedCryptosCheck
            ? parseFloat(Number(props.value).toFixed(6)) +
              " " +
              String(thedefaultSymbol)
            : he.decode(String(thedefaultSymbol)) +
              numberWithCommas(Number(props.value).toFixed(2));

          if (props.value === "") {
            return "";
          } else {
            return (
              <span className="commissionscolstyles">{commissionsFinal}</span>
            );
          }
        },
      },
      {
        Header: (header) => {
          const headerTo = "Fees";
          const sorted = header.state.sortBy;
          const id = sorted.length && sorted[0].id;
          return !sorted.length || id !== "entry.fees" ? (
            headerTo
          ) : (
            <span style={{ color: "#fbd665" }}>{headerTo}</span>
          );
        },
        accessor: "entry.fees",
        sortType: "number",
        id: "entry.fees",
        Cell: (props) => {
          const feesFinal = supportedCryptosCheck
            ? parseFloat(Number(props.value).toFixed(6)) +
              " " +
              String(thedefaultSymbol)
            : he.decode(String(thedefaultSymbol)) +
              numberWithCommas(Number(props.value).toFixed(2));

          if (props.value === "") {
            return "";
          } else {
            if (Number(props.value) > 0) {
              return (
                <span className="orderOutcome-Win-notbold">{feesFinal}</span>
              );
            } else if (Number(props.value) < 0) {
              return (
                <span className="orderOutcome-Loss-notbold">{feesFinal}</span>
              );
            } else if (Number(props.value) === 0) {
              return (
                <span className="orderOutcome-BE-notbold">{feesFinal}</span>
              );
            } else {
              return "";
            }
          }
        },
      },
      {
        Header: (header) => {
          const headerTo = "MAE";
          const sorted = header.state.sortBy;
          const id = sorted.length && sorted[0].id;
          return !sorted.length || id !== "entry.maxAdEx" ? (
            headerTo
          ) : (
            <span style={{ color: "#fbd665" }}>{headerTo}</span>
          );
        },
        id: "entry.maxAdEx",
        accessor: "entry.maxAdEx",
        sortType: "number",
      },
      {
        Header: (header) => {
          const headerTo = "MFE";
          const sorted = header.state.sortBy;
          const id = sorted.length && sorted[0].id;
          return !sorted.length || id !== "entry.maxFavEx" ? (
            headerTo
          ) : (
            <span style={{ color: "#fbd665" }}>{headerTo}</span>
          );
        },
        id: "entry.maxFavEx",
        accessor: "entry.maxFavEx",
        sortType: "number",
      },
      {
        Header: (header) => {
          const headerTo = "Efficiency";
          const sorted = header.state.sortBy;
          const id = sorted.length && sorted[0].id;
          return !sorted.length || id !== "efficiency" ? (
            headerTo
          ) : (
            <span style={{ color: "#fbd665" }}>{headerTo}</span>
          );
        },
        id: "efficiency",
        sortType: "number",
        accessor: (row) => {
          const orderType = row.entry.orderType;
          const multiExecutioninner = row.entry.multiExecution;
          let avgEntry = dollarCostAverage(
            multiExecutioninner,
            "entry",
            orderType
          );
          const exitExecutioninner = row.entry.exitExecution;
          let avgExit = dollarCostAverage(
            exitExecutioninner,
            "exit",
            orderType
          );
          if (row.entry.orderType === "Long") {
            return (
              100 *
              ((avgExit - avgEntry) / (row.entry.maxFavEx - row.entry.maxAdEx))
            );
          } else if (row.entry.orderType === "Short") {
            return (
              100 *
              ((avgEntry - avgExit) / (row.entry.maxAdEx - row.entry.maxFavEx))
            );
          } else {
            return 0;
          }
        },
        Cell: ({ row }) => {
          return <EfficiencyBar row={row.original.entry} width={"100%"} />;
        },
      },
      {
        Header: (header) => {
          const headerTo = "Risked %";
          const sorted = header.state.sortBy;
          const id = sorted.length && sorted[0].id;
          return !sorted.length || id !== "riskpercent" ? (
            headerTo
          ) : (
            <span style={{ color: "#fbd665" }}>{headerTo}</span>
          );
        },
        id: "riskpercent",
        accessor: (row) => {
          const orderType = row.entry.orderType;
          const multiExecutioninner = row.entry.multiExecution;
          let avgEntry = dollarCostAverage(
            multiExecutioninner,
            "entry",
            orderType
          );
          const exitExecutioninner = row.entry.exitExecution;
          let avgExit = dollarCostAverage(
            exitExecutioninner,
            "exit",
            orderType
          );
          const rmultiple =
            (avgExit - avgEntry) / (avgEntry - Number(row.entry.stopLoss));
          const adjrrr = -avgEntry / (avgEntry - Number(row.entry.stopLoss));

          const divisor =
            row.entry.selectedPortfolioType === "Forex" ? rmultiple : adjrrr;
          // COMPUTE % GAIN/LOSS

          const initPl = profitLossCalculation(
            multiExecutioninner,
            [],
            orderType,
            row.entry.selectedPortfolioType,
            row.entry.symbol.pointValue,
            true,
            pnlmethod
          );
          const finalPl =
            row.entry.selectedPortfolioType === "Forex"
              ? returnNetPLDollar(
                  row.entry.profitLoss,
                  row.entry.commissions,
                  row.entry.fees,
                  settings.calculationType
                )
              : profitLossCalculation(
                  multiExecutioninner,
                  exitExecutioninner,
                  orderType,
                  row.entry.selectedPortfolioType,
                  row.entry.symbol.pointValue,
                  false,
                  pnlmethod
                );
          const accountBalance = Number(row.balance) - Number(finalPl);

          const dividend =
            row.entry.selectedPortfolioType === "Forex"
              ? returnNetPLDollar(
                  row.entry.profitLoss,
                  row.entry.commissions,
                  row.entry.fees,
                  settings.calculationType
                )
              : returnNetPLDollar(
                  initPl,
                  row.entry.commissions,
                  row.entry.fees,
                  settings.calculationType
                );

          // If the asset class if forex we have to calculate risk a slightly different way
          const percent = Number(dividend / accountBalance) * 100;
          const riskedPercent = percent / divisor;
          return riskedPercent.toFixed(3);
        },
        sortType: "number",
        Cell: ({ row }) => {
          const orderType = row.original.entry.orderType;
          const multiExecutioninner = row.original.entry.multiExecution;
          let avgEntry = dollarCostAverage(
            multiExecutioninner,
            "entry",
            orderType
          );
          const exitExecutioninner = row.original.entry.exitExecution;
          let avgExit = dollarCostAverage(
            exitExecutioninner,
            "exit",
            orderType
          );
          const rmultiple =
            (avgExit - avgEntry) /
            (avgEntry - Number(row.original.entry.stopLoss));
          const adjrrr =
            -avgEntry / (avgEntry - Number(row.original.entry.stopLoss));

          const divisor =
            row.original.entry.selectedPortfolioType === "Forex"
              ? rmultiple
              : adjrrr;
          // COMPUTE % GAIN/LOSS

          const initPl = profitLossCalculation(
            multiExecutioninner,
            [],
            orderType,
            row.original.entry.selectedPortfolioType,
            row.original.entry.symbol.pointValue,
            true,
            pnlmethod
          );
          const finalPl =
            row.original.entry.selectedPortfolioType === "Forex"
              ? returnNetPLDollar(
                  row.original.entry.profitLoss,
                  row.original.entry.commissions,
                  row.original.entry.fees,
                  settings.calculationType
                )
              : profitLossCalculation(
                  multiExecutioninner,
                  exitExecutioninner,
                  orderType,
                  row.original.entry.selectedPortfolioType,
                  row.original.entry.symbol.pointValue,
                  false,
                  pnlmethod
                );
          const accountBalance = Number(row.original.balance) - Number(finalPl);

          const dividend =
            row.original.entry.selectedPortfolioType === "Forex"
              ? returnNetPLDollar(
                  row.original.entry.profitLoss,
                  row.original.entry.commissions,
                  row.original.entry.fees,
                  settings.calculationType
                )
              : returnNetPLDollar(
                  initPl,
                  row.original.entry.commissions,
                  row.original.entry.fees,
                  settings.calculationType
                );

          // If the asset class if forex we have to calculate risk a slightly different way
          const percent = Number(dividend / accountBalance) * 100;
          const riskedPercent = percent / divisor;
          const riskColor = getRiskedPercentColor(riskedPercent);
          if (riskedPercent === "" || !riskedPercent) {
            return "";
          } else {
            return (
              <div style={{ color: riskColor, fontWeight: 400 }}>
                {riskedPercent.toFixed(3) + "%"}
              </div>
            );
          }
        },
      },
      {
        Header: (header) => {
          const headerTo = "RRR";
          const sorted = header.state.sortBy;
          const id = sorted.length && sorted[0].id;
          return !sorted.length || id !== "rewardriskratio" ? (
            headerTo
          ) : (
            <span style={{ color: "#fbd665" }}>{headerTo}</span>
          );
        },
        id: "rewardriskratio",
        sortType: "number",
        accessor: (row) => {
          const orderType = row.entry.orderType;
          const multiExecutioninner = row.entry.multiExecution;
          let avgEntry = dollarCostAverage(
            multiExecutioninner,
            "entry",
            orderType
          );
          return (
            100 *
            ((row.entry.takeProfit - avgEntry) /
              (avgEntry - row.entry.stopLoss))
          );
        },
        Cell: ({ row }) => {
          const orderType = row.original.entry.orderType;
          const multiExecutioninner = row.original.entry.multiExecution;
          let avgEntry = dollarCostAverage(
            multiExecutioninner,
            "entry",
            orderType
          );
          if (
            row.original.entry.takeProfit === "" ||
            avgEntry === "" ||
            row.original.entry.stopLoss === ""
          ) {
            return "";
          } else {
            return (
              (
                (row.original.entry.takeProfit - avgEntry) /
                (avgEntry - row.original.entry.stopLoss)
              ).toFixed(3) + " : 1"
            );
          }
        },
      },
      {
        Header: (header) => {
          const headerTo = "R-Multiple";
          const sorted = header.state.sortBy;
          const id = sorted.length && sorted[0].id;
          return !sorted.length || id !== "rmultiple" ? (
            headerTo
          ) : (
            <span style={{ color: "#fbd665" }}>{headerTo}</span>
          );
        },
        id: "rmultiple",
        sortType: "number",
        accessor: (row) => {
          const orderType = row.entry.orderType;
          const multiExecutioninner = row.entry.multiExecution;
          let avgEntry = dollarCostAverage(
            multiExecutioninner,
            "entry",
            orderType
          );
          const exitExecutioninner = row.entry.exitExecution;
          let avgExit = dollarCostAverage(
            exitExecutioninner,
            "exit",
            orderType
          );
          return (avgExit - avgEntry) / (avgEntry - row.entry.stopLoss);
        },
        Cell: ({ row }) => {
          const orderType = row.original.entry.orderType;
          const multiExecutioninner = row.original.entry.multiExecution;
          let avgEntry = dollarCostAverage(
            multiExecutioninner,
            "entry",
            orderType
          );
          const exitExecutioninner = row.original.entry.exitExecution;
          let avgExit = dollarCostAverage(
            exitExecutioninner,
            "exit",
            orderType
          );
          if (
            avgExit === "" ||
            avgEntry === "" ||
            row.original.entry.stopLoss === ""
          ) {
            return "";
          } else {
            return (
              (
                (avgExit - avgEntry) /
                (avgEntry - row.original.entry.stopLoss)
              ).toFixed(3) + " R"
            );
          }
        },
      },
      {
        Header: (header) => {
          const headerTo = "Strategies";
          const sorted = header.state.sortBy;
          const id = sorted.length && sorted[0].id;
          return !sorted.length || id !== "entry.strategy" ? (
            headerTo
          ) : (
            <span style={{ color: "#fbd665" }}>{headerTo}</span>
          );
        },
        id: "entry.strategy",
        accessor: "entry.strategy",
        sortType: "basic",
        Cell: (props) => {
          if (props.value === "" || !props.value) {
            return "";
          } else {
            const value = props.value;
            const returnThis = value.map((strat) => {
              return <span className="strategyscolstyles">{strat}</span>;
            });
            return returnThis;
          }
        },
      },
      {
        Header: (header) => {
          const headerTo = "Confidence";
          const sorted = header.state.sortBy;
          const id = sorted.length && sorted[0].id;
          return !sorted.length || id !== "entry.selectedConfidence" ? (
            headerTo
          ) : (
            <span style={{ color: "#fbd665" }}>{headerTo}</span>
          );
        },
        id: "entry.selectedConfidence",
        sortType: "basic",
        accessor: (row) =>
          row.entry.selectedConfidence === "Very High"
            ? 5
            : row.entry.selectedConfidence === "High"
            ? 4
            : row.entry.selectedConfidence === "Medium"
            ? 3
            : row.entry.selectedConfidence === "Low"
            ? 2
            : row.entry.selectedConfidence === "Very Low"
            ? 1
            : 0,
        Cell: (props) => {
          if (props.value === 5) {
            return <span className="confidence-veryhigh">{"Very High"}</span>;
          } else if (props.value === 4) {
            return <span className="confidence-high">{"High"}</span>;
          } else if (props.value === 3) {
            return <span className="confidence-medium">{"Medium"}</span>;
          } else if (props.value === 2) {
            return <span className="confidence-low">{"Low"}</span>;
          } else if (props.value === 1) {
            return <span className="confidence-verylow">{"Very Low"}</span>;
          } else {
            if (props.row.original.entry.selectedConfidence === "") {
              return "";
            } else {
              return props.row.original.entry.selectedConfidence;
            }
          }
        },
      },
      {
        Header: (header) => {
          const headerTo = "Emotional States";
          const sorted = header.state.sortBy;
          const id = sorted.length && sorted[0].id;
          return !sorted.length || id !== "entry.selectedEmotion" ? (
            headerTo
          ) : (
            <span style={{ color: "#fbd665" }}>{headerTo}</span>
          );
        },
        id: "entry.selectedEmotion",
        accessor: "entry.selectedEmotion",
        sortType: "basic",
        Cell: (props) => {
          if (props.value === "" || !props.value) {
            return "";
          } else {
            const value = props.value;
            const returnThis = value.map((strat) => {
              return <span className="emotionsscolstyles">{strat}</span>;
            });
            return returnThis;
          }
        },
      },
      {
        Header: (header) => {
          const headerTo = "Physical States";
          const sorted = header.state.sortBy;
          const id = sorted.length && sorted[0].id;
          return !sorted.length || id !== "entry.selectedPhysical" ? (
            headerTo
          ) : (
            <span style={{ color: "#fbd665" }}>{headerTo}</span>
          );
        },
        id: "entry.selectedPhysical",
        accessor: "entry.selectedPhysical",
        sortType: "basic",
        Cell: (props) => {
          if (props.value === "" || !props.value) {
            return "";
          } else {
            const value = props.value;
            const returnThis = value.map((strat) => {
              return <span className="physicalscolstyles">{strat}</span>;
            });
            return returnThis;
          }
        },
      },
      {
        Header: (header) => {
          const headerTo = "Market State";
          const sorted = header.state.sortBy;
          const id = sorted.length && sorted[0].id;
          return !sorted.length || id !== "entry.selectedMarket" ? (
            headerTo
          ) : (
            <span style={{ color: "#fbd665" }}>{headerTo}</span>
          );
        },
        id: "entry.selectedMarket",
        accessor: "entry.selectedMarket",
        sortType: "basic",
        Cell: (props) => {
          if (props.value === "" || !props.value) {
            return "";
          } else {
            return <span className="marketcolstyles">{props.value}</span>;
          }
        },
      },
      {
        Header: (header) => {
          const headerTo = "Time Frame";
          const sorted = header.state.sortBy;
          const id = sorted.length && sorted[0].id;
          return !sorted.length || id !== "entry.selectedTimeframe" ? (
            headerTo
          ) : (
            <span style={{ color: "#fbd665" }}>{headerTo}</span>
          );
        },
        id: "entry.selectedTimeframe",
        accessor: "entry.selectedTimeframe",
        sortType: "basic",
        Cell: (props) => {
          if (props.value === "" || !props.value) {
            return "";
          } else {
            return <span className="timeframescolstyles">{props.value}</span>;
          }
        },
      },
      {
        Header: (header) => {
          const headerTo = "Mistakes";
          const sorted = header.state.sortBy;
          const id = sorted.length && sorted[0].id;
          return !sorted.length || id !== "entry.selectedMistake" ? (
            headerTo
          ) : (
            <span style={{ color: "#fbd665" }}>{headerTo}</span>
          );
        },
        id: "entry.selectedMistake",
        accessor: "entry.selectedMistake",
        sortType: "basic",
        Cell: (props) => {
          if (props.value === "" || !props.value) {
            return "";
          } else {
            const value = props.value;
            const returnThis = value.map((strat) => {
              return <span className="mistakescolstyles">{strat}</span>;
            });
            return returnThis;
          }
        },
      },
      {
        Header: (header) => {
          const headerTo = "Tags";
          const sorted = header.state.sortBy;
          const id = sorted.length && sorted[0].id;
          return !sorted.length || id !== "entry.tags" ? (
            headerTo
          ) : (
            <span style={{ color: "#fbd665" }}>{headerTo}</span>
          );
        },
        id: "entry.tags",
        accessor: "entry.tags",
        sortType: "basic",
        Cell: (props) => {
          if (props.value === "" || !props.value) {
            return "";
          } else {
            const value = props.value;
            const returnThis = value.map((strat) => {
              return <span className="tagscolstyles">{strat}</span>;
            });
            return returnThis;
          }
        },
      },
    ],
    [
      settings.calculationType,
      chosenPortType,
      chosenPortTypeLength,
      thedefaultSymbol,
      supportedCryptosCheck,
    ]
  );

  const newarr = settingsListFunction();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns: columns,
      data: data,
      initialState: {
        hiddenColumns: newarr,
        pageIndex: 0,
        pageSize: 25,
        sortBy: sortBy,
      },
      useRowSelect,
      autoResetPage: false,
      autoResetSortBy: false,
    },
    useSortBy,
    usePagination
  );

  return (
    <div className="containviewhist" ref={componentRef}>
      <div className="view-history-label" id="view-history-label">
        Logbook
      </div>
      <PopoverStickOnHover
        placement="bottom"
        delay={300}
        xAdjust={23}
        yAdjust={0}
        keepOpen={false}
        component={<div style={{ color: "#fa798b" }}>{`Delete Entries`}</div>}
      >
        <div
          onClick={handleDeleteTrades}
          className="deleteTradeButton"
          id="deleteTradeButton"
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </div>
      </PopoverStickOnHover>
      <PopoverStickOnHover
        placement="bottom"
        delay={300}
        xAdjust={23}
        yAdjust={0}
        keepOpen={false}
        component={<div style={{ color: "#fff7cb" }}>{`Tag Entries`}</div>}
      >
        <div
          onClick={handleTagTrades}
          className="tagTradeButton"
          id="tagTradeButton"
        >
          <FontAwesomeIcon icon={faTag} />
        </div>
      </PopoverStickOnHover>
      {tierShow && missingTradesCheck && (
        <div className="upgradelogbookbutton">
          Missing trades?
          <div
            id="promocodeelement2"
            onClick={() =>
              history.push({
                pathname: "/Manage-Account",
                state: {
                  account: "none",
                  subscription: "block",
                  actions: "none",
                },
              })
            }
          >
            <div id="promocodeelementmaintextold">Upgrade</div>
          </div>
        </div>
      )}
      <div className="pagination">
        <div
          className="paginationbuttonslg"
          onClick={async () => {
            await gotoPage(0);
            if (pageIndex * pageSize + 1 !== 1) {
              document.getElementById("boxHeaderChecks").checked = false;
            }
          }}
          disabled={!canPreviousPage}
        >
          <FontAwesomeIcon icon={faAngleDoubleLeft} />
        </div>{" "}
        <div
          className="paginationbuttons1"
          onClick={async () => {
            await previousPage();
            if (pageIndex * pageSize + 1 !== 1) {
              document.getElementById("boxHeaderChecks").checked = false;
            }
          }}
          disabled={!canPreviousPage}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </div>{" "}
        <div
          className="paginationbuttons2"
          onClick={async () => {
            await nextPage();
            if (data.length > (pageIndex + 1) * pageSize) {
              document.getElementById("boxHeaderChecks").checked = false;
            }
          }}
          disabled={!canNextPage}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </div>{" "}
        <div
          className="paginationbuttonslg paginationbuttonslg2"
          onClick={async () => {
            await gotoPage(pageOptions.length - 1);
            if (data.length > (pageIndex + 1) * pageSize) {
              document.getElementById("boxHeaderChecks").checked = false;
            }
          }}
          disabled={!canNextPage}
        >
          <FontAwesomeIcon icon={faAngleDoubleRight} />
        </div>{" "}
        <span className="pageindexwrapper">
          <strong>
            {pageIndex * pageSize + 1}{" "}
            {pageIndex * pageSize + 1 ===
            ((pageIndex + 1) * pageSize > data.length
              ? data.length
              : (pageIndex + 1) * pageSize)
              ? ""
              : (pageIndex + 1) * pageSize > data.length
              ? " - " + data.length
              : " - " + (pageIndex + 1) * pageSize}{" "}
            of {data.length}
          </strong>{" "}
        </span>
        <div style={{ width: 100, marginTop: -5, marginLeft: 8 }}>
          <Select
            onChange={async (e) => {
              await setPageSize(Number(e.value));
            }}
            options={[
              { label: "10", value: "10" },
              { label: "25", value: "25" },
              { label: "50", value: "50" },
              { label: "100", value: "100" },
            ]}
            theme={theme}
          />
        </div>
        <PopoverStickOnHover
          placement="bottom"
          delay={300}
          xAdjust={23}
          yAdjust={0}
          keepOpen={false}
          component={<div style={{ color: "#deebf7" }}>Print</div>}
        >
          <div onClick={handlePrint} className="printButtonWrapper">
            <FontAwesomeIcon icon={faPrint} className="printButton" />
          </div>
        </PopoverStickOnHover>
        <PopoverStickOnHover
          placement="bottom"
          delay={300}
          xAdjust={23}
          yAdjust={0}
          keepOpen={false}
          component={<div style={{ color: "#deebf7" }}>Choose Columns</div>}
        >
          <div
            className="historycolumnchoosewrapper"
            onClick={() => handleColumnChoosing(settings)}
          >
            <FontAwesomeIcon icon={faColumns} className="chooseColumnsButton" />
          </div>
        </PopoverStickOnHover>
      </div>
      <div
        {...getTableProps()}
        id="history-table"
        style={{
          textAlign: "center",
          /* height: width <= "481px" ? tableheight - 108 : tableheight - 120, */
          display: "block",
          overflow: "scroll",
        }}
        className="history-table viewtablecols"
        pagination="true"
      >
        <table
          id="innerHistoryTable"
          className="innerHistoryTable"
          style={{
            paddingBottom:
              54 *
                (data === undefined
                  ? 0
                  : (pageIndex + 1) * pageSize > data.length
                  ? data.length - pageIndex * pageSize
                  : pageSize) >
              tableheight - 256
                ? 0
                : tableheight -
                  256 -
                  54 *
                    (data === undefined
                      ? 0
                      : (pageIndex + 1) * pageSize > data.length
                      ? data.length - pageIndex * pageSize
                      : pageSize),
            height: "auto",
            display: "table",
            width: "100%",
          }}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={uuidv4()}
                  >
                    {column.render("Header")}
                    <span key={uuidv4()}>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon
                            icon={faCaretDown}
                            className="sortingcaret"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faCaretUp}
                            className="sortingcaret"
                          />
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} style={{ width: "100%" }}>
            {page.map((row) => {
              prepareRow(row);
              const portfolioName = row.original.entry.selectedPortfolio;
              let createdPortfolioMatching = createdPortfolios.slice();
              createdPortfolioMatching = createdPortfolioMatching.filter(
                (port) => {
                  return port.name === portfolioName;
                }
              );
              const portfolioColorPre =
                createdPortfolioMatching[0]?.settings?.portfolioColor;
              const colorRGB = portfolioColorPre && hexToRgb(portfolioColorPre);
              const portfolioColor =
                colorRGB &&
                `rgba(${colorRGB.r}, ${colorRGB.g}, ${colorRGB.b}, 0.1)`;

              return (
                <tr
                  {...row.getRowProps()}
                  style={{
                    height: 52,
                    backgroundColor:
                      (ismaster ? portfolioColor : "") || "transparent",
                  }}
                  key={uuidv4()}
                >
                  {row.cells.map((cell) => {
                    if (cell.column.id === "checkboxcolumn") {
                      return (
                        <td
                          {...cell.getCellProps()}
                          style={{ height: 52 }}
                          key={uuidv4()}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    } else {
                      return (
                        <td
                          {...cell.getCellProps()}
                          onClick={(b) => {
                            handleRowClick(row, b);
                            b.stopPropagation();
                          }}
                          style={{ height: 52 }}
                          key={uuidv4()}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EntryTable;
