import { v4 as uuidv4 } from "uuid";
import profitLossCalculation from "./../../../functions/profitLossCalculation";
import getPointValue from "../../../functions/getFuturesPointValue";
import { montharr, weekday } from "./../../../arrays/weekandmonth";
import {
  getEndDateTime,
  getStartDateTime,
} from "./../../../functions/getStartorEndDateTime";
import { filterByID } from "./../../../functions/filterByID";
const crypto = require("crypto");

function generateHash(inputString) {
  return crypto.createHash("sha256").update(inputString).digest("hex");
}
export const tradeovate = (
  readerEvent,
  temptrades,
  settemptrades,
  csvToArray,
  userId,
  portfolio,
  settrades,
  buttonFile,
  setinvalidFile,
  pnlmethod,
  entries // currently logged entries
) => {
  var content = readerEvent.target.result; // =====> this is the content!

  let tradeArray = csvToArray(content);
  tradeArray.pop(); // removes headers from incoming file
  if (!readerEvent) {
    setinvalidFile(true);
    return;
  }
  settemptrades(tradeArray);
  let trade = "";
  let trades = [...entries];
  let fullTradearray = [];
  if (
    !temptrades ||
    temptrades === "" ||
    temptrades === undefined ||
    temptrades === null
  ) {
  } else {
    if (buttonFile === "Cash History") {
      temptrades.forEach((trade) => {
        fullTradearray.push(trade); // push Cash History
      });
      tradeArray.forEach((trade) => {
        fullTradearray.push(trade); // push Orders
      });
    } else if (buttonFile === "Orders") {
      tradeArray.forEach((trade) => {
        fullTradearray.push(trade); // push Cash History
      });
      temptrades.forEach((trade) => {
        fullTradearray.push(trade); // push Orders
      });
    }
    fullTradearray.sort(function (a, b) {
      var c = new Date(!a["Fill Time"] ? a.Timestamp : a["Fill Time"]);
      var d = new Date(!b["Fill Time"] ? b.Timestamp : b["Fill Time"]);
      return c - d;
    });
    const getFees = (arr, starti) => {
      let fees = 0;
      for (let i = starti + 1, j = arr.length; i < j; i++) {
        let entryType = "";
        if (
          arr[i]["Status"] === "Canceled" ||
          arr[i]["Status"] === "Rejected"
        ) {
        } else {
          if (!arr[i]["Fill Time"]) {
            entryType = "Cash";
          } else {
            entryType = "Order";
          }
          if (entryType === "Order") {
            break;
          }
          if (
            arr[i]["Currency"] === "Exchange Fee" ||
            arr[i]["Currency"] === "Nfa Fee" ||
            arr[i]["Currency"] === "Clearing Fee" ||
            arr[i]["Currency"] === "Commission"
          ) {
            fees += Number(arr[i]["Delta"]);
          } else {
          }
        }
      }
      return fees;
    };
    /* 
    const getMultibracketSide = (arr, starti) => {
      // iterate through the next few entries until we either hit the "Trade Paired" or another ORDER (NOT CASH HISTORY)
      // if it hits the trade paired before the order, it's a close
      let multiBracketSide = "";
      for (let i = starti + 1, j = arr.length; i < j; i++) {
        let entryType = "";
        let currency = arr[i]["Currency"];
        if (
          arr[i]["Status"] === "Canceled" ||
          arr[i]["Status"] === "Rejected"
        ) {
        } else {
          if (!arr[i]["Fill Time"]) {
            entryType = "Cash";
          } else {
            entryType = "Order";
          }
          if (entryType === "Order") {
            break;
          }
          if (currency === "Trade Paired") {
            multiBracketSide = "Close";
            break;
          } else {
            multiBracketSide = "Open";
          }
        }
      }
      return multiBracketSide;
    }; */

    const verified = 0; // could be 1 in the future
    for (let i = 0, j = fullTradearray.length; i < j; i++) {
      if (
        fullTradearray[i]["Contract"] === "Fund Transaction" ||
        fullTradearray[i]["Currency"] === "Fund Transaction"
      ) {
        const myString = String(fullTradearray[i]["Transaction ID"]);

        const hashValue = generateHash(myString);
        let mutableTrades = entries.slice(); // array of user trades that can be edited
        // Filter only those trades that share at least one hash with 'trade'
        const foundTrade = mutableTrades.find((trade) =>
          trade.entry.hashes?.includes(hashValue)
        );

        var startDateTime = new Date(fullTradearray[i]["Timestamp"]);
        let delta = fullTradearray[i]["Delta"];
        delta = delta.replace(/["]+/g, "");
        // if the contract is a fund transaction, we have to create the amount
        // from "Amount" and "Delta" props
        if (fullTradearray[i]["Contract"] === "Fund Transaction") {
          let amount = fullTradearray[i]["Amount"];
          amount = amount.replace(/["]+/g, "");
          let totalDelta = Number(delta + amount);
          delta = totalDelta;
        } else {
        }
        trade = {
          entryId: uuidv4(),
          entry: {
            pictures: ["N/A"],
            symbol: {
              symbols: [],
              pointValue: 0,
            },
            strategy: "",
            selectedConfidence: "",
            selectedEmotion: "",
            selectedPhysical: "",
            selectedMarket: "",
            selectedTimeframe: "",
            selectedMistake: "",
            selectedPortfolio: portfolio.name,
            selectedPortfolioType: "",
            orderType: Number(delta) > 0 ? "Deposit" : "Withdrawal",
            orderNumber: fullTradearray[i]["Transaction ID"],
            dayOfWeek: weekday[startDateTime.getDay()],
            monthOfYear: montharr[startDateTime.getMonth()],
            stopLoss: "",
            takeProfit: "",
            profitLoss: Number(delta),
            commissions: 0,
            fees: getFees(fullTradearray, i),
            maxAdEx: "",
            maxFavEx: "",
            comments: "",
            multiExecution: [],
            exitExecution: [],
            startDateTime: startDateTime,
            endDateTime: startDateTime,
            tags: "",
            verifiedLevel: verified,
            hashes: [hashValue],
            idLinks: [],
          },
        };

        if (foundTrade) {
          // do nothing
        } else {
          trades = trades.concat(trade);
        }
      } else {
        const myString = String(fullTradearray[i]["orderId"]);

        const hashValue = generateHash(myString);
        let mutableTrades = entries.slice(); // array of user trades that can be edited
        // Filter only those trades that share at least one hash with 'trade'
        const foundTrade = mutableTrades.find((trade) =>
          trade.entry.hashes?.includes(hashValue)
        );
        // look for open trades in "trades" (not the current iteration!)
        // that matches the symbol
        let openTradesPre = [...trades];
        let openTradesPre2 = [...trades];
        let openTradesMatch = openTradesPre.filter((trade) =>
          filterByID(trade, false, fullTradearray[i]["Contract"])
        );
        let openTradesNOTMatch = openTradesPre2.filter((trade) =>
          filterByID(trade, true, fullTradearray[i]["Contract"])
        );
        // --------------------------

        // ---------- Determine if it's an opening or closing entry -----------
        let currency = fullTradearray[i]["Currency"];
        let contract = fullTradearray[i]["Contract"];
        let definedText = fullTradearray[i]["Text"];
        let definedType = fullTradearray[i]["Type"];
        // --------------------------

        // if the order was canceled or rejected dont do anything
        // also skip "Trade Paired" Cash entries - not needed
        if (
          fullTradearray[i]["Status"] === "Canceled" ||
          fullTradearray[i]["Status"] === "Rejected" ||
          currency === "Trade Paired" ||
          contract === "Trade Paired" ||
          currency === "Nfa Fee" ||
          currency === "Clearing Fee" ||
          currency === "Exchange Fee" ||
          currency === "Commission"
        ) {
          // Don't do anything here
        } else {
          let startDateTime = new Date(fullTradearray[i]["Fill Time"]);
          //let timestamp = new Date(fullTradearray[i]["Timestamp"]);
          let orderType = fullTradearray[i]["B/S"] === "Buy" ? "Long" : "Short";
          //let delta = fullTradearray[i]["Delta"];

          let entryexecution = {
            id: uuidv4(),
            lotSize: Number(fullTradearray[i]["filledQty"]),
            entryPrice: Number(fullTradearray[i]["Avg Fill Price"]),
            startDateTime: startDateTime,
            expectedEntry: "",
            strikePrice: "",
            expirationDate: "",
            legType: "",
          };
          const openTrade = openTradesMatch[0];
          const opentradeOrderType = openTrade?.entry.orderType;
          if (!openTradesMatch.length) {
            trade = {
              entryId: uuidv4(),
              entry: {
                pictures: ["N/A"],
                symbol: {
                  symbols: [fullTradearray[i]["Contract"]],
                  pointValue: getPointValue(
                    "tradovate",
                    fullTradearray[i]["Product"]
                  ),
                },
                strategy: "",
                selectedConfidence: "",
                selectedEmotion: "",
                selectedPhysical: "",
                selectedMarket: "",
                selectedTimeframe: "",
                selectedMistake: "",
                selectedPortfolio: portfolio.name,
                selectedPortfolioType: "Futures",
                orderType: orderType,
                orderNumber: fullTradearray[i]["orderId"],
                dayOfWeek: weekday[startDateTime.getDay()],
                monthOfYear: montharr[startDateTime.getMonth()],
                stopLoss: "",
                takeProfit: "",
                profitLoss: 0,
                commissions: 0,
                fees: getFees(fullTradearray, i),
                maxAdEx: "",
                maxFavEx: "",
                comments: "",
                multiExecution: [entryexecution],
                exitExecution: [],
                startDateTime: startDateTime,
                endDateTime: "",
                tags: definedText === "multibracket" ? ["Bracket"] : "",
                verifiedLevel: verified,
                hashes: [hashValue],
                idLinks: [],
              },
            };
            if (foundTrade) {
              // do nothing
            } else {
              trades.push(trade);
              openTradesPre = trades;
              openTradesPre2 = trades;
            }
          } else {
            if (orderType !== opentradeOrderType) {
              // -------- Add the exit execution, and calculate profit/loss, fees, and start & end date time stuff -------
              var endDateTime = new Date(fullTradearray[i]["Fill Time"]);
              let exitexecution = {
                id: uuidv4(),
                exitLotSize: Number(fullTradearray[i]["filledQty"]),
                exitPrice: Number(fullTradearray[i]["Avg Fill Price"]),
                endDateTime: endDateTime,
                expectedExit: "",
                exitstrikePrice: "",
                exitexpirationDate: "",
                exitlegType: "",
                equityComponents: [],
                exercised: "",
              };
              const openTrade = openTradesMatch[0];
              const openTradeEntry = openTrade?.entry;

              // -------- Add the exit execution, and calculate profit/loss, fees, and start & end date time stuff -------
              const opentrademultiExecution = openTradeEntry.multiExecution;
              const opentradeOrderType = openTradeEntry.orderType;
              const opentradeSymbol = openTradeEntry.symbol;
              const opentradeFees = openTradeEntry.fees;

              const opentradesType = openTradeEntry.selectedPortfolioType;
              const newExitExecution = [
                ...openTradeEntry.exitExecution,
                exitexecution,
              ];
              // calculate absolute start date time

              let startDateTimez = getStartDateTime(opentrademultiExecution);
              let endDateTimez = getEndDateTime(newExitExecution);
              const entry = Object.assign({}, openTradeEntry, {
                exitExecution: newExitExecution,
                profitLoss: Number(
                  profitLossCalculation(
                    opentrademultiExecution,
                    newExitExecution,
                    opentradeOrderType,
                    opentradesType,
                    opentradeSymbol.pointValue,
                    false,
                    pnlmethod
                  )
                ),
                hashes: [...openTradeEntry.hashes, hashValue],
                endDateTime: endDateTimez,
                startDateTime: startDateTimez,
                fees: opentradeFees + getFees(fullTradearray, i),
              });
              const closedTrade = Object.assign({}, openTrade, {
                entry: entry,
              });
              if (foundTrade) {
                // do nothing
              } else {
                openTradesNOTMatch.push(closedTrade);
                trades = openTradesNOTMatch;
                openTradesPre = openTradesNOTMatch;
                openTradesPre2 = openTradesNOTMatch;
              }
            } else {
              // Add new opening executions
              const openTrade = openTradesMatch[0];
              const openTradeEntry = openTrade?.entry;
              const opentradeFeesin = openTradesMatch[0].entry.fees;
              const entry = Object.assign({}, openTradeEntry, {
                multiExecution: [
                  ...openTradesMatch[0].entry.multiExecution,
                  entryexecution,
                ],
                hashes: [...openTradeEntry.hashes, hashValue],
                fees: opentradeFeesin + getFees(fullTradearray, i),
              });
              const closedTrade = Object.assign({}, openTrade, {
                entry: entry,
              });
              if (foundTrade) {
                // do nothing
              } else {
                openTradesNOTMatch.push(closedTrade);
                trades = openTradesNOTMatch;
                openTradesPre = openTradesNOTMatch;
                openTradesPre2 = openTradesNOTMatch;
              }
            }
          }
        }
      }
    }
  }
  settrades(trades);
};
export default tradeovate;
