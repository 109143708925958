import getSession from "../../functions/getSession";
import getEntryExitLots from "../../functions/getEntryExitLots";
import profitLossCalculation from "./../../functions/profitLossCalculation";
import { returnNetPLDollar } from "./../../functions/profitLossCalculation";
const displayAsCurrency = (val) => {
  return Number(val).toFixed(2);
};
export const filterTheTrades = (
  filteredTradesPre,
  whatToCompareProps,
  selectedItems,
  dateFilterBy,
  allTradesPre,
  pnlmethod
) => {
  if (!whatToCompareProps || !selectedItems) {
    return filteredTradesPre;
  }

  let filteredTrades = filteredTradesPre.slice();
  let allTrades = allTradesPre.slice();

  whatToCompareProps.forEach((filterCriteria, index) => {
    const whatToCompare = filterCriteria.label;
    const whatToCompareProp = filterCriteria.prop;
    const selectedItem = selectedItems[index].items;
    switch (whatToCompare) {
      case "Confidences":
      case "Time Frames":
      case "Order Types":
      case "Asset Classes":
      case "Market States":
        filteredTrades = filteredTrades.filter((trade) =>
          selectedItem.some(
            (item) => trade.entry[whatToCompareProp] === item.label
          )
        );
        break;
      case "Portfolios":
        filteredTrades = allTrades.filter((trade) =>
          selectedItem.some(
            (item) => trade.entry[whatToCompareProp] === item.label
          )
        );
        break;
      case "Symbols":
        filteredTrades = filteredTrades.filter((trade) =>
          selectedItem.some(
            (item) => trade.entry.symbol.symbols[0] === item.label
          )
        );
        break;
      case "Strategies":
      case "Emotional States":
      case "Tags":
      case "Physical States":
      case "Mistakes":
        filteredTrades = filteredTrades.filter(
          (trade) =>
            trade.entry[whatToCompareProp] !== null &&
            selectedItem.some((item) =>
              trade.entry[whatToCompareProp].includes(item.label)
            )
        );
        break;
      case "Months":
        filteredTrades = filteredTrades.filter((trade) => {
          const dateTime = new Date(
            dateFilterBy === "Trade Open"
              ? trade.entry.startDateTime
              : trade.entry.endDateTime
          );
          const monthIndex = dateTime.getMonth(); // This will be an integer from 0 (January) to 11 (December)
          const monthName = [
            "january",
            "february",
            "march",
            "april",
            "may",
            "june",
            "july",
            "august",
            "september",
            "october",
            "november",
            "december",
          ][monthIndex];
          return (
            dateTime !== null &&
            selectedItem.some((item) => item.value === monthName)
          );
        });
        break;
      case "Days of Week":
        filteredTrades = filteredTrades.filter((trade) => {
          const dateTime = new Date(
            dateFilterBy === "Trade Open"
              ? trade.entry.startDateTime
              : trade.entry.endDateTime
          );
          const dayOfWeekIndex = dateTime.getDay(); // This will be an integer from 0 (Sunday) to 6 (Saturday)
          const dayOfWeekName = [
            "sunday",
            "monday",
            "tuesday",
            "wednesday",
            "thursday",
            "friday",
            "saturday",
          ][dayOfWeekIndex];
          return (
            dateTime !== null &&
            selectedItem.some((item) => item.value === dayOfWeekName)
          );
        });
        break;

      case "Trading Sessions":
        filteredTrades = filteredTrades.filter((trade) => {
          const dateTime = new Date(
            dateFilterBy === "Trade Open"
              ? trade.entry.startDateTime
              : trade.entry.endDateTime
          );
          const tradingSession = getSession(dateTime);
          return (
            dateTime !== null &&
            selectedItem.some((item) => tradingSession === item.label)
          );
        });
        break;
      case "Years":
        filteredTrades = filteredTrades.filter((trade) => {
          const tradeYear = new Date(trade.entry.startDateTime).getFullYear();
          return selectedItem.some(
            (item) => tradeYear === parseInt(item.label, 10)
          );
        });
        break;
      case "Hour of Day":
        // Assuming selectedItem is structured differently for "Hour of Day"
        // This case might need adjustment based on actual data structure
        filteredTrades = filteredTrades.filter((trade) => {
          const tradeStartTime = new Date(
            dateFilterBy === "Trade Open"
              ? trade.entry.startDateTime
              : trade.entry.endDateTime
          );
          const tradeHour = tradeStartTime.getHours();
          return selectedItem.some((item) => {
            const [startRange, endRange] = item.value
              .split(" - ")
              .map((time) => parseInt(time.substring(0, 2), 10));
            if (startRange <= endRange) {
              return tradeHour >= startRange && tradeHour <= endRange;
            } else {
              return tradeHour >= startRange || tradeHour <= endRange;
            }
          });
        });
        break;
      case "Custom Date Range":
        // Assuming selectedItem is structured similarly to "Hour of Day"
        // with an array of objects each having a label and value representing date ranges
        filteredTrades = filteredTrades.filter((trade) => {
          const tradeDate = new Date(
            dateFilterBy === "Trade Open"
              ? trade.entry.startDateTime
              : trade.entry.endDateTime
          );
          return selectedItem.some((item) => {
            const [startDateString, endDateString] = item.value.split(" - ");
            const startDate = new Date(startDateString);
            const endDate = new Date(endDateString);
            return tradeDate >= startDate && tradeDate <= endDate;
          });
        });
        break;
      case "Win, Loss, or BreakEven":
        filteredTrades = filteredTrades.filter((trade) => {
          const supportedCryptosCheck = true; // This is a placeholder. Adjust according to your context.
          const settings = {}; // Placeholder for settings, assuming it's defined elsewhere in your code.

          // Simplified net profit/loss calculation without toFixed and displayAsCurrency
          const netProfitLoss = supportedCryptosCheck
            ? [
                "Deposit",
                "Withdrawal",
                "Funding Payment",
                "Commit",
                "Approval",
                "Wrap",
                "Self",
              ].includes(trade.entry.orderType) ||
              trade.entry.selectedPortfolioType === "Forex"
              ? parseFloat(trade.entry.profitLoss)
              : returnNetPLDollar(
                  Number(
                    profitLossCalculation(
                      trade.entry.multiExecution,
                      trade.entry.exitExecution,
                      trade.entry.orderType,
                      trade.entry.selectedPortfolioType,
                      trade.entry.symbol.pointValue,
                      false,
                      pnlmethod
                    )
                  ),
                  Number(trade.entry.commissions),
                  Number(trade.entry.fees),
                  settings.calculationType
                )
            : returnNetPLDollar(
                [
                  "Deposit",
                  "Withdrawal",
                  "Funding Payment",
                  "Commit",
                  "Approval",
                  "Wrap",
                  "Self",
                ].includes(trade.entry.orderType) ||
                  trade.entry.selectedPortfolioType === "Forex"
                  ? Number(trade.entry.profitLoss)
                  : Number(
                      profitLossCalculation(
                        trade.entry.multiExecution,
                        trade.entry.exitExecution,
                        trade.entry.orderType,
                        trade.entry.selectedPortfolioType,
                        trade.entry.symbol.pointValue,
                        false,
                        pnlmethod
                      )
                    ),
                Number(trade.entry.commissions),
                Number(trade.entry.fees),
                settings.calculationType
              );

          // Determine the trade outcome
          const tradeOutcome =
            netProfitLoss > 0
              ? "Win"
              : netProfitLoss < 0
              ? "Loss"
              : "Break-Even";

          // Filter trades based on the matching outcome in selectedItem
          return selectedItem.some((item) => item.value === tradeOutcome);
        });
        break;

      case "Trade Indexes":
        // Convert filteredTrades to an array of trades with their original index
        const indexedTrades = filteredTrades.map((trade, index) => ({
          trade,
          index,
        }));

        // Filter based on index or index range
        filteredTrades = indexedTrades
          .filter(({ trade, index }) =>
            selectedItem.some((item) => {
              let startIndex, endIndex;

              // Check if the item.value is a range or a single index
              if (item.value.includes(" - ")) {
                // It's a range
                const [startIndexString, endIndexString] =
                  item.value.split(" - ");
                startIndex = parseInt(startIndexString, 10);
                endIndex = parseInt(endIndexString, 10);
              } else {
                // It's a single index
                startIndex = endIndex = parseInt(item.value, 10);
              }

              // Adjust if your indexes are one-based or otherwise
              return index >= startIndex && index <= endIndex;
            })
          )
          .map(({ trade }) => trade); // Extract the trades from the filtered indexedTrades
        break;
      case "Volume":
        filteredTrades = filteredTrades.filter((trade) => {
          const entryExecs = trade.entry.multiExecution;
          const exitExecs = trade.entry.exitExecution;
          const entryExitValues = getEntryExitLots(entryExecs, exitExecs);
          const totalVolume =
            entryExitValues.entryLots + entryExitValues.exitLots;

          return selectedItem.some((item) => {
            let minVolume = 0,
              maxVolume = Infinity;

            if (item.value.includes("+")) {
              minVolume = parseFloat(item.value.replace("+", ""));
            } else if (item.value.includes("-")) {
              const [minVolumeString, maxVolumeString] =
                item.value.split(" - ");
              minVolume = parseFloat(minVolumeString);
              // Check for infinity symbol and set maxVolume accordingly
              if (maxVolumeString.trim() === "∞") {
                maxVolume = Infinity;
              } else {
                maxVolume = parseFloat(maxVolumeString);
              }
            } else if (item.value.includes("<")) {
              maxVolume = parseFloat(item.value.replace("<", ""));
            } else if (item.value.includes(">")) {
              minVolume = parseFloat(item.value.replace(">", "")) + 1;
            } else {
              // Handling for exact volume, though it's not specified, it could be useful
              minVolume = maxVolume = parseFloat(item.value);
            }

            return totalVolume >= minVolume && totalVolume <= maxVolume;
          });
        });

        break;
      default:
        // Handle any unhandled filter types
        break;
    }
  });
  return filteredTrades;
};
export default filterTheTrades;
