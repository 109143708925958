import React, {
  Fragment,
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
  useRef,
} from "react";
import supportedCryptos from "../../arrays/isCurrencyCrypto";
import profitLossCalculation from "./../../functions/profitLossCalculation";
import dollarCostAverage from "../../functions/dollarCostAvg";
import { returnNetPLDollar } from "./../../functions/profitLossCalculation";
const displayAsCurrency = (val) => {
  return Number(val).toFixed(2);
};
function numberWithCommas(x) {
  return x && x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
let he = require("he");

const getMonthWeeks = (groupsData, year, month) => {
  // month is 1-based (January=1), ensure you are passing month correctly
  const startDate = new Date(year, month - 1, 1);
  const endDate = new Date(year, month, 0); // last day of the month
  // We'll create a map of dateStrings for quick lookup in groupsData
  // groupsData keys are like "11/7/2024"
  // We need to ensure leading zeros if necessary for uniform date keys
  // but given the data structure, keys seem to be M/D/YYYY.

  // Helper function to format dates into M/D/YYYY
  function formatDate(dateObj) {
    const m = dateObj.getMonth() + 1;
    const d = dateObj.getDate();
    const y = dateObj.getFullYear();
    return `${m}/${d}/${y}`;
  }

  // Determine how many days in the month
  const totalDays = endDate.getDate();

  // We'll iterate day by day
  // A "week" in this context: The first Sunday of the month determines week start.
  // Or we can consider that the first day of the month is in "week 0".
  // The "week number" can be found by (dayIndex + firstDayOffset)/7
  // Where firstDayOffset is the day of week offset of the 1st of the month.

  const firstDayOfWeek = startDate.getDay(); // 0=Sunday, 1=Monday, etc.

  // We will store weeks in an array
  const weeks = [];

  for (let day = 1; day <= totalDays; day++) {
    const currentDate = new Date(year, month - 1, day);
    const dateStr = formatDate(currentDate);

    // Calculate which week this day falls into
    // For example, if the 1st is a Friday (day=5), that means 1st belongs to weekIndex = 0 anyway.
    // weekIndex = Math.floor((day - 1 + firstDayOfWeek)/7)
    const weekIndex = Math.floor((day - 1 + firstDayOfWeek) / 7);

    // Ensure we have the week object created
    if (!weeks[weekIndex]) {
      weeks[weekIndex] = {
        weekNumber: weekIndex,
        dates: [],
        groups: {},
      };
    }

    // Add this date to the week
    weeks[weekIndex].dates.push(dateStr);

    // If we have data for this date in groupsData, add it to groups
    if (
      groupsData &&
      Object.prototype.hasOwnProperty.call(groupsData, dateStr)
    ) {
      // We know groupsData[dateStr] exists now
      // If you want to prevent any accidental mutation, do a clone:
      // const clonedData = JSON.parse(JSON.stringify(groupsData[dateStr]));
      // weeks[weekIndex].groups[dateStr] = clonedData;

      // If no mutation concerns, just assign directly:
      weeks[weekIndex].groups[dateStr] = groupsData[dateStr];
    }
  }

  return weeks;
};

export const WeeklyComponent = (props) => {
  const groups = props.groups;
  const year = props.year;
  const month = props.month + 1;
  const portfolio = props.portfolio;
  const thedefaultSymbol = portfolio?.settings.currencySymbol;
  const supportedCryptosCheck = supportedCryptos.includes(thedefaultSymbol);

  // Function to adjust the height of .weeklymaincompwrapper to match .rbc-month-view
  function adjustWeeklyCalHeight() {
    const monthView = document.querySelector(".rbc-month-view");
    const weeklyCalWrapper = document.querySelector(".weeklymaincompwrapper");
    var height =
      window.innerHeight > 0 ? window.innerHeight : window.screen.height;
    var width = window.innerWidth > 0 ? window.innerWidth : window.screen.width;
    const heightMultiplier = width < 1680 || height < 900 ? 1.25 : 1;

    if (monthView && weeklyCalWrapper) {
      const monthViewHeight = monthView.getBoundingClientRect().height;
      weeklyCalWrapper.style.height = `${monthViewHeight * heightMultiplier}px`;
    }
  }

  // Debounce function to limit how often adjustWeeklyCalHeight is called
  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  const debouncedAdjust = debounce(adjustWeeklyCalHeight, 100);

  // Run the adjustment initially
  adjustWeeklyCalHeight();

  // Set up a ResizeObserver to watch for size changes in .rbc-month-view
  const monthView = document.querySelector(".rbc-month-view");
  if (monthView) {
    const resizeObserver = new ResizeObserver(() => {
      debouncedAdjust();
    });
    resizeObserver.observe(monthView);
  }

  // Optional: Add an event listener to adjust on window resize
  window.addEventListener("resize", debouncedAdjust);

  const weeklyobj = getMonthWeeks(groups, year, month);
  const divsofWeeks = () => {
    return weeklyobj.map((week) => {
      const numTrades = Object.values(week.groups).reduce((total, entry) => {
        // Ensure that numTrades exists and is a number
        if (
          entry.hasOwnProperty("numTrades") &&
          typeof entry.numTrades === "number"
        ) {
          return total + entry.numTrades;
        }
        return total;
      }, 0);

      const profitLoss = Object.values(week.groups).reduce((total, entry) => {
        // Ensure that numTrades exists and is a number
        if (
          entry.hasOwnProperty("profitLoss") &&
          typeof entry.numTrades === "number"
        ) {
          return total + entry.profitLoss;
        }
        return total;
      }, 0);
      // This is the net P/L

      const netprofitLoss = supportedCryptosCheck
        ? parseFloat(profitLoss).toFixed(6)
        : displayAsCurrency(profitLoss);
      const color =
        netprofitLoss > 0
          ? "#55f8a6"
          : netprofitLoss < 0
          ? "#fa798b"
          : "#808080";
      return (
        <div className="weeklymaincompweeks" key={week.weekNumber}>
          <div className="weeklymaincompweekstitle">Week {week.weekNumber}</div>
          <div className="weeklymaincompweeksnumtrades">
            Trades: {numTrades}
          </div>{" "}
          <div
            className="weeklymaincompweeksprofitLoss"
            style={{ color: color }}
          >
            {supportedCryptosCheck
              ? netprofitLoss + " " + he.decode(String(thedefaultSymbol))
              : he.decode(String(thedefaultSymbol)) +
                numberWithCommas(netprofitLoss)}
          </div>
        </div>
      );
    });
  };

  return (
    <div className="weeklycalcomponentwrapper">
      <div className="weeklycalcomponentwrappertitle">{"Weekly Overview"}</div>
      <div className="weeklymaincompwrapper">{divsofWeeks()}</div>
    </div>
  );
};
export default WeeklyComponent;
