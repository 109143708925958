export async function getSP500Data() {
  const proxyURL = "https://api.tradefuse.app/getSP500Data";

  try {
    const dataIn = {};
    const response = await fetch(proxyURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        Host: proxyURL,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods":
          "OPTIONS, DELETE, POST, GET, PATCH, PUT",
        "Access-Control-Allow-Headers": "Content-Type, Authorization",
      },
      body: JSON.stringify(dataIn),
    }).catch((err) => {
      return null;
    });

    // If the fetch fails or returns null, return an empty array
    if (!response) {
      return [];
    }

    // Safely handle the JSON parsing
    try {
      const res = await response.json();
      return res;
    } catch (jsonError) {
      return [];
    }
  } catch (error) {
    // Catch any other unexpected errors
    return [];
  }
}

export default getSP500Data;

  