/* import { ETrade } from "e-trade-api"; */

/* const eTrade = new ETrade({
  key: "key",
  secret: "secret",
}); */
import { getPortfoliowSpecificBrokerIntegration } from "../getSpecificPortfolio";
import $ from "jquery";
import {
  getRobinhoodCryptoInstrument,
  getNewRobinhoodTransactions,
} from "../../functions/robinhood/utils";
import parseRobinhoodOrders from "./parsers/robinhood";
import addNewEntriesandIds from "./../autoimport/addNewEntriesandIds";
import putUserData from "../../utils/AWS/putS3UserObject";
import updateStripeCustomerWrapper from "./../../utils/stripe/updateStripeCustomer";
const NodeRSA = require("node-rsa");
const key = new NodeRSA();
const privatePem = `-----BEGIN RSA PRIVATE KEY-----${process.env.REACT_APP_PRIVATE_KEY}-----END RSA PRIVATE KEY-----`;
key.importKey(privatePem, "pkcs1-pem");
const mergeTradesfromPolling = async (
  allData,
  setallData,
  brokerInfoArray,
  userIdIN,
  history
) => {
  const userId = userIdIN;
  const verified = 2; // because it's fully automated
  let allDataRef = allData.data;
  let changedIds = [];
  // have to call getSpecificPortfolio b/c we haven't called processDataandSettings yet
  let newtrades = 0;
  const runAllBrokerFetch = async (allDataRefIn) => {
    let allDataRefInner = allDataRefIn;
    for (var ie = 0, je = brokerInfoArray.length; ie < je; ie++) {
      switch (brokerInfoArray[ie].broker) {
        case "robinhood":
          let portfolio = {};
          if (Object.keys(allDataRefInner).length === 0) {
          } else {
            portfolio = getPortfoliowSpecificBrokerIntegration(
              allDataRefInner,
              brokerInfoArray[ie].integrationId
            );
          }
          const entries = portfolio?.entries;
          const pnlmethod = portfolio?.settings.pnlcalculationmethod;

          let trades = entries && [...entries];
          const idsToCheck = brokerInfoArray[ie] && [
            ...brokerInfoArray[ie].ids,
          ];

          const accessToken = localStorage.getItem(
            `robinhoodToken${brokerInfoArray[ie].portfolio}`
          );
          /*           let accessToken =
            accessTokenPre && key.decrypt(accessTokenPre, "utf8");
          accessToken = accessToken?.replace(/"/g, ""); */

          if (!accessToken) {
            // if the access token doesn't exist, don't do anything
          } else {
            const robinhoodCryptoInstrumentsResponse =
              await getRobinhoodCryptoInstrument(null);
            const robinhoodCryptoInstruments =
              robinhoodCryptoInstrumentsResponse &&
              robinhoodCryptoInstrumentsResponse.gotRobinhoodCryptoInstruments
                .instrument.results;
            const robinhoodData = await getNewRobinhoodTransactions(
              portfolio,
              idsToCheck,
              accessToken
            );
            const robinhoodOrders =
              robinhoodData && robinhoodData.robinhoodOrders;
            // Filter crypto instrument by Id
            const filtercryptoByID = (id) => {
              const filteredInstrument = robinhoodCryptoInstruments?.filter(
                (instrument) => instrument.id === id
              );
              return filteredInstrument[0]?.symbol;
            };

            let tradedata = robinhoodOrders;
            let ids = [];

            // SORT BEFORE DOING STUFF TO tradedata
            tradedata &&
              tradedata.sort(function (a, b) {
                var c = new Date(
                  !a["created_at"]
                    ? !a["updated_at"]
                      ? a["initiated_at"]
                      : a["updated_at"]
                    : a["created_at"]
                );
                var d = new Date(
                  !b["created_at"]
                    ? !b["updated_at"]
                      ? b["initiated_at"]
                      : b["updated_at"]
                    : b["created_at"]
                );
                return d - c;
              });
            tradedata && tradedata.reverse();
            // PARSE INCOMING ROBINHOOD ORDERS
            // CREATE NEW IDS ARRAY
            const parsedOrdersandIds = await parseRobinhoodOrders(
              trades,
              ids,
              portfolio,
              tradedata,
              userId,
              filtercryptoByID,
              verified,
              accessToken,
              pnlmethod
            );

            trades = parsedOrdersandIds.trades;
            ids = parsedOrdersandIds.ids;

            changedIds.push(...parsedOrdersandIds.changedIds);
            // ADD THE NEW ENTRIES TO THE CORRESPONDING EXISTING DATA
            newtrades = newtrades + tradedata.length;
            const preSetEntriesandIds = addNewEntriesandIds(
              allDataRefInner,
              portfolio,
              ids,
              trades
            );
            allDataRefInner["createdPortfolios"] = preSetEntriesandIds;
          }
          break;
        case "coinbase":
          break;
        case "interactivebrokers":
          break;
        case "etrade":
          try {
            /*         const requestTokenResults = await eTrade.requestToken();
      console.log(requestTokenResults)
      window.open(requestTokenResults.url);
      const accessTokenResults = await eTrade.getAccessToken({
        key: requestTokenResults.oauth_token,
        secret: requestTokenResults.oauth_token_secret,
        code: 'code from requestTokenResults.url'
      });
  
      eTrade.settings.accessToken = accessTokenResults.oauth_token;
      eTrade.settings.accessSecret = accessTokenResults.oauth_token_secret; */
          } catch {}
          break;
        default:
      }
    }

    return allDataRefInner;
  };
  const allDataRefReturn = await runAllBrokerFetch(allDataRef);

  const finalPush = {
    chosenPortfolios: allDataRefReturn.chosenPortfolios,
    createdPortfolios: allDataRefReturn.createdPortfolios,
    globalSettings: allDataRefReturn.globalSettings,
    version: Number(allDataRefReturn.version) + 1,
    sharedTrades: allDataRefReturn.sharedTrades,
  };
  // If no new messages come in, do nothing
  if (newtrades === 0) {
  } else {
    localStorage.setItem("incomingMsg", "yes");
    $(window).bind("beforeunload", function () {
      return ">>>>>Before You Go<<<<<<<< \n Your custom message go here";
    });
    localStorage.setItem("dbSetRunning", "yes");
    setallData({ data: finalPush });

    // CREATE "PULSE" FOR INCOMING TRADE DATA
    $(".openpositionswrapper > table > tbody > tr").each(function () {
      const thisEl = $(this);
      thisEl.removeClass("pulsestyling");
      const entryId = thisEl[0].children[0].innerText;
      if (changedIds.includes(entryId)) {
        thisEl.addClass("pulsestyling");
        setTimeout(() => {
          thisEl.removeClass("pulsestyling");
        }, 2000);
      }
    });

    // SET S3 DATA
    const S3Data = {
      data: finalPush,
      userId: userId,
    };
    const customerId =
      finalPush === null ? "" : finalPush.globalSettings.stripeId;
    await updateStripeCustomerWrapper(
      customerId,
      finalPush,
      "trades",
      userId,
      history
    );

    const finalAwait = async () => {
      await putUserData(S3Data, history);
      $(window).unbind("beforeunload");
      localStorage.setItem("dbSetRunning", "no");
    };
    await finalAwait();
  }
};
export default mergeTradesfromPolling;
