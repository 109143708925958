const pointValues = {
  // Indices
  ES: 50,
  MES: 5,
  MNQ: 2,
  EMD: 100,
  FESX: 10,
  NQ: 20,
  MYM: 0.5,
  YM: 5,
  B5: 100,
  TEC: 100,
  FDAX: 25,
  NIY: 500,
  NKD: 5,
  FDXM: 5,
  FDXS: 1,
  RTY: 50,
  FVS: 100,
  FXXP: 50,
  FSXE: 1,
  MME: 50,

  // Currencies
  "6E": 125000,
  "6C": 100000,
  "6J": 12500000,
  "6A": 100000,
  "6B": 62500, // Note: 6B appears twice with different values in your example. This needs clarification.
  "6M": 500000,
  DX: 1000,
  M6E: 12500,
  "6N": 100000,
  "6S": 125000,
  E7: 62500,
  M6A: 10000,
  M6B: 6250,
  J7: 6250000,
  MJY: 1250000,
  MCD: 10000,
  MSF: 12500,
  // Financials
  ZN: 1000,
  ZF: 1000,
  FGBL: 1000,
  FGBM: 1000,
  FGBS: 1000,
  FGBX: 1000,
  ZT: 2000,
  ZB: 1000,
  UB: 1000,
  TN: 1000,
  "10Y": 1000,
  "30Y": 1000,
  "5YY": 1000,
  "2YY": 1000,
  // Energies
  BRN: 1000,
  NG: 10000,
  CL: 1000,
  HO: 42000,
  RB: 42000,
  OIL: 100,
  ULS: 100,
  QM: 500,
  WBS: 1000,
  BZ: 1000,
  QG: 2500,
  // Metals
  GC: 100,
  HG: 25000,
  SI: 5000,
  PL: 50,
  PA: 100,
  MGC: 10,
  SIL: 1000,
  YG: 32.15,
  YI: 1000,
  QI: 2500,
  QO: 50,
  // Grains
  ZC: 50,
  ZS: 50,
  ZW: 50,
  ZL: 600,
  ZM: 100,
  KE: 50,
  ZO: 50,
  XK: 10,
  XW: 10,
  XC: 10,
  ZR: 2000,
  // Meats
  LE: 400,
  GF: 500,
  HE: 400,
  // Softs
  SB: 1120,
  KC: 375,
  CC: 10,
  CT: 500,
  OJ: 150,
  LBS: 110,
  // Crypto
  MBT: 0.1,
  BTC: 5,
  MET: 0.1,
  ETH: 50,
  // Miscellaneous
  DXH2: 1000,
};

export const getPointValue = (broker, product) => {
  let pointValue = 0;
  for (const [key, value] of Object.entries(pointValues)) {
    if (product?.includes(key)) {
      pointValue = value;
      break; // Stops at the first match, assuming no overlapping symbols.
    }
  }
  return pointValue;
};

export const getPointKey = (broker, product) => {
  let pointKey = 0;
  for (const [key, value] of Object.entries(pointValues)) {
    if (product?.includes(key)) {
      pointKey = key;
      break; // Stops at the first match, assuming no overlapping symbols.
    }
  }
  return pointKey;
};

export default getPointValue;
