import e from "cors";

const fixRound = (num) => {
  return Math.round(num * 1e10) / 1e10;
};
export const filterByID = (trade, anti, instrument) => {
  const entryExecs = trade.entry.multiExecution;
  const symbol = trade.entry.symbol.symbols[0];
  const symbol2 = instrument;
  let entryLots = 0;
  entryExecs.forEach((exec) => {
    entryLots += Number(exec.lotSize);
  });
  let exitLots = 0;
  const exitExecs = trade.entry.exitExecution;
  exitExecs.forEach((exec) => {
    exitLots += Number(exec.exitLotSize);
  });
  entryLots = fixRound(entryLots); // fix rounding issue
  exitLots = fixRound(exitLots);
  if (
    exitLots !== entryLots &&
    symbol === symbol2 &&
    trade.entry.selectedPortfolioType !== "Options"
  ) {
    return anti ? false : true;
  } else {
    return anti ? true : false;
  }
};

export const filterByIDandType = (trade, anti, instrument, type, entryType) => {
  const entryExecs = trade.entry.multiExecution;
  const orderType = trade.entry.orderType;
  const orderType2 = type;
  const symbol = trade.entry.symbol.symbols[0];
  const symbol2 = instrument;
  let entryLots = 0;
  entryExecs.forEach((exec) => {
    entryLots += Number(exec.lotSize);
  });
  let exitLots = 0;
  const exitExecs = trade.entry.exitExecution;
  exitExecs.forEach((exec) => {
    exitLots += Number(exec.exitLotSize);
  });
  entryLots = fixRound(entryLots); // fix rounding issue
  exitLots = fixRound(exitLots);

  // if the deal type from metatrader is "DEAL_ENTRY_IN" then we look for the same order type
  // but if its the DEAL_ENTRY_OUT version, we look for the opposite
  // for example, if we are in a short, we should be looking for a short orderType, but the incoming deal will be
  // "DEAL_ENTRY_OUT" and type "long/buy", because it's buy to exit, so we look for the opposite
  if (entryType === "DEAL_ENTRY_IN") {
    if (
      orderType === orderType2 &&
      exitLots !== entryLots &&
      symbol === symbol2 &&
      trade.entry.selectedPortfolioType !== "Options"
    ) {
      return anti ? false : true;
    } else {
      return anti ? true : false;
    }
  } else {
    if (
      orderType !== orderType2 &&
      exitLots !== entryLots &&
      symbol === symbol2 &&
      trade.entry.selectedPortfolioType !== "Options"
    ) {
      return anti ? false : true;
    } else {
      return anti ? true : false;
    }
  }
};


// Webull options filtering
export const filterByIDWebullOption = (
  trade,
  anti,
  instrument,
  rhType,
  lastVarIN
) => {
  const entryExecs = trade.entry.multiExecution;
  const symbol = trade.entry.symbol.symbols[0];
  const symbol2 = instrument;
  const legs = rhType === "Option" && lastVarIN;
  const eventData = rhType === "optionevent" && lastVarIN;

  let optionsidcheck = false;
  // For this check, we return true if there is an option with the same expiration, leg type, and strike price
  if (rhType === "Option") {
    legs.forEach((leg) => {
      const optionStrike = leg["strikePrice"];
      const optionExpirationNewDatePre = new Date(leg["expirationDate"]);
      const optionExpirationNewDate = new Date(
        optionExpirationNewDatePre.getTime() +
          optionExpirationNewDatePre.getTimezoneOffset() * 60000
      ); //this converts the "YYYY-MM-DD" string to the correct date time object

      const optionType = leg["legType"];
      const optionSide = leg["side"];
      const buysellcheck = optionSide === "Buy" ? "sell" : "buy";
      let optionTypeF = "";
      if (optionType === "Call") {
        optionTypeF = buysellcheck === "buy" ? "Long Call" : "Short Call";
      } else {
        optionTypeF = buysellcheck === "buy" ? "Long Put" : "Short Put";
      }
      entryExecs.forEach((execution) => {
        if (
          Number(execution.strikePrice) === Number(optionStrike) &&
          String(execution.expirationDate) ===
            String(optionExpirationNewDate) &&
          String(execution.legType) === String(optionTypeF)
        ) {
          optionsidcheck = true;
        }
      });
    });
  } else {
    // it an option event here
    const optionStrike = eventData.strikePrice;
    const optionExpirationNewDatePre = new Date(eventData.expirationDate);
    const optionExpirationNewDate = new Date(
      optionExpirationNewDatePre.getTime() +
        optionExpirationNewDatePre.getTimezoneOffset() * 60000
    ); //this converts the "YYYY-MM-DD" string to the correct date time object

    const optionLegType = eventData.legType;

    entryExecs.forEach((execution) => {
      if (
        Number(execution.strikePrice) === Number(optionStrike) &&
        String(execution.expirationDate) === String(optionExpirationNewDate) &&
        String(execution.legType) === String(optionLegType)
      ) {
        optionsidcheck = true;
      }
    });
  }

  let entryLots = 0;
  entryExecs.forEach((exec) => {
    entryLots += Number(exec.lotSize);
  });
  let exitLots = 0;
  const exitExecs = trade.entry.exitExecution;
  exitExecs.forEach((exec) => {
    exitLots += Number(exec.exitLotSize);
  });
  entryLots = fixRound(entryLots); // fix rounding issue
  exitLots = fixRound(exitLots);
  if (
    optionsidcheck &&
    exitLots !== entryLots &&
    symbol === symbol2 &&
    trade.entry.selectedPortfolioType === "Options"
  ) {
    return anti ? false : true;
  } else {
    return anti ? true : false;
  }
};

// robinhood options filtering
export const filterByIDRHOption = (
  trade,
  anti,
  instrument,
  rhType,
  lastVarIN
) => {
  const entryExecs = trade.entry.multiExecution;
  const symbol = trade.entry.symbol.symbols[0];
  const symbol2 = instrument;
  const legs = rhType === "option" && lastVarIN;
  const eventData = rhType === "optionevent" && lastVarIN;

  let optionsidcheck = false;
  // For this check, we return true if there is an option with the same expiration, leg type, and strike price
  if (rhType === "option") {
    legs.forEach((leg) => {
      const optionStrike = leg["strike_price"];
      const optionExpirationNewDatePre = new Date(leg["expiration_date"]);
      const optionExpirationNewDate = new Date(
        optionExpirationNewDatePre.getTime() +
          optionExpirationNewDatePre.getTimezoneOffset() * 60000
      ); //this converts the "YYYY-MM-DD" string to the correct date time object

      const optionType = leg["option_type"];
      const optionSide = leg["side"];
      const optionEffect = leg["position_effect"];
      const buysellcheck =
        optionEffect === "open"
          ? optionSide
          : optionSide === "buy"
          ? "sell"
          : "buy";
      let optionTypeF = "";
      if (optionType === "call") {
        optionTypeF = buysellcheck === "buy" ? "Long Call" : "Short Call";
      } else {
        optionTypeF = buysellcheck === "buy" ? "Long Put" : "Short Put";
      }
      entryExecs.forEach((execution) => {
        if (
          Number(execution.strikePrice) === Number(optionStrike) &&
          String(execution.expirationDate) ===
            String(optionExpirationNewDate) &&
          String(execution.legType) === String(optionTypeF)
        ) {
          optionsidcheck = true;
        }
      });
    });
  } else {
    // it an option event here
    const optionStrike = eventData.strikePrice;
    const optionExpirationNewDatePre = new Date(eventData.expirationDate);
    const optionExpirationNewDate = new Date(
      optionExpirationNewDatePre.getTime() +
        optionExpirationNewDatePre.getTimezoneOffset() * 60000
    ); //this converts the "YYYY-MM-DD" string to the correct date time object

    const optionLegType = eventData.legType;

    entryExecs.forEach((execution) => {
      if (
        Number(execution.strikePrice) === Number(optionStrike) &&
        String(execution.expirationDate) === String(optionExpirationNewDate) &&
        String(execution.legType) === String(optionLegType)
      ) {
        optionsidcheck = true;
      }
    });
  }

  let entryLots = 0;
  entryExecs.forEach((exec) => {
    entryLots += Number(exec.lotSize);
  });
  let exitLots = 0;
  const exitExecs = trade.entry.exitExecution;
  exitExecs.forEach((exec) => {
    exitLots += Number(exec.exitLotSize);
  });
  entryLots = fixRound(entryLots); // fix rounding issue
  exitLots = fixRound(exitLots);
  if (
    optionsidcheck &&
    exitLots !== entryLots &&
    symbol === symbol2 &&
    trade.entry.selectedPortfolioType === "Options"
  ) {
    return anti ? false : true;
  } else {
    return anti ? true : false;
  }
};
export default filterByID;
