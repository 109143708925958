import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import supportedCryptos from "../../arrays/isCurrencyCrypto";
import generateRandomColors from "../../functions/generateRandomColors";
import { profitLossCalculation } from "../../functions/profitLossCalculation";
import { returnNetPLDollar } from "../../functions/profitLossCalculation";
import dollarCostAverage from "../../functions/dollarCostAvg";
import { useHistory } from "react-router-dom";
import getStandardDeviation from "../../functions/calculations_for_metrics/standardDeviation";
import filterTheTrades from "./filterTheTrades";
import { getDateDifferencewSeconds } from "../../functions/getDateDifference";
import {
  getMaxDD,
  getMaxDDPercent,
} from "../../functions/calculations_for_metrics/getmaxDD";
import longShortDelineation from "../../functions/longShortDelineation";
import {
  calculateSharpeRatios,
  calculateSortinoRatios,
} from "../../functions/sharpeRatio";
import getSession from "../../functions/getSession";
import ulcerIndex from "../../functions/calculations_for_metrics/ulcerIndex";
import calculateZScore from "../../functions/zScoreCalculation";
import linearRegression from "../../functions/calculations_for_metrics/linearRegression";
import pcorr from "../../functions/calculations_for_metrics/pearsonCorrelation";
import STEYX from "../../functions/calculations_for_metrics/steyx";
import DEVSQ from "../../functions/calculations_for_metrics/DEVSQ";
import getEntryExitLots from "../../functions/getEntryExitLots";

import Modal from "react-modal";

var { jStat } = require("jstat");

function studentsTCumulativeValue(tValue, degreesOfFreedom) {
  // Calculate the cumulative t-distribution
  let cumulativeValue = jStat.studentt.cdf(tValue, degreesOfFreedom);

  // Return the result
  return cumulativeValue;
}
let he = require("he");

const arrAvg = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;
const arrSum = (arr) => arr.reduce((a, b) => a + b, 0);
function findLargestArray(arrays) {
  let largestArray = [];

  for (let i = 0; i < arrays.length; i++) {
    if (arrays[i].length > largestArray.length) {
      largestArray = arrays[i];
    }
  }

  return largestArray;
}

const ComparisonGraph = (props) => {
  const lineColorMain = props.lineColors;
  const whatToCompareSingle = props.selectComparisons.single;
  const whatToCompare = props.selectComparisons.label;
  const whatToCompareProp = props.selectComparisons.prop;
  const metricToCompare = props.metricToCompare;
  const graphType = props.type;

  const riskFreeRate = props.riskFreeRate;
  const selectedItems = props.selectedItems;
  const allEntries = props.allEntries; // used in portfolio comparison
  const allEntriesButFiltered = props.userData; // used in every other comparison

  const graphTitle = (
    <span>
      <span className="metricTocomparegraphheader">{metricToCompare}</span>
      {` of `}
      <span className="whatTocomparegraphheader">{whatToCompare}</span>
    </span>
  );
  const entriespre = props.userData;
  const entries = entriespre.slice();
  const symbol = props.defaultSymbol;
  const settings = props.userSettings;
  const calType = settings.calculationType;
  const pnlmethod = settings.pnlcalculationmethod;
  const supportedCryptosCheck = supportedCryptos.includes(symbol);
  const metricsToCompare = props.metricsToCompare;
  const history = useHistory();
  const dateToggle = settings?.dateFilterBy;

  const toComponentB2 = (row) => {
    // NEED TO REMOVE EDIT TRADE HERE TO ALLOW FOR EDIT TRADES
    // TO MERGE WITH INCOMING WEBSOCKET MSGS
    localStorage.removeItem("editTrade");

    localStorage.removeItem("editTradeId");
    history.push({
      pathname: "/Trade-Details",
      state: row,
    });
  };
  const getEntrybyIndex = (i, filteredTrades) => {
    let entry = {};
    entry = filteredTrades.filter((_, index) => index === i);
    return entry;
  };
  // Helper function to calculate week of the year
  function getWeek(date) {
    let firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    let pastDaysOfYear = (date - firstDayOfYear) / 86400000;
    return (
      date.getFullYear() +
      "-" +
      Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7)
    );
  }

  // Helper function to calculate average
  function calculateAverage(total, count) {
    let perAvg = 0;
    let totalCount = 0;
    for (let key in total) {
      perAvg += total[key];
      totalCount += 1;
    }
    return perAvg / totalCount;
  }
  const metricValues = (data, selectedItems, metric) => {
    let metricValues = [];
    for (let ix = 0, jx = selectedItems.length; ix < jx; ix++) {
      const lineColor = lineColorMain[ix];
      let dataValues = [];
      const selectedItem = selectedItems[ix].label;
      let accountbalancearr = [];
      let plArr = [];
      let percentGainArr = [];
      let percentgainLOSSArray = [];
      let percentgainWINArray = [];
      let totalPL_dollar = 0;
      let totalLosses = 0;
      let totalTrades = 0;
      let totalProfits = 0;
      let riskedPercentArr = [];
      let efficiencesArr = [];
      let timeInMarketArr = [];
      let timeInMarket = 0;
      let numWins = 0;
      let numLosses = 0;
      let numTrades = 0;
      let profitFactor = 0;
      let profitLossRatio = 0;
      let volatility = 0;
      let volatilityPercent = 0;
      let avgHoldingTime = 0;
      let tfIndex = 0;
      let xArray = [];
      let yArray = [];
      let iterationarray = [];
      let dailyPL = {},
        weeklyPL = {},
        monthlyPL = {};
      let dailyCount = {},
        weeklyCount = {},
        monthlyCount = {};

      //First filter by the selected item
      let filteredTrades = "";
      let filteredTradesPre = data.slice();
      filteredTrades = filterTheTrades(
        whatToCompare,
        whatToCompareProp,
        selectedItem,
        filteredTradesPre,
        pnlmethod
      );
      //console.log(whatToCompare, whatToCompareProp, selectedItem);
      for (let i = 0, j = filteredTrades.length; i < j; i++) {
        const thisAccountBalance = Number(filteredTrades[i].balance);
        const entryId = filteredTrades[i].entryId;
        let orderType = filteredTrades[i].entry.orderType;
        let multiExecution = filteredTrades[i].entry.multiExecution;
        let exitExecution = filteredTrades[i].entry.exitExecution;
        const entryExitValues = getEntryExitLots(multiExecution, exitExecution);
        const entryLots = entryExitValues.entryLots;
        const exitLots = entryExitValues.exitLots;
        let x100profitLoss = Number(filteredTrades[i].entry.profitLoss * 100);
        let x100commissions = Number(filteredTrades[i].entry.commissions * 100);
        let x100fees = Number(filteredTrades[i].entry.fees * 100);
        const correctedSUM = returnNetPLDollar(
          filteredTrades[i].entry.profitLoss,
          filteredTrades[i].entry.commissions,
          filteredTrades[i].entry.fees,
          calType
        );
        const thisTotalDws = Number(filteredTrades[i].totaldw);

        if (
          filteredTrades[i].entry.orderType === "Deposit" ||
          filteredTrades[i].entry.orderType === "Withdrawal" ||
          filteredTrades[i].entry.orderType === "Funding Payment" ||
          filteredTrades[i].entry.orderType === "Commit" ||
          filteredTrades[i].entry.orderType === "Approval" ||
          filteredTrades[i].entry.orderType === "Wrap" ||
          filteredTrades[i].entry.orderType === "Self"
        ) {
          continue;
        } else if (exitLots === 0) {
          continue; // exclude trades with no exit executions
        } else {
          iterationarray.push(i);

          accountbalancearr.push(thisAccountBalance);
          let percentgain =
            (correctedSUM / (thisAccountBalance - correctedSUM)) * 100;
          totalPL_dollar += correctedSUM;
          totalTrades += 1;
          plArr.push(correctedSUM);
          percentGainArr.push(percentgain);
          if (correctedSUM < 0) {
            totalLosses += correctedSUM;
            numLosses += 1;
          } else if (correctedSUM > 0) {
            totalProfits += correctedSUM;
            numWins += 1;
          }
          for (let i = 0, j = percentGainArr.length; i < j; i++) {
            if (percentGainArr[i] < 0) {
              percentgainLOSSArray.push(percentGainArr[i]);
            } else if (percentGainArr[i] > 0) {
              percentgainWINArray.push(percentGainArr[i]);
            }
          }
        }

        let eachStartDateTime = new Date(filteredTrades[i].entry.startDateTime); // or startDateTimez
        let eachEndDateTime = new Date(filteredTrades[i].entry.endDateTime);
        let day = eachEndDateTime.toISOString().slice(0, 10); // Format as 'YYYY-MM-DD'
        let week = getWeek(eachEndDateTime); // Format as 'YYYY-WW'
        let month = eachEndDateTime.toISOString().slice(0, 7); // Format as 'YYYY-MM'
        // Calculate daily P/L
        dailyPL[day] = (dailyPL[day] || 0) + correctedSUM;
        dailyCount[day] = (dailyCount[day] || 0) + 1;

        // Calculate weekly P/L
        weeklyPL[week] = (weeklyPL[week] || 0) + correctedSUM;
        weeklyCount[week] = (weeklyCount[week] || 0) + 1;

        // Calculate monthly P/L
        monthlyPL[month] = (monthlyPL[month] || 0) + correctedSUM;
        monthlyCount[month] = (monthlyCount[month] || 0) + 1;

        // Calculations
        let winRate = numWins / totalTrades;
        let averagePLPerTradedollar = totalPL_dollar / totalTrades;
        let averagePLPerWindollar = totalProfits / numWins;
        let averagePLPerLossdollar = totalLosses / numLosses;
        let avgPL = arrAvg(plArr);
        let stdDev = getStandardDeviation(plArr);
        let averagePLPerTradepercent = arrAvg(percentGainArr);
        let averagePLPerWinpercent = arrAvg(percentgainWINArray);
        let averagePLPerLosspercent = arrAvg(percentgainLOSSArray);

        let maxDD = getMaxDD(plArr);
        volatility = stdDev / totalTrades;
        let stdDevPercent = getStandardDeviation(percentGainArr);
        volatilityPercent = stdDevPercent / totalTrades;
        profitFactor = totalProfits / Math.abs(totalLosses);
        profitLossRatio =
          averagePLPerWindollar / Math.abs(averagePLPerLossdollar);
        tfIndex = winRate * (1 + profitLossRatio);
        let winRateasNumber = winRate * 100;
        let cummulativeGain = (totalPL_dollar / thisTotalDws) * 100;
        let dailyAvgPL = calculateAverage(dailyPL, dailyCount);
        let weeklyAvgPL = calculateAverage(weeklyPL, weeklyCount);
        let monthlyAvgPL = calculateAverage(monthlyPL, monthlyCount);

        // linear array
        let lineararr = [];
        const linearRegressionReturn = linearRegression(
          accountbalancearr,
          iterationarray
        );
        const slope = linearRegressionReturn.slope;
        const intercept = linearRegressionReturn.intercept;
        for (let i = 0, j = accountbalancearr.length; i < j; i++) {
          let lineararrentry = slope * i + intercept;
          lineararr.push(lineararrentry);
        }
        // --------

        let recoveryFactor = totalPL_dollar / Math.abs(getMaxDD(plArr));
        let expectation =
          averagePLPerTradepercent / Math.abs(averagePLPerLosspercent);

        // COMPUTE TIME IN MARKET
        let eachTime = (eachEndDateTime - eachStartDateTime) / 1000;
        timeInMarketArr.push(eachTime);

        avgHoldingTime = arrAvg(timeInMarketArr);
        timeInMarket += eachTime;

        let entryAvg = dollarCostAverage(
          filteredTrades[i].entry.multiExecution,
          "entry",
          filteredTrades[i].entry.orderType
        );
        let exitAvg = dollarCostAverage(
          filteredTrades[i].entry.exitExecution,
          "exit",
          filteredTrades[i].entry.orderType
        );
        let RRR =
          (Number(filteredTrades[i].entry.takeProfit) - entryAvg) /
          (entryAvg - Number(filteredTrades[i].entry.stopLoss));

        let RMultiple =
          (exitAvg - entryAvg) /
          (entryAvg - Number(filteredTrades[i].entry.stopLoss));

        xArray.push(RRR);
        yArray.push(RMultiple);

        // COMPUTE AVG EFFICIENCIES
        let efficiency = 0;
        if (
          longShortDelineation(
            filteredTrades[i].entry.orderType,
            multiExecution
          ) === "Long"
        ) {
          efficiency =
            100 *
            ((exitAvg - entryAvg) /
              (filteredTrades[i].entry.maxFavEx -
                filteredTrades[i].entry.maxAdEx));
        } else if (
          longShortDelineation(
            filteredTrades[i].entry.orderType,
            multiExecution
          ) === "Short"
        ) {
          efficiency =
            100 *
            ((entryAvg - exitAvg) /
              (filteredTrades[i].entry.maxAdEx -
                filteredTrades[i].entry.maxFavEx));
        }
        efficiencesArr.push(efficiency);
        const avgEff = arrAvg(efficiencesArr);
        // COMPUTE RISK %
        let adjrrr =
          -entryAvg / (entryAvg - Number(filteredTrades[i].entry.stopLoss));

        let divisor =
          filteredTrades[i].entry.selectedPortfolioType === "Forex"
            ? RMultiple
            : adjrrr;

        let initPl = profitLossCalculation(
          multiExecution,
          [],
          orderType,
          filteredTrades[i].entry.selectedPortfolioType,
          filteredTrades[i].entry.symbol.pointValue,
          true,
          pnlmethod
        );
        // If the asset class if forex we have to calculate risk a slightly different way
        let dividend =
          filteredTrades[i].entry.selectedPortfolioType === "Forex"
            ? correctedSUM
            : returnNetPLDollar(
                initPl,
                filteredTrades[i].entry.commissions,
                filteredTrades[i].entry.fees,
                calType
              );
        let percent =
          Number(dividend / (thisAccountBalance - Number(correctedSUM))) * 100;
        let riskedPercent = percent / divisor;
        riskedPercentArr.push(riskedPercent);
        let goldenRatio =
          averagePLPerWindollar /
          Math.abs(averagePLPerLossdollar) /
          arrAvg(xArray);
        let sharpeRatioArith = calculateSharpeRatios(
          accountbalancearr,
          Number(riskFreeRate)
        ).arithmeticSharpeRatio;

        let returnOverMaxDrawdown =
          cummulativeGain / Math.abs(getMaxDDPercent(accountbalancearr)); //maxxdd percent needs a special array
        let CPCIndex = profitFactor * profitLossRatio * winRate;
        let kellypercent =
          (averagePLPerTradedollar / averagePLPerWindollar) * 100;
        let normalizedExpectancypercent =
          (averagePLPerTradedollar / Math.abs(averagePLPerLossdollar)) * 100;
        let ulcerIndexVal = ulcerIndex(accountbalancearr);
        let sortinoRatio = calculateSortinoRatios(
          accountbalancearr,
          Number(riskFreeRate)
        ).arithmeticSortinoRatio;
        let probabilityOfRandomChance =
          totalTrades <= 0
            ? 0
            : 1 -
              studentsTCumulativeValue(
                averagePLPerTradepercent /
                  (stdDevPercent / Math.sqrt(totalTrades - 1)),
                totalTrades - 1
              );
        let zScore = calculateZScore(accountbalancearr).zScore;
        let LRCorrelation = pcorr(accountbalancearr, lineararr);
        let LRStandardError = STEYX(accountbalancearr, lineararr);
        let KRatio =
          slope /
          ((STEYX(accountbalancearr, lineararr) / Math.sqrt(DEVSQ(lineararr))) *
            totalTrades);
        const mapDataValues = (dataValuesArr, metricToPush) => {
          if (metricToPush === "Profit Factor") {
            dataValuesArr.push(profitFactor);
          } else if (metricToPush === "Profit/Loss Ratio") {
            dataValuesArr.push(profitLossRatio);
          } else if (metricToPush === "Total P/L") {
            dataValuesArr.push(totalPL_dollar);
          } else if (metricToPush === "Avg. P/L") {
            dataValuesArr.push(avgPL);
          } else if (metricToPush === "Daily Avg. P/L") {
            dataValuesArr.push(dailyAvgPL);
          } else if (metricToPush === "Weekly Avg. P/L") {
            dataValuesArr.push(weeklyAvgPL);
          } else if (metricToPush === "Monthly Avg. P/L") {
            dataValuesArr.push(monthlyAvgPL);
          } else if (metric === "Sharpe Ratio") {
            dataValuesArr.push(sharpeRatioArith);
          } else if (metricToPush === "TradeFuse Index") {
            dataValuesArr.push(tfIndex);
          } else if (metricToPush === "TradeFuse Golden Ratio") {
            dataValuesArr.push(goldenRatio);
          } else if (metricToPush === "Win Rate") {
            dataValuesArr.push(winRateasNumber);
          } else if (metricToPush === "Std. Dev.") {
            dataValuesArr.push(stdDev);
          } else if (metricToPush === "Std. Dev. %") {
            dataValuesArr.push(stdDevPercent);
          } else if (metricToPush === "Volatility") {
            dataValuesArr.push(volatility);
          } else if (metricToPush === "Volatility %") {
            dataValuesArr.push(volatilityPercent);
          } else if (metricToPush === "Avg. Holding Time") {
            dataValuesArr.push(avgHoldingTime);
          } else if (metricToPush === "Max. Drawdown") {
            dataValuesArr.push(maxDD);
          } else if (metricToPush === "Avg. Total Efficiency") {
            dataValuesArr.push(avgEff);
          } else if (metricToPush === "Recovery Factor") {
            dataValuesArr.push(recoveryFactor);
          } else if (metricToPush === "Expectation") {
            dataValuesArr.push(expectation);
          } else if (metricToPush === "Return Over Max Drawdown") {
            dataValuesArr.push(returnOverMaxDrawdown);
          } else if (metricToPush === "CPC Index") {
            dataValuesArr.push(CPCIndex);
          } else if (metricToPush === "Kelly %") {
            dataValuesArr.push(kellypercent);
          } else if (metricToPush === "Normalized Expectancy %") {
            dataValuesArr.push(normalizedExpectancypercent);
          } else if (metricToPush === "Ulcer Index") {
            dataValuesArr.push(ulcerIndexVal);
          } else if (metricToPush === "Sortino Ratio") {
            dataValuesArr.push(sortinoRatio);
          } else if (metricToPush === "Probability of Random Chance") {
            dataValuesArr.push(probabilityOfRandomChance);
          } else if (metricToPush === "Z-Score") {
            dataValuesArr.push(zScore);
          } else if (metricToPush === "LR Correlation") {
            dataValuesArr.push(LRCorrelation);
          } else if (metricToPush === "LR Standard Error") {
            dataValuesArr.push(LRStandardError);
          } else if (metricToPush === "K-Ratio") {
            dataValuesArr.push(KRatio);
          } else if (metricToPush === "R-Multiple") {
            dataValuesArr.push(RMultiple);
          } else if (metricToPush === "Reward-Risk Ratio") {
            dataValuesArr.push(RRR);
          }
        };

        if (
          whatToCompare === "Confidences" ||
          whatToCompare === "Days of Week" ||
          whatToCompare === "Months" ||
          whatToCompare === "Time Frames" ||
          whatToCompare === "Order Types" ||
          whatToCompare === "Asset Classes" ||
          whatToCompare === "Portfolios" ||
          whatToCompare === "Market States"
        ) {
          if (selectedItem === filteredTrades[i].entry[whatToCompareProp]) {
            mapDataValues(dataValues, metric);
          }
        } else if (whatToCompare === "Symbols") {
          if (selectedItem === filteredTrades[i].entry.symbol.symbols[0]) {
            mapDataValues(dataValues, metric);
          }
        } else if (
          whatToCompare === "Strategies" ||
          whatToCompare === "Emotional States" ||
          whatToCompare === "Tags" ||
          whatToCompare === "Physical States" ||
          whatToCompare === "Mistakes"
        ) {
          if (
            filteredTrades[i].entry[whatToCompareProp] != null &&
            filteredTrades[i].entry[whatToCompareProp].includes(selectedItem)
          ) {
            mapDataValues(dataValues, metric);
          }
        } else if (whatToCompare === "Trading Sessions") {
          var dateTime = new Date(
            dateToggle === "Trade Open"
              ? filteredTrades[i].entry.startDateTime
              : filteredTrades[i].entry.endDateTime
          );
          // Add condition to filter trades based on trading session
          const tradingSession = getSession(dateTime);

          const selectedSession = selectedItem; // Replace with the selected trading session

          if (tradingSession === selectedSession) {
            mapDataValues(dataValues, metric);
          }
        } else if (whatToCompare === "Years") {
          // Extract the year from trade's startDateTime or endDateTime
          const tradeYear = new Date(
            filteredTrades[i].entry.startDateTime // Assuming filtering based on startDateTime, adjust if necessary
          ).getFullYear();

          const selectedYear = parseInt(selectedItem, 10); // Ensure selectedItem is treated as a year
          if (tradeYear === selectedYear) {
            mapDataValues(dataValues, metric);
          }
        }
      }
      const dataObj = {
        label: selectedItem,
        fill: false,
        lineTension: 0.1,
        bezierCurve: true,
        backgroundColor: lineColor,
        borderColor: lineColor,
        borderCapStyle: "butt",
        type: props.type,
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: lineColor,
        pointBackgroundColor: lineColor,
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: lineColor,
        pointHoverBorderColor: lineColor,
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 10,
        data: dataValues,
      };
      metricValues.push(dataObj);
    }
    if (props.type === "bar") {
      const allLastValues =
        metricValues?.length &&
        metricValues.map((obj) =>
          !obj.data[obj?.data?.length - 1] ? 0 : obj.data[obj?.data?.length - 1]
        );
      metricValues = [
        {
          fill: false,
          lineTension: 0.1,
          backgroundColor: lineColorMain,
          borderColor: lineColorMain,
          borderCapStyle: "butt",
          borderDash: [],
          type: props.type,
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: lineColorMain,
          pointBackgroundColor: lineColorMain,
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: lineColorMain,
          pointHoverBorderColor: lineColorMain,
          pointHoverBorderWidth: 2,
          pointRadius: 2,
          pointHitRadius: 10,
          data: allLastValues,
        },
      ];
    }
    return metricValues;
  };
  const metricVals = metricValues(
    whatToCompare === "Portfolios" ? allEntries : allEntriesButFiltered,
    selectedItems,
    metricToCompare
  );
  const metricDatas =
    props.type === "line" && metricVals.map((obj) => obj.data); // for only line
  const labelsonly = selectedItems.map((obj) => (obj.label ? obj.label : "")); // for only bar
  const largestArray = metricVals?.length && findLargestArray(metricDatas);
  const xaxisfunction = () => {
    let xaxisvalue = [];
    for (var iiy = 0, jjy = largestArray.length; iiy < jjy; iiy++) {
      xaxisvalue.push(iiy + 1);
    }
    return xaxisvalue;
  };

  const data = {
    labels: props.type === "line" ? xaxisfunction() : labelsonly,
    datasets: metricVals,
  };
  /*   const data = {
    labels: xaxisfunction(),
    datasets: [
      {
        label: "Your Equity",
        fill: false,
        lineTension: 0.1,
        bezierCurve: true,
        backgroundColor: lineColor,
        borderColor: lineColor,
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: lineColor,
        pointBackgroundColor: lineColor,
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: lineColor,
        pointHoverBorderColor: lineColor,
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        pointHitRadius: 10,
        data: simulationResults,
      },
    ],
  }; */
  const options = {
    tooltips: {
      mode: props.type === "line" ? "nearest" : "index",
      intersect: false,
      enabled: true,
      backgroundColor: "#14181e",
      titleFontSize: 14,
      titleFontColor: "#fbd665",
      bodyFontColor: "#fff7cb",
      bodyFontSize: 14,
      displayColors: false,
      callbacks: {
        title: (tooltipItems) => {
          const datasetIndex =
            props.type === "line"
              ? tooltipItems[0].datasetIndex
              : tooltipItems[0].index;
          return `${selectedItems[datasetIndex].label} ${metricToCompare}`;
        },
        label: (tooltipItems) => {
          let returnVal = "";
          if (
            metricToCompare === "Total P/L" ||
            metricToCompare === "Avg. P/L" ||
            metricToCompare === "Std. Dev." ||
            metricToCompare === "Daily Avg. P/L" ||
            metricToCompare === "Weekly Avg. P/L" ||
            metricToCompare === "Monthly Avg. P/L" ||
            metricToCompare === "Volatility" ||
            metricToCompare === "Max. Drawdown"
          ) {
            returnVal = supportedCryptosCheck
              ? parseFloat(tooltipItems.yLabel.toFixed(6)) +
                " " +
                props.defaultSymbol
              : he.decode(props.defaultSymbol) +
                parseFloat(tooltipItems.yLabel).toFixed(2);
          } else if (
            metricToCompare === "Profit Factor" ||
            metricToCompare === "Profit/Loss Ratio" ||
            metricToCompare === "TradeFuse Index" ||
            metricToCompare === "TradeFuse Golden Ratio" ||
            metricToCompare === "Sharpe Ratio" ||
            metricToCompare === "Sortino Ratio" ||
            metricToCompare === "Recovery Factor" ||
            metricToCompare === "Expectation" ||
            metricToCompare === "Return Over Max Drawdown" ||
            metricToCompare === "CPC Index" ||
            metricToCompare === "Ulcer Index" ||
            metricToCompare === "Z-Score" ||
            metricToCompare === "LR Correlation" ||
            metricToCompare === "LR Standard Error" ||
            metricToCompare === "K-Ratio"
          ) {
            returnVal = parseFloat(tooltipItems.yLabel).toFixed(3);
          } else if (
            metricToCompare === "Win Rate" ||
            metricToCompare === "Std. Dev. %" ||
            metricToCompare === "Volatility %" ||
            metricToCompare === "Avg. Total Efficiency" ||
            metricToCompare === "Kelly %" ||
            metricToCompare === "Normalized Expectancy %" ||
            metricToCompare === "Probability of Random Chance"
          ) {
            returnVal = parseFloat(tooltipItems.yLabel).toFixed(3) + "%";
          } else if (metricToCompare === "Avg. Holding Time") {
            returnVal = getDateDifferencewSeconds(tooltipItems.yLabel);
          } else if (metricToCompare === "R-Multiple") {
            returnVal = `${parseFloat(tooltipItems.yLabel).toFixed(3)} R`;
          } else if (metricToCompare === "Reward-Risk Ratio") {
            returnVal = `${parseFloat(tooltipItems.yLabel).toFixed(3)}:1`;
          }
          return returnVal;
        },
      },
    },
    onHover: (event, chartElement) => {
      event.target.style.cursor =
        chartElement[0] && props.type === "line" ? "pointer" : "default";
    },
    onClick: (event, chartElement) => {
      if (!chartElement.length || props.type === "bar") {
      } else {
        const datasetIndex = chartElement[0]._datasetIndex;
        const selectedItem = selectedItems[datasetIndex].label;

        //First filter by the selected item
        let filteredTrades = "";
        let filteredTradesPre =
          whatToCompare === "Portfolios"
            ? allEntries.slice()
            : allEntriesButFiltered.slice();
        filteredTrades = filterTheTrades(
          whatToCompare,
          whatToCompareProp,
          selectedItem,
          filteredTradesPre,
          pnlmethod
        );
        let onlyTrades = [];
        for (let i = 0, j = filteredTrades.length; i < j; i++) {
          let multiExecution = filteredTrades[i].entry.multiExecution;
          let exitExecution = filteredTrades[i].entry.exitExecution;
          const entryExitValues = getEntryExitLots(
            multiExecution,
            exitExecution
          );
          const exitLots = entryExitValues.exitLots;
          if (
            filteredTrades[i].entry.orderType === "Deposit" ||
            filteredTrades[i].entry.orderType === "Withdrawal" ||
            filteredTrades[i].entry.orderType === "Funding Payment" ||
            filteredTrades[i].entry.orderType === "Commit" ||
            filteredTrades[i].entry.orderType === "Approval" ||
            filteredTrades[i].entry.orderType === "Wrap" ||
            filteredTrades[i].entry.orderType === "Self"
          ) {
            continue;
          } else if (exitLots === 0) {
            continue; // exclude trades with no exit executions
          } else {
            onlyTrades.push(filteredTrades[i]);
          }
        }
        // filteredTrades and onlyTrades are in the same order, that's why this works
        toComponentB2(getEntrybyIndex(chartElement[0]._index, onlyTrades)[0]);
      }
    },
    hover: {
      mode: props.type === "line" ? "nearest" : "index",
      intersect: false,
    },
    interaction: {
      mode: props.type === "line" ? "nearest" : "index",
      intersect: false,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: true,
          },
          ticks: {
            suggestedMin: 0,
            fontColor: "#98b5eb",
            callback: function (value) {
              let returnVal = "";
              if (
                metricToCompare === "Total P/L" ||
                metricToCompare === "Avg. P/L" ||
                metricToCompare === "Std. Dev." ||
                metricToCompare === "Daily Avg. P/L" ||
                metricToCompare === "Weekly Avg. P/L" ||
                metricToCompare === "Monthly Avg. P/L" ||
                metricToCompare === "Volatility" ||
                metricToCompare === "Max. Drawdown"
              ) {
                returnVal = supportedCryptosCheck
                  ? parseFloat(value).toFixed(6) + " " + props.defaultSymbol
                  : he.decode(props.defaultSymbol) +
                    parseFloat(value).toFixed(2);
              } else if (
                metricToCompare === "Profit Factor" ||
                metricToCompare === "Profit/Loss Ratio" ||
                metricToCompare === "TradeFuse Index" ||
                metricToCompare === "TradeFuse Golden Ratio" ||
                metricToCompare === "Sharpe Ratio" ||
                metricToCompare === "Sortino Ratio" ||
                metricToCompare === "Recovery Factor" ||
                metricToCompare === "Expectation" ||
                metricToCompare === "Return Over Max Drawdown" ||
                metricToCompare === "CPC Index" ||
                metricToCompare === "Ulcer Index" ||
                metricToCompare === "Z-Score" ||
                metricToCompare === "LR Correlation" ||
                metricToCompare === "LR Standard Error" ||
                metricToCompare === "K-Ratio"
              ) {
                returnVal = parseFloat(value).toFixed(3);
              } else if (
                metricToCompare === "Win Rate" ||
                metricToCompare === "Std. Dev. %" ||
                metricToCompare === "Volatility %" ||
                metricToCompare === "Avg. Total Efficiency" ||
                metricToCompare === "Kelly %" ||
                metricToCompare === "Normalized Expectancy %" ||
                metricToCompare === "Probability of Random Chance"
              ) {
                returnVal = parseFloat(value).toFixed(3) + "%";
              } else if (metricToCompare === "Avg. Holding Time") {
                returnVal = getDateDifferencewSeconds(value);
              } else if (metricToCompare === "R-Multiple") {
                returnVal = `${parseFloat(value).toFixed(3)} R`;
              } else if (metricToCompare === "Reward-Risk Ratio") {
                returnVal = `${parseFloat(value).toFixed(3)}:1`;
              }
              return returnVal;
            },
          },
          scaleLabel: {
            display: true,
            labelString: metricToCompare,
            fontColor: "#fff7cb",
            fontSize: 12,
          },
        },
      ],
      xAxes: [
        {
          offset: true,
          gridLines: {
            display: false,
          },
          ticks: {
            fontColor: "#98b5eb",
          },
          scaleLabel: {
            display: true,
            labelString:
              props.type === "line" ? "Entry Number" : whatToCompareSingle,
            fontColor: "#fff7cb",
            fontSize: 12,
          },
        },
      ],
    },
    maintainAspectRatio: false,
    responsive: true,
    animation: false,
    responsiveAnimationDuration: 0,
    legend: {
      position: "bottom",
      align: "end",
      display: false,
    },
  };
  return (
    <div
      className={
        metricsToCompare.length === 1
          ? "ComparisonGraph onehundredpercentwidth"
          : "ComparisonGraph"
      }
    >
      {" "}
      <div className="ComparisonGraph-label" id="ComparisonGraph-label">
        {graphTitle}
      </div>
      <div className="ComparisonGraphwrapper">
        <Line data={data} options={options} />
      </div>
    </div>
  );
};

export default React.memo(ComparisonGraph);
