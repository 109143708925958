import React, { useState, useEffect, useContext, useCallback } from "react";
import { useHistory } from "react-router-dom";
import DeleteButton from "../../components/deleteButton";
import $ from "jquery";
import { useAuth0 } from "@auth0/auth0-react";
import putUserData from "../../utils/AWS/putS3UserObject";
import createDatafromSettingsProps from "../../functions/createDatafromSettingsFunction";
import store from "../../store";
import { setLoaderState } from "../../actions/actionCreators";
import { DataContext } from "../../DataContext";
import dydxlogo from "./../../images/dydxlogo.svg";
import robinhoodlogo from "./../../images/Logo-Robinhood.png";
import metamasklogo from "./../../images/MetaMask_Fox.svg";
import mt4logo from "./../../images/metatrader-4-logo-og.png";
import mt5logo from "./../../images/mt5-logo.png";
import tradovatelogo from "./../../images/Tradovate-darkbg.png";
import apolloxlogo from "./../../images/Apollox.png";
import krakenlogo from "./../../images/kraken-logo.png";
import iblogo from "./../../images/Interactive_Brokers-Logo.png";
import webulllogo from "./../../images/Webull.png";

import thinkorswimlogo from "./../../images/thinkorswim.png";
import Select from "react-select";
import dropdownStyles from "../../functions/dropdownStyles";
import { theme } from "./../../components/selectTheme";
import PopoverStickOnHover from "./../../components/PopoverStickOnHover";
import mt4ServerImg from "./../../images/mt4serverimage2.png";
import mt5ServerImg from "./../../images/mt5serverimage2.png";

import getUniqueItemsByProps from "../../functions/getUniqueItemsByProps";
import {
  getAllRobinhoodTransactions,
  getRobinhoodCryptoInstrument,
} from "../../functions/robinhood/utils";
import parseRobinhoodOrders from "../../functions/autoimport/parsers/robinhood";
import parsedYdXOrders from "../../functions/autoimport/parsers/dydx";
import parseMetaTraderOrders from "../../functions/autoimport/parsers/metatrader";
import parseKrakenOrders from "../../functions/autoimport/parsers/kraken";
import { ethers } from "ethers";
import usePrevious from "../../hooks/usePrevious";
import allMT4Brokers from "./allMT4Brokers";
import allMT5Brokers from "./allMT5Brokers";
import { v4 as uuidv4 } from "uuid";
import parseEtherOrders from "../../functions/autoimport/parsers/etherwallet";
import MetaApi from "metaapi.cloud-sdk";
import getSpecificPortfolio from "./../../functions/getSpecificPortfolio";
import updateStripeCustomerWrapper from "./../../utils/stripe/updateStripeCustomer";
import CreatableSelect from "react-select/creatable";
import solflarelogo from "./../../images/solflare-logo-white.c53d256d.png";
const crypto = require("crypto");
const qs = require("qs");
const { DydxClient } = require("@dydxprotocol/v3-client");
const NodeRSA = require("node-rsa");
const metaapi = new MetaApi(process.env.REACT_APP_METAAPIKEY);
const key = new NodeRSA();
const key2 = new NodeRSA();
key.importKey(
  `-----BEGIN PUBLIC KEY-----${process.env.REACT_APP_PUB_KEY}-----END PUBLIC KEY-----`,
  "pkcs8-public-pem"
);
key2.importKey(
  `-----BEGIN RSA PRIVATE KEY-----${process.env.REACT_APP_PRIVATE_KEY}-----END RSA PRIVATE KEY-----`,
  "pkcs1-pem"
);
const ethereum = window.ethereum;
const etherscanProvider =
  ethereum &&
  new ethers.providers.EtherscanProvider(
    "homestead",
    process.env.REACT_APP_ETHERSCAN_KEY
  );

function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  const toRad = (value) => (value * Math.PI) / 180;

  const R = 6371; // Radius of the earth in km
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) *
      Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in km
  return distance;
}
async function getUserIP() {
  const response = await fetch("https://api.ipify.org?format=json");
  const data = await response.json();
  return data.ip;
}

async function determineRegion(userIp) {
  try {
    // Fetch user's geolocation based on IP
    const response = await fetch(`http://ip-api.com/json/${userIp}`);
    const data = await response.json();

    if (data.status !== "success") {
      throw new Error("Unable to determine location");
    }

    const userLat = data.lat;
    const userLon = data.lon;

    // Coordinates for New York and London
    const newYork = { lat: 40.7128, lon: -74.006 };
    const london = { lat: 51.5074, lon: -0.1278 };

    // Calculate distances
    const distanceToNY = getDistanceFromLatLonInKm(
      userLat,
      userLon,
      newYork.lat,
      newYork.lon
    );
    const distanceToLondon = getDistanceFromLatLonInKm(
      userLat,
      userLon,
      london.lat,
      london.lon
    );

    // Determine the closer region
    return distanceToNY <= distanceToLondon ? "new-york" : "london";
  } catch (error) {
    console.error("Error determining region:", error);
    // Fallback to a default region if location can't be determined
    return "new-york";
  }
}

export const AutoImportModal = (props) => {
  // ------- FIRST PART OF FULL AUTOMATION: GET ALL TRADES FROM ACCOUNT -------
  // THEN SET TO SKYNET DATA
  const overWrite = props.overwriteentries === "Keep Entries" ? false : true;
  const broker = props.broker;
  const isweb3selected = props.isweb3selected;
  const { user /* isAuthenticated */ } = useAuth0();
  const allGlobalData = useContext(DataContext);
  const allData = allGlobalData.allData;
  const portfolio = getSpecificPortfolio(allGlobalData.allData);
  const createdPortfolios = allGlobalData.allData.createdPortfolios;
  const verifiedLevel = 2; // because it's fully automated
  const [email, setemail] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [inputType, setInputType] = useState("email");

  const [password, setpassword] = useState();
  const [accountNumber, setaccountNumber] = useState();
  const [walletAddress, setwalletAddress] = useState();

  const [mfaCode, setmfaCode] = useState();
  const [APIsecret, setAPIsecret] = useState();
  const [APIkey, setAPIkey] = useState();
  const [APIpassphrase, setAPIpassphrase] = useState();
  const [selectCheck, setselectCheck] = useState("");
  const [selectCheck2, setselectCheck2] = useState("");

  const prevEmail = usePrevious(email);
  const [selectedBroker, setselectedBroker] = useState();
  const [selectedServer, setselectedServer] = useState();
  const [selectedServerOptions, setselectedServerOptions] = useState();
  const [accountExistsError, setaccountExistsError] = useState(false);
  const [accountExistsName, setaccountExistsName] = useState();
  const history = useHistory();
  const [disabled, setdisabled] = useState(true);

  const HTTP_HOST = "https://api.dydx.exchange";

  /*   const { library, chainId, account, activate, deactivate, active } =
  useWeb3React();
  console.log(library)
  const [signature, setSignature] = useState("");
  const [error, setError] = useState("");
  const [network, setNetwork] = useState(undefined);
  const [message, setMessage] = useState("");
  const [signedMessage, setSignedMessage] = useState("");


  const handleNetwork = (e) => {
    const id = e.target.value;
    setNetwork(Number(id));
  };
  const truncateAddress = (address) => {
    if (!address) return "No Account";
    const match = address.match(
      /^(0x[a-zA-Z0-9]{2})[a-zA-Z0-9]+([a-zA-Z0-9]{2})$/
    );
    if (!match) return address;
    return `${match[1]}…${match[2]}`;
  };
  
  const toHex = (num) => {
    const val = Number(num);
    return "0x" + val.toString(16);
  };
  
  const handleInput = (e) => {
    const msg = e.target.value;
    setMessage(msg);
  };

  const switchNetwork = async () => {
    try {
      await library.provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: toHex(network) }]
      });
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await library.provider.request({
            method: "wallet_addEthereumChain",
            params: [networkParams[toHex(network)]]
          });
        } catch (error) {
          setError(error);
        }
      }
    }
  };

  const signMessage = async () => {
    if (!library) return;
    try {
      const signature = await library.provider.request({
        method: "personal_sign",
        params: [message, account]
      });
      setSignedMessage(message);
      setSignature(signature);
    } catch (error) {
      setError(error);
    }
  };

  const verifyMessage = async () => {
    if (!library) return;
    try {
      const verify = await library.provider.request({
        method: "personal_ecRecover",
        params: [signedMessage, signature]
      });
      setverifiedLevel(verify === account.toLowerCase());
    } catch (error) {
      setError(error);
    }
  };

  const refreshState = () => {
    window.localStorage.setItem("provider", undefined);
    setNetwork("");
    setMessage("");
    setSignature("");
    setverifiedLevel(undefined);
  };

  const disconnect = () => {
    refreshState();
    deactivate();
  };

  useEffect(() => {
    const provider = window.localStorage.getItem("provider");
    if (provider) activate(connectors[provider]);
  }, []); */
  // Create array of connections info

  const fetchHistoryWithInternals = async (
    address,
    etherscanApiKey,
    element
  ) => {
    try {
      // Step 1: Fetch External Transactions
      const externalHistory = await etherscanProvider.getHistory(address);

      // Step 2: Fetch Internal Transactions using Etherscan API
      const internalResponse = await fetch(
        `https://api.etherscan.io/api?module=account&action=txlistinternal&address=${address}&apikey=${etherscanApiKey}`
      );

      if (!internalResponse.ok) {
        throw new Error(
          `Failed to fetch internal transactions: ${internalResponse.statusText}`
        );
      }

      const internalData = await internalResponse.json();
      if (internalData.status !== "1") {
        console.warn(
          "No internal transactions found or error from Etherscan:",
          internalData.message
        );
        return externalHistory; // Return external history only if no internal transactions
      }

      const internalHistory = internalData.result;

      // Combine External and Internal Transactions
      // Need to filter out transactions with the same hash
      const externalHashes = new Set(externalHistory.map((tx) => tx.hash));

      const fullHistory = [
        ...externalHistory,
        ...internalHistory
          .filter((transaction) => !externalHashes.has(transaction.hash))
          .map((transaction) => {
            return { ...transaction, isInternal: true };
          }),
      ];

      // Step 3: Sort Transactions by Timestamp (Earliest to Latest)
      const sortedHistory = fullHistory.sort((a, b) => {
        const timestampA = Number(a.timeStamp)
          ? Number(a.timeStamp)
          : Number(a.timestamp); // Ensure timestamps are numbers
        const timestampB = Number(b.timeStamp)
          ? Number(b.timeStamp)
          : Number(b.timestamp);
        return timestampA - timestampB;
      });

      return sortedHistory;
    } catch (error) {
      console.error("Error fetching transactions:", error.message);

      if (element) {
        element.style.opacity = 0;
        element.style.transform = "translate(100%, 0)";
        element.style.transition = "none";
      }
      $(window).unbind("beforeunload");
      store.dispatch(setLoaderState({ loading: false }));
      localStorage.setItem(
        "autoiterationMessage",
        `Connection failed. Wait a few moments and try again.`
      );
      localStorage.removeItem("connectingBroker");
      setTimeout(() => {
        localStorage.removeItem("autoiterationMessage");
      }, 6000);
    }
  };

  let allAutoImportConnectionsArray = [];
  let allAutoImportConnectionsFullArray = [];

  createdPortfolios.forEach((portfolio) => {
    allAutoImportConnectionsArray.push(
      portfolio.settings.linkedBrokerInfo.broker
    );
    allAutoImportConnectionsFullArray.push(portfolio.settings.linkedBrokerInfo);
  });
  const importType = "initialLink";
  const isAccountNumberExists = useCallback(
    (
      connections,
      accountNumber,
      email,
      APIsecret,
      APIkey,
      APIpassphrase,
      address
    ) => {
      for (const connection of connections) {
        if (
          (connection.broker === "metatrader4" ||
            connection.broker === "metatrader5") &&
          (broker.value === "metatrader4" || broker.value === "metatrader5")
        ) {
          const encryptedAccountNumber = connection.accountNumber;

          if (!encryptedAccountNumber) {
            continue; // Skip the iteration if accountNumber is blank, undefined, or null
          }

          try {
            const decryptedString = key2.decrypt(
              encryptedAccountNumber,
              "utf8"
            );
            const decryptedAccountNumber = JSON.parse(decryptedString);

            if (decryptedAccountNumber === accountNumber) {
              setaccountExistsName(connection.portfolio);
              return true;
            }
          } catch (error) {
            // Handle decryption errors, e.g., invalid encryption or unexpected format
            console.error("Decryption error:", error);
            continue;
          }
        } else if (
          connection.broker === "robinhood" &&
          broker.value === "robinhood"
        ) {
          const accountEmail = connection.email;

          if (!accountEmail) {
            continue; // Skip the iteration if accountNumber is blank, undefined, or null
          }

          try {
            if (String(accountEmail) === String(email)) {
              setaccountExistsName(connection.portfolio);
              return true;
            }
          } catch (error) {
            // Handle decryption errors, e.g., invalid encryption or unexpected format
            console.error("No email:", error);
            continue;
          }
        } else if (
          connection.broker === "metamask" &&
          broker.value === "metamask"
        ) {
          const accountAddress = connection.address;

          if (!accountAddress) {
            continue; // Skip the iteration if accountNumber is blank, undefined, or null
          }

          try {
            if (String(accountAddress) === String(address)) {
              setaccountExistsName(connection.portfolio);
              return true;
            }
          } catch (error) {
            // Handle decryption errors, e.g., invalid encryption or unexpected format
            console.error("No email:", error);
            continue;
          }
        } else if (connection.broker === "dYdX" && broker.value === "dYdX") {
          const encryptedaccountCred = connection.cred;
          const creds = {
            secret: APIsecret,
            key: APIkey,
            passphrase: APIpassphrase,
          };
          if (!encryptedaccountCred) {
            continue; // Skip the iteration if accountNumber is blank, undefined, or null
          }

          try {
            const decryptedString = key2.decrypt(encryptedaccountCred, "utf8");
            const decryptedAccountCreds = decryptedString?.replace(/"/g, "");

            if (
              String(decryptedAccountCreds.secret) === String(creds.secret) &&
              String(decryptedAccountCreds.key) === String(creds.key) &&
              String(decryptedAccountCreds.passphrase) ===
                String(creds.passphrase)
            ) {
              setaccountExistsName(connection.portfolio);
              return true;
            }
          } catch (error) {
            // Handle decryption errors, e.g., invalid encryption or unexpected format
            console.error("Decryption error:", error);
            continue;
          }
        }
      }

      return false;
    },
    []
  );
  const handleCloseLinkBroker = useCallback(() => {
    $("#autoimportmodalwrapper").fadeOut(300);
    $("#overlay").fadeOut(300);
    $(".linkRobinhoodErrorMsg").css({ display: "none" });
    $(".linkRobinhoodErrorMsg").html("");
    setemail("");
    setpassword("");
    setmfaCode("");
    setselectedBroker("");
    setselectedServer("");
    setselectCheck(false);
    props.setShowImport(false);
  }, [props]);

  const toggleInputType = (type) => {
    setInputType(type);
  };

  const selectFunction = () => {
    if (selectCheck) {
      setselectCheck(false);
      setemail(prevEmail);
    } else {
      setselectCheck(true);
      setemail(user.email);
    }
  };

  const selectFunction2 = () => {
    if (selectCheck2) {
      setselectCheck2(false);
      setemail(prevEmail);
    } else {
      setselectCheck2(true);
      setemail(user.email);
    }
  };

  useEffect(() => {
    const accountNumberExistsCheck = isAccountNumberExists(
      allAutoImportConnectionsFullArray,
      accountNumber,
      email,
      APIsecret,
      APIkey,
      APIpassphrase,
      walletAddress
    );
    if (accountNumberExistsCheck) {
      setaccountExistsError(true);
    } else {
      setaccountExistsError(false);
    }
  }, [
    accountNumber,
    setaccountExistsError,
    isAccountNumberExists,
    allAutoImportConnectionsFullArray,
    email,
    APIsecret,
    APIkey,
    APIpassphrase,
    walletAddress,
  ]);
  useEffect(() => {
    if (broker && broker.value === "robinhood") {
      if (!email || !password || !mfaCode || accountExistsError) {
        setdisabled(true);
      } else {
        setdisabled(false);
      }
    } else if (broker && broker.value === "metatrader4") {
      if (
        !selectedServer ||
        !password ||
        !accountNumber ||
        accountExistsError
      ) {
        setdisabled(true);
      } else {
        setdisabled(false);
      }
    } else if (broker && broker.value === "metatrader5") {
      if (
        !selectedServer ||
        !password ||
        !accountNumber ||
        accountExistsError
      ) {
        setdisabled(true);
      } else {
        setdisabled(false);
      }
    } else if (broker && broker.value === "dYdX") {
      if (!APIsecret || !APIkey || !APIpassphrase || accountExistsError) {
        setdisabled(true);
      } else {
        setdisabled(false);
      }
    }
  }, [
    email,
    password,
    mfaCode,
    broker,
    APIpassphrase,
    APIkey,
    APIsecret,
    selectedBroker,
    selectedServer,
    accountNumber,
    accountExistsError,
  ]);
  const movecursor = {
    paddingLeft: "10px",
  };
  const setLoaderText = () => {
    setTimeout(() => {
      const element = document.getElementById("loadertext");
      element &&
        (element.innerHTML =
          "Hang tight, this may take a couple of minutes...");
    }, 7011);
  };

  // Function that actually sets AWS data;
  // called individually in each switch case above
  // this will add all trades from the broker account initially if needed
  const callsetData = useCallback(
    async (propsToChange, trades, errCheck) => {
      // Add trades to entries array
      let temparr = allData.createdPortfolios.slice();
      for (var ie = 0, je = allData.createdPortfolios.length; ie < je; ie++) {
        let currentData = [];

        // If the user checks overwrite, keep the current data array blank,
        // else add the entries already existing
        if (overWrite) {
        } else {
          currentData = allData.createdPortfolios[ie].entries.slice();
        }

        let tempName = allData.createdPortfolios[ie].name;
        if (
          tempName === "Default" ||
          portfolio.name !==
            tempName /*  This is where the main check is. Add to the selected portfolio  */
        ) {
        } else {
          // filter portfolios to only deal with the one we need
          temparr = temparr.filter((x) => x.name !== tempName);
          trades.forEach((trade) => {
            currentData.push(trade);
          });

          const portfolioedit = {
            name: allData.createdPortfolios[ie].name,
            assetClasses: allData.createdPortfolios[ie].assetClasses,
            settings: allData.createdPortfolios[ie].settings,
            entries: currentData,
          };
          // then add back to array of portfolios
          temparr = temparr.concat(portfolioedit);
        }
      }
      let globalpropsToChange = {};

      const finalPush = createDatafromSettingsProps(
        allData.chosenPortfolios,
        temparr,
        propsToChange,
        globalpropsToChange,
        portfolio.settings,
        allData.globalSettings,
        allData.version
      );
      const S3Data = {
        data: finalPush,
        userId: user.sub,
      };
      const customerId =
        finalPush === null ? "" : finalPush.globalSettings.stripeId;

      await updateStripeCustomerWrapper(
        customerId,
        finalPush,
        "trades",
        user.sub,
        history
      );

      await putUserData(S3Data);
      allGlobalData.updateAllData(finalPush);
      localStorage.removeItem("connectingBroker");
      $(window).unbind("beforeunload");
      if (errCheck === "err") {
      } else {
        if (broker.value === "robinhood") {
          localStorage.setItem(
            "autoiterationMessage",
            `Robinhood connection successful!`
          );
        } else if (broker.value === "dYdX") {
          localStorage.setItem(
            "autoiterationMessage",
            `dYdX connection successful!`
          );
        } else if (
          broker.value === "metatrader4" ||
          broker.value === "metatrader5"
        ) {
          localStorage.setItem(
            "autoiterationMessage",
            `MetaTrader connection successful!`
          );
        } else if (broker.value === "metamask") {
          localStorage.setItem(
            "autoiterationMessage",
            `MetaMask connection successful!`
          );
        }
      }
      setTimeout(() => {
        const element = document.querySelector("#fetchingInsightsloader3");

        const ellipsisElement =
          element && element.querySelector(".fetchingInsightsloader3x");
        ellipsisElement && (ellipsisElement.textContent = "");

        if (element) {
          element.style.opacity = 0;
          element.style.transform = "translate(100%, 0)";
          element.style.transition = "none";
        }
        localStorage.removeItem("autoiterationMessage");
        localStorage.removeItem("connectingBroker");
      }, 8000);
      history.push("/Dashboard");
    },
    [allData, allGlobalData, portfolio, history, overWrite, user, broker]
  );

  const requestBroker = useCallback(
    async (broker) => {
      $(window).bind("beforeunload", function () {
        return ">>>>>Before You Go<<<<<<<< \n Your custom message go here";
      });
      localStorage.setItem("connectingBroker", "yes");
      const element = document.querySelector("#fetchingInsightsloader3");
      if (element) {
        element.style.opacity = 0.8;
        element.style.transform = "translate(0, 0)";
        element.style.transition = "opacity 1s, transform 1s";
      }
      let propsToChange = {};
      let trades = []; // can put outside since only one is called at a time
      const portfolioINIT = broker;
      if (isweb3selected) {
        if (portfolioINIT === "metamask") {
          // ----------- METAMASK -----------
          if (ethereum) {
            // From now on, this should always be true:
            // provider === window.ethereum
            let ids = [];
            localStorage.setItem(
              "autoiterationMessage",
              `Connecting to MetaMask API's...`
            );
            const account = await ethereum.request({
              method: "eth_requestAccounts",
            });
            const address = account[0].toLowerCase();
            setwalletAddress(address);
            const accountNumberExistsCheck = isAccountNumberExists(
              allAutoImportConnectionsFullArray,
              accountNumber,
              email,
              APIsecret,
              APIkey,
              APIpassphrase,
              address
            );
            if (accountNumberExistsCheck) {
              setaccountExistsError(true);
              $(window).unbind("beforeunload");
              if (element) {
                element.style.opacity = 0;
                element.style.transform = "translate(100%, 0)";
                element.style.transition = "none";
              }
              store.dispatch(setLoaderState({ loading: false }));
              localStorage.setItem(
                "autoiterationMessage",
                `Connection failed. Wait a few moments and try again.`
              );
              localStorage.removeItem("connectingBroker");
              setTimeout(() => {
                localStorage.removeItem("autoiterationMessage");
              }, 6000);
              return false;
            } else {
              setaccountExistsError(false);
            }
            handleCloseLinkBroker();
            //store.dispatch(setLoaderState({ loading: true }));
            setLoaderText();

            /*             const history = await etherscanProvider
              .getHistory(address)
              .then(async (history) => {
                return history;
              })
              .catch((e) => {
                if (element) {
                  element.style.opacity = 0;
                  element.style.transform = "translate(100%, 0)";
                  element.style.transition = "none";
                }
                $(window).unbind("beforeunload");
                store.dispatch(setLoaderState({ loading: false }));
                localStorage.setItem(
                  "autoiterationMessage",
                  `Connection failed. Wait a few moments and try again.`
                );
                localStorage.removeItem("connectingBroker");
                setTimeout(() => {
                  localStorage.removeItem("autoiterationMessage");
                }, 6000);
              }); */

            const history = await fetchHistoryWithInternals(
              address,
              process.env.REACT_APP_ETHERSCAN_KEY,
              element
            );
            localStorage.setItem(
              "autoiterationMessage",
              `Importing 0/${history.length} executions...`
            );
            const pnlmethod = portfolio?.settings.pnlcalculationmethod;

            const parsedOrdersandIds = await parseEtherOrders(
              trades,
              ids,
              portfolio,
              history,
              user.sub,
              verifiedLevel,
              address,
              importType,
              pnlmethod
            );
            trades = parsedOrdersandIds.trades;
            ids = parsedOrdersandIds.ids;

            // Have set new connections array
            const newProp = {
              broker: "metamask",
              address: address,
              integrationId: uuidv4(),
              portfolio: portfolio.name,
              ids: ids,
              integrationDate: new Date(),
            };

            allGlobalData.setconnectionsArray([
              ...allGlobalData.connectionsArray,
              newProp,
            ]);

            propsToChange = {
              currencySymbol: "ETH",
              verified: 2,
              linkedBrokerInfo: newProp,
              verifiedDate: new Date(),
            };

            await callsetData(propsToChange, trades);
            store.dispatch(setLoaderState({ loading: false }));
          } else {
            if (element) {
              element.style.opacity = 0;
              element.style.transform = "translate(100%, 0)";
              element.style.transition = "none";
            }
            $(window).unbind("beforeunload");
            store.dispatch(setLoaderState({ loading: false }));
            localStorage.removeItem("autoiterationMessage"); //go ahead an remove automatically
            localStorage.removeItem("connectingBroker");

            alert("Please install MetaMask!");
          }
        } else if (portfolioINIT === "solflare") {
          // ----------------- SOLFLARE -------------------

        }
      } else if (portfolioINIT === "robinhood") {
        props.setShowImport(false);
        // ----------- ROBINHOOD -----------
        handleCloseLinkBroker();
        //store.dispatch(setLoaderState({ loading: true }));
        setLoaderText();
        localStorage.setItem(
          "autoiterationMessage",
          `Connecting to Robinhood API's...`
        );
        const robinhoodCryptoInstrumentsResponse =
          await getRobinhoodCryptoInstrument(handleCloseLinkBroker);
        const robinhoodCryptoInstruments =
          robinhoodCryptoInstrumentsResponse &&
          robinhoodCryptoInstrumentsResponse.gotRobinhoodCryptoInstruments
            .instrument.results;
        const robinhoodData = await getAllRobinhoodTransactions(
          email,
          password,
          mfaCode,
          portfolio
        );
        const robinhoodOrders = robinhoodData && robinhoodData.robinhoodOrders;
        if (robinhoodOrders === "error") {
          $(window).unbind("beforeunload");
          if (element) {
            element.style.opacity = 0;
            element.style.transform = "translate(100%, 0)";
            element.style.transition = "none";
          }
          store.dispatch(setLoaderState({ loading: false }));
          localStorage.setItem(
            "autoiterationMessage",
            `Connection failed. Wait a few moments and try again.`
          );
          localStorage.removeItem("connectingBroker");

          props.setShowError({ state: true, broker: "robinhood" });
          setTimeout(() => {
            props.setShowError({ state: false, broker: "" });
            localStorage.removeItem("autoiterationMessage");
          }, 6000);
        } else {
          const accessToken = robinhoodData && robinhoodData.accessToken;
          // Filter crypto instrument by Id
          const filtercryptoByID = (id) => {
            const filteredInstrument = robinhoodCryptoInstruments?.filter(
              (instrument) => instrument.id === id
            );
            return filteredInstrument[0]?.symbol;
          };

          let tradedata = robinhoodOrders;
          let ids = [];

          // SORT BEFORE DOING STUFF TO tradedata
          tradedata &&
            tradedata.sort(function (a, b) {
              var c = new Date(
                !a["created_at"]
                  ? !a["updated_at"]
                    ? a["initiated_at"]
                    : a["updated_at"]
                  : a["created_at"]
              );
              var d = new Date(
                !b["created_at"]
                  ? !b["updated_at"]
                    ? b["initiated_at"]
                    : b["updated_at"]
                  : b["created_at"]
              );
              return d - c;
            });
          tradedata && tradedata.reverse();

          // PARSE INCOMING ROBINHOOD ORDERS
          // CREATE NEW IDS ARRAY
          localStorage.setItem(
            "autoiterationMessage",
            `Importing 0/${tradedata.length} executions...`
          );
          const pnlmethod = portfolio?.settings.pnlcalculationmethod;

          const parsedOrdersandIds = await parseRobinhoodOrders(
            trades,
            ids,
            portfolio,
            tradedata,
            user.sub,
            filtercryptoByID,
            verifiedLevel,
            accessToken,
            importType,
            pnlmethod
          );

          // encrypt their password
          const encryptedCredentials = key.encrypt(
            JSON.stringify(password),
            "base64"
          );

          trades = parsedOrdersandIds.trades;
          ids = parsedOrdersandIds.ids;
          // Have set new connections array
          const newProp = {
            broker: "robinhood",
            email: email,
            password: encryptedCredentials,
            portfolio: portfolio.name,
            integrationId: uuidv4(),
            ids: ids,
            integrationDate: new Date(),
          };
          propsToChange = {
            linkedBrokerInfo: newProp,
            verified: 2,
            verifiedDate: new Date(),
          };
          allGlobalData.setconnectionsArray([
            ...allGlobalData.connectionsArray,
            newProp,
          ]);
          await callsetData(propsToChange, trades);
        }
      } else if (portfolioINIT === "kraken") {
        props.setShowImport(false);
        // ----------- DYDX -----------
        handleCloseLinkBroker();
        setLoaderText();
        localStorage.setItem(
          "autoiterationMessage",
          `Connecting to Kraken API's...`
        );
        const krakenCreds = {
          secret: APIsecret,
          key: APIkey,
        };
        const getMessageSignature = (path, request, secret, nonce) => {
          const message = qs.stringify(request);
          const secret_buffer = new Buffer(secret, "base64");
          const hash = new crypto.createHash("sha256");
          const hmac = new crypto.createHmac("sha512", secret_buffer);
          const hash_digest = hash.update(nonce + message).digest("binary");
          const hmac_digest = hmac
            .update(path + hash_digest, "binary")
            .digest("base64");

          return hmac_digest;
        };
        const APIsignature = getMessageSignature("/0/private/TradesHistory");
        // encrypt their credentials
        const encryptedCredentials = key.encrypt(
          JSON.stringify(krakenCreds),
          "base64"
        );
        //Get trades
        /*         localStorage.setItem(
          "autoiterationMessage",
          `Importing 0/${tradedata.length} executions...`
        );
        const parsedOrdersandIds = parseKrakenOrders(
          trades,
          ids,
          portfolio,
          tradedata,
          user.sub,
          verifiedLevel,
          importType
        );
        
        trades = parsedOrdersandIds.trades;
        ids = parsedOrdersandIds.ids;
        // Have set new connections array
        const newProp = {
          broker: "kraken",
          cred: encryptedCredentials,
          integrationId: uuidv4(),
          portfolio: portfolio.name,
          ids: ids,
        };
        propsToChange = {
          linkedBrokerInfo: newProp,
          verified: 2,
          verifiedDate: new Date(),
        };
        allGlobalData.setconnectionsArray([
          ...allGlobalData.connectionsArray,
          newProp,
        ]);
        await callsetData(propsToChange, trades); */
      } else if (
        portfolioINIT === "metatrader4" ||
        portfolioINIT === "metatrader5"
      ) {
        props.setShowImport(false);
        handleCloseLinkBroker();
        //store.dispatch(setLoaderState({ loading: true }));
        setLoaderText();
        localStorage.setItem(
          "autoiterationMessage",
          `Connecting to MetaApi API's...`
        );
        // Get trades
        const userIp = await getUserIP();
        const region = await determineRegion(userIp);
        try {
          const account = await metaapi.metatraderAccountApi.createAccount({
            name: `${user.email} - MetaTrader Connection 1`,
            type: "cloud",
            login: accountNumber,
            platform: portfolioINIT === "metatrader4" ? "mt4" : "mt5",
            // password can be investor password for read-only access
            password: password,
            region: region,
            server: selectedServer.label,
            magic: 123456,
            resourceSlots: 2,
            keywords: ["selectedBroker"],
            quoteStreamingIntervalInSeconds: 2.5, // set to 0 to receive quote per tick
            reliability: "high", // set this field to 'high' value if you want to increase uptime of your account (recommended for production environments)
          });
          const connectionId = uuidv4();
          // -------------------------------
          if (!account) {
            if (element) {
              element.style.opacity = 0;
              element.style.transform = "translate(100%, 0)";
              element.style.transition = "none";
            }
            $(window).unbind("beforeunload");
            store.dispatch(setLoaderState({ loading: false }));
            localStorage.removeItem("connectingBroker");
            localStorage.setItem(
              "autoiterationMessage",
              `Connection failed. Wait a few moments and try again.`
            );
            props.setShowError({
              state: true,
              broker: portfolioINIT,
            });
            setTimeout(() => {
              props.setShowError({ state: false, broker: "" });
              localStorage.removeItem("autoiterationMessage");
            }, 6000);
          } else {
            const myFunction = async () => {
              // Your function code here
              try {
                account && (await account.remove());
              } catch (err) {
                console.log(err);
              }
              if (element) {
                element.style.opacity = 0;
                element.style.transform = "translate(100%, 0)";
                element.style.transition = "none";
              }
              const newProp = {
                broker: "",
              };
              propsToChange = {
                linkedBrokerInfo: newProp,
                verified: 0,
                verifiedDate: "",
              };
              let tempconncetionarray = allGlobalData.connectionsArray.slice();
              tempconncetionarray = tempconncetionarray.filter(
                (connection) => connection.id !== connectionId
              );
              allGlobalData.setconnectionsArray(tempconncetionarray);
              await callsetData(propsToChange, trades, "err");
              $(window).unbind("beforeunload");
              store.dispatch(setLoaderState({ loading: false }));
            };
            try {
              // wait until account is deployed and connected to broker
              // Can be removed in future maybe...
              /*               window.addEventListener("beforeunload", async function (event) {
                // This function will be executed before the page is unloaded
                // You can perform any necessary tasks here
                // Make sure to include a return statement to display the browser's default confirmation message
                event.returnValue = "";
                await myFunction();
              }); */
              $(window).bind("beforeunload", async function () {
                await myFunction();
                return ">>>>>Before You Go<<<<<<<< \n Your custom message go here";
              });
              if (account.state !== "DEPLOYED") {
                localStorage.setItem(
                  "autoiterationMessage",
                  `Deploying account...`
                );
                await account.deploy();
              } else {
                //console.log("Account already deployed");
              }
              if (account.connectionStatus !== "CONNECTED") {
                localStorage.setItem(
                  "autoiterationMessage",
                  `Connecting server to ${
                    selectedBroker?.label
                      ? selectedBroker.label
                      : selectedServer.label
                  } (may take a couple of minutes)...`
                );
                await account.waitConnected();
              }
              const connection = account.getStreamingConnection();
              await connection.connect();
              // wait until synchronization completed
              await connection.waitSynchronized();

              // access local copy of terminal state
              //const terminalState = connection.terminalState;
              const accountId = account._data._id;
              const marginMode = account?._data?.marginMode;
              // access history storage
              const historyStorage = connection.historyStorage;
              // both orderSynchronizationFinished and dealSynchronizationFinished
              // should be true once history synchronization have finished
              let tradedata = historyStorage.deals;
              let ids = [];
              // PARSE INCOMING DYDX ORDERS
              // CREATE NEW IDS ARRAY
              localStorage.setItem(
                "autoiterationMessage",
                `Importing 0/${tradedata.length} executions...`
              );
              const pnlmethod = portfolio?.settings.pnlcalculationmethod;

              const parsedOrdersandIds = parseMetaTraderOrders(
                trades,
                ids,
                portfolio,
                tradedata,
                user.sub,
                verifiedLevel,
                marginMode,
                importType,
                pnlmethod
              );

              trades = parsedOrdersandIds.trades;
              ids = parsedOrdersandIds.ids;

              // Have set new connections array
              // encrypt their accountId
              const encryptedCredentials = key.encrypt(
                JSON.stringify(accountId),
                "base64"
              );
              const encryptedCredentials2 = key.encrypt(
                JSON.stringify(accountNumber),
                "base64"
              );
              const newProp = {
                broker: portfolioINIT,
                cred: encryptedCredentials,
                accountNumber: encryptedCredentials2,
                portfolio: portfolio.name,
                marginMode: marginMode,
                integrationId: connectionId,
                ids: ids,
                integrationDate: new Date(),
              };
              propsToChange = {
                linkedBrokerInfo: newProp,
                verified: 2,
                verifiedDate: new Date(),
              };
              allGlobalData.setconnectionsArray([
                ...allGlobalData.connectionsArray,
                newProp,
              ]);
              await callsetData(propsToChange, trades);
            } catch (err) {
              await myFunction();
              localStorage.removeItem("connectingBroker");
              localStorage.setItem(
                "autoiterationMessage",
                `Connection failed. Wait a few moments and try again.`
              );
              props.setShowError({ state: true, broker: portfolioINIT });
              setTimeout(() => {
                props.setShowError({ state: false, broker: "" });
                localStorage.removeItem("autoiterationMessage");
              }, 6000);
            }
          }
        } catch (err) {
          // process errors
          $(window).unbind("beforeunload");
          if (element) {
            element.style.opacity = 0;
            element.style.transform = "translate(100%, 0)";
            element.style.transition = "none";
          }
          store.dispatch(setLoaderState({ loading: false }));
          localStorage.removeItem("connectingBroker");
          localStorage.setItem(
            "autoiterationMessage",
            `Connection failed. Wait a few moments and try again.`
          );
          if (err.details) {
            // returned if the server file for the specified server name has not been found
            // recommended to check the server name or create the account using a provisioning profile
            if (err.details === "E_SRV_NOT_FOUND") {
              console.error(err);
              // returned if the server has failed to connect to the broker using your credentials
              // recommended to check your login and password
            } else if (err.details === "E_AUTH") {
              console.log(err);
              // returned if the server has failed to detect the broker settings
              // recommended to try again later or create the account using a provisioning profile
            } else if (err.details === "E_SERVER_TIMEZONE") {
              console.log(err);
            }
          }
          store.dispatch(setLoaderState({ loading: false }));
          props.setShowError({ state: true, broker: portfolioINIT });
          setTimeout(() => {
            props.setShowError({ state: false, broker: "" });
            localStorage.removeItem("autoiterationMessage");
          }, 6000);
        }
      } else if (portfolioINIT === "dYdX") {
        props.setShowImport(false);
        // ----------- DYDX -----------
        handleCloseLinkBroker();
        //store.dispatch(setLoaderState({ loading: true }));
        setLoaderText();
        localStorage.setItem(
          "autoiterationMessage",
          `Connecting to dYdX API's...`
        );
        const apiKeyCredentials = {
          secret: APIsecret,
          key: APIkey,
          passphrase: APIpassphrase,
        };
        const client = new DydxClient(HTTP_HOST, {
          apiKeyCredentials: apiKeyCredentials,
        });
        const fillsCheck = await client.private
          .getFills({
            limit: 1,
            createdBeforeOrAt: new Date().toISOString(),
          })
          .catch((err) => {
            return "error";
          });
        if (fillsCheck === "error") {
          $(window).unbind("beforeunload");
          if (element) {
            element.style.opacity = 0;
            element.style.transform = "translate(100%, 0)";
            element.style.transition = "none";
          }
          store.dispatch(setLoaderState({ loading: false }));
          localStorage.setItem(
            "autoiterationMessage",
            `Connection failed. Wait a few moments and try again.`
          );
          localStorage.removeItem("connectingBroker");

          props.setShowError({ state: true, broker: portfolioINIT });
          setTimeout(() => {
            props.setShowError({ state: false, broker: "dYdX" });
            localStorage.removeItem("autoiterationMessage");
          }, 6000);
          store.dispatch(setLoaderState({ loading: false }));
          return;
        }
        // encrypt their credentials
        const encryptedCredentials = key.encrypt(
          JSON.stringify(apiKeyCredentials),
          "base64"
        );

        let tradedata = [];
        // get fills, transfers and funding transactions
        // because they are limited to 100 we need to loop through
        const timestamp = new Date().toISOString();

        const pushtrades = (trades, topush) => {
          trades.forEach((item) => {
            topush.push(item);
          });
          // use this function to prevent the error
          // Function declared in a loop contains unsafe references
          // to variable(s) 'fills' **or fundingfees or depositswithdrawals
        };
        let ids = [];
        let createdBeforeAt = timestamp;
        let nomorepages = false;
        let fills = [];
        let iteration = 0;
        while (!nomorepages) {
          const fillsper = await client.private.getFills({
            limit: 100,
            createdBeforeOrAt: createdBeforeAt,
          });
          let trades = fillsper.fills;
          if (trades.length < 100 && iteration > 0) {
            nomorepages = true;
          } else {
          }
          const [lastItem] = trades.slice(-1);
          let lasttradeDatePre = lastItem && new Date(lastItem.createdAt);
          let lasttradeDate =
            lasttradeDatePre &&
            new Date(
              lasttradeDatePre.setSeconds(lasttradeDatePre.getSeconds() + 1)
            ).toISOString(); // add 1 sec
          createdBeforeAt = lasttradeDate;
          pushtrades(trades, fills);
          iteration++;
        }

        let depositswithdrawals = [];
        nomorepages = false; // reset this var
        createdBeforeAt = timestamp; //and this one
        iteration = 0; //and this one
        while (!nomorepages) {
          const dwsper = await client.private.getTransfers({
            limit: 100,
            createdBeforeOrAt: createdBeforeAt,
          });
          let trades = dwsper.transfers;
          if (trades.length < 100 && iteration > 0) {
            nomorepages = true;
          } else {
          }
          const [lastItem] = trades.slice(-1);
          let lasttradeDatePre = lastItem && new Date(lastItem.createdAt);
          let lasttradeDate =
            lasttradeDatePre &&
            new Date(
              lasttradeDatePre.setSeconds(lasttradeDatePre.getSeconds() + 1)
            ).toISOString(); // add 1 sec
          createdBeforeAt = lasttradeDate;
          pushtrades(trades, depositswithdrawals);
          iteration++;
        }

        let fundingfees = [];
        nomorepages = false; // reset this var
        createdBeforeAt = timestamp; //and this one
        iteration = 0; //and this one
        while (!nomorepages) {
          const fundingsper = await client.private.getFundingPayments({
            limit: 100,
            effectiveBeforeOrAt: createdBeforeAt,
          });
          let trades = fundingsper.fundingPayments;
          if (trades.length < 100 && iteration > 0) {
            nomorepages = true;
          } else {
          }
          const [lastItem] = trades.slice(-1);
          let lasttradeDatePre = lastItem && new Date(lastItem.effectiveAt);
          let lasttradeDate =
            lasttradeDatePre &&
            new Date(
              lasttradeDatePre.setSeconds(lasttradeDatePre.getSeconds() - 0.01)
            ).toISOString(); // add 1 sec
          createdBeforeAt = lasttradeDate;
          pushtrades(trades, fundingfees);
          iteration++;
        }

        nomorepages = false; // reset this var
        createdBeforeAt = timestamp; //and this one

        // REMOVE DUPLICATES
        fills = getUniqueItemsByProps(fills, ["id"]);
        depositswithdrawals = getUniqueItemsByProps(depositswithdrawals, [
          "id",
        ]);
        fundingfees = getUniqueItemsByProps(fundingfees, [
          "market",
          "effectiveAt",
          "price",
          "positionSize",
          "rate",
          "payment",
        ]);

        fills.forEach((item) => {
          tradedata.push(item);
        });
        depositswithdrawals.forEach((item) => {
          tradedata.push(item);
        });
        fundingfees.forEach((item) => {
          tradedata.push(item);
        });

        // SORT BEFORE DOING STUFF TO tradedata
        tradedata &&
          tradedata.sort(function (a, b) {
            var c = new Date(
              !a["createdAt"] ? a["effectiveAt"] : a["createdAt"]
            );
            var d = new Date(
              !b["createdAt"] ? b["effectiveAt"] : b["createdAt"]
            );
            return d - c;
          });
        tradedata && tradedata.reverse();
        // INITIALLY ADD TRADES FROM DYDX ACCOUNT
        // must look for open trades

        // PARSE INCOMING DYDX ORDERS
        // CREATE NEW IDS ARRAY
        localStorage.setItem(
          "autoiterationMessage",
          `Importing 0/${tradedata.length} executions...`
        );
        const pnlmethod = portfolio?.settings.pnlcalculationmethod;

        const parsedOrdersandIds = parsedYdXOrders(
          trades,
          ids,
          portfolio,
          tradedata,
          user.sub,
          verifiedLevel,
          importType,pnlmethod
        );

        trades = parsedOrdersandIds.trades;
        ids = parsedOrdersandIds.ids;
        // Have set new connections array
        const newProp = {
          broker: "dYdX",
          cred: encryptedCredentials,
          integrationId: uuidv4(),
          portfolio: portfolio.name,
          ids: ids,
          integrationDate: new Date(),
        };
        propsToChange = {
          linkedBrokerInfo: newProp,
          verified: 2,
          verifiedDate: new Date(),
        };
        allGlobalData.setconnectionsArray([
          ...allGlobalData.connectionsArray,
          newProp,
        ]);
        await callsetData(propsToChange, trades);
      }
    },
    [
      APIkey,
      APIpassphrase,
      APIsecret,
      callsetData,
      email,
      isweb3selected,
      mfaCode,
      password,
      portfolio,
      user,
      accountExistsError,
      allGlobalData,
      accountNumber,
      allAutoImportConnectionsFullArray,
      isAccountNumberExists,
      selectedBroker,
      selectedServer,
      handleCloseLinkBroker,
      props,
    ]
  );

  let childdiv = "";
  let modalheader = "";
  let modallogodiv = "";
  let modallogo = "";

  // Create the necessary child components
  if (isweb3selected) {
    if (broker && broker.value === "metamask") {
      modalheader = "Link Ethereum Wallet";
      const showProps = {
        metamask: allAutoImportConnectionsArray.includes("metamask"),
        coinbase: allAutoImportConnectionsArray.includes("coinbase"),
        walletconnect: allAutoImportConnectionsArray.includes("walletconnect"),
      };
      childdiv = (
        <div>
          <button
            id="linkweb3brokerbutton"
            onClick={async () => await requestBroker("metamask")}
          >
            <img
              className="linklogowrapperimgrweb3"
              src={metamasklogo}
              alt=""
            />
            MetaMask
          </button>
          {accountExistsError && (
            <div className="portaddinputs-accountexistserror">
              Address already connected to your {accountExistsName} portfolio.
            </div>
          )}
        </div>
      );
    } else if (broker && broker.value === "solflare") {
      modalheader = "Link Solana Wallet";
      const showProps = {
        metamask: allAutoImportConnectionsArray.includes("metamask"),
        coinbase: allAutoImportConnectionsArray.includes("coinbase"),
        walletconnect: allAutoImportConnectionsArray.includes("walletconnect"),
      };
      childdiv = (
        <div>
          <button
            id="linkweb3brokerbutton"
            onClick={async () => await requestBroker("solflare")}
          >
            <img
              className="linklogowrapperimgrweb3"
              src={solflarelogo}
              alt=""
            />
          </button>
          {accountExistsError && (
            <div className="portaddinputs-accountexistserror">
              Address already connected to your {accountExistsName} portfolio.
            </div>
          )}
        </div>
      );
    }
  } else if (broker && broker.value === "thinkorswim") {
    modalheader = "Link thinkorswim";
    modallogo = thinkorswimlogo;
    modallogodiv = (
      <img className="linklogowrapperimgapollox" src={modallogo} alt="" />
    );
    childdiv = (
      <div style={{ marginTop: "-252px" }}>
        <ol type="1"></ol>
      </div>
    );
  } else if (broker && broker.value === "webull") {
    modalheader = "Link Webull";
    modallogo = webulllogo;
    modallogodiv = (
      <img className="linklogowrapperimgwebull" src={modallogo} alt="" />
    );
    childdiv = (
      <div style={{ marginTop: "-94px" }}>
        <ol style={{ position: "absolute", top: 24, left: 4 }} type="1">
          <li
            style={{
              textAlign: "justify",
              textJustify: "inter-word",
              padding: "0px 38px 0px 0px",
              margin: "0px 0px 10px 0px",
              color: "#fff7cb",
            }}
          >
            Enter the {inputType === "email" ? "email" : "phone number"} and
            password you use to log into your Webull account.
            <span> This is a one-time action.</span>
          </li>
          <label className="portaddinputs-linkbroker">
            <div className="dropdown-container">
              <span>{inputType === "email" ? "Email" : "Phone Number"}</span>
              <div className="dropdown-caret" onClick={(e) => {}}>
                &#x25BC;
              </div>
              <ul className="dropdown-options">
                <li
                  onClick={() => {
                    toggleInputType("email");
                  }}
                >
                  Email
                </li>
                <li
                  onClick={() => {
                    toggleInputType("phone");
                  }}
                >
                  Phone Number
                </li>
              </ul>
            </div>
            {accountExistsError && (
              <div className="portaddinputs-accountexistserror">
                Account already connected to your {accountExistsName} portfolio.
              </div>
            )}
            <span className="mandatory linkRobinhoodErrorMsg">{""}</span>
            <input
              name="portfolioName"
              value={email}
              style={movecursor}
              className="text-input-boxes-linkbroker required"
              autoComplete="off"
              pattern="^(?!\(.*[^)]$|[^(].*\)$)\(?\$?(0|[1-9]\d{0,2}(,?\d{3})?)(\.\d\d?)?\)?$"
              required
              placeholder="Required"
              onChange={(e) => setemail(e.target.value)}
            />
          </label>
          <label>
            {inputType === "email" ? (
              <span className="container checkssettings">
                <input
                  type="checkbox"
                  id="boxNoEntrySlip"
                  name="hasEntrySlippage"
                  checked={selectCheck}
                  onChange={(e) => selectFunction(e)}
                />
                <span className="checkmark checkmark-col1-2"></span>
                <span style={{ marginTop: "0px", marginRight: 4 }}>
                  &emsp;&ensp;Same as TradeFuse account email
                </span>
              </span>
            ) : (
              ""
            )}
          </label>
          <label className="portaddinputs-linkbroker">
            Password
            <input
              name="portfoliopassword"
              value={password}
              style={movecursor}
              className="text-input-boxes-linkbroker2 required"
              autoComplete="off"
              pattern="^(?!\(.*[^)]$|[^(].*\)$)\(?\$?(0|[1-9]\d{0,2}(,?\d{3})?)(\.\d\d?)?\)?$"
              required
              type="password"
              placeholder="Required"
              onChange={(e) => setpassword(e.target.value)}
            />
          </label>
          <div
            style={{
              backgroundColor: "#556171",
              width: "calc(100% - 38px)",
              height: "1px",
              margin: "20px 0px 20px 0px",
            }}
          ></div>
          <li
            style={{
              margin: "0px 0px 10px 0px",
              color: "#fff7cb",
              width: "calc(100% - 32px)",
              textAlign: "left",
            }}
          >
            Enter your Webull Trading Password. You will need to enter this
            every log in for security purposes.
          </li>
          <label className="portaddinputs-linkbroker">
            <span className="mandatory linkRobinhoodMFAErrorMsg">{""}</span>
            Trading Password
            <input
              name="portfoliopassword"
              value={mfaCode}
              style={movecursor}
              className="text-input-boxes-linkbroker2 required"
              autoComplete="off"
              pattern="^(?!\(.*[^)]$|[^(].*\)$)\(?\$?(0|[1-9]\d{0,2}(,?\d{3})?)(\.\d\d?)?\)?$"
              required
              type="password"
              placeholder="Required"
              onChange={(e) => setmfaCode(e.target.value)}
            />
          </label>
          {/*           <div style={{ margin: "14px 0px 0px 0px", padding: "0px" }}>
            <label className="container checksss">
              <input
                type="checkbox"
                id="boxNoEntrySlip"
                name="hasEntrySlippage"
                checked={checked}
                onClick={(e) =>
                  checked ? setChecked(false) : setChecked(true)
                }
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;Ask for an MFA Code every login
            </label>
          </div> */}
          <div
            style={{
              backgroundColor: "#556171",
              width: "calc(100% - 38px)",
              height: "1px",
              margin: "20px 0px 20px 0px",
            }}
          ></div>
          <div
            style={{
              textAlign: "justify",
              textJustify: "inter-word",
              padding: "0px 38px 0px 0px",
            }}
          >
            ** We are using Webull's official API's. We exclusively use the
            read-only API's. Your credentials are encrypted and securely
            handled.
          </div>
        </ol>
      </div>
    );
  } else if (broker && broker.value === "robinhood") {
    modalheader = "Link Robinhood";
    modallogo = robinhoodlogo;
    modallogodiv = (
      <img className="linklogowrapperimgrh" src={modallogo} alt="" />
    );
    childdiv = (
      <div style={{ marginTop: "-94px" }}>
        <ol style={{ position: "absolute", top: 24, left: 4 }} type="1">
          <li
            style={{
              textAlign: "justify",
              textJustify: "inter-word",
              padding: "0px 38px 0px 0px",
              margin: "0px 0px 10px 0px",
              color: "#fff7cb",
            }}
          >
            Enter the email and password you use to log into your Robinhood
            account.<span> This is a one-time action.</span>
          </li>
          <label className="portaddinputs-linkbroker">
            Email
            {accountExistsError && (
              <div className="portaddinputs-accountexistserror">
                Account already connected to your {accountExistsName} portfolio.
              </div>
            )}
            <span className="mandatory linkRobinhoodErrorMsg">{""}</span>
            <input
              name="portfolioName"
              value={email}
              style={movecursor}
              className="text-input-boxes-linkbroker required"
              autoComplete="off"
              pattern="^(?!\(.*[^)]$|[^(].*\)$)\(?\$?(0|[1-9]\d{0,2}(,?\d{3})?)(\.\d\d?)?\)?$"
              required
              placeholder="Required"
              onChange={(e) => setemail(e.target.value)}
            />
          </label>{" "}
          <label>
            <span className="container checkssettings">
              <input
                type="checkbox"
                id="boxNoEntrySlip"
                name="hasEntrySlippage"
                checked={selectCheck}
                onChange={(e) => selectFunction(e)}
              />
              <span className="checkmark checkmark-col1-2"></span>
              <span style={{ marginTop: "0px", marginRight: 4 }}>
                &emsp;&ensp;Same as TradeFuse account email
              </span>
            </span>
          </label>
          <label className="portaddinputs-linkbroker">
            Password
            <input
              name="portfoliopassword"
              value={password}
              style={movecursor}
              className="text-input-boxes-linkbroker2 required"
              autoComplete="off"
              pattern="^(?!\(.*[^)]$|[^(].*\)$)\(?\$?(0|[1-9]\d{0,2}(,?\d{3})?)(\.\d\d?)?\)?$"
              required
              type="password"
              placeholder="Required"
              onChange={(e) => setpassword(e.target.value)}
            />
          </label>
          <div
            style={{
              backgroundColor: "#556171",
              width: "calc(100% - 38px)",
              height: "1px",
              margin: "20px 0px 20px 0px",
            }}
          ></div>
          <li
            style={{
              margin: "0px 0px 10px 0px",
              color: "#fff7cb",
              width: "calc(100% - 32px)",
              textAlign: "left",
            }}
          >
            Enter the MFA code from your authentication app. **Occasionally, MFA
            may fail and will send a push notification via text/email; enter
            this code instead.
          </li>
          <label className="portaddinputs-linkbroker">
            <span className="mandatory linkRobinhoodMFAErrorMsg">{""}</span>
            MFA Code
            <input
              name="portfoliopassword"
              value={mfaCode}
              style={movecursor}
              className="text-input-boxes-linkbroker2 required"
              autoComplete="off"
              pattern="^(?!\(.*[^)]$|[^(].*\)$)\(?\$?(0|[1-9]\d{0,2}(,?\d{3})?)(\.\d\d?)?\)?$"
              required
              type="password"
              placeholder="Required"
              onChange={(e) => setmfaCode(e.target.value)}
            />
          </label>
          {/*           <div style={{ margin: "14px 0px 0px 0px", padding: "0px" }}>
            <label className="container checksss">
              <input
                type="checkbox"
                id="boxNoEntrySlip"
                name="hasEntrySlippage"
                checked={checked}
                onClick={(e) =>
                  checked ? setChecked(false) : setChecked(true)
                }
              />
              <span className="checkmark checkmark-col1-2"></span>
              &emsp;&ensp;Ask for an MFA Code every login
            </label>
          </div> */}
          <div
            style={{
              backgroundColor: "#556171",
              width: "calc(100% - 38px)",
              height: "1px",
              margin: "20px 0px 20px 0px",
            }}
          ></div>
          <div
            style={{
              textAlign: "justify",
              textJustify: "inter-word",
              padding: "0px 38px 0px 0px",
            }}
          >
            ** We are using Robinhood's unofficial API. We exclusively use the
            read-only API's. Your credentials are encrypted and securely
            handled.
          </div>
        </ol>
      </div>
    );
  } else if (broker && broker.value === "interactivebrokers") {
    modalheader = "Link Interactive Brokers";
    modallogo = iblogo;
    modallogodiv = (
      <img className="linklogowrapperimgkraken" src={modallogo} alt="" />
    );
    childdiv = (
      <div style={{ marginTop: "-260px" }}>
        <ol type="1">
          <li
            style={{
              textAlign: "justify",
              textJustify: "inter-word",
              padding: "0px 38px 0px 0px",
              margin: "0px 0px 16px 0px",
              color: "#fff7cb",
            }}
          >
            {`Enter your Interactive Brokers API credentials. These will be encrypted and stored securely; they grant us access to ONLY the Interactive Brokers API's; we exclusively use the read-only API's.`}
            <span> This is a one-time action.</span>
          </li>
          {accountExistsError && (
            <div className="portaddinputs-accountexistserror">
              Account already connected to your {accountExistsName} portfolio.
            </div>
          )}
          <label className="portaddinputs-linkbroker">
            Email
            <span className="container checksIBsettings">
              <input
                type="checkbox"
                id="boxNoEntrySlip"
                name="hasEntrySlippage"
                checked={selectCheck}
                onChange={(e) => selectFunction(e)}
              />
              <span className="checkmark checkmark-col1-2"></span>
              <span style={{ marginTop: "0px", marginRight: 4 }}>
                &emsp;&ensp;Same as TradeFuse account email
              </span>
            </span>
            {accountExistsError && (
              <div className="portaddinputs-accountexistserror">
                Account already connected to your {accountExistsName} portfolio.
              </div>
            )}
            <span className="mandatory linkRobinhoodErrorMsg">{""}</span>
            <input
              name="portfolioName"
              value={email}
              style={movecursor}
              className="text-input-boxes-linkbroker required"
              autoComplete="off"
              required
              placeholder="Enter your email"
              onChange={(e) => setemail(e.target.value)}
            />
          </label>
          <label className="portaddinputs-linkbroker">
            Password
            <input
              name="portfoliopassword"
              value={password}
              style={movecursor}
              className="text-input-boxes-linkbroker2 required"
              autoComplete="off"
              pattern="^(?!\(.*[^)]$|[^(].*\)$)\(?\$?(0|[1-9]\d{0,2}(,?\d{3})?)(\.\d\d?)?\)?$"
              required
              type="password"
              placeholder="Required"
              onChange={(e) => setpassword(e.target.value)}
            />
          </label>
        </ol>
      </div>
    );
  } else if (broker && broker.value === "kraken") {
    modalheader = "Link Kraken";
    modallogo = krakenlogo;
    modallogodiv = (
      <img className="linklogowrapperimgkraken" src={modallogo} alt="" />
    );
    childdiv = (
      <div style={{ marginTop: "-230px" }}>
        <ol type="1">
          <li
            style={{
              textAlign: "justify",
              textJustify: "inter-word",
              padding: "0px 38px 0px 0px",
              margin: "0px 0px 16px 0px",
              color: "#fff7cb",
            }}
          >
            {`Enter your Kraken API credentials (API Secret & API Key). These will be encrypted and stored securely; they grant us access to ONLY the Kraken API's; we exclusively use the read-only API's.`}
            <span> This is a one-time action.</span>
          </li>
          {accountExistsError && (
            <div className="portaddinputs-accountexistserror">
              Account already connected to your {accountExistsName} portfolio.
            </div>
          )}
          <label className="portaddinputs-linkbroker">
            API Secret
            <input
              name="APIsecret"
              value={APIsecret}
              style={movecursor}
              className="text-input-boxes-linkbroker required"
              autoComplete="off"
              pattern="^(?!\(.*[^)]$|[^(].*\)$)\(?\$?(0|[1-9]\d{0,2}(,?\d{3})?)(\.\d\d?)?\)?$"
              required
              placeholder="Required"
              type="password"
              onChange={(e) => setAPIsecret(e.target.value)}
            />
          </label>
          <label className="portaddinputs-linkbroker2">
            API Key
            <input
              name="APIkey"
              value={APIkey}
              style={movecursor}
              className="text-input-boxes-linkbroker2 required"
              autoComplete="off"
              pattern="^(?!\(.*[^)]$|[^(].*\)$)\(?\$?(0|[1-9]\d{0,2}(,?\d{3})?)(\.\d\d?)?\)?$"
              required
              type="password"
              placeholder="Required"
              onChange={(e) => setAPIkey(e.target.value)}
            />
          </label>
          <div className="portaddinputs-linkbroker4">
            {`Need help? `}
            <a
              href="https://dydx-tv.gitbook.io/docs/setuup/how-to-get-dydx-credentials"
              target="_blank"
              rel="noopener noreferrer"
              id="howtogetdydxcredentialslink"
            >
              How To Get Kraken API Credentials
            </a>
          </div>
        </ol>
      </div>
    );
  } else if (broker && broker.value === "dYdX") {
    modalheader = "Link dYdX";
    modallogo = dydxlogo;
    modallogodiv = (
      <img className="linklogowrapperimgdydx" src={modallogo} alt="" />
    );
    childdiv = (
      <div style={{ marginTop: "-158px" }}>
        <ol type="1">
          <li
            style={{
              textAlign: "justify",
              textJustify: "inter-word",
              padding: "0px 38px 0px 0px",
              margin: "0px 0px 16px 0px",
              color: "#fff7cb",
            }}
          >
            {`Enter your dYdX API credentials (API Secret, API Key & API Passphrase). These will be encrypted and stored securely; they grant us access to ONLY the dYdX API's; we exclusively use the read-only API's.`}
            <span> This is a one-time action.</span>
          </li>
          {accountExistsError && (
            <div className="portaddinputs-accountexistserror">
              Account already connected to your {accountExistsName} portfolio.
            </div>
          )}
          <label className="portaddinputs-linkbroker">
            API Secret
            <input
              name="APIsecret"
              value={APIsecret}
              style={movecursor}
              className="text-input-boxes-linkbroker required"
              autoComplete="off"
              pattern="^(?!\(.*[^)]$|[^(].*\)$)\(?\$?(0|[1-9]\d{0,2}(,?\d{3})?)(\.\d\d?)?\)?$"
              required
              placeholder="Required"
              type="password"
              onChange={(e) => setAPIsecret(e.target.value)}
            />
          </label>
          <label className="portaddinputs-linkbroker2">
            API Key
            <input
              name="APIkey"
              value={APIkey}
              style={movecursor}
              className="text-input-boxes-linkbroker2 required"
              autoComplete="off"
              pattern="^(?!\(.*[^)]$|[^(].*\)$)\(?\$?(0|[1-9]\d{0,2}(,?\d{3})?)(\.\d\d?)?\)?$"
              required
              type="password"
              placeholder="Required"
              onChange={(e) => setAPIkey(e.target.value)}
            />
          </label>
          <label className="portaddinputs-linkbroker3">
            API Passphrase
            <input
              name="APIpassphrase"
              value={APIpassphrase}
              style={movecursor}
              className="text-input-boxes-linkbroker2 required"
              autoComplete="off"
              pattern="^(?!\(.*[^)]$|[^(].*\)$)\(?\$?(0|[1-9]\d{0,2}(,?\d{3})?)(\.\d\d?)?\)?$"
              required
              type="password"
              placeholder="Required"
              onChange={(e) => setAPIpassphrase(e.target.value)}
            />
          </label>
          <div className="portaddinputs-linkbroker4">
            {`Need help? `}
            <a
              href="https://dydx-tv.gitbook.io/docs/setuup/how-to-get-dydx-credentials"
              target="_blank"
              rel="noopener noreferrer"
              id="howtogetdydxcredentialslink"
            >
              How To Get dYdX API Credentials
            </a>
          </div>
        </ol>
      </div>
    );
  } else if (broker && broker.value === "metatrader4") {
    modalheader = "Link MetaTrader 4";
    modallogo = mt4logo;
    modallogodiv = (
      <img className="linklogowrapperimgmt4" src={modallogo} alt="" />
    );
    childdiv = (
      <div style={{ marginTop: "-156px" }}>
        <ol type="1">
          <li
            style={{
              textAlign: "justify",
              textJustify: "inter-word",
              padding: "0px 38px 0px 0px",
              margin: "0px 0px 16px 0px",
              color: "#fff7cb",
            }}
          >
            {`Enter your MetaTrader4 information. This will be encrypted and stored securely; we exclusively use the read-only API's.`}
            <span> This is a one-time action.</span>
          </li>
          <label className="portaddinputs-linkbroker">
            Broker&nbsp;&nbsp;
            <PopoverStickOnHover
              component={
                <span
                  style={{
                    color: "#fff7cb",
                    padding: "2px",
                    textAlign: "left",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  If you don't see your broker,{" "}
                  <a
                    href="https://tradefuse.app/support/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="contactuslink"
                  >
                    contact us
                  </a>
                  .
                </span>
              }
              placement="bottom"
              setClass="accountNumberSuperWrapper"
              delay={300}
              xAdjust={27}
              yAdjust={0}
              keepOpen={true}
            >
              <span className="tooptip-i-style6">&#x1D48A;</span>
            </PopoverStickOnHover>
            <Select
              isClearable
              styles={dropdownStyles}
              value={selectedBroker}
              onChange={(e) => {
                setselectedBroker(e);
                const selectedServers =
                  e &&
                  Object.keys(e)
                    .filter((key) => key !== "label" && key !== "value")
                    .map((key) => {
                      return { label: e[key], value: e[key] };
                    });
                setselectedServerOptions(selectedServers);
              }}
              theme={theme}
              options={allMT4Brokers}
              placeholder={
                <em
                  style={{
                    fontStyle: "italic",
                    color: "#fbd665",
                    opacity: 0.7,
                  }}
                >
                  {"Select..."}
                </em>
              }
            />
          </label>
          <label className="portaddinputs-linkbroker332">
            Server&nbsp;&nbsp;
            <PopoverStickOnHover
              component={
                <span
                  style={{
                    color: "#fff7cb",
                    padding: "2px",
                    textAlign: "left",
                    whiteSpace: "pre-wrap",
                    width: 300,
                  }}
                >
                  Find the Server by going to the login screen in MetaTrader 4:
                  <img
                    className="brokerserverimage"
                    src={mt4ServerImg}
                    alt="0"
                  />
                </span>
              }
              placement="bottom-start"
              setClass="serverimageTooltipSuperWrapper"
              delay={300}
              xAdjust={27}
              yAdjust={0}
              keepOpen={false}
            >
              <span className="tooptip-i-style6">&#x1D48A;</span>
            </PopoverStickOnHover>
            <CreatableSelect
              isClearable
              styles={dropdownStyles}
              value={selectedServer}
              onChange={(e) => {
                setselectedServer(e);
              }}
              theme={theme}
              options={selectedServerOptions}
              placeholder={
                <em
                  style={{
                    fontStyle: "italic",
                    color: "#fa798b",
                    opacity: 1,
                  }}
                >
                  {"Required"}
                </em>
              }
            />
          </label>
          <label className="portaddinputs-linkbroker332">
            Account Number&nbsp;&nbsp;
            <PopoverStickOnHover
              component={
                <span
                  style={{
                    color: "#fff7cb",
                    padding: "2px",
                    textAlign: "left",
                    whiteSpace: "nowrap",
                  }}
                >
                  Your MetaTrader 4 account number.
                </span>
              }
              placement="bottom"
              setClass="accountNumberSuperWrapper"
              delay={300}
              xAdjust={27}
              yAdjust={0}
              keepOpen={false}
            >
              <span className="tooptip-i-style6">&#x1D48A;</span>
            </PopoverStickOnHover>{" "}
            {accountExistsError && (
              <div className="portaddinputs-accountexistserror">
                Account already connected to your {accountExistsName} portfolio.
              </div>
            )}
            <input
              name="accountnumber"
              value={accountNumber}
              style={movecursor}
              className="text-input-boxes-linkbroker required"
              autoComplete="off"
              placeholder="Required"
              pattern="^(?!\(.*[^)]$|[^(].*\)$)\(?\$?(0|[1-9]\d{0,2}(,?\d{3})?)(\.\d\d?)?\)?$"
              required
              onChange={(e) => setaccountNumber(e.target.value)}
            />
          </label>
          <label className="portaddinputs-linkbroker2">
            Investor Password&nbsp;&nbsp;
            <PopoverStickOnHover
              component={
                <span
                  style={{
                    color: "#fff7cb",
                    padding: "2px",
                    textAlign: "left",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <div className="investorpasswordtextwrapper">
                    The investor password is a read-only access password to your
                    account.
                  </div>
                  <div className="investorpasswordtextwrapper">
                    {`If you're not sure what your investor password is, you can reset it in your MetaTrader platform by going to Tools > Options > Server tab > Change.`}
                  </div>
                  <div className="investorpasswordtextwrapperlast">
                    If an investor password is unavailable for your account, you
                    can import your trades using{" "}
                    <span
                      className="contactuslink"
                      onClick={() => {
                        props.setImportShow();
                      }}
                    >
                      CSV Import
                    </span>
                    .
                  </div>
                </span>
              }
              placement="bottom"
              setClass="accountNumberSuperWrapper"
              delay={300}
              xAdjust={27}
              yAdjust={0}
              keepOpen={true}
            >
              <span className="tooptip-i-style6">&#x1D48A;</span>
            </PopoverStickOnHover>
            <input
              name="invstorpassword"
              value={password}
              style={movecursor}
              className="text-input-boxes-linkbroker2 required"
              autoComplete="off"
              pattern="^(?!\(.*[^)]$|[^(].*\)$)\(?\$?(0|[1-9]\d{0,2}(,?\d{3})?)(\.\d\d?)?\)?$"
              required
              type="password"
              placeholder="Required"
              onChange={(e) => setpassword(e.target.value)}
            />
          </label>
        </ol>
      </div>
    );
  } else if (broker && broker.value === "metatrader5") {
    modalheader = "Link MetaTrader 5";
    modallogo = mt5logo;
    modallogodiv = (
      <img className="linklogowrapperimgmt4" src={modallogo} alt="" />
    );
    childdiv = (
      <div style={{ marginTop: "-156px" }}>
        <ol type="1">
          <li
            style={{
              textAlign: "justify",
              textJustify: "inter-word",
              padding: "0px 38px 0px 0px",
              margin: "0px 0px 16px 0px",
              color: "#fff7cb",
            }}
          >
            {`Enter your MetaTrader 5 information. This will be encrypted and stored securely; we exclusively use the read-only API's.`}
            <span> This is a one-time action.</span>
          </li>
          <label className="portaddinputs-linkbroker">
            Broker&nbsp;&nbsp;
            <PopoverStickOnHover
              component={
                <span
                  style={{
                    color: "#fff7cb",
                    padding: "2px",
                    textAlign: "left",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  If you don't see your broker,{" "}
                  <a
                    href="https://tradefuse.app/support/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="contactuslink"
                  >
                    contact us
                  </a>
                  .
                </span>
              }
              placement="bottom"
              setClass="accountNumberSuperWrapper"
              delay={300}
              xAdjust={27}
              yAdjust={0}
              keepOpen={true}
            >
              <span className="tooptip-i-style6">&#x1D48A;</span>
            </PopoverStickOnHover>
            <Select
              isClearable
              styles={dropdownStyles}
              value={selectedBroker}
              onChange={(e) => {
                setselectedBroker(e);
                const selectedServers =
                  e &&
                  Object.keys(e)
                    .filter((key) => key !== "label" && key !== "value")
                    .map((key) => {
                      return { label: e[key], value: e[key] };
                    });
                e && setselectedServerOptions(selectedServers);
              }}
              theme={theme}
              options={allMT5Brokers}
              placeholder={
                <em
                  style={{
                    fontStyle: "italic",
                    color: "#fbd665",
                    opacity: 0.7,
                  }}
                >
                  {"Select..."}
                </em>
              }
            />
          </label>
          <label className="portaddinputs-linkbroker332">
            Server{" "}
            <PopoverStickOnHover
              component={
                <span
                  style={{
                    color: "#fff7cb",
                    padding: "2px",
                    textAlign: "left",
                    whiteSpace: "pre-wrap",
                    width: 300,
                  }}
                >
                  Find the Server by going to the login screen in MetaTrader 5:
                  <img
                    className="brokerserverimage"
                    src={mt5ServerImg}
                    alt="0"
                  />
                </span>
              }
              placement="bottom-start"
              setClass="serverimageTooltipSuperWrapper"
              delay={300}
              xAdjust={27}
              yAdjust={0}
              keepOpen={false}
            >
              <span className="tooptip-i-style6">&#x1D48A;</span>
            </PopoverStickOnHover>
            <CreatableSelect
              isClearable
              styles={dropdownStyles}
              value={selectedServer}
              onChange={(e) => {
                setselectedServer(e);
              }}
              theme={theme}
              options={selectedServerOptions}
              placeholder={
                <em
                  style={{
                    fontStyle: "italic",
                    color: "#fa798b",
                    opacity: 1,
                  }}
                >
                  {"Required"}
                </em>
              }
            />
          </label>
          <label className="portaddinputs-linkbroker332">
            Account Number&nbsp;&nbsp;
            <PopoverStickOnHover
              component={
                <span
                  style={{
                    color: "#fff7cb",
                    padding: "2px",
                    textAlign: "left",
                    whiteSpace: "nowrap",
                  }}
                >
                  Your MetaTrader 5 account number.
                </span>
              }
              placement="bottom"
              setClass="accountNumberSuperWrapper"
              delay={300}
              xAdjust={27}
              yAdjust={0}
              keepOpen={false}
            >
              <span className="tooptip-i-style6">&#x1D48A;</span>
            </PopoverStickOnHover>{" "}
            {accountExistsError && (
              <div className="portaddinputs-accountexistserror">
                Account already connected to your {accountExistsName} portfolio.
              </div>
            )}
            <input
              name="APIsecret"
              value={accountNumber}
              style={movecursor}
              className="text-input-boxes-linkbroker required"
              autoComplete="off"
              pattern="^(?!\(.*[^)]$|[^(].*\)$)\(?\$?(0|[1-9]\d{0,2}(,?\d{3})?)(\.\d\d?)?\)?$"
              required
              type="password"
              placeholder="Required"
              onChange={(e) => setaccountNumber(e.target.value)}
            />
          </label>
          <label className="portaddinputs-linkbroker2">
            Investor Password&nbsp;&nbsp;
            <PopoverStickOnHover
              component={
                <span
                  style={{
                    color: "#fff7cb",
                    padding: "2px",
                    textAlign: "left",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <div className="investorpasswordtextwrapper">
                    The investor password is a read-only access password to your
                    account.
                  </div>
                  <div className="investorpasswordtextwrapper">
                    {`If you're not sure what your investor password is, you can reset it in your MetaTrader platform by going to Tools > Options > Server tab > Change.`}
                  </div>
                  <div className="investorpasswordtextwrapperlast">
                    If an investor password is unavailable for your account, you
                    can import your trades using{" "}
                    <span
                      className="contactuslink"
                      onClick={() => {
                        props.setImportShow();
                      }}
                    >
                      CSV Import
                    </span>
                    .
                  </div>
                </span>
              }
              placement="bottom"
              setClass="accountNumberSuperWrapper"
              delay={300}
              xAdjust={27}
              yAdjust={0}
              keepOpen={true}
            >
              <span className="tooptip-i-style6">&#x1D48A;</span>
            </PopoverStickOnHover>
            <input
              name="APIkey"
              value={password}
              style={movecursor}
              className="text-input-boxes-linkbroker2 required"
              autoComplete="off"
              pattern="^(?!\(.*[^)]$|[^(].*\)$)\(?\$?(0|[1-9]\d{0,2}(,?\d{3})?)(\.\d\d?)?\)?$"
              required
              type="password"
              placeholder="Required"
              onChange={(e) => setpassword(e.target.value)}
            />
          </label>
        </ol>
      </div>
    );
  } else if (broker && broker.value === "apollox") {
    modalheader = "Link ApolloX";
    modallogo = apolloxlogo;
    modallogodiv = (
      <img className="linklogowrapperimgapollox" src={modallogo} alt="" />
    );
    childdiv = (
      <div style={{ marginTop: "-252px" }}>
        <ol type="1">
          <li
            style={{
              textAlign: "justify",
              textJustify: "inter-word",
              padding: "0px 38px 0px 0px",
              margin: "0px 0px 16px 0px",
            }}
          >
            {`Enter your ApolloX API credentials (API Secret & API Key). These will be encrypted and stored in your Skynet data. These grant us access to ONLY the ApplloX API's; we use the only the read-only API's.`}
            <span> This is a one-time action.</span>
          </li>
          <label className="portaddinputs-linkbroker">
            API Secret
            <input
              name="APIsecret"
              value={APIsecret}
              style={movecursor}
              className="text-input-boxes-linkbroker required"
              autoComplete="off"
              pattern="^(?!\(.*[^)]$|[^(].*\)$)\(?\$?(0|[1-9]\d{0,2}(,?\d{3})?)(\.\d\d?)?\)?$"
              required
              type="password"
              onChange={(e) => setAPIsecret(e.target.value)}
            />
          </label>
          <label className="portaddinputs-linkbroker2">
            API Key
            <input
              name="APIkey"
              value={APIkey}
              style={movecursor}
              className="text-input-boxes-linkbroker2 required"
              autoComplete="off"
              pattern="^(?!\(.*[^)]$|[^(].*\)$)\(?\$?(0|[1-9]\d{0,2}(,?\d{3})?)(\.\d\d?)?\)?$"
              required
              type="password"
              onChange={(e) => setAPIkey(e.target.value)}
            />
          </label>
        </ol>
      </div>
    );
  } else {
  }
  return (
    <div id="autoimportmodalwrapper">
      <span className="autoimportmodalheading">{modalheader}</span>
      {!isweb3selected && (
        <div className="linklogowrapper">
          <div className="linklogowrappertext">Powered By</div>
          {modallogodiv}
        </div>
      )}
      {/*       <button
        className="deletetradesclosebutton"
        onClick={handleCloseLinkBroker}
      >
        <DeleteButton />
      </button> */}
      {childdiv}
      <span className="linkbuttonwrapper">
        <button
          name="cancelBrokerActualButton"
          className="cancelBrokerActualButton"
          onClick={handleCloseLinkBroker}
        >
          Cancel
        </button>
        {!isweb3selected && (
          <button
            name="linkBrokerActualButton"
            className="linkBrokerActualButton"
            onClick={async () => await requestBroker(broker.value)}
            disabled={disabled}
          >
            Link
          </button>
        )}
      </span>
    </div>
  );
};
export default AutoImportModal;
