import { montharr, weekday } from "./../../../arrays/weekandmonth";

import { v4 as uuidv4 } from "uuid";
const crypto = require("crypto");

function generateHash(inputString) {
  return crypto.createHash("sha256").update(inputString).digest("hex");
}

const createImportData = (start, number, tbody) => {
  let data = [];
  for (var iii = start; iii < start + number; iii++) {
    var tableRow2 = tbody.rows[iii];
    var rowData = [];
    for (var jj = 0; jj < tableRow2.cells.length; jj++) {
      const value =
        tableRow2.cells[jj].className === "hidden"
          ? null
          : tableRow2.cells[jj].innerHTML;
      if (value === null) {
      } else {
        rowData.push(value);
      }
    }
    data = data.concat([rowData]);
  }
  return data;
};

function countLinesInHTMLFile(htmlContent) {
  const lines = htmlContent.split("\n");
  // Remove empty lines and lines that only contain whitespace
  const nonEmptyLines = lines.filter((line) => line.trim() !== "");
  // Return the number of non-empty lines
  return nonEmptyLines.length;
}

export const mt5 = (
  readerEvent,
  userId,
  portfolio,
  settrades,
  el,
  setinvalidFile,
  pnlmethod,
  entries // currently logged entries
) => {
  var content = readerEvent.target.result; // =====> this is the content!
  el.innerHTML = content;
  let tbody = "";
  try {
    tbody = el.children[1].children[0].children[0].childNodes[1]; //found tbody in nested children
  } catch {
    return false;
  }
  const verified = 0; // could be 1 in the future

  // Check if the file is from the correct broker
  // If it is then continue

  const numLines = countLinesInHTMLFile(content);
  let foundDeals = false;
  if (tbody === "" || !tbody) {
    setinvalidFile(true);
  } else {
    setinvalidFile(false);
    let startiteration = 0;
    // Extract balance transactions from the "Deals" and create trades from the "Positions"

    // Get iteration number of "Deals" (row number essentially)
    for (var iz = 0; iz < numLines; iz++) {
      // Find the "Deals" table then stop on that iteration
      let firstchild = tbody.children[iz] && tbody.children[iz].children[0];
      let firstchildoffirstchild =
        firstchild && tbody.children[iz].children[0].children[0];
      let childoffirstchildoffirstchild =
        firstchildoffirstchild &&
        tbody.children[iz].children[0].children[0].children[0];
      let innerText =
        childoffirstchildoffirstchild &&
        tbody.children[iz].children[0].children[0].children[0].innerHTML;
      if (innerText === "Deals") {
        // set startiteration variable to the current iteration
        // Used in the below functions
        foundDeals = true;
        startiteration = iz;
        break;
      }
    }

    if (!foundDeals) {
      setinvalidFile(true);
    }

    var positions = [];
    var deals = [];

    let numberdeals = 0;
    for (var ii = startiteration + 2; ii < Number.MAX_VALUE; ii++) {
      var tableRow3 = tbody.rows[ii];
      if (tableRow3.children[0].innerHTML === "") {
        numberdeals = ii - (startiteration + 2);
        break;
      }
    }
    let numberpositions = 0;
    for (var iiz = 8; iiz < Number.MAX_VALUE; iiz++) {
      var tableRow4 = tbody.rows[iiz];
      if (tableRow4.children[0].innerHTML === "") {
        numberpositions = iiz - 8;
        break;
      }
    }
    // ------ create arrays of deals data ------
    deals = createImportData(startiteration + 2, numberdeals, tbody);
    // ------ create arrays of position data ------
    positions = createImportData(8, numberpositions, tbody);

    let tradesInner = [...entries];
    // ADD DEPOSITS/WITHDRAWALS FROM DEALS
    for (var ik = 0; ik < deals.length; ik++) {
      const myString = String(deals[ik][1]);

      const hashValue = generateHash(myString);
      let mutableTrades = entries.slice(); // array of user trades that can be edited

      const foundTrade = mutableTrades.find((trade) =>
        trade.entry.hashes?.includes(hashValue)
      );
      let trade = "";
      if (deals[ik][3] === "balance") {
        var startDateTime = new Date(deals[ik][0]);
        trade = {
          entryId: uuidv4(),
          entry: {
            pictures: ["N/A"],
            symbol: {
              symbols: [],
              pointValue: 0,
            },
            strategy: "",
            selectedConfidence: "",
            selectedEmotion: "",
            selectedPhysical: "",
            selectedMarket: "",
            selectedTimeframe: "",
            selectedMistake: "",
            selectedPortfolio: portfolio.name,
            selectedPortfolioType: "",
            orderType:
              Number(deals[ik][12].replace(/\s/g, "")) > 0
                ? "Deposit"
                : "Withdrawal",
            orderNumber: deals[ik][1],
            dayOfWeek: weekday[startDateTime.getDay()],
            monthOfYear: montharr[startDateTime.getMonth()],
            stopLoss: "",
            takeProfit: "",
            profitLoss: Number(deals[ik][12].replace(/\s/g, "")),
            commissions: Number(deals[ik][8].replace(/\s/g, "")),
            fees: Number(deals[ik][9].replace(/\s/g, "")),
            maxAdEx: "",
            maxFavEx: "",
            comments: "",
            multiExecution: [],
            exitExecution: [],
            startDateTime: startDateTime,
            endDateTime: startDateTime,
            tags: "",
            hashes: [hashValue],
            verifiedLevel: verified, // for now,
            idLinks: [],
          },
        };

        if (foundTrade) {
          // do nothing
        } else {
          tradesInner.push(trade);
        }
      }
    }
    // -------- CREATE TRADES FROM POSITIONS --------
    for (var ip = 0, jp = positions.length; ip < jp; ip++) {
      const myString = String(positions[ip][1]);

      const hashValue = generateHash(myString);
      let mutableTrades = entries.slice(); // array of user trades that can be edited

      const foundTrade = mutableTrades.find((trade) =>
        trade.entry.hashes?.includes(hashValue)
      );
      let trade = "";
      var startDateTime2 = new Date(positions[ip][0]);
      let entryexecution = {
        id: uuidv4(),
        lotSize: Number(positions[ip][4]),
        entryPrice: Number(positions[ip][5]),
        startDateTime: startDateTime2,
        expectedEntry: "",
        strikePrice: "",
        expirationDate: "",
        legType: "",
      };
      var endDateTime = new Date(positions[ip][8]);
      let exitexecution = {
        id: uuidv4(),
        exitLotSize: Number(positions[ip][4]),
        exitPrice: Number(positions[ip][9]),
        endDateTime: endDateTime,
        expectedExit: "",
        exitstrikePrice: "",
        exitexpirationDate: "",
        exitlegType: "",
        equityComponents: [],
        exercised: "",
      };
      trade = {
        entryId: uuidv4(),
        entry: {
          pictures: ["N/A"],
          symbol: {
            symbols: [positions[ip][2].toUpperCase()],
            pointValue: 0,
          },
          strategy: "",
          selectedConfidence: "",
          selectedEmotion: "",
          selectedPhysical: "",
          selectedMarket: "",
          selectedTimeframe: "",
          selectedMistake: "",
          selectedPortfolio: portfolio.name,
          selectedPortfolioType: "Forex",
          orderType: positions[ip][3] === "buy" ? "Long" : "Short",
          orderNumber: positions[ip][1],
          dayOfWeek: weekday[startDateTime2.getDay()],
          monthOfYear: montharr[startDateTime2.getMonth()],
          stopLoss: positions[ip][6],
          takeProfit: positions[ip][7],
          profitLoss: Number(positions[ip][12].replace(/\s/g, "")),
          commissions: Number(positions[ip][10].replace(/\s/g, "")),
          fees: Number(positions[ip][11].replace(/\s/g, "")),
          maxAdEx: "",
          maxFavEx: "",
          comments: "",
          multiExecution: [entryexecution],
          exitExecution: [exitexecution],
          startDateTime: startDateTime2,
          endDateTime: endDateTime,
          tags: "",
          hashes: [hashValue],
          verifiedLevel: verified, // for now,
          idLinks: [],
        },
      };

      if (foundTrade) {
        // do nothing
      } else {
        tradesInner.push(trade);
      }
    }
    settrades(tradesInner);
  }
};
export default mt5;
