import dollarCostAverage from "./../functions/dollarCostAvg";
import { profitLossCalculation } from "./../functions/profitLossCalculation";
import { returnNetPLDollar } from "./../functions/profitLossCalculation";
import { handleNANGOOD } from "./../functions/handleUndefined";
import getEntryExitLots from "./../functions/getEntryExitLots";

function getStandardDeviation(array) {
  const n = array.length;
  const mean = array.reduce((a, b) => a + b, 0) / n;
  return Math.sqrt(
    array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b, 0) / n
  );
}

const arrAvg = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;
//const arrSum = (arr) => arr.reduce((a, b) => a + b, 0);
export const simulatorCalculations = (
  data,
  calctype,
  start_Balance,
  start_DW,
  pnlmethod
) => {
  let result = {};
  let pLArr = [];
  let stdDevArr = [];
  let percentgainArray = [];

  //let totalLossTrades = 0;
  let totalPL_dollar = 0;
  let totalProfits = 0;
  let totalLosses = 0;
  let totalTrades = 0;
  let totalWinningTrades = 0;
  let currentBalance = 0;
  let totalLosingTrades = 0;
  //let miscellaneousStdDevPerTradePercenttemparray = [];
  //let adjfullaccountbalancearr = [];

  // Variables to init before the loop
  let riskedPercentArr = [];

  // Main For Loop
  for (let i = 0, j = data.length; i < j; i++) {
    const thisAccountBalance = Number(data[i].balance);
    const entryId = data[i].entryId;
    let orderType = data[i].entry.orderType;
    let multiExecution = data[i].entry.multiExecution;
    let exitExecution = data[i].entry.exitExecution;
    const entryExitValues = getEntryExitLots(multiExecution, exitExecution);
    const entryLots = entryExitValues.entryLots;
    const exitLots = entryExitValues.exitLots;
    const correctedSUM = returnNetPLDollar(
      data[i].entry.profitLoss,
      data[i].entry.commissions,
      data[i].entry.fees,
      calctype
    );
    currentBalance = thisAccountBalance;

    if (
      data[i].entry.orderType === "Deposit" ||
      data[i].entry.orderType === "Withdrawal" ||
      data[i].entry.orderType === "Funding Payment" ||
      data[i].entry.orderType === "Commit" ||
      data[i].entry.orderType === "Approval" ||
      data[i].entry.orderType === "Wrap" ||
      data[i].entry.orderType === "Self"
    ) {
      continue;
    } else if (exitLots === 0) {
      continue; // exclude trades with no exit executions
    } else {
      let percentgain =
        (correctedSUM / (thisAccountBalance - correctedSUM)) * 100;
      totalPL_dollar = totalPL_dollar + correctedSUM;
      //adjfullaccountbalancearr.push(thisAccountBalance);
      pLArr.push(correctedSUM);
      percentgainArray.push(percentgain);

      if (correctedSUM > 0) {
        totalWinningTrades += 1;
        totalProfits += correctedSUM;
      } else if (correctedSUM < 0) {
        totalLosingTrades += 1;
        totalLosses += correctedSUM;
      }
      totalTrades += 1;
      stdDevArr.push(correctedSUM);

      // COMPUTE RISK %
      let avgEntry = dollarCostAverage(multiExecution, "entry", orderType);
      let avgExit = dollarCostAverage(exitExecution, "exit", orderType);
      let rmultiple =
        (avgExit - avgEntry) / (avgEntry - Number(data[i].entry.stopLoss));

      let adjrrr = -avgEntry / (avgEntry - Number(data[i].entry.stopLoss));

      let divisor =
        data[i].entry.selectedPortfolioType === "Forex" ? rmultiple : adjrrr;

      let initPl = profitLossCalculation(
        multiExecution,
        [],
        orderType,
        data[i].entry.selectedPortfolioType,
        data[i].entry.symbol.pointValue,
        true,
        pnlmethod
      );
      // If the asset class if forex we have to calculate risk a slightly different way
      let dividend =
        data[i].entry.selectedPortfolioType === "Forex"
          ? returnNetPLDollar(
              data[i].entry.profitLoss,
              data[i].entry.commissions,
              data[i].entry.fees,
              calctype
            )
          : returnNetPLDollar(
              initPl,
              data[i].entry.commissions,
              data[i].entry.fees,
              calctype
            );
      let percent =
        Number(dividend / (thisAccountBalance - Number(correctedSUM))) * 100;
      let riskedPercent = percent / divisor;
      riskedPercentArr.push(riskedPercent);
    }
  }
  let averagePLPerWindollar = totalProfits / totalWinningTrades;
  let averagePLPerLossdollar = totalLosses / totalLosingTrades;
  let winRate = handleNANGOOD(
    (totalTrades === 0 ? 0 : (totalWinningTrades / totalTrades) * 100).toFixed(
      3
    ),
    "-"
  );
  let avgRisk = handleNANGOOD(arrAvg(riskedPercentArr).toFixed(3), "-");
  let profitLossRatio = handleNANGOOD(
    (averagePLPerWindollar / Math.abs(averagePLPerLossdollar)).toFixed(3),
    "-"
  );
  let stdDev = handleNANGOOD(
    getStandardDeviation(percentgainArray).toFixed(3),
    "-"
  );

  // Result
  result = {
    winRate,
    avgRisk,
    profitLossRatio,
    stdDev,
    currentBalance,
  };
  return result;
};

export default simulatorCalculations;
