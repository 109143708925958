import React from "react";
import { Radar } from "react-chartjs-2";
import supportedCryptos from "../../arrays/isCurrencyCrypto";
import generateRandomColors from "../../functions/generateRandomColors";
import { profitLossCalculation } from "../../functions/profitLossCalculation";
import { returnNetPLDollar } from "../../functions/profitLossCalculation";
import dollarCostAverage from "../../functions/dollarCostAvg";
import { useHistory } from "react-router-dom";
import getStandardDeviation from "../../functions/calculations_for_metrics/standardDeviation";
import filterTheTrades from "./filterTheTrades";
import { getDateDifferencewSeconds } from "../../functions/getDateDifference";
import {
  getMaxDD,
  getMaxDDPercent,
} from "../../functions/calculations_for_metrics/getmaxDD";
import longShortDelineation from "../../functions/longShortDelineation";
import PopoverStickOnHover from "../../components/PopoverStickOnHover";
import {
  calculateSharpeRatios,
  calculateSortinoRatios,
} from "../../functions/sharpeRatio";
import getSession from "../../functions/getSession";
import ulcerIndex from "../../functions/calculations_for_metrics/ulcerIndex";
import calculateZScore from "../../functions/zScoreCalculation";
import linearRegression from "../../functions/calculations_for_metrics/linearRegression";
import pcorr from "../../functions/calculations_for_metrics/pearsonCorrelation";
import STEYX from "../../functions/calculations_for_metrics/steyx";
import DEVSQ from "../../functions/calculations_for_metrics/DEVSQ";
import getEntryExitLots from "../../functions/getEntryExitLots";

var { jStat } = require("jstat");

function studentsTCumulativeValue(tValue, degreesOfFreedom) {
  // Calculate the cumulative t-distribution
  let cumulativeValue = jStat.studentt.cdf(tValue, degreesOfFreedom);

  // Return the result
  return cumulativeValue;
}
let he = require("he");

const arrAvg = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;
const arrSum = (arr) => arr.reduce((a, b) => a + b, 0);
function findLargestArray(arrays) {
  let largestArray = [];

  for (let i = 0; i < arrays.length; i++) {
    if (arrays[i].length > largestArray.length) {
      largestArray = arrays[i];
    }
  }

  return largestArray;
}
function scaleMetrics(arr) {
  // Get the maximum and minimum value of each metric across all objects
  const metricMaxValues = {};
  const metricMinValues = {};
  arr.forEach((obj) => {
    obj.data.forEach((metric, i) => {
      if (!metricMaxValues[i] || metric > metricMaxValues[i]) {
        metricMaxValues[i] = metric;
      }
      if (!metricMinValues[i] || metric < metricMinValues[i]) {
        metricMinValues[i] = metric;
      }
    });
  });

  // Scale each metric down to a range of 0-10
  arr.forEach((obj) => {
    obj.data.forEach((metric, i) => {
      const metricRange = metricMaxValues[i] - metricMinValues[i];
      const scaledMetric =
        metricRange === 0
          ? 0
          : ((metric - metricMinValues[i]) / metricRange) * 10;
      obj.data[i] = scaledMetric;
    });
  });

  return arr;
}

function setOpacity(hexColor, opacity) {
  // Convert the hex code color to an RGB value
  const red = parseInt(hexColor?.substring(1, 3), 16);
  const green = parseInt(hexColor?.substring(3, 5), 16);
  const blue = parseInt(hexColor?.substring(5, 7), 16);

  // Set the opacity to 0.2 and return the RGBA value as a string
  return `rgba(${red}, ${green}, ${blue}, ${Number(opacity)})`;
}

function addValueAtIndex(arr1, arr2, value, searchValue) {
  // Find the index of the search value in arr1
  const index = arr1.indexOf(searchValue);

  // If the search value is not found in arr1, return -1
  if (index === -1) {
    return -1;
  }

  arr2[index] = value;

  // Return the index
  return index;
}
// Helper function to calculate week of the year
function getWeek(date) {
  let firstDayOfYear = new Date(date.getFullYear(), 0, 1);
  let pastDaysOfYear = (date - firstDayOfYear) / 86400000;
  return (
    date.getFullYear() +
    "-" +
    Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7)
  );
}

// Helper function to calculate average
function calculateAverage(total, count) {
  let perAvg = 0;
  let totalCount = 0;
  for (let key in total) {
    perAvg += total[key];
    totalCount += 1;
  }
  return perAvg / totalCount;
}
const splitString = (text, chunkSize) => {
  const arr = text.split(" ");
  const output = [];

  for (let i = 0, length = arr.length; i < length; i += chunkSize) {
    output.push(arr.slice(i, i + chunkSize));
  }

  return output;
};
const RadarGraph = (props) => {
  const lineColorMain = props.lineColors;
  const whatToCompare = props.selectComparisons.label;
  const whatToCompareSingle = props.selectComparisons.single;

  const whatToCompareProp = props.selectComparisons.prop;
  const metricsToCompare = props.metricsToCompare;
  const metricArr = metricsToCompare.map((metric) => {
    return metric.label;
  });
  const riskFreeRate = props.riskFreeRate;

  const graphType = props.type;
  const selectedItems = props.selectedItems;
  const allEntries = props.allEntries; // used in portfolio comparison
  const allEntriesButFiltered = props.userData; // used in every other comparison
  const graphTitle = (
    <span>
      <span className="metricTocomparegraphheader">{metricsToCompare}</span>
      {` of `}
      <span className="whatTocomparegraphheader">{whatToCompare}</span>
    </span>
  );
  const entriespre = props.userData;
  const entries = entriespre.slice();
  const symbol = props.defaultSymbol;
  const settings = props.userSettings;
  const pnlmethod = settings.pnlcalculationmethod;

  const calType = settings.calculationType;
  const supportedCryptosCheck = supportedCryptos.includes(symbol);
  const labelsonly = selectedItems.map((obj) => obj.label); // for only bar
  const dateToggle = settings?.dateFilterBy;

  const history = useHistory();
  const metricValues = (data, selectedItems, metrics) => {
    let metricValues = [];
    for (let ix = 0, jx = selectedItems.length; ix < jx; ix++) {
      const lineColor = lineColorMain[ix];
      let dataValues = [];
      const selectedItem = selectedItems[ix].label;
      let accountbalancearr = [];
      let plArr = [];
      let percentGainArr = [];
      let percentgainLOSSArray = [];
      let percentgainWINArray = [];
      let totalPL_dollar = 0;
      let totalLosses = 0;
      let totalTrades = 0;
      let totalProfits = 0;
      let riskedPercentArr = [];
      let efficiencesArr = [];
      let timeInMarketArr = [];
      let timeInMarket = 0;
      let numWins = 0;
      let numLosses = 0;
      let numTrades = 0;
      let profitFactor = 0;
      let profitLossRatio = 0;
      let volatility = 0;
      let volatilityPercent = 0;
      let avgHoldingTime = 0;
      let tfIndex = 0;
      let xArray = [];
      let yArray = [];
      let iterationarray = [];
      let dailyPL = {},
        weeklyPL = {},
        monthlyPL = {};
      let dailyCount = {},
        weeklyCount = {},
        monthlyCount = {};
      //First filter by the selected item
      let filteredTrades = "";
      let filteredTradesPre = data.slice();
      filteredTrades = filterTheTrades(
        whatToCompare,
        whatToCompareProp,
        selectedItem,
        filteredTradesPre,
        pnlmethod
      );

      for (let i = 0, j = filteredTrades.length; i < j; i++) {
        const thisAccountBalance = Number(filteredTrades[i].balance);
        const entryId = filteredTrades[i].entryId;
        let orderType = filteredTrades[i].entry.orderType;
        let multiExecution = filteredTrades[i].entry.multiExecution;
        let exitExecution = filteredTrades[i].entry.exitExecution;
        const entryExitValues = getEntryExitLots(multiExecution, exitExecution);
        const entryLots = entryExitValues.entryLots;
        const exitLots = entryExitValues.exitLots;
        let x100profitLoss = Number(filteredTrades[i].entry.profitLoss * 100);
        let x100commissions = Number(filteredTrades[i].entry.commissions * 100);
        let x100fees = Number(filteredTrades[i].entry.fees * 100);

        const correctedSUM = returnNetPLDollar(
          filteredTrades[i].entry.profitLoss,
          filteredTrades[i].entry.commissions,
          filteredTrades[i].entry.fees,
          calType
        );
        const thisTotalDws = Number(filteredTrades[i].totaldw);
        if (
          filteredTrades[i].entry.orderType === "Deposit" ||
          filteredTrades[i].entry.orderType === "Withdrawal" ||
          filteredTrades[i].entry.orderType === "Funding Payment" ||
          filteredTrades[i].entry.orderType === "Commit" ||
          filteredTrades[i].entry.orderType === "Approval" ||
          filteredTrades[i].entry.orderType === "Wrap" ||
          filteredTrades[i].entry.orderType === "Self"
        ) {
          continue;
        } else if (exitLots === 0) {
          continue; // exclude trades with no exit executions
        } else {
          iterationarray.push(i);

          accountbalancearr.push(thisAccountBalance);
          let percentgain =
            (correctedSUM / (thisAccountBalance - correctedSUM)) * 100;
          totalPL_dollar += correctedSUM;
          totalTrades += 1;
          plArr.push(correctedSUM);
          percentGainArr.push(percentgain);
          if (correctedSUM < 0) {
            totalLosses += correctedSUM;
            numLosses += 1;
          } else if (correctedSUM > 0) {
            totalProfits += correctedSUM;
            numWins += 1;
          }
        }

        let eachStartDateTime = new Date(filteredTrades[i].entry.startDateTime); // or startDateTimez
        let eachEndDateTime = new Date(filteredTrades[i].entry.endDateTime);
        let day = eachEndDateTime.toISOString().slice(0, 10); // Format as 'YYYY-MM-DD'
        let week = getWeek(eachEndDateTime); // Format as 'YYYY-WW'
        let month = eachEndDateTime.toISOString().slice(0, 7); // Format as 'YYYY-MM'
        // Calculate daily P/L
        dailyPL[day] = (dailyPL[day] || 0) + correctedSUM;
        dailyCount[day] = (dailyCount[day] || 0) + 1;

        // Calculate weekly P/L
        weeklyPL[week] = (weeklyPL[week] || 0) + correctedSUM;
        weeklyCount[week] = (weeklyCount[week] || 0) + 1;

        // Calculate monthly P/L
        monthlyPL[month] = (monthlyPL[month] || 0) + correctedSUM;
        monthlyCount[month] = (monthlyCount[month] || 0) + 1;
        for (let i = 0, j = percentGainArr.length; i < j; i++) {
          if (percentGainArr[i] < 0) {
            percentgainLOSSArray.push(percentGainArr[i]);
          } else if (percentGainArr[i] > 0) {
            percentgainWINArray.push(percentGainArr[i]);
          }
        }
        // Calculations
        let winRate = numWins / totalTrades;
        let averagePLPerTradedollar = totalPL_dollar / totalTrades;
        let averagePLPerWindollar = totalProfits / numWins;
        let averagePLPerLossdollar = totalLosses / numLosses;
        let avgPL = arrAvg(plArr);
        let stdDev = getStandardDeviation(plArr);
        let averagePLPerTradepercent = arrAvg(percentGainArr);
        let averagePLPerWinpercent = arrAvg(percentgainWINArray);
        let averagePLPerLosspercent = arrAvg(percentgainLOSSArray);
        let maxDD = getMaxDD(plArr);
        volatility = stdDev / totalTrades;
        let stdDevPercent = getStandardDeviation(percentGainArr);
        volatilityPercent = stdDevPercent / totalTrades;
        profitFactor = totalProfits / Math.abs(totalLosses);
        profitLossRatio =
          averagePLPerWindollar / Math.abs(averagePLPerLossdollar);
        tfIndex = winRate * (1 + profitLossRatio);
        let winRateasNumber = winRate * 100;
        let cummulativeGain = (totalPL_dollar / thisTotalDws) * 100;
        let dailyAvgPL = calculateAverage(dailyPL, dailyCount);
        let weeklyAvgPL = calculateAverage(weeklyPL, weeklyCount);
        let monthlyAvgPL = calculateAverage(monthlyPL, monthlyCount);

        // linear array
        let lineararr = [];
        const linearRegressionReturn = linearRegression(
          accountbalancearr,
          iterationarray
        );
        const slope = linearRegressionReturn.slope;
        const intercept = linearRegressionReturn.intercept;
        for (let i = 0, j = accountbalancearr.length; i < j; i++) {
          let lineararrentry = slope * i + intercept;
          lineararr.push(lineararrentry);
        }
        // --------

        let recoveryFactor = totalPL_dollar / Math.abs(getMaxDD(plArr));
        let expectation =
          averagePLPerTradepercent / Math.abs(averagePLPerLosspercent);

        // COMPUTE TIME IN MARKET
        let eachTime = (eachEndDateTime - eachStartDateTime) / 1000;
        timeInMarketArr.push(eachTime);

        avgHoldingTime = arrAvg(timeInMarketArr);
        timeInMarket += eachTime;

        let entryAvg = dollarCostAverage(
          filteredTrades[i].entry.multiExecution,
          "entry",
          filteredTrades[i].entry.orderType
        );
        let exitAvg = dollarCostAverage(
          filteredTrades[i].entry.exitExecution,
          "exit",
          filteredTrades[i].entry.orderType
        );
        let RRR =
          (Number(filteredTrades[i].entry.takeProfit) - entryAvg) /
          (entryAvg - Number(filteredTrades[i].entry.stopLoss));

        let RMultiple =
          (exitAvg - entryAvg) /
          (entryAvg - Number(filteredTrades[i].entry.stopLoss));

        xArray.push(RRR);
        yArray.push(RMultiple);

        // COMPUTE AVG EFFICIENCIES
        let efficiency = 0;
        if (
          longShortDelineation(
            filteredTrades[i].entry.orderType,
            multiExecution
          ) === "Long"
        ) {
          efficiency =
            100 *
            ((exitAvg - entryAvg) /
              (filteredTrades[i].entry.maxFavEx -
                filteredTrades[i].entry.maxAdEx));
        } else if (
          longShortDelineation(
            filteredTrades[i].entry.orderType,
            multiExecution
          ) === "Short"
        ) {
          efficiency =
            100 *
            ((entryAvg - exitAvg) /
              (filteredTrades[i].entry.maxAdEx -
                filteredTrades[i].entry.maxFavEx));
        }
        efficiencesArr.push(efficiency);
        const avgEff = arrAvg(efficiencesArr);
        // COMPUTE RISK %
        let adjrrr =
          -entryAvg / (entryAvg - Number(filteredTrades[i].entry.stopLoss));

        let divisor =
          filteredTrades[i].entry.selectedPortfolioType === "Forex"
            ? RMultiple
            : adjrrr;

        let initPl = profitLossCalculation(
          multiExecution,
          [],
          orderType,
          filteredTrades[i].entry.selectedPortfolioType,
          filteredTrades[i].entry.symbol.pointValue,
          true,
          pnlmethod
        );
        // If the asset class if forex we have to calculate risk a slightly different way
        let dividend =
          filteredTrades[i].entry.selectedPortfolioType === "Forex"
            ? correctedSUM
            : returnNetPLDollar(
                initPl,
                filteredTrades[i].entry.commissions,
                filteredTrades[i].entry.fees,
                calType
              );
        let percent =
          Number(dividend / (thisAccountBalance - Number(correctedSUM))) * 100;
        let riskedPercent = percent / divisor;
        riskedPercentArr.push(riskedPercent);
        let goldenRatio =
          averagePLPerWindollar /
          Math.abs(averagePLPerLossdollar) /
          arrAvg(xArray);
        let sharpeRatioArith = calculateSharpeRatios(
          accountbalancearr,
          Number(riskFreeRate)
        ).arithmeticSharpeRatio;
        let returnOverMaxDrawdown =
          cummulativeGain / Math.abs(getMaxDDPercent(accountbalancearr)); //maxxdd percent needs a special array
        let CPCIndex = profitFactor * profitLossRatio * winRate;
        let kellypercent =
          (averagePLPerTradedollar / averagePLPerWindollar) * 100;
        let normalizedExpectancypercent =
          (averagePLPerTradedollar / Math.abs(averagePLPerLossdollar)) * 100;
        let ulcerIndexVal = ulcerIndex(accountbalancearr);
        let sortinoRatio = calculateSortinoRatios(
          accountbalancearr,
          Number(riskFreeRate)
        ).arithmeticSortinoRatio;
        let probabilityOfRandomChance =
          totalTrades <= 0
            ? 0
            : 1 -
              studentsTCumulativeValue(
                averagePLPerTradepercent /
                  (stdDevPercent / Math.sqrt(totalTrades - 1)),
                totalTrades - 1
              );
        let zScore = calculateZScore(accountbalancearr).zScore;
        let LRCorrelation = pcorr(accountbalancearr, lineararr);
        let LRStandardError = STEYX(accountbalancearr, lineararr);
        let KRatio =
          slope /
          ((STEYX(accountbalancearr, lineararr) / Math.sqrt(DEVSQ(lineararr))) *
            totalTrades);
        const mapDataValues = (dataValuesArr, metricsToPush) => {
          for (let k in metricsToPush) {
            const metric = metricsToPush[k];
            if (metric === "Profit Factor") {
              addValueAtIndex(
                metricsToPush,
                dataValuesArr,
                profitFactor,
                metric
              );
            } else if (metric === "Profit/Loss Ratio") {
              addValueAtIndex(
                metricsToPush,
                dataValuesArr,
                profitLossRatio,
                metric
              );
            } else if (metric === "Total P/L") {
              addValueAtIndex(
                metricsToPush,
                dataValuesArr,
                totalPL_dollar,
                metric
              );
            } else if (metric === "Avg. P/L") {
              addValueAtIndex(metricsToPush, dataValuesArr, avgPL, metric);
            } else if (metric === "Daily Avg. P/L") {
              addValueAtIndex(metricsToPush, dataValuesArr, dailyAvgPL, metric);
            } else if (metric === "Weekly Avg. P/L") {
              addValueAtIndex(
                metricsToPush,
                dataValuesArr,
                weeklyAvgPL,
                metric
              );
            } else if (metric === "Monthly Avg. P/L") {
              addValueAtIndex(
                metricsToPush,
                dataValuesArr,
                monthlyAvgPL,
                metric
              );
            } else if (metric === "Sharpe Ratio") {
              addValueAtIndex(
                metricsToPush,
                dataValuesArr,
                sharpeRatioArith,
                metric
              );
            } else if (metric === "TradeFuse Index") {
              addValueAtIndex(metricsToPush, dataValuesArr, tfIndex, metric);
            } else if (metric === "TradeFuse Golden Ratio") {
              addValueAtIndex(
                metricsToPush,
                dataValuesArr,
                goldenRatio,
                metric
              );
            } else if (metric === "Win Rate") {
              addValueAtIndex(
                metricsToPush,
                dataValuesArr,
                winRateasNumber,
                metric
              );
            } else if (metric === "Std. Dev.") {
              addValueAtIndex(metricsToPush, dataValuesArr, -stdDev, metric);
            } else if (metric === "Std. Dev. %") {
              addValueAtIndex(
                metricsToPush,
                dataValuesArr,
                -stdDevPercent,
                metric
              );
            } else if (metric === "Volatility") {
              addValueAtIndex(
                metricsToPush,
                dataValuesArr,
                -volatility,
                metric
              );
            } else if (metric === "Volatility %") {
              addValueAtIndex(
                metricsToPush,
                dataValuesArr,
                -volatilityPercent,
                metric
              );
            } else if (metric === "Avg. Holding Time") {
              addValueAtIndex(
                metricsToPush,
                dataValuesArr,
                avgHoldingTime,
                metric
              );
            } else if (metric === "Max. Drawdown") {
              addValueAtIndex(metricsToPush, dataValuesArr, -maxDD, metric);
            } else if (metric === "Avg. Total Efficiency") {
              addValueAtIndex(metricsToPush, dataValuesArr, avgEff, metric);
            } else if (metric === "Recovery Factor") {
              addValueAtIndex(
                metricsToPush,
                dataValuesArr,
                recoveryFactor,
                metric
              );
            } else if (metric === "Expectation") {
              addValueAtIndex(
                metricsToPush,
                dataValuesArr,
                expectation,
                metric
              );
            } else if (metric === "Return Over Max Drawdown") {
              addValueAtIndex(
                metricsToPush,
                dataValuesArr,
                returnOverMaxDrawdown,
                metric
              );
            } else if (metric === "CPC Index") {
              addValueAtIndex(metricsToPush, dataValuesArr, CPCIndex, metric);
            } else if (metric === "Kelly %") {
              addValueAtIndex(
                metricsToPush,
                dataValuesArr,
                kellypercent,
                metric
              );
            } else if (metric === "Normalized Expectancy %") {
              addValueAtIndex(
                metricsToPush,
                dataValuesArr,
                normalizedExpectancypercent,
                metric
              );
            } else if (metric === "Ulcer Index") {
              addValueAtIndex(
                metricsToPush,
                dataValuesArr,
                -ulcerIndexVal,
                metric
              );
            } else if (metric === "Sortino Ratio") {
              addValueAtIndex(
                metricsToPush,
                dataValuesArr,
                sortinoRatio,
                metric
              );
            } else if (metric === "Probability of Random Chance") {
              addValueAtIndex(
                metricsToPush,
                dataValuesArr,
                -probabilityOfRandomChance,
                metric
              );
            } else if (metric === "Z-Score") {
              addValueAtIndex(metricsToPush, dataValuesArr, -zScore, metric);
            } else if (metric === "LR Correlation") {
              addValueAtIndex(
                metricsToPush,
                dataValuesArr,
                LRCorrelation,
                metric
              );
            } else if (metric === "LR Standard Error") {
              addValueAtIndex(
                metricsToPush,
                dataValuesArr,
                LRStandardError,
                metric
              );
            } else if (metric === "K-Ratio") {
              addValueAtIndex(metricsToPush, dataValuesArr, KRatio, metric);
            } else if (metric === "R-Multiple") {
              addValueAtIndex(metricsToPush, dataValuesArr, RMultiple, metric);
            } else if (metric === "Reward-Risk Ratio") {
              addValueAtIndex(metricsToPush, dataValuesArr, RRR, metric);
            }
          }
        };

        if (
          whatToCompare === "Confidences" ||
          whatToCompare === "Days of Week" ||
          whatToCompare === "Months" ||
          whatToCompare === "Time Frames" ||
          whatToCompare === "Order Types" ||
          whatToCompare === "Asset Classes" ||
          whatToCompare === "Portfolios" ||
          whatToCompare === "Market States"
        ) {
          if (selectedItem === filteredTrades[i].entry[whatToCompareProp]) {
            mapDataValues(dataValues, metrics);
          }
        } else if (whatToCompare === "Symbols") {
          if (selectedItem === filteredTrades[i].entry.symbol.symbols[0]) {
            mapDataValues(dataValues, metrics);
          }
        } else if (
          whatToCompare === "Strategies" ||
          whatToCompare === "Emotional States" ||
          whatToCompare === "Tags" ||
          whatToCompare === "Physical States" ||
          whatToCompare === "Mistakes"
        ) {
          if (
            filteredTrades[i].entry[whatToCompareProp] != null &&
            filteredTrades[i].entry[whatToCompareProp].includes(selectedItem)
          ) {
            mapDataValues(dataValues, metrics);
          }
        } else if (whatToCompare === "Trading Sessions") {
          var dateTime = new Date(
            dateToggle === "Trade Open"
              ? filteredTrades[i].entry.startDateTime
              : filteredTrades[i].entry.endDateTime
          );

          // Add condition to filter trades based on trading session
          const tradingSession = getSession(dateTime);

          const selectedSession = selectedItem; // Replace with the selected trading session

          if (tradingSession === selectedSession) {
            mapDataValues(dataValues, metrics);
          }
        } else if (whatToCompare === "Years") {
          // Extract the year from trade's startDateTime or endDateTime
          const tradeYear = new Date(
            filteredTrades[i].entry.startDateTime // Assuming filtering based on startDateTime, adjust if necessary
          ).getFullYear();

          const selectedYear = parseInt(selectedItem, 10); // Ensure selectedItem is treated as a year
          if (tradeYear === selectedYear) {
            mapDataValues(dataValues, metrics);
          }
        }
      }
      const newOpaqueColor = setOpacity(lineColor, 0.2);
      const dataObj = {
        label: selectedItem,
        lineTension: 0.1,
        backgroundColor: newOpaqueColor,
        borderColor: lineColor,
        borderCapStyle: "butt",
        borderWidth: 1,
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: lineColor,
        pointBackgroundColor: lineColor,
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: lineColor,
        pointHoverBorderColor: lineColor,
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        pointHitRadius: 10,
        data: dataValues,
        untamperedData: dataValues.slice(),
      };
      metricValues.push(dataObj);
    }
    return metricValues;
  };
  const metricVals = metricValues(
    whatToCompare === "Portfolios" ? allEntries : allEntriesButFiltered,
    selectedItems,
    metricArr
  );
  const scaledMetrics = scaleMetrics(metricVals);

  /*   const data = {
    labels: labelsonly,
    datasets: [
      {
        label: "Your Equity",
        fill: false,
        lineTension: 0.1,
        bezierCurve: true,
        backgroundColor: lineColorMain,
        borderColor: lineColorMain,
        borderCapStyle: "butt",
        type: "bar",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: lineColorMain,
        pointBackgroundColor: lineColorMain,
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: lineColorMain,
        pointHoverBorderColor: lineColorMain,
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        pointHitRadius: 10,
        data: [1, 2, 3, 12, 2, 12, 3],
      },
    ],
  }; */
  const data = {
    labels: metricArr,
    datasets: scaledMetrics,
  };
  const options = {
    tooltips: {
      mode: "nearest",
      intersect: false,
      backgroundColor: "#14181e",
      titleFontSize: 14,
      titleFontColor: "#fbd665",
      bodyFontColor: "#fff7cb",
      bodyFontSize: 14,
      displayColors: false,
      enabled: true,
      callbacks: {
        title: (tooltipItems) => {
          const datasetIndex = tooltipItems[0].datasetIndex;
          return `${selectedItems[datasetIndex].label}`;
        },
        label: (tooltipItems) => {
          let returnVal = "";
          const index = tooltipItems.index;
          const datasetIndex = tooltipItems.datasetIndex;
          returnVal = parseFloat(tooltipItems.yLabel);

          const scaledMetricsPre = scaledMetrics[datasetIndex].untamperedData;
          const metricToCompare = metricArr[index];
          if (
            metricToCompare === "Total P/L" ||
            metricToCompare === "Avg. P/L" ||
            metricToCompare === "Std. Dev." ||
            metricToCompare === "Daily Avg. P/L" ||
            metricToCompare === "Weekly Avg. P/L" ||
            metricToCompare === "Monthly Avg. P/L" ||
            metricToCompare === "Volatility" ||
            metricToCompare === "Max. Drawdown"
          ) {
            // Higher values here are bad, so make them negative
            if (
              metricToCompare === "Std. Dev." ||
              metricToCompare === "Volatility" ||
              metricToCompare === "Max. Drawdown"
            ) {
              return [
                `${metricToCompare}: ${
                  supportedCryptosCheck
                    ? parseFloat(-scaledMetricsPre[index].toFixed(6)) +
                      " " +
                      props.defaultSymbol
                    : he.decode(props.defaultSymbol) +
                      parseFloat(-scaledMetricsPre[index]).toFixed(2)
                }`,
                `Scaled ${metricToCompare}: ${parseFloat(returnVal).toFixed(
                  3
                )}`,
              ];
            } else {
              return [
                `${metricToCompare}: ${
                  supportedCryptosCheck
                    ? parseFloat(scaledMetricsPre[index].toFixed(6)) +
                      " " +
                      props.defaultSymbol
                    : he.decode(props.defaultSymbol) +
                      parseFloat(scaledMetricsPre[index]).toFixed(2)
                }`,
                `Scaled ${metricToCompare}: ${parseFloat(returnVal).toFixed(
                  3
                )}`,
              ];
            }
          } else if (
            metricToCompare === "Profit Factor" ||
            metricToCompare === "Profit/Loss Ratio" ||
            metricToCompare === "TradeFuse Index" ||
            metricToCompare === "TradeFuse Golden Ratio" ||
            metricToCompare === "Sharpe Ratio" ||
            metricToCompare === "Sortino Ratio" ||
            metricToCompare === "Recovery Factor" ||
            metricToCompare === "Expectation" ||
            metricToCompare === "Return Over Max Drawdown" ||
            metricToCompare === "CPC Index" ||
            metricToCompare === "Z-Score" ||
            metricToCompare === "LR Correlation" ||
            metricToCompare === "LR Standard Error" ||
            metricToCompare === "K-Ratio" ||
            metricToCompare === "Ulcer Index"
          ) {
            // Higher values here are bad, so make them negative
            if (metricToCompare === "Ulcer Index") {
              return [
                `${metricToCompare}: ${parseFloat(
                  -scaledMetricsPre[index]
                ).toFixed(3)}`,
                `Scaled ${metricToCompare}: ${parseFloat(returnVal).toFixed(
                  3
                )}`,
              ];
            } else {
              return [
                `${metricToCompare}: ${parseFloat(
                  scaledMetricsPre[index]
                ).toFixed(3)}`,
                `Scaled ${metricToCompare}: ${parseFloat(returnVal).toFixed(
                  3
                )}`,
              ];
            }
          } else if (
            metricToCompare === "Win Rate" ||
            metricToCompare === "Std. Dev. %" ||
            metricToCompare === "Volatility %" ||
            metricToCompare === "Avg. Total Efficiency" ||
            metricToCompare === "Kelly %" ||
            metricToCompare === "Normalized Expectancy %" ||
            metricToCompare === "Probability of Random Chance"
          ) {
            // Higher values here are bad, so make them negative
            if (
              metricToCompare === "Std. Dev. %" ||
              metricToCompare === "Volatility %" ||
              metricToCompare === "Probability of Random Chance" ||
              metricToCompare === "Ulcer Index"
            ) {
              return [
                `${metricToCompare}: ${parseFloat(
                  -scaledMetricsPre[index]
                ).toFixed(3)}%`,
                `Scaled ${metricToCompare}: ${parseFloat(returnVal).toFixed(
                  3
                )}`,
              ];
            } else {
              return [
                `${metricToCompare}: ${parseFloat(
                  scaledMetricsPre[index]
                ).toFixed(3)}%`,
                `Scaled ${metricToCompare}: ${parseFloat(returnVal).toFixed(
                  3
                )}`,
              ];
            }
          } else if (metricToCompare === "Avg. Holding Time") {
            return [
              `${metricToCompare}: ${getDateDifferencewSeconds(
                scaledMetricsPre[index]
              )}`,
              `Scaled ${metricToCompare}: ${parseFloat(returnVal).toFixed(3)}`,
            ];
          } else if (metricToCompare === "R-Multiple") {
            return [
              `${metricToCompare}: ${parseFloat(
                scaledMetricsPre[index]
              ).toFixed(3)} R`,
              `Scaled ${metricToCompare}: ${parseFloat(returnVal).toFixed(
                3
              )} R`,
            ];
          } else if (metricToCompare === "Reward-Risk Ratio") {
            return [
              `${metricToCompare}: ${parseFloat(
                scaledMetricsPre[index]
              ).toFixed(3)}:1`,
              `Scaled ${metricToCompare}: ${parseFloat(returnVal).toFixed(
                3
              )}:1`,
            ];
          }
        },
      },
    },
    onHover: (event, chartElement) => {
      event.target.style.cursor = "default";
    },

    hover: {
      mode: "nearest",
      intersect: false,
    },
    interaction: {
      mode: "nearest",
      intersect: false,
    },
    scale: {
      ticks: {
        backdropColor: "#556171",
        fontColor: "#fff7cb",
        // Include a dollar sign in the ticks
        callback: function (value, index, values) {
          return value;
        },
      },
      pointLabels: {
        callback: function (value, index, values) {
          return splitString(value, 1);
        },
        fontColor: "#98b5eb",
      },
    },
    plugins: [
      {
        afterLayout: (chart) => {
          chart
            .getDatasetMeta(0)
            .rScale._pointLabelItems.forEach((o) => (o.textAlign = "center"));
        },
      },
    ],
    maintainAspectRatio: false,
    responsive: true,
    animation: false,
    responsiveAnimationDuration: 0,
    legend: {
      position: "bottom",
      align: "end",
      display: false,
    },
  };
  return (
    <div className={props.className}>
      {props.showTitle && (
        <div className="ComparisonGraph-label" id="ComparisonGraph-label">
          <span className={`comparerReportInfo_Comparingheader23`}>
            Metric Comparison
          </span>
          <PopoverStickOnHover
            component={
              <div
                style={{
                  color: "#fff7cb",
                  padding: "2px",
                  width: 260,
                  textAlign: "left",
                }}
              >
                We've scaled down the range of each metric to a scale of 0-10 to
                easily compare their values across different{" "}
                <span>{whatToCompare.toLowerCase()}</span>. Generally, the
                larger the area for a particular{" "}
                <span>{whatToCompareSingle.toLowerCase()}</span>, the better.
              </div>
            }
            placement="bottom"
            onMouseEnter={() => {}}
            delay={300}
            xAdjust={27}
            setClass="accountNumberSuperWrapper"
            yAdjust={0}
            keepOpen={false}
          >
            <span className="tooptip-i-style7">&#x1D48A;</span>
          </PopoverStickOnHover>
        </div>
      )}
      <div className="ComparisonGraphwrapper">
        <Radar data={data} options={options} />
      </div>
    </div>
  );
};

export default RadarGraph;
