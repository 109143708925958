import React, {
  Fragment,
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
  useRef,
} from "react";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import moment from "moment/moment";
import "./styles.scss";
import getSpecificPortfolio from "./../../functions/getSpecificPortfolio";
import supportedCryptos from "../../arrays/isCurrencyCrypto";
import { renderToStaticMarkup } from "react-dom/server";
import { useHistory } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import TextField from "@mui/material/TextField";
import ReactDOM from "react-dom";
import dollarCostAverage from "./../../functions/dollarCostAvg";
import getOptionsStrategy from "./../../functions/getOptionsStrategy";
import useForceUpdate from "../../hooks/useForceUpdate";
import { handleNANGOOD } from "../../functions/handleUndefined";
import showConfirmedActionButton from "../../functions/showConfirmedActionButton";
import { v4 as uuidv4 } from "uuid";
import $ from "jquery";
import createDatafromSettingsProps from "../../functions/createDatafromSettingsFunction";
import { useAuth0 } from "@auth0/auth0-react";
import putUserData from "../../utils/AWS/putS3UserObject";
import { DataContext } from "../../DataContext";
import CalendarNoteComponent from "./CalendarNoteComponent";
import isObjectEqual from "./../../functions/isObjectEqual";
import { EditorState } from "draft-js";
import { convertFromRaw, convertToRaw } from "draft-js";
import WeeklyComponent from "./WeeklyComponent";
function checkAndSetDefaultState(originalState) {
  const defaultState = {
    blocks: [],
    entityMap: {},
  };

  if (originalState && originalState.blocks && originalState.entityMap) {
    return originalState;
  }

  return defaultState;
}
function hexToRgb(hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}
function formatDate(date) {
  const month = String(date.getMonth() + 1);
  const day = String(date.getDate());
  const year = String(date.getFullYear()).slice(-2); // Get the last two digits of the year

  return `${month}/${day}/${year}`;
}
function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

let he = require("he");
function dateIsValid(date) {
  return !Number.isNaN(new Date(date).getTime());
}
const DragAndDropCalendar = withDragAndDrop(Calendar);

const localizer = momentLocalizer(moment);

export const CalendarComponent = (props) => {
  const processedData = props.processedData;
  const portfolio = processedData.portfolio;
  const settings = portfolio.settings;

  const showINIT = props.showINIT;

  const history = useHistory();
  const forceUpdate = useForceUpdate();
  const { user /* isAuthenticated */ } = useAuth0();
  const allGlobalData = useContext(DataContext);
  const allData = allGlobalData.allData;

  /// LOCAL STORAGE VARIABLES
  var localCalendarNotes = JSON.parse(localStorage.getItem("calendarNotes"));
  var isDBRunning = localStorage.getItem("dbSetRunning");
  const toComponentB2 = (row) => {
    // NEED TO REMOVE EDIT TRADE HERE TO ALLOW FOR EDIT TRADES
    // TO MERGE WITH INCOMING WEBSOCKET MSGS
    localStorage.removeItem("editTrade");

    localStorage.removeItem("editTradeId");
    history.push({
      pathname: "/Trade-Details",
      state: row,
    });
  };
  const entries = portfolio.entries;
  const calctype = portfolio.settings.calculationType;
  const symbol = portfolio.settings.currencySymbol;
  // dates in notes must be in the format mm/dd/yyyy, like 1/1/23, NOT 01/01/23
  const tier = props.tier;
  const active = props.active;

  const calendarNotes = portfolio.settings.calendarNotes;

  // Tiering
  let showMaster = false;

  if (tier === "free") {
  } else if (tier === "pro" && active) {
  } else if (tier === "oldpro" && active) {
  } else if (tier === "master" && active) {
    showMaster = true;
  } else if (tier === "ultimate" && active) {
    showMaster = true;
  } else if (tier === "admin" && active) {
    showMaster = true;
  } else {
  }

  const supportedCryptosCheck = supportedCryptos.includes(String(symbol));
  const { views, ...otherProps } = useMemo(
    () => ({
      views: {
        month: true,
        /*         week: true,
        day: true */
      },
    }),
    []
  );
  const getEntrybyId = (id) => {
    let entry = {};
    entry = entries.filter((entry) => entry.entryId === id);
    return entry;
  };
  const [editcalProps, seteditcalProps] = useState(false);
  const [calToggle, setcalToggle] = useState(true);
  const firstUpdate = useRef(true);
  const dateNow = new Date();
  const newdate1 = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1);
  const [yourDate, setyourDate] = useState(newdate1);
  const [DWDTOpen, setDWDTOpen] = useState(false);
  const notes = useRef(calendarNotes || {});
  const [notesFirst, setnotesFirst] = useState(calendarNotes);

  const start = Date.now();
  const [finalStateTimeChanged, setfinalStateTimeChanged] = useState(start);
  const [callcounter, setcallcounter] = useState(0);

  const handleNoteChange = useCallback(
    async (e, lolevent, notesState, notesStateINIT) => {
      if (isObjectEqual(notesState, notesStateINIT)) {
      } else {
        const localNotes = localStorage.getItem("calendarNotes");
        let actualNotes = !localNotes ? notes.current : JSON.parse(localNotes);

        // set the day
        actualNotes[formatDate(lolevent.start)] = notesState;

        // remove blank notes
        for (const date in actualNotes) {
          if (actualNotes[date] === "") {
            delete actualNotes[date];
          }
        }

        // set local storage & ref
        localStorage.setItem("calendarNotes", JSON.stringify(actualNotes));
        //notesFirst.current = actualNotes;
      }
    },
    [forceUpdate]
  );

  const handleChange = useCallback(
    async (e, lolevent, notesState, notesStateINIT) => {
      if (isObjectEqual(notesState, notesStateINIT)) {
      } else {
        const localNotes = localStorage.getItem("calendarNotes");
        let actualNotes = !localNotes ? notes.current : JSON.parse(localNotes);
        const currentTimez = Date.now();
        setfinalStateTimeChanged(currentTimez);
        $(window).bind("beforeunload", function () {
          return ">>>>>Before You Go<<<<<<<< \n Your custom message go here";
        });
        setcallcounter(1);
        // set the day
        actualNotes[formatDate(lolevent.start)] = notesState;

        // remove blank notes
        for (const date in actualNotes) {
          if (actualNotes[date] === "") {
            delete actualNotes[date];
          }
        }

        // set local storage & ref
        localStorage.setItem("calendarNotes", JSON.stringify(actualNotes));
        notes.current = actualNotes;
        //notesFirst.current = actualNotes;
        forceUpdate();
      }
    },
    [forceUpdate, notes.current]
  );

  const handleCallSetJSON = useCallback(async () => {
    localStorage.setItem("dbSetRunning", "yes");
    var localCalendarNotes2 = JSON.parse(localStorage.getItem("calendarNotes"));
    const checkvar = settings.calendarNotes;
    let propsToChangei = {
      calendarNotes:
        localCalendarNotes2 !== null ? localCalendarNotes2 : checkvar,
    };
    let globalpropsToChange = {};
    const finalPush = createDatafromSettingsProps(
      allData.chosenPortfolios,
      allData.createdPortfolios,
      propsToChangei,
      globalpropsToChange,
      settings,
      allData.globalSettings,
      allData.version
    );
    // SET S3 DATA
    const S3Data = {
      data: finalPush,
      userId: user.sub,
    };
    const finalAwait = async () => {
      await putUserData(S3Data, history);
      allGlobalData.updateAllData(finalPush);
      $(window).unbind("beforeunload");
      localStorage.setItem("dbSetRunning", "no");
      setcallcounter(0);
    };
    await finalAwait();
  }, [allData, settings, allGlobalData, user]);

  useEffect(() => {
    // checks every 200ms for state updates
    // If the user does not change a state after 1.6 seconds, handleCallSetJSON gets called
    const userTimeAllowance = 1.6;
    let interval = setInterval(async () => {
      var a = new Date();
      var b = new Date(finalStateTimeChanged);
      var difference = (a - b) / 1000;
      if (
        callcounter === 1 &&
        difference > userTimeAllowance &&
        isDBRunning === "no"
      ) {
        setcallcounter(0);
        await handleCallSetJSON();
      } else {
      }
    }, 200);

    if (firstUpdate.current) {
    }
    return () => {
      firstUpdate.current = false;
      clearInterval(interval);
    };
  }, [
    allData,
    localCalendarNotes,
    settings,
    notes.current,
    firstUpdate,
    callcounter,
    finalStateTimeChanged,
    handleCallSetJSON,
    isDBRunning,
  ]);

  const localNotes = localStorage.getItem("calendarNotes");
  const actualnotes = !localNotes ? notes.current : JSON.parse(localNotes);
  function pad(n) {
    return n < 10 ? "0" + n : n;
  }
  const generateEvents = useCallback(
    (entries, calctype, supportedCryptosCheck, symbol) => {
      if (showINIT) {
        return { result: [], groups: {} };
      }
      const groups = {};
      for (let ii = 0, jj = entries.length; ii < jj; ii++) {
        let correctedSUM = 0;
        let x100profitLoss = Number(entries[ii].entry.profitLoss * 100);
        let x100commissions = Number(entries[ii].entry.commissions * 100);
        let x100fees = Number(entries[ii].entry.fees * 100);
        if (calctype === "Gross") {
          correctedSUM = x100profitLoss / 100;
        } else if (calctype === "Net") {
          correctedSUM = (x100profitLoss + x100commissions + x100fees) / 100;
        }
        //add elements to symbol and strategy arrays
        const startDateTime = new Date(entries[ii].entry.startDateTime);
        const endDateTime = new Date(entries[ii].entry.endDateTime);
        const chosenDateTime =
          calToggle === false ? startDateTime : endDateTime;
        if (
          entries[ii].entry.orderType !== "Deposit" &&
          entries[ii].entry.orderType !== "Withdrawal" &&
          entries[ii].entry.orderType !== "Funding Payment" &&
          entries[ii].entry.orderType !== "Commit" &&
          entries[ii].entry.orderType !== "Approval" &&
          entries[ii].entry.orderType !== "Wrap" &&
          entries[ii].entry.orderType !== "Self"
        ) {
          if (dateIsValid(chosenDateTime)) {
            const key =
              chosenDateTime.getMonth() +
              1 +
              "/" +
              chosenDateTime.getDate() +
              "/" +
              chosenDateTime.getFullYear();

            if (!(key in groups)) {
              groups[key] = {
                profitLoss: 0,
                numTrades: 0,
                trades: [],
                strategies: [],
                mistakes: [],
                endDate: key,
                balance: entries[ii].balance,
              };
            }
            groups[key].trades = [...groups[key].trades, entries[ii]];
            groups[key].profitLoss += Number(correctedSUM);
            groups[key].numTrades += 1;
            const uniqstrategies = [
              ...new Set([
                ...groups[key].strategies,
                ...entries[ii].entry.strategy,
              ]),
            ];
            const uniqmistakes = [
              ...new Set([
                ...groups[key].mistakes,
                ...entries[ii].entry.selectedMistake,
              ]),
            ];
            groups[key].strategies = uniqstrategies;
            groups[key].mistakes = uniqmistakes;
          }
        }
      }
      const result = [];
      let id = 1;
      const titleElement = (text) => (
        <div className="greenCalendarItem">{text}</div>
      );
      const titleElement2 = (text) => (
        <div className="redCalendarItem">{text}</div>
      );

      const month = yourDate.getMonth();
      const year = yourDate.getFullYear();
      const startDate = moment(`${year}-${month + 1}-01`).startOf("month");
      const endDate = moment(startDate).endOf("month");
      const daysInMonth = endDate.date();
      var width =
        window.innerWidth > 0 ? window.innerWidth : window.screen.width;
      var height =
        window.innerHeight > 0 ? window.innerHeight : window.screen.height;

      // only display "Daily Notes" per month
      for (let day = 1; day <= daysInMonth; day++) {
        const currentDate = moment(`${year}-${month + 1}-${day}`);
        const dateFormatted = formatDate(moment(currentDate).toDate());
        const initContent = convertFromRaw(
          checkAndSetDefaultState(actualnotes[dateFormatted])
        );
        const editorStatePre = EditorState.createWithContent(initContent);

        result.push({
          id: uuidv4(),
          title:
            !actualnotes ||
            !actualnotes[dateFormatted] ||
            actualnotes[dateFormatted] === "" ||
            !editorStatePre.getCurrentContent().hasText() ? (
              <div
                id={"dailynotesbuttoncalendar"}
                className={"dailynotesbuttoncalendar"}
              >
                {width < 481 ? "✎" : "✎ Notes"}{" "}
              </div>
            ) : (
              <div
                id={"dailynotesbuttoncalendar"}
                className={"dailynotesbuttoncalendar2"}
              >
                {width < 481 ? "✎ 📜" : "✎ Notes 📜"}
              </div>
            ),
          allDay: false,
          start: moment(currentDate).toDate(),
          end: moment(currentDate).toDate(),
          desc: "",
          trades: 0,
          notes: actualnotes && actualnotes[dateFormatted],
          type: "notes",
        });
      }

      for (const key in groups) {
        const group = groups[key];
        result.push({
          id: id,
          title: (
            <div
              className={
                group.profitLoss === 0
                  ? ""
                  : group.profitLoss < 0
                  ? "tiqred calplitem"
                  : "tiqgreen calplitem"
              }
            >
              {supportedCryptosCheck
                ? group.profitLoss.toFixed(6) + " " + String(symbol)
                : he.decode(String(symbol)) + group.profitLoss.toFixed(2)}
            </div>
          ),
          allDay: false,
          start: moment(group.endDate).toDate(),
          end: moment(group.endDate).toDate(),
          desc: "",
          trades: 0,
          notes: "",
          type: "profitLoss",
        });
        result.push({
          id: id + 1,
          title: (
            <div className={"numtradescalitem"}>
              {Number(group.numTrades) === 1
                ? "1 trade"
                : `${Number(group.numTrades)} trades`}
            </div>
          ),
          allDay: false,
          start: moment(group.endDate).toDate(),
          end: moment(group.endDate).toDate(),
          desc: "",
          trades: group.trades,
          notes: "",
          type: "numTrades",
        });
        result.push(
          ...group.strategies.map((strat) => {
            return {
              id: group.endDate + "3" + strat,
              title: titleElement(strat),
              allDay: false,
              start: moment(group.endDate).toDate(),
              end: moment(group.endDate).toDate(),
              desc: "",
              trades: 0,
              notes: "",
              type: "strategy",
            };
          })
        );
        result.push(
          ...group.mistakes.map((mistake) => {
            return {
              id: group.endDate + "4" + mistake,
              title: titleElement2(mistake),
              allDay: false,
              start: moment(group.endDate).toDate(),
              end: moment(group.endDate).toDate(),
              desc: "",
              trades: 0,
              notes: "",
              type: "mistake",
            };
          })
        );
        id += 2;
      }
      return { result: result, groups: groups };
    },
    [calToggle, showINIT, yourDate, localNotes, notes]
  );

  const [myEvents, setMyEvents] = useState();
  const [myGroups, setmyGroups] = useState();

  /*   const moveEvent = useCallback(
    ({ event, start, end, isAllDay: droppedOnAllDaySlot = false }) => {
      const { allDay } = event;
      if (!allDay && droppedOnAllDaySlot) {
        event.allDay = true;
      }

      setMyEvents((prev) => {
        const existing = prev.find((ev) => ev.id === event.id) ?? {};
        const filtered = prev.filter((ev) => ev.id !== event.id);
        return [...filtered, { ...existing, start, end, allDay }];
      });
    },
    [setMyEvents]
  );

  const resizeEvent = useCallback(
    ({ event, start, end }) => {
      setMyEvents((prev) => {
        const existing = prev.find((ev) => ev.id === event.id) ?? {};
        const filtered = prev.filter((ev) => ev.id !== event.id);
        return [...filtered, { ...existing, start, end }];
      });
    },
    [setMyEvents]
  ); */

  const defaultDate = useMemo(() => new Date(), []);
  /* 
  const eventStyleGetter = (event, start, end, isSelected) => {
    console.log(event);
    const profitLoss = event.profitLoss;
    var backgroundColor = "#" + event.hexColor;
    var style = {
      backgroundColor: "transparent",
      padding: "0px 0px 0px 6px",
      borderRadius: "8px",
      opacity: 0.8,
      color: "#14181e",
      border: "0px",
      display: "block",
    };
    return {
      style: style,
    };
  }; */
  useEffect(() => {
    const events = generateEvents(
      entries,
      calctype,
      supportedCryptosCheck,
      symbol,
      calToggle
    );
    setMyEvents(events.result);
    setmyGroups(events.groups);
    if (firstUpdate.current) {
      var addedType2 = localStorage.getItem("loadDemoDataAction");
      if (addedType2 === "yes") {
        showConfirmedActionButton("Demo Data Added!");
        localStorage.removeItem("loadDemoDataAction");
      } else if (addedType2 === "no") {
        showConfirmedActionButton("Demo Data Deleted!");
        localStorage.removeItem("loadDemoDataAction");
      }
    }
    return () => {
      firstUpdate.current = false;
    };
  }, [
    calToggle,
    calctype,
    entries,
    generateEvents,
    supportedCryptosCheck,
    symbol,
  ]);
  const buttonClick = (entryId) => {
    toComponentB2(getEntrybyId(String(entryId))[0]);
  };

  function createTradePopup(event, lolevent) {
    if (showINIT) {
      return;
    }
    const trades = lolevent.trades;
    const popup = document.createElement("div");
    popup.style.width = "260px";
    popup.style.height = "220px";
    popup.style.position = "absolute";
    popup.style.top = event.clientY + "px";
    popup.id = "calendarcustompopup";
    popup.style.left = event.clientX + "px";
    popup.style.zIndex = "99999999";
    var wrapper = document.createElement("div");
    const tradeObjs = trades.map((trade) => {
      const entry = trade.entry;
      const multiexecution = entry.multiExecution;
      const orderType = entry.orderType;
      const symbol = entry.symbol.symbols[0];
      const finalOrderType =
        orderType === "Multi-Leg Strategy"
          ? getOptionsStrategy(multiexecution, "", orderType)
          : orderType + " ";
      const porttype = entry.selectedPortfolioType;
      let totalLots = 0;
      const typestring =
        porttype === "Stocks"
          ? "Shares"
          : porttype === "Options" || porttype === "Futures"
          ? "Contracts"
          : porttype === "Forex"
          ? "Lots"
          : porttype === "Crypto"
          ? "Coins/Tokens"
          : porttype === "CFD"
          ? "Quantity"
          : "Units";
      // Loop through the array and add up the lots
      for (let i = 0; i < multiexecution.length; i++) {
        totalLots += Number(multiexecution[i].lotSize);
      }
      const portfolioName = entry.selectedPortfolio;
      let createdPortfolioMatching = allData?.createdPortfolios.slice();
      createdPortfolioMatching = createdPortfolioMatching.filter((port) => {
        return port.name === portfolioName;
      });
      const portfolioColorPre =
        createdPortfolioMatching[0]?.settings?.portfolioColor;
      const colorRGB = portfolioColorPre && hexToRgb(portfolioColorPre);
      const portfolioColor =
        colorRGB && `rgba(${colorRGB.r}, ${colorRGB.g}, ${colorRGB.b}, 1)`;
      function getBrightness(color) {
        var rgb = parseInt(color.slice(1), 16); // slice to remove the '#'
        var r = (rgb >> 16) & 0xff;
        var g = (rgb >> 8) & 0xff;
        var b = rgb & 0xff;
        return 0.299 * r + 0.587 * g + 0.114 * b;
      }

      function textColorBasedOnBgColor(bgColor) {
        return getBrightness(bgColor) > 127 ? "#14181e" : "#deebf7";
      }
      const textColor =
        !portfolioColor || portfolioColor === ""
          ? "#14181e"
          : textColorBasedOnBgColor(portfolioColorPre);

      function shadeColor(color, percent) {
        var R = parseInt(color.substring(1, 3), 16);
        var G = parseInt(color.substring(3, 5), 16);
        var B = parseInt(color.substring(5, 7), 16);

        R = parseInt((R * (100 + percent)) / 100);
        G = parseInt((G * (100 + percent)) / 100);
        B = parseInt((B * (100 + percent)) / 100);

        R = R < 255 ? R : 255;
        G = G < 255 ? G : 255;
        B = B < 255 ? B : 255;

        R = Math.round(R);
        G = Math.round(G);
        B = Math.round(B);

        var RR =
          R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16);
        var GG =
          G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16);
        var BB =
          B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16);

        return "#" + RR + GG + BB;
      }
      const portfolioColorDark = shadeColor(portfolioColorPre, -30);

      return (
        <button
          onClick={() => {
            popup.remove();
            buttonClick(String(trade.entryId));
          }}
          className="calendarcustombuttons"
          style={{
            color: textColor,
            backgroundImage: showMaster
              ? !portfolioColor || portfolioColor === ""
                ? "linear-gradient(to bottom right, #ffe593, #8f783e)"
                : `linear-gradient(to bottom right, ${portfolioColor}, ${portfolioColorDark})`
              : "linear-gradient(to bottom right, #ffe593, #8f783e)",
          }}
          key={String(trade.entryId)}
        >
          {symbol +
            " - " +
            finalOrderType +
            totalLots +
            ` ${typestring}` +
            ` @ ` +
            dollarCostAverage(multiexecution, "entry", orderType)}
        </button>
      );
    });

    const customDiv = <div className="calcustomobjwrapper">{tradeObjs}</div>;
    //wrapper.innerHTML = renderToStaticMarkup(customDiv);
    ReactDOM.render(customDiv, wrapper);
    var div = wrapper.firstChild;
    popup.appendChild(div);
    document.body.appendChild(popup);

    const x = event.clientX;
    const y = event.clientY;
    const w = window.innerWidth;
    const h = window.innerHeight;

    // ----------- RESPONSIVE DESIGN STUFF -----------
    const heightToAdjust = 900;
    const widthToAdjust = 1680;
    let scaleAmountIn = 0;
    var width = window.innerWidth > 0 ? window.innerWidth : window.screen.width;
    var height =
      window.innerHeight > 0 ? window.innerHeight : window.screen.height;
    if (width < widthToAdjust || height < heightToAdjust) {
      scaleAmountIn = 1.25;
    } else {
      scaleAmountIn = 1;
    }
    // -----------

    if (x < w / 2 && y < h / 2) {
      popup.style.top = event.clientY * scaleAmountIn + 10 + "px";
      popup.style.left = event.clientX * scaleAmountIn + 10 + "px";
    } else if (x > w / 2 && y < h / 2) {
      popup.style.top = event.clientY * scaleAmountIn + 10 + "px";
      popup.style.left = event.clientX * scaleAmountIn - 270 + "px";
    } else if (x < w / 2 && y > h / 2) {
      popup.style.top = event.clientY * scaleAmountIn - 230 + "px";
      popup.style.left = event.clientX * scaleAmountIn + 10 + "px";
    } else {
      popup.style.top = event.clientY * scaleAmountIn - 230 + "px";
      popup.style.left = event.clientX * scaleAmountIn - 270 + "px";
    }

    const outsideClickHandler = (event) => {
      if (!popup.contains(event.target)) {
        popup.remove();
        document.removeEventListener("click", outsideClickHandler);
      }
    };

    document.addEventListener("click", outsideClickHandler);
  }

  const createNotesPopup = useCallback(
    (event, lolevent) => {
      //setnote(lolevent.notes);
      if (showINIT) {
        return;
      }
      const popup = document.createElement("div");
      popup.style.width = "420px";
      popup.style.height = "340px";
      popup.style.position = "absolute";
      popup.style.top = event.clientY + "px";
      popup.id = "calendarcustompopup";
      popup.style.left = event.clientX + "px";
      popup.style.zIndex = "99999999";
      var wrapper = document.createElement("div");
      const localNotes = localStorage.getItem("calendarNotes");
      const customDiv = (
        <CalendarNoteComponent
          popup={popup}
          notes={notes.current}
          lolevent={lolevent}
          handleChange={(e, lolevent, notesState, notesStateINIT) =>
            handleChange(e, lolevent, notesState, notesStateINIT)
          }
          handleNoteChange={(e, lolevent, notesState, notesStateINIT) =>
            handleNoteChange(e, lolevent, notesState, notesStateINIT)
          }
        />
      );
      //wrapper.innerHTML = renderToStaticMarkup(customDiv);
      ReactDOM.render(customDiv, wrapper);
      var div = wrapper.firstChild;
      popup.appendChild(div);
      document.body.appendChild(popup);

      const x = event.clientX;
      const y = event.clientY;
      const w = window.innerWidth;
      const h = window.innerHeight;

      // ----------- RESPONSIVE DESIGN STUFF -----------
      const heightToAdjust = 900;
      const widthToAdjust = 1680;
      let scaleAmountIn = 0;
      var width =
        window.innerWidth > 0 ? window.innerWidth : window.screen.width;
      var height =
        window.innerHeight > 0 ? window.innerHeight : window.screen.height;
      if (width < widthToAdjust || height < heightToAdjust) {
        scaleAmountIn = 1.25;
      } else {
        scaleAmountIn = 1;
      }
      // -----------

      if (x < w / 2 && y < h / 2) {
        popup.style.top = event.clientY * scaleAmountIn + 10 + "px";
        popup.style.left = event.clientX * scaleAmountIn + 10 + "px";
      } else if (x > w / 2 && y < h / 2) {
        popup.style.top = event.clientY * scaleAmountIn + 10 + "px";
        popup.style.left = event.clientX * scaleAmountIn - 430 + "px";
      } else if (x < w / 2 && y > h / 2) {
        popup.style.top = event.clientY * scaleAmountIn - 350 + "px";
        popup.style.left = event.clientX * scaleAmountIn + 10 + "px";
      } else {
        popup.style.top = event.clientY * scaleAmountIn - 350 + "px";
        popup.style.left = event.clientX * scaleAmountIn - 430 + "px";
      }
    },
    [notes.current, handleChange]
  );

  function handleNavigate(date) {
    if (showINIT) {
      return;
    }
    setyourDate(moment(date).toDate());
  }
  const handleCalChange = (e) => {
    if (showINIT) {
      return;
    }
    if (e === false) {
      setcalToggle(false);
    } else {
      setcalToggle(true);
    }
  };
  const monthName = month[yourDate.getMonth()];
  const year = yourDate.getFullYear();
  const calendarStyle = useCallback(
    (date) => {
      const green = "rgba(48,253,150, .15)";
      const red = "rgba(255,93,115, .15)";
      const key = `${
        date.getMonth() + 1
      }/${date.getDate()}/${date.getFullYear()}`;
      const neededGroup = myGroups[key];
      const profitLoss = neededGroup?.profitLoss;
      if (profitLoss > 0) {
        return {
          style: {
            backgroundColor: green,
            margin: 0,
            padding: 0,
          },
        };
      } else if (neededGroup?.profitLoss < 0) {
        return {
          style: {
            backgroundColor: red,
            margin: 0,
            padding: 0,
          },
        };
      }
    },
    [myGroups]
  );

  function getEventsByMonthAndYear(events, month, year) {
    return events.filter(
      (event) =>
        event.start.getMonth() === month && event.start.getFullYear() === year
    );
  }
  /*   const neededGroup2 = getEventsByMonthAndYear(
    myGroups,
    yourDate.getMonth(),
    year
  );
  console.log(neededGroup2); */

  // have to use usecallback
  const calendarCalculations = useCallback(() => {
    if (showINIT) {
      return {
        totaltrades: 0,
        totalProfitLoss: 0,
        percentProfitLoss: 0,
        color: "#deebf7",
      };
    }
    const targetMonth = yourDate.getMonth() + 1;
    const targetYear = year;
    let filteredValues = [];

    for (const dateKey in myGroups) {
      const dateParts = dateKey.split("/");
      const month = parseInt(dateParts[0]);
      const year = parseInt(dateParts[2]);
      if (month === targetMonth && year === targetYear) {
        filteredValues.push(myGroups[dateKey]);
      }
    }
    let totalProfitLoss = 0;
    let totaltrades = 0;
    const startBalance = filteredValues[0]?.balance;
    for (let iv = 0; iv < filteredValues.length; iv++) {
      totalProfitLoss += filteredValues[iv].profitLoss;
      totaltrades += filteredValues[iv]?.trades?.length;
    }

    const percentProfitLoss = (totalProfitLoss / startBalance) * 100;
    const color =
      totalProfitLoss > 0
        ? "#55f8a6"
        : totalProfitLoss < 0
        ? "#fa798b"
        : "#808080";
    return {
      totaltrades: totaltrades,
      totalProfitLoss: totalProfitLoss,
      percentProfitLoss: percentProfitLoss,
      color: color,
    };
  }, [myGroups, yourDate, showINIT, year]);

  const caldularcalreturn = calendarCalculations();
  return (
    <div className="calendarWrapper">
      <div className="calendarWrapperToggler" id="calendarWrapperToggler">
        <div className="calendarWrapperTogglerHeader">{"Group by"}</div>
        {"First Entry"}
        <label className="switch">
          <input
            id="perftogglebutton"
            type="checkbox"
            checked={calToggle}
            onChange={(e) => handleCalChange(e.target.checked)}
          />
          <span className="sliderperf roundperf"></span>
        </label>
        {"Last Exit"}
      </div>
      <div className="calculatedPLValueCalWrapper">
        <span className="calculatedPLValueCalinnerWrapper">
          <span className="calculatedPLValueCalheader">{`P/L: `} </span>
          <span
            className="calculatedPLValueCal"
            style={{ color: caldularcalreturn.color }}
          >
            {(supportedCryptosCheck
              ? Number(caldularcalreturn.totalProfitLoss).toFixed(6) +
                " " +
                he.decode(String(symbol))
              : he.decode(String(symbol)) +
                numberWithCommas(
                  Number(caldularcalreturn.totalProfitLoss).toFixed(2)
                )) +
              " / " +
              (handleNANGOOD(caldularcalreturn.percentProfitLoss, "-") === "-"
                ? "-"
                : caldularcalreturn.percentProfitLoss.toFixed(3)) +
              "%"}
          </span>
        </span>
        <span className="calculatedPLValueCalinnerWrapper">
          <span className="calculatedPLValueCalheader">{`Trades: `} </span>
          <div className="calculatedPLValueCal2">
            {caldularcalreturn.totaltrades}
          </div>
        </span>
      </div>
      {!editcalProps || showINIT ? (
        <div
          className="calendarWrapperDateStatic"
          onClick={() => {
            seteditcalProps(true);
            setDWDTOpen(true);
            forceUpdate();
          }}
        >
          {monthName} {year}
        </div>
      ) : (
        <div className="calendarWrapperdatePicker">
          <div className="calendarWrapperDateStatic">
            {monthName} {year}
          </div>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              renderInput={(props) => {
                return (
                  <TextField
                    {...props}
                    inputProps={{
                      ...props.inputProps,
                    }}
                    className="cal-input-boxes"
                    id="cal-text-input-boxes-dt"
                    onBlur={() => {
                      seteditcalProps(false);
                    }}
                    onFocus={() => {
                      document
                        .getElementById("cal-text-input-boxes-dt")
                        .addEventListener("keyup", function (event) {
                          // Number 13 is the "Enter" key on the keyboard
                          if (event.keyCode === 13) {
                            // Cancel the default action, if needed
                            event.preventDefault();
                            // Trigger the button element with a click
                            seteditcalProps(false);
                          } else if (event.keyCode === 9) {
                            // Cancel the default action, if needed
                            event.preventDefault();
                            // Trigger the button element with a click
                            seteditcalProps(false);
                          }
                        });
                      document
                        .getElementById("cal-text-input-boxes-dt")
                        .addEventListener("keydown", function (event) {
                          // Number 9 is the "Enter" key on the keyboard
                          if (event.keyCode === 9) {
                            // Cancel the default action, if needed
                            event.preventDefault();
                            // Trigger the button element with a click
                          }
                        });
                    }}
                  />
                );
              }}
              inputFormat="MM/DD/YYYY"
              views={["year", "month"]}
              clearable={true}
              ampm={false}
              open={DWDTOpen}
              onOpen={() => {
                setDWDTOpen(true);
              }}
              value={yourDate || null}
              name="exitexpirationDate"
              onChange={(e) => {
                let dateN = !e ? "" : new Date(e._d);
                if (dateN === "") {
                } else {
                  dateN.setHours(0, 0, 0, 0); // last midnight
                  const newdate = new Date(
                    dateN.getFullYear(),
                    dateN.getMonth(),
                    1
                  );
                  setyourDate(newdate);
                }
                forceUpdate();
              }}
              onAccept={() => {
                seteditcalProps(false);
              }}
              onClose={() => {
                seteditcalProps(false);
                forceUpdate();
                setDWDTOpen(false);
              }}
              TextFieldComponent={() => null}
            />
          </LocalizationProvider>
        </div>
      )}
      <DragAndDropCalendar
        defaultDate={defaultDate}
        defaultView={Views.MONTH}
        events={myEvents}
        localizer={localizer}
        onSelectEvent={(event, actualEv) => {
          if (event.type === "numTrades") {
            createTradePopup(actualEv, event);
          } else if (event.type === "notes") {
            createNotesPopup(actualEv, event);
          }
        }}
        date={yourDate}
        onNavigate={handleNavigate}
        tooltipAccessor={null} // prevents tooltip! 0 upvotes on the best solution, smh https://stackoverflow.com/questions/58605394/modifying-the-tooltip-in-react-big-calendar
        /*         eventPropGetter={eventStyleGetter}
         */ /* onEventDrop={moveEvent}
        onEventResize={resizeEvent} */
        views={views}
        popup
        draggableAccessor={(event) => false}
        dayPropGetter={calendarStyle}

        /*         resizable
         */
      />
      {
        <WeeklyComponent
          groups={myGroups}
          month={yourDate.getMonth()}
          year={year}
          portfolio={portfolio}
        />
      }
    </div>
  );
};

export default CalendarComponent;
