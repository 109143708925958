import React, { useRef, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
/* import { returnNetPLDollar } from "./../../functions/profitLossCalculation";
 */ import dollarCostAverage from "./../../functions/dollarCostAvg";
/* import getRiskedPercentColor from "../../functions/getRiskedPercentColor";
import { profitLossCalculation } from "./../../functions/profitLossCalculation";
 */ import getEfficiencyBarColor from "../Logbook/getEfficiencyBarColor";
import { handleNANGOOD } from "./../../functions/handleUndefined";
import ProTierComponent from "./../../components/tierComponents/pro";
import { profitLossCalculation } from "./../../functions/profitLossCalculation";
import { returnNetPLDollar } from "./../../functions/profitLossCalculation";
import getColorPoint from "./../../functions/includeColor";
import PopoverStickOnHover from "./../../components/PopoverStickOnHover";
import longShortDelineation from "../../functions/longShortDelineation";

const SinglePercentMetric = (props) => {
  const rowData = props.row;
  const balance = props.balance;
  const settings = props.userSettings;
  const pnlmethod = settings.pnlcalculationmethod;

  const graph = props.graph;
  const graphTitle = props.graphTitle;
  const firstUpdate = useRef(true);
  /*   const [height, setHeight] = useState(0);
   */ /* const [width, setWidth] = useState(0); */
  const color = useRef("");

  const ref = useRef(null);

  const showINIT = props.showCheck;

  useEffect(() => {
    /*     document.onreadystatechange = () => {
      setHeight(ref.current.clientHeight);
      setWidth(ref.current.clientWidth);
      console.log(ref.current.clientWidth);
    }; */
    setTimeout(() => {
      /*       setHeight(ref.current.clientHeight);
       */
      /* setWidth(ref.current.clientWidth); */
    }, 1);
    if (firstUpdate.current) {
    }

    return () => {
      firstUpdate.current = false;
    };
  }, []);
  const returnMetrics = () => {
    let elements = [];
    let graphs = [props.graph];

    // Calculate risk %
    const equityvaluefunction = () => {
      const orderType = rowData.orderType;
      const multiExecutioninner = rowData.multiExecution;
      let avgEntry = dollarCostAverage(multiExecutioninner, "entry", orderType);
      const multiExecutioninner2 = rowData.exitExecution;
      let avgExit = dollarCostAverage(multiExecutioninner2, "exit", orderType);
      let efficiency = 0;
      let entryefficiency = 0;
      let exitefficiency = 0;
      if (longShortDelineation(multiExecutioninner) === "Long") {
        efficiency =
          100 * ((avgEntry - avgExit) / (rowData.maxAdEx - rowData.maxFavEx));
        entryefficiency =
          100 *
          ((rowData.maxFavEx - avgEntry) /
            (rowData.maxFavEx - rowData.maxAdEx));
        exitefficiency =
          100 *
          ((avgExit - rowData.maxAdEx) / (rowData.maxFavEx - rowData.maxAdEx));
      } else {
        efficiency =
          100 * ((avgExit - avgEntry) / (rowData.maxFavEx - rowData.maxAdEx));
        entryefficiency =
          100 *
          ((avgEntry - rowData.maxFavEx) /
            (rowData.maxAdEx - rowData.maxFavEx));
        exitefficiency =
          100 *
          ((rowData.maxAdEx - avgExit) / (rowData.maxAdEx - rowData.maxFavEx));
      }

      const acceptableefficiency =
        handleNANGOOD(efficiency, "-") === "-"
          ? "-"
          : handleNANGOOD(efficiency, 0).toFixed(3);
      const acceptableentryefficiency =
        handleNANGOOD(entryefficiency, "-") === "-"
          ? "-"
          : handleNANGOOD(entryefficiency, 0).toFixed(3);
      const acceptableexitefficiency =
        handleNANGOOD(exitefficiency, "-") === "-"
          ? "-"
          : handleNANGOOD(exitefficiency, 0).toFixed(3);
      const rmultiple =
        (avgExit - avgEntry) / (avgEntry - Number(rowData.stopLoss));
      const adjrrr = -avgEntry / (avgEntry - Number(rowData.stopLoss));

      const divisor =
        rowData.selectedPortfolioType === "Forex" ? rmultiple : adjrrr;

      const initPl = profitLossCalculation(
        multiExecutioninner,
        [],
        orderType,
        rowData.selectedPortfolioType,
        rowData.symbol.pointValue,
        true,
        pnlmethod
      );

      const dividend =
        rowData.selectedPortfolioType === "Forex"
          ? returnNetPLDollar(
              rowData.profitLoss,
              rowData.commissions,
              rowData.fees,
              settings.calculationType
            )
          : returnNetPLDollar(
              initPl,
              rowData.commissions,
              rowData.fees,
              settings.calculationType
            );
      // If the asset class if forex we have to calculate risk a slightly different way

      const percent = Number(dividend / Number(balance)) * 100;
      const riskPercent = percent / divisor;
      return {
        efficiency: acceptableefficiency,
        entryefficiency: acceptableentryefficiency,
        exitefficiency: acceptableexitefficiency,
        riskedpercent: riskPercent,
      };
    };
    const graphValues = equityvaluefunction();
    const graphPropValues = graphValues[graph];

    if (graph === "riskedpercent") {
      if (
        graphPropValues < 0 ||
        graphPropValues > 100 ||
        graphPropValues === "-" ||
        !graphPropValues ||
        showINIT
      ) {
        color.current = "#28303c";
      } else {
        if (graphPropValues < 5) {
          color.current = getColorPoint(
            "#49f875",
            "#e1f967",
            graphPropValues / 5,
            "hex"
          );
        } else if (graphPropValues >= 5 && graphPropValues < 10) {
          color.current = getColorPoint(
            "#e1f967",
            "#ff9735",
            (graphPropValues - 5) / 5,
            "hex"
          );
        } else if (graphPropValues >= 10 && graphPropValues < 15) {
          color.current = getColorPoint(
            "#ff9735",
            "rgb(238, 73, 73)",
            (graphPropValues - 10) / 5,
            "hex"
          );
        } else if (graphPropValues >= 15 && graphPropValues < 20) {
          color.current = getColorPoint(
            "rgb(238, 73, 73)",
            "rgb(139, 43, 43)",
            (graphPropValues - 15) / 5,
            "hex"
          );
        } else {
          color.current = "rgb(139, 43, 43)";
        }
      }
    }

    const colours = (color) => [color, "#28303c"];
    const data = (title, color) => {
      return {
        labels: [title],
        datasets: [
          {
            data:
              graphPropValues < 0 ||
              graphPropValues > 100 ||
              graphPropValues === "-" ||
              !graphPropValues ||
              showINIT
                ? [0, 100]
                : [
                    Number(graphPropValues).toFixed(3),
                    100 - Number(graphPropValues).toFixed(3),
                  ],
            backgroundColor: colours(color),
            borderColor: colours(color),
          },
        ],
      };
    };
    const options = (title) => {
      return {
        tooltips: {
          enabled: false,
          callbacks: {
            title: () => {
              return title + ": ";
            },
            label: () => {
              const returnthis =
                graphPropValues === "-" || !graphPropValues || showINIT
                  ? "-"
                  : Number(graphPropValues).toFixed(3) + "%";
              return returnthis;
            },
          },
        },
        animation: {
          animateRotate: true,
          duration: 1700,
          render: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 90,
        legend: {
          reverse: true,
          display: false,
        },
      };
    };
    let riskColor = "";
    if (graph === "riskedpercent") {
      riskColor =
        graphPropValues === "-" || !graphPropValues || showINIT
          ? "#1a2028"
          : color.current;
    } else if (
      graph === "efficiency" ||
      graph === "entryefficiency" ||
      graph === "exitefficiency"
    ) {
      riskColor =
        graphPropValues === "-" || !graphPropValues || showINIT
          ? "#1a2028"
          : getEfficiencyBarColor(graphPropValues);
    }

    graphs.forEach((g) => {
      let tooltipText = "";
      if (g === "riskedpercent") {
        tooltipText =
          "The percent of your account balance you risked to take this trade. To ensure the accuracy of this number, make sure to enter your stop loss.";
      } else if (g === "efficiency") {
        tooltipText =
          "Total efficiency is the percentage of the total trade range that you captured. This indicates you how well you entered and exited the trade as a whole. To ensure the accuracy of this number, enter both the MAE & MFE.";
      } else if (g === "entryefficiency") {
        tooltipText =
          "Entry efficiency is the percentage of the trade range that you captured on entry. This indicates you how well you entered the trade. To ensure the accuracy of this number, enter both the MAE & MFE.";
      } else if (g === "exitefficiency") {
        tooltipText =
          "Exit efficiency is the percentage of the trade range that you captured on exit. This indicates you how well you exited the trade. To ensure the accuracy of this number, enter both the MAE & MFE.";
      } else {
      }
      const graph = (
        <div className="singlepinmetric" key={"graph" + g}>
          <div className="deletepicbutton2" onClick={() => {}}>
            X
          </div>
          <div className="singlepinmetricheader">
            {graphTitle}
            <PopoverStickOnHover
              component={
                <div
                  style={{
                    color: "#fff7cb",
                    padding: "2px",
                    textAlign: "left",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {tooltipText} <br></br>
                  {/*                   <Link to={"/KPIs"} className="linktooltiphover">
                    Explore &#8594;
                  </Link> */}
                </div>
              }
              placement="bottom"
              delay={300}
              xAdjust={27}
              yAdjust={0}
              keepOpen={false}
            >
              <div className="tooptip-i-style3">
                <div
                  style={{
                    margin: "-2px 0px 0px -5px",
                    padding: "0px 6px 0px 4px",
                    width: "16px",
                    height: "16px",
                    textAlign: "center",
                  }}
                >
                  &#x1D48A;
                </div>
              </div>
            </PopoverStickOnHover>
            {showINIT && (
              <div style={{ position: "relative", top: -12, left: "0" }}>
                <ProTierComponent />
              </div>
            )}
          </div>
          <div className="singlepinneddivtextwrapper" ref={ref}>
            <div className={""}>
              {
                <Doughnut
                  data={data("Risked %", riskColor)}
                  options={options("Risked %", "riskedpercentviewtrade")}
                  className="singlepinmetricactual"
                />
              }
            </div>
          </div>
          <div
            className="singlepinmetricmetric"
            style={{
              color: riskColor,
              /*               filter: `drop-shadow(0 0 2px ${riskColor})`,
              msFilter: `drop-shadow(0 0 2px ${riskColor})`,
              MozFilter: `drop-shadow(0 0 2px ${riskColor})`,
              WebkitFilter: `drop-shadow(0 0 2px ${riskColor})`, */
            }}
          >
            {graphPropValues === "-" ||
            !graphPropValues ||
            showINIT ||
            (g === "riskedpercent" &&
              (Number(graphPropValues) > 100 || Number(graphPropValues) < 0))
              ? "-%"
              : Number(graphPropValues).toFixed(3) + "%"}
          </div>
        </div>
      );
      elements.push(graph);
    });
    return elements;
  };
  return (
    <div className="singlepinnedMetricsWrapper" id="singlepinnedMetricsWrapper">
      {returnMetrics()}
    </div>
  );
};

export default SinglePercentMetric;
