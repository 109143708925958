import convertUTCDateToLocalDate from "../../convertUTCDateToLocalTime";
import { v4 as uuidv4 } from "uuid";
import { getEndDateTime, getStartDateTime } from "../../getStartorEndDateTime";
import profitLossCalculation from "../../profitLossCalculation";
import { montharr, weekday } from "../../../arrays/weekandmonth";
import sha256 from "./../../sha256";
import { filterByID } from "./../../filterByID";

const fixRound = (num) => {
  return Math.round(num * 1e10) / 1e10;
};
export const parsedYdXOrders = (
  tradesIn, // trades already imported
  idsIn, // arrays if ids already imported
  portfolioIn, // visible portfolio (selected portfolio or default)
  tradedata, // incoming entries from dydx
  userId,
  verified, // trade verification level
  importType,
  pnlmethod
) => {
  let trades = tradesIn;
  let ids = idsIn;
  let portfolio = portfolioIn;
  let changedIds = [];
  for (let i = 0, j = tradedata.length; i < j; i++) {
    const currentIteration = i + 1;
    if (importType === "initialLink") {
      localStorage.setItem(
        "autoiterationMessage",
        `Importing ${currentIteration}/${tradedata.length} executions...`
      );
    }
    let trade = {};
    let openTradesPre = [...trades];
    let openTradesPre2 = [...trades];
    const type = tradedata[i]["type"];
    if (
      type === "DEPOSIT" ||
      type === "WITHDRAWAL" ||
      type === "FAST_WITHDRAWAL"
    ) {
      if (!ids.includes(String(tradedata[i]["id"]))) {
        var startDateTime = new Date(tradedata[i]["createdAt"]);
        trade = {
          entryId: String(tradedata[i]["id"]),
          entry: {
            pictures: ["N/A"],
            symbol: {
              symbols: [],
              pointValue: 0,
            },
            strategy: "",
            selectedConfidence: "",
            selectedEmotion: "",
            selectedPhysical: "",
            selectedMarket: "",
            selectedTimeframe: "",
            selectedMistake: "",
            selectedPortfolio: portfolio.name,
            selectedPortfolioType: "Crypto",
            orderType: type === "DEPOSIT" ? "Deposit" : "Withdrawal",
            orderNumber: "",
            dayOfWeek: weekday[startDateTime.getDay()],
            monthOfYear: montharr[startDateTime.getMonth()],
            stopLoss: "",
            takeProfit: "",
            profitLoss:
              type === "DEPOSIT"
                ? Number(tradedata[i]["creditAmount"])
                : -Number(tradedata[i]["creditAmount"]),
            commissions: 0,
            fees: 0,
            maxAdEx: "",
            maxFavEx: "",
            comments: "",
            multiExecution: [],
            exitExecution: [],
            startDateTime: startDateTime,
            endDateTime: startDateTime,
            tags: "",
            verifiedLevel: verified, // because it was a fully automated trade,
            idLinks: [],
            hashes: [],
          },
        };
        trades.push(trade);
        ids.push(String(tradedata[i]["id"]));
        changedIds.push(String(tradedata[i]["id"]));
      } else {
      }
    } else if (tradedata[i]["rate"]) {
      // Keep up with individual asset fees
      const symbol = tradedata[i]?.["market"]?.toString()?.replace("-", "");
      var startDateTimefp = new Date(tradedata[i]["effectiveAt"]);
      /*               let fundingPaymentPre = fundingPayments.slice();
      fundingPaymentPre = fundingPaymentPre.filter(
        (item) => item.market === symbol.replace("-", "")
      ); */
      /*               let pushdix = {
        market: symbol.replace("-", ""),
        payments: Number(tradedata[i]["payment"]),
      };
      fundingPayments.push(pushdix); */
      const stringForId =
        tradedata[i]["market"] +
        tradedata[i]["effectiveAt"] +
        Number(tradedata[i]["price"]).toFixed(6) +
        tradedata[i]["positionSize"] +
        Number(tradedata[i]["rate"]).toFixed(10) +
        Number(tradedata[i]["payment"]).toFixed(6);

      const id = sha256(String(stringForId));

      if (!ids.includes(String(id))) {
        trade = {
          entryId: String(id),
          entry: {
            pictures: ["N/A"],
            symbol: {
              symbols: [symbol],
              pointValue: 0,
            },
            strategy: "",
            selectedConfidence: "",
            selectedEmotion: "",
            selectedPhysical: "",
            selectedMarket: "",
            selectedTimeframe: "",
            selectedMistake: "",
            selectedPortfolio: portfolio.name,
            selectedPortfolioType: "Crypto",
            orderType: "Funding Payment",
            orderNumber: "",
            dayOfWeek: weekday[startDateTimefp.getDay()],
            monthOfYear: montharr[startDateTimefp.getMonth()],
            stopLoss: "",
            takeProfit: "",
            profitLoss: Number(tradedata[i]["payment"]),
            commissions: 0,
            fees: 0,
            maxAdEx: "",
            maxFavEx: "",
            comments: "",
            multiExecution: [],
            exitExecution: [],
            startDateTime: startDateTimefp,
            endDateTime: startDateTimefp,
            tags: "",
            verifiedLevel: verified, // because it was a fully automated trade,
            idLinks: [],
            hashes: [],
          },
        };
        trades.push(trade);
        ids.push(String(id));
        changedIds.push(String(id));
      } else {
      }
    } else {
      if (!ids.includes(String(tradedata[i]["id"]))) {
        const symbol = tradedata[i]?.["market"]?.toString()?.replace("-", "");
        let openTradesMatch = openTradesPre.filter((trade) =>
          filterByID(trade, false, symbol)
        );
        let openTradesNOTMatch = openTradesPre2.filter((trade) =>
          filterByID(trade, true, symbol)
        );

        let startDateTime = new Date(tradedata[i]["createdAt"]);
        //let timestamp = new Date(fullTradearray[i]["Timestamp"]);
        let orderType = tradedata[i]["side"] === "BUY" ? "Long" : "Short";
        //let delta = fullTradearray[i]["Delta"];
        let entryexecution = {
          id: uuidv4(),
          lotSize: fixRound(Number(tradedata[i]["size"])),
          entryPrice: tradedata[i]["price"],
          startDateTime: startDateTime,
          expectedEntry: "",
          strikePrice: "",
          expirationDate: "",
          legType: "",
        };

        // Because dYdX is not a hedging account, we can just check if there are any open trades matching the symbol.
        // Side doesn't matter
        // Need to fix rounding issue of lotSizes by doing :
        // Math.round(Number(tradedata[i]["size"]) * 1e10) / 1e10,
        if (!openTradesMatch.length) {
          trade = {
            entryId: String(tradedata[i]["id"]),
            entry: {
              pictures: ["N/A"],
              symbol: {
                symbols: [symbol],
                pointValue: 0,
              },
              strategy: "",
              selectedConfidence: "",
              selectedEmotion: "",
              selectedPhysical: "",
              selectedMarket: "",
              selectedTimeframe: "",
              selectedMistake: "",
              selectedPortfolio: portfolio.name,
              selectedPortfolioType: "Crypto",
              orderType: orderType,
              orderNumber: "",
              dayOfWeek: weekday[startDateTime.getDay()],
              monthOfYear: montharr[startDateTime.getMonth()],
              stopLoss: "",
              takeProfit: "",
              profitLoss: 0,
              commissions: 0,
              fees: -Number(tradedata[i]["fee"]),
              maxAdEx: "",
              maxFavEx: "",
              comments: "",
              multiExecution: [entryexecution],
              exitExecution: [],
              startDateTime: startDateTime,
              endDateTime: "",
              tags: "",
              verifiedLevel: verified, // because it was a fully automated trade,
              idLinks: [],
              hashes: [],
            },
          };
          trades.push(trade);
          changedIds.push(String(tradedata[i]["id"]));

          openTradesPre = trades;
          openTradesPre2 = trades;
        } else {
          const openTrade = openTradesMatch[0];
          changedIds.push(openTrade?.entryId);
          const openTradeEntry = openTrade?.entry;
          const opentrademultiExecution = openTrade?.entry.multiExecution;
          const opentradeOrderType = openTrade?.entry.orderType;
          const opentradeSymbol = openTrade?.entry.symbol;
          const opentradeFees = openTrade?.entry.fees;
          const opentradesType = openTrade?.entry.selectedPortfolioType;
          // if the sides do not match then it's a close
          if (orderType !== openTradeEntry.orderType) {
            // -------- Add the exit execution, and calculate profit/loss, fees, and start & end date time stuff -------
            var endDateTimePRE = convertUTCDateToLocalDate(
              new Date(tradedata[i]["createdAt"])
            );
            let exitexecutionPRE = {
              id: uuidv4(),
              exitLotSize: fixRound(Number(tradedata[i]["size"])),
              exitPrice: tradedata[i]["price"],
              endDateTime: endDateTimePRE,
              expectedExit: "",
              exitstrikePrice: "",
              exitexpirationDate: "",
              exitlegType: "",
              equityComponents: [],
              exercised: "",
            };
            let entryLots = 0;
            opentrademultiExecution.forEach((exec) => {
              entryLots += Number(exec.lotSize);
            });
            let exitLots = 0;
            const newExitExecutionPRE = [
              ...openTrade?.entry.exitExecution,
              exitexecutionPRE,
            ];
            newExitExecutionPRE.forEach((exec) => {
              exitLots += Number(exec.exitLotSize);
            });
            let exitLotsOLD = 0;
            const newExitExecutionOLD = openTrade?.entry.exitExecution;
            newExitExecutionOLD.forEach((exec) => {
              exitLotsOLD += Number(exec.exitLotSize);
            });

            entryLots = fixRound(entryLots); // fix rounding issues
            exitLots = fixRound(exitLots);
            exitLotsOLD = fixRound(exitLotsOLD);

            const differenceIn = Number(entryLots) - Number(exitLotsOLD);
            const differenceNew = Number(exitLots) - Number(differenceIn);

            // Check if the user took an exiting position
            // whose lot size made the summed exit lot size
            // greater than the entry summed lot size
            if (exitLots > entryLots) {
              // We still close the existing trade
              let exitexecution = {
                id: uuidv4(),
                exitLotSize: fixRound(Number(differenceIn)),
                exitPrice: tradedata[i]["price"],
                endDateTime: endDateTimePRE,
                expectedExit: "",
                exitstrikePrice: "",
                exitexpirationDate: "",
                exitlegType: "",
                equityComponents: [],
                exercised: "",
              };
              const newExitExecutionPREinner = [
                ...openTrade?.entry.exitExecution,
                exitexecution,
              ];
              let startDateTimez = getStartDateTime(opentrademultiExecution);
              let endDateTimez = getEndDateTime(newExitExecutionPREinner);
              let finalFees =
                opentradeFees -
                Number(tradedata[i]["fee"]) *
                  (Number(differenceIn) / Number(exitLots));

              const entry = Object.assign({}, openTradeEntry, {
                exitExecution: newExitExecutionPREinner,
                profitLoss: Number(
                  profitLossCalculation(
                    opentrademultiExecution,
                    newExitExecutionPREinner,
                    opentradeOrderType,
                    opentradesType,
                    opentradeSymbol.pointValue,
                    false,
                    pnlmethod
                  )
                ),
                endDateTime: endDateTimez,
                startDateTime: startDateTimez,
                fees: finalFees, //add funding payments at the end
              });

              const closedTrade = Object.assign({}, openTrade, {
                entry: entry,
              });
              openTradesNOTMatch.push(closedTrade);

              // If the exit Execution makes the summed exit lots sizes more than the entry
              // We add a new trade to the other side
              const newLotSize = fixRound(Number(differenceNew));
              let orderTypeinner =
                tradedata[i]["side"] === "BUY" ? "Long" : "Short";

              let entryexecutioninner = {
                id: uuidv4(),
                lotSize: newLotSize,
                entryPrice: tradedata[i]["price"],
                startDateTime: endDateTimePRE,
                expectedEntry: "",
                strikePrice: "",
                expirationDate: "",
                legType: "",
              };
              let newFees =
                Number(tradedata[i]["fee"]) *
                (Number(differenceNew) / Number(exitLots));
              const tradeNew = {
                entryId: String(tradedata[i]["id"]),
                entry: {
                  pictures: ["N/A"],
                  symbol: {
                    symbols: [symbol],
                    pointValue: 0,
                  },
                  strategy: "",
                  selectedConfidence: "",
                  selectedEmotion: "",
                  selectedPhysical: "",
                  selectedMarket: "",
                  selectedTimeframe: "",
                  selectedMistake: "",
                  selectedPortfolio: portfolio.name,
                  selectedPortfolioType: "Crypto",
                  orderType: orderTypeinner,
                  orderNumber: "",
                  dayOfWeek: weekday[endDateTimePRE.getDay()],
                  monthOfYear: montharr[endDateTimePRE.getMonth()],
                  stopLoss: "",
                  takeProfit: "",
                  profitLoss: 0,
                  commissions: 0,
                  fees: -Number(newFees),
                  maxAdEx: "",
                  maxFavEx: "",
                  comments: "",
                  multiExecution: [entryexecutioninner],
                  exitExecution: [],
                  startDateTime: endDateTimePRE,
                  endDateTime: "",
                  tags: "",
                  verifiedLevel: verified, // because it was a fully automated trade,
                  idLinks: [],
                  hashes: [],
                },
              };
              openTradesNOTMatch.push(tradeNew);
              changedIds.push(String(tradedata[i]["id"]));

              // Then set to trades
              trades = openTradesNOTMatch;
              openTradesPre = openTradesNOTMatch;
              openTradesPre2 = openTradesNOTMatch;
            } else {
              let startDateTimez = getStartDateTime(opentrademultiExecution);
              let endDateTimez = getEndDateTime(newExitExecutionPRE);
              let finalFees = opentradeFees - Number(tradedata[i]["fee"]);

              const entry = Object.assign({}, openTradeEntry, {
                exitExecution: newExitExecutionPRE,
                profitLoss: Number(
                  profitLossCalculation(
                    opentrademultiExecution,
                    newExitExecutionPRE,
                    opentradeOrderType,
                    opentradesType,
                    opentradeSymbol.pointValue,
                    false,
                    pnlmethod
                  )
                ),
                endDateTime: endDateTimez,
                startDateTime: startDateTimez,
                fees: finalFees, //add funding payments at the end
              });

              const closedTrade = Object.assign({}, openTrade, {
                entry: entry,
              });
              openTradesNOTMatch.push(closedTrade);
              trades = openTradesNOTMatch;
              openTradesPre = openTradesNOTMatch;
              openTradesPre2 = openTradesNOTMatch;
            }
            // calculate absolute start date time
            // -------------------
          } else {
            // Add new opening executions
            const opentradeFeesin = openTrade?.entry.fees;
            const opentrademultiExecutionNew = [
              ...opentrademultiExecution,
              entryexecution,
            ];
            const entry = Object.assign({}, openTradeEntry, {
              multiExecution: opentrademultiExecutionNew,
              fees: opentradeFeesin - Number(tradedata[i]["fee"]),
            });
            const closedTrade = Object.assign({}, openTrade, {
              entry: entry,
            });
            openTradesNOTMatch.push(closedTrade);
            trades = openTradesNOTMatch;
            openTradesPre = openTradesNOTMatch;
            openTradesPre2 = openTradesNOTMatch;
          }
        }
        ids.push(String(tradedata[i]["id"]));
      } else {
      }
    }
  }

  return { trades: trades, ids: ids, changedIds: changedIds };
};

export default parsedYdXOrders;
