import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";
import { faCrown } from "@fortawesome/free-solid-svg-icons";
//import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

import { handleNANGOOD } from "../../functions/handleUndefined";
import supportedCryptos from "../../arrays/isCurrencyCrypto";
import createDatafromSettingsProps from "../../functions/createDatafromSettingsFunction";
import putUserData from "../../utils/AWS/putS3UserObject";
import $ from "jquery";
import { useAuth0 } from "@auth0/auth0-react";
import { DataContext } from "../../DataContext";
import { useHistory } from "react-router-dom";
import PopoverStickOnHover from "./../../components/PopoverStickOnHover";
let he = require("he");

const CurrentEquity = (props) => {
  var isDBRunning = localStorage.getItem("dbSetRunning");

  //const startingBalance = props.startingBalance;
  //const reputationPoints = props.reputationPoints;
  //const history = useHistory();
  const { user /* isAuthenticated  */ } = useAuth0();
  const calculations = props.calculations;
  const portfolio = props.portfolio;
  const symbol = props.defaultSymbol;
  const allData = props.allData;
  const dasheditMode = props.dasheditMode;
  const settings = portfolio && portfolio.settings;
  const dashfreqsetting = settings && settings.dashboardFrequencyToggle;
  const allGlobalData = useContext(DataContext);
  const supportedCryptosCheck = supportedCryptos.includes(symbol);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const start = Date.now();
  const history = useHistory();

  const [finalStateTimeChanged, setfinalStateTimeChanged] = useState(start);
  const [callcounter, setcallcounter] = useState(0);
  const [selectFrequencyDropdownShow, setselectFrequencyDropdownShow] =
    useState(false);
  const freqsettingINIT = (dashfreqsetting) => {
    let selectedItem = {
      label: "week",
      value: calculations.tradefreqperweek,
    };

    if (dashfreqsetting === "day") {
      selectedItem = {
        label: "day",
        value: calculations.tradefreqperday,
      };
    } else if (dashfreqsetting === "week") {
      selectedItem = {
        label: "week",
        value: calculations.tradefreqperweek,
      };
    } else if (dashfreqsetting === "month") {
      selectedItem = {
        label: "month",
        value: calculations.tradefreqpermonth,
      };
    } else if (dashfreqsetting === "year") {
      selectedItem = {
        label: "year",
        value: calculations.tradefreqperyear,
      };
    }
    return selectedItem;
  };
  const [frequencyCalc, setfrequencyCalc] = useState(
    freqsettingINIT(dashfreqsetting)
  );
  const toggleDropdown = (e) => {
    e.stopPropagation();

    setselectFrequencyDropdownShow(!selectFrequencyDropdownShow);
  };

  const saveDashboardToggle = useCallback(async (e) => {
    localStorage.setItem("dashboardFrequencyToggle", JSON.stringify(e));
    const currentTimez = Date.now();
    setfinalStateTimeChanged(currentTimez);
    $(window).bind("beforeunload", function () {
      return ">>>>>Before You Go<<<<<<<< \n Your custom message go here";
    });
    setcallcounter(1);
  }, []);

  // This function gets called after 1.6 seconds of
  // no user provoked state changes
  const handleCallSetJSON = useCallback(async () => {
    localStorage.setItem("dbSetRunning", "yes");
    var localDashboardSettings2 = JSON.parse(
      localStorage.getItem("dashboardFrequencyToggle")
    );
    const checkvar = settings.dashboardFrequencyToggle;
    let propsToChangei = {
      dashboardFrequencyToggle:
        localDashboardSettings2 !== null ? localDashboardSettings2 : checkvar,
    };

    let globalpropsToChange = {};

    const finalPush = createDatafromSettingsProps(
      allData.allData.chosenPortfolios,
      allData.allData.createdPortfolios,
      propsToChangei,
      globalpropsToChange,
      settings,
      allData.allData.globalSettings,
      allData.allData.version
    );

    //**4/7/22 remember to add context state setting if necessary */
    allGlobalData.updateAllData(finalPush);
    const S3Data = {
      data: finalPush,
      userId: user.sub,
    };
    const finalAwait = async () => {
      await putUserData(S3Data, history);
      $(window).unbind("beforeunload");
      localStorage.setItem("dbSetRunning", "no");
      setcallcounter(0);
    };
    await finalAwait();
  }, [allData, allGlobalData, settings, user.sub]);

  useEffect(() => {
    // Function to handle outside click
    const handleOutsideClick = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setselectFrequencyDropdownShow(false);
      }
    };

    // Attach the event listener
    document.addEventListener("click", handleOutsideClick);
    // checks every 300ms for state updates
    // If the user does not change a state after 1.6 seconds, handleCallSetJSON gets called
    const userTimeAllowance = 1.6;
    let interval = setInterval(async () => {
      var a = Date.now();
      var b = new Date(finalStateTimeChanged);
      var difference = (a - b) / 1000;
      if (
        callcounter === 1 &&
        difference > userTimeAllowance &&
        isDBRunning === "no"
      ) {
        if (dasheditMode) {
          setcallcounter(0);
          await handleCallSetJSON();
        }
      } else {
      }
    }, 200);
    // Clean up the event listener
    return () => {
      document.removeEventListener("click", handleOutsideClick);
      clearInterval(interval);
    };
  }, [
    callcounter,
    dasheditMode,
    settings,
    isDBRunning,
    frequencyCalc,
    finalStateTimeChanged,
    handleCallSetJSON,
    saveDashboardToggle,
  ]);
  const equityvaluefunction = () => {
    const thisAccountBalance = !props.userData[props.userData.length - 1]
      ? Number(portfolio.settings.startingBalance)
      : Number(props.userData[props.userData.length - 1].balance);
    return Math.abs(thisAccountBalance) === 0.0 ? 0 : thisAccountBalance;
  };
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return (
    <div className="currentEquity">
      <div className="currentEquityinnerWrap">
        <div className="currentEquityheader noselect">
          {"Current Equity"}{" "}
          <PopoverStickOnHover
            component={
              <div
                style={{
                  color: "#fff7cb",
                  padding: "2px",
                  textAlign: "left",
                  width: 214,
                  whiteSpace: "pre-wrap",
                }}
              >
                {
                  "At TradeFuse, we strive to display accurate account balances, but limitations with some brokers' financial APIs may affect accuracy of some metrics, especially for percentage metrics. If anything seems off, please update missing deposit or withdrawal info to correct it, or add a Starting Balance in the"
                }{" "}
                <Link to={"/Settings"} className="linktooltiphover">
                  settings
                </Link>{"."}
              </div>
            }
            placement="bottom"
            setClass="accountNumberSuperWrapper"
            onMouseEnter={() => {}}
            delay={300}
            xAdjust={27}
            yAdjust={0}
            keepOpen={true}
          >
            <div className="tooptip-i-style90">&#x1D48A;</div>
          </PopoverStickOnHover>
        </div>
        <div className="currentEquityval">
          {supportedCryptosCheck
            ? parseFloat(equityvaluefunction()?.toFixed(6)) + " " + symbol
            : he.decode(symbol) +
              numberWithCommas(equityvaluefunction()?.toFixed(2))}
        </div>
      </div>
      <div className="currentEquitybottomwrapper">
        <div className="currentEquityinnerWrapAbove">
          <div className="currentEquityinnerWrapBelow ceborderright">
            <div className="currentEquityheader2 noselect">
              {"Total P/L"}{" "}
              <PopoverStickOnHover
                placement="bottom"
                delay={300}
                xAdjust={18}
                yAdjust={0}
                setClass="accountNumberSuperWrapper"
                keepOpen={true}
                onMouseEnter={() => {}}
                component={
                  <div
                    style={{
                      color: "#fff7cb",
                      padding: "2px",
                      width: 167,
                      textAlign: "left",
                    }}
                  >
                    {
                      <div>
                        {
                          "If your P/L appears off, change the P/L calculation method in the"
                        }{" "}
                        <Link to={"/Settings"} className="linktooltiphover">
                          settings
                        </Link>{" "}
                        {
                          " to match your broker (FIFO, LIFO, or Avg. Cost Basis). Does not apply to Forex trades."
                        }
                      </div>
                    }
                  </div>
                }
              >
                <span style={{ marginTop: 4 }} className="tooptip-i-style90">
                  &#x1D48A;
                </span>
              </PopoverStickOnHover>
            </div>
            <div className="currentEquitycoltext">
              <span className="currentEquitycoltextkeepsameline">
                {" "}
                <span
                  className={
                    calculations && calculations.totalPL_dollar > 0
                      ? "tiqgreen"
                      : calculations && calculations.totalPL_dollar < 0
                      ? "tiqred"
                      : ""
                  }
                >
                  {supportedCryptosCheck
                    ? handleNANGOOD(
                        calculations &&
                          parseFloat(calculations.totalPL_dollar?.toFixed(6)),
                        "-"
                      ) +
                      " " +
                      symbol
                    : he.decode(symbol) +
                      numberWithCommas(
                        handleNANGOOD(
                          calculations &&
                            calculations.totalPL_dollar?.toFixed(2),
                          "-"
                        )
                      )}
                </span>
                <span style={{ color: "#deebf7" }}>{` /`}&nbsp;</span>
              </span>
              <span
                className={
                  calculations && calculations.totalPL_percent > 0
                    ? "tiqgreen"
                    : calculations && calculations.totalPL_percent < 0
                    ? "tiqred"
                    : ""
                }
              >
                {handleNANGOOD(
                  calculations && calculations.totalPL_percent?.toFixed(3),
                  "-"
                )}
                {"%"}
              </span>
            </div>
          </div>
        </div>
        <div className="currentEquityinnerWrapAbove">
          <div className="currentEquityinnerWrapBelow ceborderright">
            <div className="currentEquityheader2 noselect">
              {"Total # of Trades"}
            </div>{" "}
            <div className="currentEquityval4">{calculations.totalTrades}</div>
          </div>
        </div>
        <div className="currentEquityinnerWrapAbove">
          <div className="currentEquityinnerWrapBelow ceborderright">
            <div className="currentEquityheader2 noselect">
              {"Trade Frequency"}
            </div>{" "}
            <div className="currentEquityval4">
              {frequencyCalc.value}
              {calculations.totalTrades === 0 ? (
                ""
              ) : (
                <div
                  ref={buttonRef}
                  className={
                    selectFrequencyDropdownShow
                      ? "selectfrequencydropdownbutton selectfrequencydropdownbuttonselected"
                      : "selectfrequencydropdownbutton"
                  }
                  onClick={(e) => {
                    toggleDropdown(e);
                  }}
                >
                  {frequencyCalc.label}
                </div>
              )}
            </div>
          </div>{" "}
          {selectFrequencyDropdownShow && (
            <div ref={dropdownRef} className="selectfrequencydropdown">
              <div
                className="selectfrequencydropdownitem"
                onClick={async () => {
                  setfrequencyCalc({
                    label: "day",
                    value: calculations.tradefreqperday,
                  });
                  if (dasheditMode) {
                    await saveDashboardToggle("day");
                  }
                }}
              >
                Day
              </div>
              <div
                className="selectfrequencydropdownitem"
                onClick={async () => {
                  setfrequencyCalc({
                    label: "week",
                    value: calculations.tradefreqperweek,
                  });
                  if (dasheditMode) {
                    await saveDashboardToggle("week");
                  }
                }}
              >
                Week
              </div>
              <div
                className="selectfrequencydropdownitem"
                onClick={async () => {
                  setfrequencyCalc({
                    label: "month",
                    value: calculations.tradefreqpermonth,
                  });
                  if (dasheditMode) {
                    await saveDashboardToggle("month");
                  }
                }}
              >
                Month
              </div>
              <div
                className="selectfrequencydropdownitem"
                onClick={async () => {
                  setfrequencyCalc({
                    label: "year",
                    value: calculations.tradefreqperyear,
                  });
                  if (dasheditMode) {
                    await saveDashboardToggle("year");
                  }
                }}
              >
                Year
              </div>
            </div>
          )}
        </div>
        <div className="currentEquityinnerWrapAbove">
          <div className="currentEquityinnerWrapBelow ceborderright">
            <div className="currentEquityheader2 noselect">
              {"Current Streak"}
            </div>{" "}
            <div className="currentEquityval4">
              {calculations.miscellaneousCurrentStreak}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentEquity;
