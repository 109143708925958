import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import { DataContext } from "../../DataContext";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./styles.scss";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  setLoaderState,
  resetFilteredList,
} from "../../actions/actionCreators";
import {
  faEdit,
  faTrashAlt,
  faTimes,
  faCheck,
  faCalendarAlt,
  faCog,
} from "@fortawesome/free-solid-svg-icons";
import useForceUpdate from "../../hooks/useForceUpdate";
import initialSettingsState from "./../../pages/Settings/initialStates";
import store from "../../store";
import $ from "jquery";
import mergeTradesSwitchingPortfolios from "../../functions/autoimport/mergeTradesSwitchingPortfolios";
import { getMatchingPortfolios } from "../../functions/getSpecificPortfolio";
import { useAuth0 } from "@auth0/auth0-react";
import putUserData from "../../utils/AWS/putS3UserObject";
import { handleNANGOOD } from "../../functions/handleUndefined";
import newportfolio from "./../../images/newportfolio.png";
import deletePictures from "../../utils/AWS/deletePictures";
import supportedCryptos from "./../../arrays/isCurrencyCrypto";
import Modal from "react-modal";
import PopoverStickOnHover from "./../../components/PopoverStickOnHover";
import demoData from "../../demoData";
import processTieredPortfolios from "./../../functions/processTieredPortfolios";
import verifiedImage from "./../../images/noun-verified-4364676-2.png";
import updateStripeCustomerWrapper from "./../../utils/stripe/updateStripeCustomer";
import MetaApi from "metaapi.cloud-sdk";
import createSettings from "../../pages/Settings/createSettings";

import ProTierComponent, {
  MasterTierComponent,
} from "./../../components/tierComponents/pro";
function formatDate(timestamp) {
  const date = new Date(timestamp);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
}
var he = require("he");
export const UnorderedList = styled.div`
  list-style-type: none;
  padding: 2px 2px 3px 3px;
  margin: 0;
`;
const hashCode = (str, seed = 0) => {
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
  h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
  h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};
const arraysEqual = (a, b) => {
  a.sort();
  b.sort();
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};
const NodeRSA = require("node-rsa");

const metaapi = new MetaApi(process.env.REACT_APP_METAAPIKEY);
const key = new NodeRSA();
const privatePem = `-----BEGIN RSA PRIVATE KEY-----${process.env.REACT_APP_PRIVATE_KEY}-----END RSA PRIVATE KEY-----`;
key.importKey(privatePem, "pkcs1-pem");

const PortfolioDropdown = (propsIn) => {
  const props = propsIn;
  const tier = props.tier;
  const active = props.active;
  const allGlobalData = useContext(DataContext);
  const { user /* isAuthenticated */ } = useAuth0();
  const editModeInit = props.editMode;
  var initwidth =
    window.innerWidth > 0 ? window.innerWidth : window.screen.width;
  var initheight =
    window.innerHeight > 0 ? window.innerHeight : window.screen.height;
  const portfolio = props.data.portfolio;
  const settings = portfolio?.settings;
  const loadDemoSetting = settings.demoDataLoaded;
  const colorSetting = settings.portfolioColor || "";

  const globalCalculations = props.data.globalCalculations;
  let initcheckState = {
    Options: false,
    Stocks: false,
    Futures: false,
    Forex: false,
    Crypto: false,
    CFD: false,
  };
  const createdportINITPre = allGlobalData.allData.createdPortfolios;
  // Tiering logic
  let createdisabled = false;
  if (tier === "ultimate" && active) {
    // ultimate users get 10 portfolios (plus the default)
    if (createdportINITPre.length < 11) {
      createdisabled = false;
    } else {
      createdisabled = true;
    }
  }
  /*   if (tier === "free") {
    // free users get 1 portfolio (plus the default)
    createdisabled = false; // to allow for users to click to show the upgrade modal
  } else if (tier === "pro" && active) {
    // pro users get 5 portfolios (plus the default)
    if (createdportINITPre.length < 4) {
      createdisabled = false;
    } else {
      createdisabled = true;
    }
  } else if (tier === "master" && active) {
    // master users get 10 portfolios (plus the default)
    if (createdportINITPre.length < 6) {
      createdisabled = false;
    } else {
      createdisabled = true;
    }
  } else if (tier === "ultimate" && active) {
    // ultimate users get 50 portfolios (plus the default)
    if (createdportINITPre.length < 21) {
      createdisabled = false;
    } else {
      createdisabled = true;
    }
  } else if (tier === "admin" && active) {
    // unlimited portfolios
    createdisabled = false;
  } else {
    createdisabled = false; // to allow for users to click to show the upgrade modal
  } */
  // Tiering logic
  let ispro = false;
  let ismaster = false;

  let tierDiv2 = "";
  if (tier === "free") {
    tierDiv2 = (
      <div style={{ position: "relative", top: -36, left: 140 }}>
        <MasterTierComponent />
      </div>
    );
  } else if (tier === "pro" && active) {
    ispro = true;
    tierDiv2 = (
      <div style={{ position: "relative", top: -36, left: 140 }}>
        <MasterTierComponent />
      </div>
    );
  } else if (tier === "oldpro" && active) {
    ispro = true;
    tierDiv2 = (
      <div style={{ position: "relative", top: -36, left: 140 }}>
        <MasterTierComponent />
      </div>
    );
  } else if (tier === "master" && active) {
    ispro = true;
    ismaster = true;
  } else if (tier === "ultimate" && active) {
    ispro = true;
    ismaster = true;
  } else if (tier === "admin" && active) {
    ispro = true;
    ismaster = true;
  } else {
    tierDiv2 = (
      <div style={{ position: "relative", top: -36, left: 140 }}>
        <MasterTierComponent />
      </div>
    );
  }
  const [submitApply, setsubmitApply] = useState(false);

  const [editPortState, seteditPortState] = useState(false);
  const [editPortStateNu, seteditPortStateNu] = useState(false);
  const [editName, seteditName] = useState("");
  const [createdPortfolioAll, setcreatedPortfolioAll] =
    useState(createdportINITPre);
  const [createdPortfolioAllINIT] = useState(createdportINITPre);
  const [chosenPortfolio, setchosenPortfolio] = useState(
    allGlobalData.allData.chosenPortfolios
  );
  const [chosenPortfolio2, setchosenPortfolio2] = useState(chosenPortfolio);
  const firstUpdate = useRef(true);
  const [onlyOnce, setonlyOnce] = useState(0);
  const [height, setHeight] = useState(initheight);
  const [width, setWidth] = useState(initwidth);
  const [isDisabled, setisDisabled] = useState(true);
  const [innerhtml, setinnerhtml] = useState("");
  const [createPortfolioName, setcreatePortfolioName] = useState("");
  const [checkedItems, setCheckedItems] = useState(initcheckState);
  const [picsToDelete, setpicsToDelete] = useState([]);
  const [loadDemoData, setloadDemoData] = useState(false);
  const [colorCheck, setcolorCheck] = useState();

  const [colorState, setColorState] = useState(ismaster ? colorSetting : "");
  const [deletedPortfolios, setdeletedPortfolios] = useState([]);

  const [loadDemoDataSetting, setloadDemoDataSetting] =
    useState(loadDemoSetting);
  const { Options, Stocks, Futures, Forex, Crypto, CFD } = checkedItems;
  const history = useHistory();
  const location = useLocation();
  const forceUpdate = useForceUpdate();

  const validation = useCallback(() => {
    if (editPortStateNu === true) {
      let checked = true;
      const finalname = !editName ? portfolio.name : editName;
      let tempALLOther = allGlobalData.allData.createdPortfolios;
      tempALLOther = tempALLOther.filter((x) => x.name === finalname);

      const assetClasses = tempALLOther.length && tempALLOther[0].assetClasses;
      const settingsIn = tempALLOther.length && tempALLOther[0].settings;
      const colorSettingIn = settingsIn?.portfolioColor || "";
      let arr = [];
      for (const prop in checkedItems) {
        const tempprop = !checkedItems[prop] ? null : prop;
        if (tempprop === null) {
        } else {
          arr = arr.concat(tempprop);
        }
      }
      if (tempALLOther.length && !arraysEqual(arr, assetClasses)) {
        checked = false;
      } else {
        checked = true;
      }
      let tempALLOther2 = allGlobalData.allData.createdPortfolios.slice();
      tempALLOther2 = tempALLOther2.filter((x) => x.name !== editName);
      if (
        createPortfolioName !== editName ||
        !checked ||
        (ismaster && colorSettingIn !== colorState)
      ) {
        setisDisabled(false);
      } else {
        setisDisabled(true);
      }
      for (let i = 0, j = tempALLOther2.length; i < j; i++) {
        if (tempALLOther2[i]?.name === createPortfolioName) {
          $(".accountDropdownerrMsg").css({ display: "inline" });
          $(".accountDropdownerrMsg").html("Name already used");
          setisDisabled(true);
          break;
        } else {
          $(".accountDropdownerrMsg").css({ display: "none" });
          $(".accountDropdownerrMsg").html("");
        }
      }
    } else {
      for (
        let i = 0, j = allGlobalData.allData.createdPortfolios.length;
        i < j;
        i++
      ) {
        if (
          allGlobalData.allData.createdPortfolios[i].name ===
          createPortfolioName
        ) {
          $(".accountDropdownerrMsg").css({ display: "inline" });
          $(".accountDropdownerrMsg").html("Name already used");
          break;
        } else {
          $(".accountDropdownerrMsg").css({ display: "none" });
          $(".accountDropdownerrMsg").html("");
        }
      }
      if (
        $(".accountDropdownerrMsg").css("display") === "none" &&
        createPortfolioName !== "" &&
        (checkedItems["Crypto"] === true ||
          checkedItems["Forex"] === true ||
          checkedItems["Stocks"] === true ||
          checkedItems["Futures"] === true ||
          checkedItems["CFD"] === true ||
          checkedItems["Options"] === true)
      ) {
        setisDisabled(false);
      } else {
        setisDisabled(true);
      }
    }
  }, [
    editName,
    checkedItems,
    createPortfolioName,
    editPortStateNu,
    allGlobalData,
    portfolio,
    colorState,
    ismaster,
  ]);
  /*   useEffect(() => {
    createTooltip("#accdropdownapplybutton", "#accdropdropdownapplychanges");
    createTooltip("#accdropdowncancelbutton", "#accdropdowndiscardchanges");
  }, []); */
  useEffect(() => {
    validation();
  }, [checkedItems, validation]);
  useEffect(() => {
    validation();
  }, [createPortfolioName, validation]);

  useEffect(() => {
    if (firstUpdate.current) {
    }
    const handleWindowResize = () => {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", () => {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    });
    let updatedState = {
      Options: false,
      Stocks: false,
      Futures: false,
      Forex: false,
      Crypto: false,
      CFD: false,
    };
    let initassetClasses = [];
    let initname = "";
    if (!editModeInit) {
    } else {
      if (editModeInit === "yes3") {
        setinnerhtml("Create");
        seteditPortState(true);
        seteditPortStateNu(false);
        setcreatePortfolioName("");
        seteditName("");
        setCheckedItems(initcheckState);
      } else {
        initname = portfolio.name;
        initassetClasses = portfolio.assetClasses;
        for (var i = 0, j = initassetClasses.length; i < j; i++) {
          updatedState[initassetClasses[i]] = true;
        }
        (innerhtml === "Create" || innerhtml === "") && setinnerhtml("Save"); // to prevent unwanted changed to the save button
        seteditPortState(true);
        seteditPortStateNu(true);
        setcreatePortfolioName(initname);
        seteditName(initname);
        setCheckedItems(updatedState);
      }
      props.setEditMode(undefined);
    }
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [
    props,
    editModeInit,
    portfolio,
    allGlobalData,
    innerhtml,
    onlyOnce,
    initcheckState,
  ]);
  function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }
  const handleColorChange = (event) => {
    const newColor = event.target.value;
    if (!newColor || newColor === "") {
      setcolorCheck(true);
    } else {
      setcolorCheck(false);
    }
    setColorState(newColor);
  };

  const openApplyModal = async () => {
    setsubmitApply(true);
  };
  const closeApplyModal = () => {
    setsubmitApply(false);
  };
  const setInitialPortStyles = useCallback(
    async (chosenPortfolio) => {
      let allPorts = [];
      allGlobalData.allData.createdPortfolios.forEach((port) => {
        allPorts = allPorts.concat(port.name);
      });
      if (!chosenPortfolio) {
      } else {
        allPorts.forEach(async (item) => {
          const outString = hashCode(item);
          const classCheck = ".checkPortoflioUnique\\;" + outString;
          const classNM = "#portfolio-linkinner" + outString;
          if (item === "Default") {
          } else {
            if (chosenPortfolio.includes(String(item))) {
              $(classCheck).prop("checked", true);
              $(classNM).css({
                border: "1px solid #fbd665",
                borderRadius: "8px",
                backgroundColor: "#1a2028",
                width: "100%",
                transition: "all 0.1s ease-in-out",
                display: "flex",
                padding: "13px 10px 5px 16px",
                "box-shadow": "inset 0 0 4px #ffe593",
                "-moz-box-shadow": "inset 0 0 4px #ffe593",
                "-webkit-box-shadow": "inset 0 0 4px #ffe593",
              });
              $(classNM).hover(
                function () {
                  $(classNM).css("background-color", "#1a2028");
                },
                function () {
                  $(classNM).css("background-color", "#1a2028");
                }
              );
            } else {
              $(classCheck).prop("checked", false);
              $(classNM).css({
                border: "none",
                borderRadius: "0px",
                borderTop: "1px solid #1a2028",
                backgroundColor: "#222a35",
                width: "100%",
                transition: "all 0.1s ease-in-out",
                display: "flex",
                padding: "13px 10px 5px 16px",
                "box-shadow": "none",
                "-moz-box-shadow": "none",
                "-webkit-box-shadow": "none",
              });
              $(classNM).hover(
                function () {
                  $(classNM).css("background-color", "#1a2028");
                },
                function () {
                  $(classNM).css("background-color", "#222a35");
                }
              );
            }
          }
        });
      }
    },
    [allGlobalData]
  );
  const validationTop = (chosenPortfolio2IN, createdPortfoliosIN) => {
    const allPortNames = allGlobalData.allData.createdPortfolios.map(
      (port) => port.name
    );
    if (
      !arraysEqual(
        chosenPortfolio2IN,
        allGlobalData.allData.chosenPortfolios
      ) ||
      !arraysEqual(createdPortfoliosIN, allPortNames)
    ) {
      $(".accdropdownapplybutton").show();
      $(".accdropdowncancelbutton").show();
    } else {
      $(".accdropdownapplybutton").hide();
      $(".accdropdowncancelbutton").hide();
    }
  };

  const applyFunction = async () => {
    var isDBRunning = localStorage.getItem("dbSetRunning");
    localStorage.setItem("pollingToggle", "off");
    var areFiltersRunning = localStorage.getItem("filtersAreRunning");
    if (isDBRunning === "yes" || areFiltersRunning === "yes") {
      $(window).bind("beforeunload", function () {
        return ">>>>>Before You Go<<<<<<<< \n Your custom message go here";
      });
      window.location.reload();
      store.dispatch(setLoaderState({ loading: false }));
    } else {
      store.dispatch(setLoaderState({ loading: true }));
      $(".portfolio-dropdown").hide();
      $(".viewingportbutton").removeClass("viewingportbuttonhovered");
      $(".accdropdownapplybutton").hide();
      $(".accdropdowncancelbutton").hide();

      // Create data based on local storage and current AWS state
      // createdPortoflioAll is the updated (UI state) created portfolios array

      // HACKY WAY OF DOING THIS - ITERATE THROUGH STATE THEN UPDATE FROM LOGBOOK STATE
      // This is to ensure before updating the portfolio configuration,
      // we've captured any changes made to the logbook state via websockets etc
      let editedCreatedPortfolios = [];
      for (
        var ie = 0, je = allGlobalData.allData.createdPortfolios.length;
        ie < je;
        ie++
      ) {
        const portfolio = allGlobalData.allData.createdPortfolios[ie];
        const settings = portfolio.settings;
        const linkedBrokerInfo = settings.linkedBrokerInfo;

        if (deletedPortfolios.includes(portfolio.name)) {
          // Delete integration data if they delete portfolio
          if (linkedBrokerInfo.broker === "robinhood") {
            localStorage.removeItem(
              `robinhoodToken${linkedBrokerInfo.portfolio}`
            );
          } else if (linkedBrokerInfo.broker === "dYdX") {
            /*             localStorage.removeItem(`candYdXOpen`);
             */
          } else if (linkedBrokerInfo.broker === "metamask") {
            /*             localStorage.removeItem(`canMetaMaskOpen`);
             */
          } else if (
            linkedBrokerInfo.broker === "metatrader4" ||
            linkedBrokerInfo.broker === "metatrader5"
          ) {
            //decrypt credentials
            const apiCredsPre = linkedBrokerInfo.cred;
            const decryptedString = key.decrypt(apiCredsPre, "utf8");
            const apiCreds = JSON.parse(decryptedString);
            try {
              const account = await metaapi.metatraderAccountApi.getAccount(
                apiCreds
              );
              await account.remove();
            } catch (e) {
              console.log(e);
            }
          }
        }
        let stateData = createdPortfolioAll.slice();
        stateData = stateData.filter((x) => x.name === portfolio.name);
        if (!stateData.length) {
        } else {
          editedCreatedPortfolios.push(portfolio);
        }
      }

      // --------------------
      const settingsDataFINAL = {
        chosenPortfolios: chosenPortfolio2,
        createdPortfolios: editedCreatedPortfolios,
        globalSettings: allGlobalData.allData.globalSettings,
        version: Number(allGlobalData.allData.version) + 1,
        sharedTrades: allGlobalData.allData.sharedTrades,
      };
      // DATA SETS MUST ALWAYS INCLUDE THE CONTEXT STATE SET!!!!!
      //props.dataInstance.firstUpdate.current = true; // this is to make fetchData in DataContext run again, once
      //localStorage.setItem("iNeedtoInit", "yes");

      // ------- PART 4 OF FULL AUTOMATION: IF THE USER SWITCHES PORTFOLIOS,
      // WE NEED TO GET THE NEW BROKER DATA IF THEY HAVE IT LINKED
      let matchingPortfolios = {};
      if (Object.keys(settingsDataFINAL).length === 0) {
      } else {
        matchingPortfolios = getMatchingPortfolios(settingsDataFINAL);
      }

      // Create array of connections info based on selected portfolios
      let autoImportConnectionsArray = [];

      matchingPortfolios.forEach((portfolio) => {
        const linkedBroker = portfolio.settings.linkedBrokerInfo;
        linkedBroker.broker !== "" &&
          autoImportConnectionsArray.push({
            ...portfolio.settings.linkedBrokerInfo,
            port: portfolio.name,
          });
      });
      let mergedData;
      if (tier === "free") {
        autoImportConnectionsArray = [];
      } else if (tier === "pro" && active) {
        autoImportConnectionsArray = [];
      } else if (tier === "oldpro" && active) {
        autoImportConnectionsArray = [];
      } else if (tier === "master" && active) {
      } else if (tier === "ultimate" && active) {
      } else if (tier === "admin" && active) {
      } else {
        autoImportConnectionsArray = [];
      }
      try {
        mergedData = await mergeTradesSwitchingPortfolios(
          autoImportConnectionsArray,
          settingsDataFINAL,
          user.sub,
          portfolio
        );
        // -------------- CLOSE SOCKETS --------------
        localStorage.setItem("resetConnections", "no"); // look in autoimportws.js. Prevents websockets from reopening when forcing them to close

        const currentSockets = allGlobalData.sockets.current; // this is from DataContext

        currentSockets.forEach((socketData) => {
          const socket = socketData.socket;
          const type = socketData.type;

          if (type === "traditional") {
            // Just close all the sockets.
            // The ones we need will be reinstantiated after this function is finished running
            // Do this before we set the DataContext data
            socket.onclose = function () {}; // disable onclose handler first
            socket.close();
          } else if (type === "alchemy") {
            socket.removeAllListeners();
          } else if (type === "metatrader") {
            socket.close();
          }
        });
        allGlobalData.sockets.current = [];

        // --------------------------------
        const S3PictureDataDelete = {
          data: {
            pictureKeys: picsToDelete,
          },
        };
        await deletePictures(S3PictureDataDelete, history);
        const S3Data = {
          data: mergedData,
          userId: user.sub,
        };
        const customerId =
          mergedData === null ? "" : mergedData.globalSettings.stripeId;
        await updateStripeCustomerWrapper(
          customerId,
          mergedData,
          "trades",
          user.sub,
          history
        );

        await putUserData(S3Data, history);
        allGlobalData.updateAllData(mergedData);

        // Set Needed MFA Code state
        const neededMFAs = autoImportConnectionsArray.some(
          (obj) =>
            obj.broker === "robinhood" &&
            !localStorage.getItem(
              `robinhoodToken${obj.portfolio}`
            ) /* || other brokers */
        );

        if (autoImportConnectionsArray?.length && neededMFAs) {
          allGlobalData.setmfaModalOpen(true);
        }
        allGlobalData.setconnectionsArray(autoImportConnectionsArray);

        // MUST RESET LOCAL STORAGE VARIABLES WHEN SWITCHING BETWEEN PORTFOLIOS
        localStorage.setItem("reloadFilters", "yes");
        localStorage.removeItem("initialLoad");
        localStorage.removeItem("drawdownSetting");
        localStorage.removeItem("drawdownToggle");
        localStorage.removeItem("calendarNotes");
        localStorage.removeItem("quantumConversations");
        localStorage.removeItem("performanceMetricGraphs");
        localStorage.removeItem("performanceMetricDollarPercent");
        localStorage.removeItem("performanceMetricArithGeo");
        localStorage.removeItem("editTrade");
        localStorage.removeItem("editTradeId");
        localStorage.removeItem("autoiterationMessage");
        localStorage.removeItem("connectingBroker");
        localStorage.removeItem("dashboardSettings");
        localStorage.removeItem("dashboardFrequencyToggle");
        localStorage.removeItem("filters");
        // Need to remove storage related to websockets
        localStorage.removeItem("candYdXOpen");
        localStorage.removeItem("canMetaMaskOpen");
        localStorage.removeItem("canMetaTraderOpen");
        localStorage.removeItem("canCallWS");
        localStorage.setItem("resetConnections", "yes"); // re allow reopening of webcockets connections
        localStorage.setItem("pollingToggle", "on")

        if (location.pathname === "/Trade-Details") {
          if (
            createdPortfolioAllINIT.length === editedCreatedPortfolios.length
          ) {
            // here we have only changed selection
            history.push("/Dashboard");
          } else {
            history.push("/BlankPage");
            history.goBack();
          }
        } else {
          history.push("/BlankPage");
          history.goBack();
        }
      } catch (err) {
        window.location.reload();
        console.log(err);
      }
    }
  };
  const cancelFunction = useCallback(async () => {
    setcreatedPortfolioAll(allGlobalData.allData.createdPortfolios);
    setchosenPortfolio(allGlobalData.allData.chosenPortfolios);
    setchosenPortfolio2(allGlobalData.allData.chosenPortfolios);
    seteditPortState(false);
    seteditPortStateNu(false);
    setcreatePortfolioName("");
    seteditName("");
    setloadDemoData(false);
    setonlyOnce(0);
    setCheckedItems(initcheckState);
    setpicsToDelete([]);
    $(".accdropdownapplybutton").hide();
    $(".accdropdowncancelbutton").hide();
    await setInitialPortStyles(allGlobalData.allData.chosenPortfolios);
    forceUpdate();
  }, [allGlobalData, forceUpdate, initcheckState, setInitialPortStyles]);
  const addnewportfolio = async (text, data) => {
    let updatedState = {};

    if (text === "Create") {
      seteditPortState(true);
      seteditPortStateNu(false);
      setcreatePortfolioName("");
      seteditName("");
      setCheckedItems(initcheckState);
    } else {
      // only set this data when in edit mode
      if (!data) {
      } else {
        const assets = data.assetClasses;
        setcreatePortfolioName(data.name);
        for (var i = 0, j = assets.length; i < j; i++) {
          updatedState[assets[i]] = true;
        }
        setCheckedItems(updatedState);
        seteditName(data.name);
      }
      // have to have this logic again for another setstate (seteditName)
      /*       if (!data) {
      } else {
        seteditName(data.name);
      } */
      const settings = data.settings;
      const entries = data.entries;
      const loadDemoDataSetting = settings?.demoDataLoaded;
      const colorSetting = !settings?.portfolioColor
        ? ""
        : settings?.portfolioColor;

      setColorState(ismaster ? colorSetting : "");
      if (!colorSetting || colorSetting === "") {
        setcolorCheck(true);
      } else {
        setcolorCheck(false);
      }
      if (tier === "free") {
        if (entries.length === 0) {
          setloadDemoDataSetting(loadDemoDataSetting);
        } else {
          if (!loadDemoDataSetting) {
            setloadDemoDataSetting("bad");
          } else {
            setloadDemoDataSetting(loadDemoDataSetting);
          }
        }
      } else if (tier === "pro" && active) {
        setloadDemoDataSetting(loadDemoDataSetting);
      } else if (tier === "oldpro" && active) {
        setloadDemoDataSetting(loadDemoDataSetting);
      } else if (tier === "master" && active) {
        setloadDemoDataSetting(loadDemoDataSetting);
      } else if (tier === "ultimate" && active) {
        setloadDemoDataSetting(loadDemoDataSetting);
      } else if (tier === "admin" && active) {
        addnewportfolio("Create");
      } else {
        if (entries.length === 0) {
          setloadDemoDataSetting(loadDemoDataSetting);
        } else {
          if (!loadDemoDataSetting) {
            setloadDemoDataSetting("bad");
          } else {
            setloadDemoDataSetting(loadDemoDataSetting);
          }
        }
      }
      seteditPortState(true);
      seteditPortStateNu(true);
    }
    setisDisabled(true);
    setinnerhtml(String(text));
    forceUpdate();
  };
  function removeItem(array, n) {
    const result = array.slice();
    result.splice(n, 1);
    return result;
  }
  function merge(a, b, i) {
    return a.slice(0, i).concat(b, a.slice(i));
  }
  const selectPortfolio = async (e) => {
    const elementid = e.target.id;
    const checked = e.target.checked;
    const res = elementid.split(";");

    let tempports = !chosenPortfolio2 ? chosenPortfolio : chosenPortfolio2;

    const spacedname = e.target.name;

    const classNM = "#portfolio-linkinner" + res[1];
    if (!checked) {
      $(classNM).css({
        border: "none",
        borderRadius: "0px",
        borderTop: "1px solid #1a2028",
        backgroundColor: "#222a35",
        width: "100%",
        transition: "all 0.1s ease-in-out",
        display: "flex",
        padding: "13px 10px 5px 16px",
      });
      $(classNM).hover(
        function () {
          $(classNM).css("background-color", "#1a2028");
        },
        function () {
          $(classNM).css("background-color", "#222a35");
        }
      );
    } else {
      $(classNM).css({
        borderRadius: "8px",
        border: "1px solid #fbd665",
        backgroundColor: "#1a2028",
        width: "100%",
        transition: "all 0.1s ease-in-out",
        display: "flex",
        padding: "13px 10px 5px 16px",
      });
      $(classNM).hover(
        function () {
          $(classNM).css("background-color", "#1a2028");
        },
        function () {
          $(classNM).css("background-color", "#1a2028");
        }
      );
    }
    const index = tempports.indexOf(spacedname);
    if (!checked) {
      tempports = removeItem(tempports, index);
    } else {
      tempports = merge(tempports, spacedname, index);
    }
    await setInitialPortStyles(tempports);
    setchosenPortfolio2(tempports);
    const allPortNames = allGlobalData.allData.createdPortfolios.map(
      (port) => port.name
    );
    validationTop(tempports, allPortNames);
  };

  const postPortfolios = async (mode) => {
    store.dispatch(setLoaderState({ loading: true }));
    const assetClasses = checkedItems;
    let temparrayu = [];
    for (const property in assetClasses) {
      if (assetClasses[property] === true) {
        temparrayu = temparrayu.concat(property);
      }
    }
    let portfolio = {
      name: "",
      assetClasses: "",
      entries: "",
      settings: "",
    };
    const globalSettings = allGlobalData.allData.globalSettings;
    const integrationsInfo = globalSettings.integrationsInfo;

    // change integrationsinfo
    const targetPort = editName;

    // loop through the integrationsInfo object
    for (const broker in integrationsInfo) {
      // check if the port property of the current broker object matches the target port string
      if (integrationsInfo[broker].port === targetPort) {
        // update the port property with the new value "x"
        integrationsInfo[broker].port = createPortfolioName;
      }
    }

    let temparr = allGlobalData.allData.createdPortfolios;
    let tempALL = allGlobalData.allData.createdPortfolios.slice();
    let tempALLOther = allGlobalData.allData.createdPortfolios.slice();
    const loadDemoData = localStorage.getItem("loadDemoData");
    if (innerhtml === "Save") {
      tempALL = tempALL.filter((x) => x.name !== editName);
      tempALLOther = tempALLOther.filter((x) => x.name === editName);
      let entries = tempALLOther[0].entries;
      // Change portfolio name in current change to updated name

      // update all entries with new portfolio name
      entries.forEach((entry) => {
        if (entry.entry.selectedPortfolio === editName) {
          entry.entry.selectedPortfolio = createPortfolioName;
        }
      });
      let tempSettings = tempALLOther[0].settings;
      const propsValuesToChange = {
        portfolioColor: colorState,
      };
      const settingsDataPre = createSettings(tempSettings, propsValuesToChange);
      portfolio = {
        name: createPortfolioName,
        assetClasses: temparrayu,
        entries: entries,
        settings: settingsDataPre,
      };
    } else {
      let tempSettings = initialSettingsState;
      const propsValuesToChange = {
        portfolioColor: colorState,
        demoDataLoaded: loadDemoData === "yes" ? true : false,
      };
      const settingsDataPre = createSettings(tempSettings, propsValuesToChange);
      portfolio = {
        name: createPortfolioName,
        assetClasses: temparrayu,
        settings: settingsDataPre,
        entries:
          loadDemoData === "yes" ? demoData(createPortfolioName).entries : [],
      };
      if (loadDemoData === "yes") {
        localStorage.removeItem("loadDemoData");
      }
    }
    if (!temparr.length) {
      tempALL[0] = portfolio;
    } else {
      tempALL.push(portfolio);
    }

    // Set data stuff (Handle if no portfolios are created then select the one they create)
    let chosenOnes = "";
    let tempALL_CHOSEN = allGlobalData.allData.chosenPortfolios;
    let tempALL_CHOSENOTHER = allGlobalData.allData.chosenPortfolios;

    // Create the chosen portfolios array for skynet
    if (editPortState === true) {
      tempALL_CHOSENOTHER = tempALL_CHOSENOTHER.filter((x) => x === editName);
      // check if the portfolio name (editName) (the portfolio the user chose to edit) exists
      // in the original chosen portoflios array
      if (!tempALL_CHOSENOTHER.length) {
        if (tempALL_CHOSEN.length === 1) {
          tempALL_CHOSEN = tempALL_CHOSEN.concat(createPortfolioName); // add the portfolio if no portfolios are created
        } else {
          //tempALL_CHOSEN = tempALL_CHOSEN.concat("Default"); // add the portfolio if no portfolios are created
        }
      } else {
        tempALL_CHOSEN = tempALL_CHOSEN.filter((x) => x !== editName);
        tempALL_CHOSEN = tempALL_CHOSEN.concat(createPortfolioName);
      }
      chosenOnes = tempALL_CHOSEN;
    } else {
      chosenOnes = allGlobalData.allData.chosenPortfolios;
    }

    // If the user creates a portfolio, auto select that portfolio
    if (innerhtml === "Create") {
      chosenOnes = ["Default", createPortfolioName];
    } else {
    }
    // ------------
    const settingsData = {
      chosenPortfolios: chosenOnes,
      createdPortfolios: tempALL,
      globalSettings: allGlobalData.allData.globalSettings,
      version: Number(allGlobalData.allData.version) + 1,
      sharedTrades: allGlobalData.allData.sharedTrades,
    };
    seteditPortState(false);
    $(".portfolio-dropdown").hide();
    $(".viewingportbutton").removeClass("viewingportbuttonhovered");
    setCheckedItems({});
    setcreatePortfolioName("");
    setcreatedPortfolioAll(tempALL);
    await setInitialPortStyles(chosenPortfolio);

    // SET AWS DATA AND CONTEXT STATE
    forceUpdate();
    const S3Data = {
      data: settingsData,
      userId: user.sub,
    };
    await putUserData(S3Data, history);
    if (innerhtml === "Create") {
      $("#overlay").fadeOut(0);
      $("#noclick").fadeOut(0);
      store.dispatch(resetFilteredList());
      // -------------- CLOSE SOCKETS --------------
      localStorage.setItem("resetConnections", "no"); // look in autoimportws.js. Prevents websockets from reopening when forcing them to close

      const currentSockets = allGlobalData.sockets.current; // this is from DataContext
      currentSockets.forEach((socketData) => {
        const socket = socketData.socket;
        const type = socketData.type;
        if (type === "traditional") {
          // Just close all the sockets.
          // The ones we need will be reinstantiated after this function is finished running
          // Do this before we set the DataContext data
          socket.onclose = function () {}; // disable onclose handler first
          socket.close();
        } else if (type === "alchemy") {
          // for ethereum websocket Currently using alchemy
          socket.removeAllListeners();
        }
      });
      allGlobalData.sockets.current = [];
    } else {
    }
    allGlobalData.updateAllData(settingsData);

    if (innerhtml === "Create") {
      history.push("/BlankPage");
      history.push({
        pathname: "/Integrations",
      });
    } else {
      history.push("/BlankPage");
      history.goBack();
    }
  };

  const deleteportfolio = async (item) => {
    setdeletedPortfolios([...deletedPortfolios, item]);
    let tempchosenPorts = createdPortfolioAll.slice();
    let tempchosen = chosenPortfolio2;

    for (var iv = 0; iv < chosenPortfolio2.length; iv++) {
      if (chosenPortfolio2[iv] === item) {
        tempchosen = removeItem(tempchosen, iv);
        break;
      }
    }
    let picstoDeleteArr = [];
    for (var i = 0; i < createdPortfolioAll.length; i++) {
      if (createdPortfolioAll[i].name === item) {
        // create array of pics to delete
        const entriesInner = createdPortfolioAll[i].entries;
        entriesInner.forEach((entry) => {
          const pics = entry.entry.pictures;
          picstoDeleteArr.push(...pics);
        });
        // -----------

        // remove the portfolio from the created portfolios
        tempchosenPorts = removeItem(tempchosenPorts, i);
        break;
      }
    }

    // Need to delete all pictures as well
    // this creates the array of pics to delete
    let uniquePicsToDelete = [...new Set(picstoDeleteArr)];
    setpicsToDelete(uniquePicsToDelete);
    const allPortNames = tempchosenPorts.map((port) => port.name);
    await setInitialPortStyles(tempchosen);
    setchosenPortfolio(tempchosen);
    setchosenPortfolio2(tempchosen);
    setcreatedPortfolioAll(tempchosenPorts);

    validationTop(tempchosen, allPortNames);

    forceUpdate();
  };
  const handleCheckedEvents = (event) => {
    seteditPortState(true);
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  };

  const deleteTrades = async () => {
    store.dispatch(setLoaderState({ loading: true }));
    const allportfolios = allGlobalData.allData;
    let tempALL = allGlobalData.allData.createdPortfolios.slice();
    let tempALLOther = allGlobalData.allData.createdPortfolios.slice();
    tempALL = tempALL.filter((x) => x.name !== editName);
    tempALLOther = tempALLOther.filter((x) => x.name === editName);
    let entries = tempALLOther[0].entries.slice();
    entries = entries.filter((obj) => !obj?.entry?.demoData);
    let tempsettings = tempALLOther[0].settings;
    tempsettings.demoDataLoaded = true; // since it's just one line, no need to import the function that creates the settings obj
    const propsValuesToChange = {
      demoDataLoaded: false,
    };
    const settingsDataPre = createSettings(tempsettings, propsValuesToChange);

    // Change portfolio name in current change to updated name

    const portfolio = {
      name: editName,
      assetClasses: tempALLOther[0].assetClasses,
      entries: entries,
      settings: settingsDataPre,
    };

    tempALL = tempALL.concat(portfolio);
    const finalPush = {
      chosenPortfolios: allportfolios.chosenPortfolios,
      createdPortfolios: tempALL,
      globalSettings: allportfolios.globalSettings,
      version: Number(allportfolios.version) + 1,
      sharedTrades: allportfolios.sharedTrades,
    };
    seteditPortState(false);
    $(".portfolio-dropdown").hide();
    $(".viewingportbutton").removeClass("viewingportbuttonhovered");
    setCheckedItems({});
    setcreatePortfolioName("");
    setcreatedPortfolioAll(tempALL);
    await setInitialPortStyles(chosenPortfolio);
    const customerId =
      finalPush === null ? "" : finalPush.globalSettings.stripeId;
    await updateStripeCustomerWrapper(
      customerId,
      finalPush,
      "trades",
      user.sub,
      history
    );

    const S3Data = {
      data: finalPush,
      userId: user.sub,
    };
    const finalAwait = async () => {
      await putUserData(S3Data, history);
      allGlobalData.updateAllData(finalPush); // this one needs to run on the res

      $(window).unbind("beforeunload");
      localStorage.setItem("dbSetRunning", "no");
      localStorage.setItem("loadDemoDataAction", "no");
      store.dispatch(setLoaderState({ loading: false }));
      history.push("/BlankPage");
      history.goBack();
    };
    await finalAwait();
  };

  const handleSaveDemoData = async () => {
    store.dispatch(setLoaderState({ loading: true }));
    let portfolio = {};
    let temparr = allGlobalData.allData.createdPortfolios;
    let tempALL = allGlobalData.allData.createdPortfolios.slice();
    let tempALLOther = allGlobalData.allData.createdPortfolios.slice();
    tempALL = tempALL.filter((x) => x.name !== editName);
    tempALLOther = tempALLOther.filter((x) => x.name === editName);
    let entries = tempALLOther[0].entries;
    let tempsettings = tempALLOther[0].settings;
    tempsettings.demoDataLoaded = true; // since it's just one line, no need to import the function that creates the settings obj
    const propsValuesToChange = {
      demoDataLoaded: true,
    };
    const settingsDataPre = createSettings(tempsettings, propsValuesToChange);

    // Change portfolio name in current change to updated name

    portfolio = {
      name: editName,
      assetClasses: tempALLOther[0].assetClasses,
      entries: [...entries, ...demoData(editName).entries],
      settings: settingsDataPre,
    };
    if (!temparr.length) {
      tempALL[0] = portfolio;
    } else {
      tempALL.push(portfolio);
    }
    // ------------
    const settingsData = {
      chosenPortfolios: allGlobalData.allData.chosenPortfolios,
      createdPortfolios: tempALL,
      globalSettings: allGlobalData.allData.globalSettings,
      version: Number(allGlobalData.allData.version) + 1,
      sharedTrades: allGlobalData.allData.sharedTrades,
    };
    seteditPortState(false);
    $(".portfolio-dropdown").hide();
    $(".viewingportbutton").removeClass("viewingportbuttonhovered");
    setCheckedItems({});
    setcreatePortfolioName("");
    setcreatedPortfolioAll(tempALL);
    await setInitialPortStyles(chosenPortfolio);

    // SET AWS DATA AND CONTEXT STATE
    forceUpdate();
    const S3Data = {
      data: settingsData,
      userId: user.sub,
    };
    const customerId =
      settingsData === null ? "" : settingsData.globalSettings.stripeId;
    await updateStripeCustomerWrapper(
      customerId,
      settingsData,
      "trades",
      user.sub,
      history
    );

    await putUserData(S3Data, history);
    allGlobalData.updateAllData(settingsData);

    store.dispatch(setLoaderState({ loading: false }));

    localStorage.setItem("loadDemoDataAction", "yes");
    history.push("/BlankPage");
    history.goBack();
  };
  const handleLoadDemoData = (event) => {
    seteditPortState(true);
    if (loadDemoData) {
      setloadDemoData(false);
      localStorage.removeItem("loadDemoData");
    } else {
      setloadDemoData(true);
      localStorage.setItem("loadDemoData", "yes");
    }
  };

  const displayPortfolios = (allports) => {
    let portfoliosArray = [];
    let allportfolios = allports;
    let tempChosen = chosenPortfolio.slice();
    chosenPortfolio && tempChosen.sort();
    chosenPortfolio && tempChosen.reverse();
    //sort the portfolios alphabetically
    chosenPortfolio &&
      allportfolios.sort((a, b) => a.name?.localeCompare(b.name));

    // then we put the selected portfolios alphabetically on top
    const selectedPortfolios = allportfolios
      .filter((portfolio) => tempChosen.includes(portfolio.name))
      .sort((a, b) => a.name?.localeCompare(b.name));

    const sortedPortfolios = [
      ...selectedPortfolios,
      ...allportfolios.filter(
        (portfolio) => !tempChosen.includes(portfolio.name)
      ),
    ];
    // hide selected the portfolios if it does not exists in the largest n
    // this is mainly logic for users who have downgraded
    if (tier === "free") {
      allportfolios = processTieredPortfolios(
        sortedPortfolios,
        1,
        chosenPortfolio.slice()
      ).createdPortfolios;
      //tempChosen = getPortfolios(allportfolios, 1).chosenPortfolios;
      // free users get 1 portfolio (plus the default)
    } else if (tier === "pro" && active) {
      // pro users get 2 portfolios (plus the default)
      allportfolios = processTieredPortfolios(
        sortedPortfolios,
        2,
        chosenPortfolio.slice()
      ).createdPortfolios;
      //tempChosen = getPortfolios(allportfolios, 5).chosenPortfolios;
    } else if (tier === "oldpro" && active) {
      // pro users get 5 portfolios (plus the default)
      allportfolios = processTieredPortfolios(
        sortedPortfolios,
        5,
        chosenPortfolio.slice()
      ).createdPortfolios;
      //tempChosen = getPortfolios(allportfolios, 5).chosenPortfolios;
    } else if (tier === "master" && active) {
      // master users get 5 portfolios (plus the default)
      allportfolios = processTieredPortfolios(
        sortedPortfolios,
        5,
        chosenPortfolio.slice()
      ).createdPortfolios;
      //tempChosen = getPortfolios(allportfolios, 10).chosenPortfolios;
    } else if (tier === "ultimate" && active) {
      // ultimate users get 10 portfolios (plus the default)
      allportfolios = processTieredPortfolios(
        sortedPortfolios,
        10,
        chosenPortfolio.slice()
      ).createdPortfolios;
      //tempChosen = getPortfolios(allportfolios, 10).chosenPortfolios;
    } else if (tier === "admin" && active) {
      // unlimited portfolios
    } else {
      allportfolios = processTieredPortfolios(
        sortedPortfolios,
        1,
        chosenPortfolio.slice()
      ).createdPortfolios;
      //tempChosen = getPortfolios(allportfolios, 1).chosenPortfolios;
    }

    const numiterations = !allportfolios ? 0 : allportfolios.length;

    for (let i = 0, j = numiterations; i < j; i++) {
      if (allportfolios[i].name === "Default") {
        continue;
      }
      const globalCalc = globalCalculations[allportfolios[i].name];
      const outString = hashCode(allportfolios[i].name);

      // Check if portfolio exists in chosenPortfolios to display as chcked
      const defaultCheck = tempChosen.includes(String(allportfolios[i].name));

      // checks if its a crypto account or not
      const supportedCryptosCheck = supportedCryptos.includes(
        allportfolios[i].settings.currencySymbol
      );

      const currentBalance = supportedCryptosCheck
        ? handleNANGOOD(
            globalCalc && parseFloat(globalCalc.currentBalance.toFixed(6)),
            "-"
          ) +
          " " +
          allportfolios[i].settings.currencySymbol
        : he.decode(allportfolios[i].settings.currencySymbol) +
          handleNANGOOD(
            globalCalc && globalCalc.currentBalance.toFixed(2),
            "-"
          );
      const totalPL = supportedCryptosCheck
        ? handleNANGOOD(
            globalCalc && parseFloat(globalCalc.totalPL.toFixed(6)),
            "-"
          ) +
          " " +
          he.decode(allportfolios[i].settings.currencySymbol)
        : he.decode(allportfolios[i].settings.currencySymbol) +
          handleNANGOOD(globalCalc && globalCalc.totalPL.toFixed(2), "-");
      const verificationStatus =
        allportfolios[i].settings.verified === 2 ? true : false;
      const verificationDate = !allportfolios[i]?.settings?.verifiedDate
        ? ""
        : formatDate(allportfolios[i].settings.verifiedDate);
      let divtopush = "";
      if (!defaultCheck) {
        divtopush = (
          <div
            className="portfolio-linkinnerunchecked"
            id={"portfolio-linkinner" + outString}
            key={outString}
            name={outString}
          >
            <div
              style={{
                margin: "0px 0px 0px 0px",
                padding: "8px 0px 0px 0px",
                display: "flex",
              }}
            >
              <label className="container checksports">
                <input
                  type="checkbox"
                  defaultChecked={defaultCheck}
                  id={"checkPortoflioUnique;" + outString}
                  className={"checkPortoflioUnique;" + outString}
                  name={allportfolios[i].name}
                  onClick={(e) => selectPortfolio(e)}
                />
                <span className="checkmark checkmark-col1-2"></span>
                &emsp;&ensp;&ensp;{" "}
                {width > 481 ? (
                  <PopoverStickOnHover
                    placement="left"
                    delay={550}
                    xAdjust={0}
                    yAdjust={20}
                    keepOpen={true}
                    setClass="portfolioTooltipSuperWrapper"
                    component={
                      <div className="portfolioTooltipWrapper">
                        <div className="portfolioTooltipWrappercol1">
                          {" "}
                          <div className="portfolioTooltipWrappercol1text">
                            Current Balance
                          </div>
                          <div className="portfolioTooltipWrappercol1text">
                            Total P/L
                          </div>
                          <div className="portfolioTooltipWrappercol1text">
                            Total Entries
                          </div>
                          <div className="portfolioTooltipWrappercol1text">
                            TradeFuse Index
                          </div>
                        </div>
                        <div className="portfolioTooltipWrappercol2">
                          <div className="portfolioTooltipWrappercol2text">
                            {/* Check if symbol is a crypto */}
                            {currentBalance}
                          </div>
                          <div className="portfolioTooltipWrappercol2text2">
                            <span
                              className={
                                globalCalc && globalCalc.totalPL > 0
                                  ? "tiqgreen"
                                  : globalCalc && globalCalc.totalPL < 0
                                  ? "tiqred"
                                  : ""
                              }
                            >
                              {totalPL}
                            </span>
                            {" / "}
                            <span
                              className={
                                globalCalc && globalCalc.totalPLPercent > 0
                                  ? "tiqgreen"
                                  : globalCalc && globalCalc.totalPLPercent < 0
                                  ? "tiqred"
                                  : ""
                              }
                            >
                              {handleNANGOOD(
                                globalCalc &&
                                  globalCalc.totalPLPercent.toFixed(3),
                                "-"
                              )}
                              {"%"}
                            </span>
                          </div>
                          <div className="portfolioTooltipWrappercol2text">
                            {handleNANGOOD(
                              globalCalc && globalCalc.totalEntries,
                              "0"
                            )}
                          </div>
                          <div className="portfolioTooltipWrappercol2text">
                            {handleNANGOOD(
                              globalCalc && globalCalc.tfIndex.toFixed(3),
                              "-"
                            )}
                          </div>
                        </div>
                      </div>
                    }
                  >
                    <div
                      id="portfolio-linkinner-name"
                      style={{
                        color: ismaster
                          ? allportfolios[i].settings?.portfolioColor
                          : "",
                      }}
                    >
                      {allportfolios[i].name}
                    </div>
                  </PopoverStickOnHover>
                ) : (
                  <div
                    id="portfolio-linkinner-name"
                    style={{
                      color: ismaster
                        ? allportfolios[i].settings?.portfolioColor
                        : "",
                    }}
                  >
                    {allportfolios[i].name}
                  </div>
                )}
              </label>
              {verificationStatus && (
                <PopoverStickOnHover
                  placement="bottom"
                  delay={300}
                  xAdjust={20}
                  setClass="accountNumberSuperWrapper"
                  yAdjust={0}
                  keepOpen={false}
                  component={
                    <span
                      style={{
                        color: "#ffeba8",
                        padding: "2px",
                        textAlign: "left",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      <div style={{ width: 220 }}>
                        <div className="verifiedportfoliotooltipheader">
                          Verified portfolio{" "}
                          <img
                            className="portfolioverifiedimage2"
                            alt="0"
                            src={verifiedImage}
                          />
                        </div>{" "}
                        <div className="verifiedportfoliotooltipheader2">
                          <span className="small-icons-OC6g">
                            <FontAwesomeIcon icon={faCalendarAlt} />
                          </span>
                          Verified since {verificationDate}
                        </div>
                        <div className="verifiedportfoliotooltipbody">
                          Verified portfolios mean all trades in the portfolio
                          are verified, and no trades have been deleted or
                          added.
                        </div>
                      </div>
                    </span>
                  }
                >
                  <img
                    className="portfolioverifiedimage"
                    alt="0"
                    src={verifiedImage}
                  />
                </PopoverStickOnHover>
              )}
              {width > 481 ? (
                <div className="editdeleteportfoliosun editdeleteportsrightajdust12">
                  <PopoverStickOnHover
                    placement="bottom"
                    delay={300}
                    xAdjust={20}
                    yAdjust={0}
                    keepOpen={false}
                    component={
                      <div style={{ color: "#deebf7" }}>Quick Edit</div>
                    }
                  >
                    <span
                      className="small-icons-OC6"
                      onClick={() => addnewportfolio("Save", allportfolios[i])}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </span>
                  </PopoverStickOnHover>{" "}
                  <PopoverStickOnHover
                    placement="bottom"
                    delay={300}
                    xAdjust={20}
                    yAdjust={0}
                    keepOpen={false}
                    component={
                      <div style={{ color: "#fa798b" }}>Delete Portfolio</div>
                    }
                  >
                    <span
                      className="small-icons-OC6-red"
                      onClick={() => deleteportfolio(allportfolios[i].name)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </span>
                  </PopoverStickOnHover>
                </div>
              ) : (
                <div className="editdeleteportfoliosun editdeleteportsrightajdust2">
                  <span
                    className="small-icons-OC6"
                    onClick={() => addnewportfolio("Save", allportfolios[i])}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </span>{" "}
                  <span
                    className="small-icons-OC6-red"
                    onClick={() => deleteportfolio(allportfolios[i].name)}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </span>
                </div>
              )}
            </div>
          </div>
        );
      } else {
        divtopush = (
          <div
            className="portfolio-linkinnerchecked"
            id={"portfolio-linkinner" + outString}
            key={outString}
            name={outString}
          >
            <div
              style={{
                margin: "0px 0px 0px -1px",
                padding: "8px 0px 0px 0px",
                display: "flex",
              }}
            >
              <label className="container checksports">
                <input
                  type="checkbox"
                  defaultChecked={defaultCheck}
                  id={"checkPortoflioUnique;" + outString}
                  className={"checkPortoflioUnique;" + outString}
                  name={allportfolios[i].name}
                  onClick={(e) => selectPortfolio(e)}
                />
                <span className="checkmark checkmark-col1-2"></span>
                &emsp;&ensp;&ensp;{" "}
                {width > 481 ? (
                  <PopoverStickOnHover
                    placement="left"
                    delay={550}
                    xAdjust={0}
                    yAdjust={20}
                    keepOpen={true}
                    setClass="portfolioTooltipSuperWrapper"
                    component={
                      <div className="portfolioTooltipWrapper">
                        <div className="portfolioTooltipWrappercol1">
                          {" "}
                          <div className="portfolioTooltipWrappercol1text">
                            Current Balance
                          </div>
                          <div className="portfolioTooltipWrappercol1text">
                            Total P/L
                          </div>
                          <div className="portfolioTooltipWrappercol1text">
                            Total Entries
                          </div>
                          <div className="portfolioTooltipWrappercol1text">
                            TradeFuse Index
                          </div>
                        </div>
                        <div className="portfolioTooltipWrappercol2">
                          <div className="portfolioTooltipWrappercol2text">
                            {currentBalance}
                          </div>
                          <div className="portfolioTooltipWrappercol2text2">
                            <span
                              className={
                                globalCalc && globalCalc.totalPL > 0
                                  ? "tiqgreen"
                                  : globalCalc && globalCalc.totalPL < 0
                                  ? "tiqred"
                                  : ""
                              }
                            >
                              {totalPL}
                            </span>
                            {" / "}
                            <span
                              className={
                                globalCalc && globalCalc.totalPLPercent > 0
                                  ? "tiqgreen"
                                  : globalCalc && globalCalc.totalPLPercent < 0
                                  ? "tiqred"
                                  : ""
                              }
                            >
                              {handleNANGOOD(
                                globalCalc &&
                                  globalCalc.totalPLPercent.toFixed(3),
                                "-"
                              )}
                              {"%"}
                            </span>
                          </div>
                          <div className="portfolioTooltipWrappercol2text">
                            {handleNANGOOD(
                              globalCalc && globalCalc.totalEntries,
                              "0"
                            )}
                          </div>
                          <div className="portfolioTooltipWrappercol2text">
                            {handleNANGOOD(
                              globalCalc && globalCalc.tfIndex.toFixed(3),
                              "-"
                            )}
                          </div>
                        </div>
                      </div>
                    }
                  >
                    <div
                      id="portfolio-linkinner-name"
                      style={{
                        color: ismaster
                          ? allportfolios[i].settings?.portfolioColor
                          : "",
                      }}
                    >
                      {allportfolios[i].name}
                    </div>
                  </PopoverStickOnHover>
                ) : (
                  <div
                    id="portfolio-linkinner-name"
                    style={{
                      color: ismaster
                        ? allportfolios[i].settings?.portfolioColor
                        : "",
                    }}
                  >
                    {allportfolios[i].name}
                  </div>
                )}
              </label>
              {verificationStatus && (
                <PopoverStickOnHover
                  placement="bottom"
                  delay={300}
                  xAdjust={20}
                  yAdjust={0}
                  keepOpen={false}
                  setClass="accountNumberSuperWrapper"
                  component={
                    <span
                      style={{
                        color: "#ffeba8",
                        padding: "2px",
                        textAlign: "left",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      <div style={{ width: 220 }}>
                        <div className="verifiedportfoliotooltipheader">
                          Verified portfolio{" "}
                          <img
                            className="portfolioverifiedimage2"
                            alt="0"
                            src={verifiedImage}
                          />
                        </div>{" "}
                        <div className="verifiedportfoliotooltipheader2">
                          <span className="small-icons-OC6g">
                            <FontAwesomeIcon icon={faCalendarAlt} />
                          </span>
                          Verified since {verificationDate}
                        </div>
                        <div className="verifiedportfoliotooltipbody">
                          Verified portfolios mean all trades in the portfolio
                          are verified, and no trades have been deleted or
                          added.
                        </div>
                      </div>
                    </span>
                  }
                >
                  <img
                    className="portfolioverifiedimage"
                    alt="0"
                    src={verifiedImage}
                  />
                </PopoverStickOnHover>
              )}
              {width > 481 ? (
                <div className="editdeleteportfolios">
                  <PopoverStickOnHover
                    placement="bottom"
                    delay={300}
                    xAdjust={27}
                    yAdjust={0}
                    keepOpen={false}
                    component={
                      <div style={{ color: "#deebf7" }}>Quick Edit</div>
                    }
                  >
                    <span
                      className="small-icons-OC6"
                      onClick={() => addnewportfolio("Save", allportfolios[i])}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </span>
                  </PopoverStickOnHover>{" "}
                  <PopoverStickOnHover
                    placement="bottom"
                    delay={300}
                    xAdjust={27}
                    yAdjust={0}
                    keepOpen={false}
                    component={<div style={{ color: "#deebf7" }}>Settings</div>}
                  >
                    <span
                      className={
                        location.pathname === "/Settings"
                          ? "small-icons-OC6-gold-s"
                          : "small-icons-OC6-s"
                      }
                      onClick={() => {
                        history.push("/Settings");
                      }}
                    >
                      <FontAwesomeIcon icon={faCog} />
                    </span>
                  </PopoverStickOnHover>{" "}
                  <PopoverStickOnHover
                    placement="bottom"
                    delay={300}
                    xAdjust={27}
                    yAdjust={0}
                    keepOpen={false}
                    component={
                      <div style={{ color: "#fa798b" }}>Delete Portfolio</div>
                    }
                  >
                    <span
                      className="small-icons-OC6-red"
                      onClick={() => deleteportfolio(allportfolios[i].name)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </span>
                  </PopoverStickOnHover>
                </div>
              ) : (
                <div className="editdeleteportfolios">
                  <span
                    className="small-icons-OC6"
                    onClick={() => addnewportfolio("Save", allportfolios[i])}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </span>{" "}
                  <span
                    className={
                      location.pathname === "/Settings"
                        ? "small-icons-OC6-gold-s"
                        : "small-icons-OC6-s"
                    }
                    onClick={() => {
                      history.push("/Settings");
                    }}
                  >
                    <FontAwesomeIcon icon={faCog} />
                  </span>{" "}
                  <span
                    className="small-icons-OC6-red"
                    onClick={() => deleteportfolio(allportfolios[i].name)}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </span>
                </div>
              )}
            </div>
          </div>
        );
      }
      portfoliosArray = portfoliosArray.concat(divtopush);
    }
    return <div id="portfolio-display">{portfoliosArray}</div>;
  };
  const movecursor = {
    paddingLeft: "10px",
  };

  return (
    <div>
      <Modal
        ariaHideApp={false}
        id="ConfirmationModal"
        className="ConfirmationModal"
        isOpen={submitApply}
        onRequestClose={closeApplyModal}
        closeTimeoutMS={250}
        contentLabel="Trade"
        overlayClassName="OverlayModal"
      >
        <h3 className="modalSymbolHeader4">{"DELETE TRADE"}</h3>

        <div className="deletemodaltext">
          You are about to make the following changes
        </div>
        <button
          className="edittradehistroytablebutton movedeletebutton"
          onClick={() => {
            store.dispatch(setLoaderState({ loading: true }));
            forceUpdate();
          }}
        >
          {"Delete"}
        </button>
        <button
          className="clearEditExecutionButton clearEditExecutionButton2 movecancelbutton"
          onClick={() => {}}
        >
          {"Cancel"}
        </button>
      </Modal>
      {editPortState === true ? (
        <UnorderedList id="portfolio-dropdown" className="portfolio-dropdown">
          <div className="mandatory accountDropdownerrMsg">{""}</div>
          <div className="portaddinputs">
            Portfolio Name{" "}
            {innerhtml === "Create" && !createPortfolioName && (
              <em className="requiredlegtype2">&nbsp;Required</em>
            )}
            <input
              name="portfolioName"
              value={createPortfolioName}
              style={movecursor}
              className="text-input-boxes-addport"
              autoComplete="off"
              pattern="^(?!\(.*[^)]$|[^(].*\)$)\(?\$?(0|[1-9]\d{0,2}(,?\d{3})?)(\.\d\d?)?\)?$"
              required
              onChange={(e) => {
                setcreatePortfolioName(e.target.value);
                seteditPortState(true);
              }}
            />
          </div>
          <div className="portfoliotypecontain">
            <div className="portfoliotypecontaintext">
              Portfolio Asset Class(es){" "}
              {innerhtml === "Create" &&
                checkedItems["Crypto"] === false &&
                checkedItems["Forex"] === false &&
                checkedItems["Stocks"] === false &&
                checkedItems["Futures"] === false &&
                checkedItems["CFD"] === false &&
                checkedItems["Options"] === false && (
                  <em className="requiredlegtype2">&nbsp;Required</em>
                )}
            </div>
            <div className="portfoliotypecontainersuper">
              <div className="portfoliotypecontainersuperinner">
                <label className="containerSA">
                  <input
                    type="checkbox"
                    id="boxChangePortfolios"
                    name="Stocks"
                    checked={Stocks || false}
                    onChange={(e) => handleCheckedEvents(e)}
                  />
                  <span className="checkmarkSA checkmark-col1-2"></span>
                  <div className="porttext">Stocks</div>
                </label>
                <label className="containerSA">
                  <input
                    type="checkbox"
                    id="boxChangePortfolios"
                    name="Forex"
                    checked={Forex || false}
                    onChange={(e) => handleCheckedEvents(e)}
                  />
                  <span className="checkmarkSA checkmark-col1-2"></span>
                  <div className="porttext">Forex</div>
                </label>
                <label className="containerSA">
                  <input
                    type="checkbox"
                    id="boxChangePortfolios"
                    name="Crypto"
                    checked={Crypto || false}
                    onChange={(e) => handleCheckedEvents(e)}
                  />
                  <span className="checkmarkSA checkmark-col1-2"></span>
                  <div className="porttext">Crypto</div>
                </label>
              </div>
              <div className="portfoliotypecontainersuperinner">
                <label className="containerSA">
                  <input
                    type="checkbox"
                    id="boxChangePortfolios"
                    name="Futures"
                    checked={Futures || false}
                    onChange={(e) => handleCheckedEvents(e)}
                  />
                  <span className="checkmarkSA checkmark-col1-2"></span>
                  <div className="porttext">Futures</div>
                </label>
                <label className="containerSA">
                  <input
                    type="checkbox"
                    id="boxChangePortfolios"
                    name="Options"
                    checked={Options || false}
                    onChange={(e) => handleCheckedEvents(e)}
                  />
                  <span className="checkmarkSA checkmark-col1-2"></span>
                  <div className="porttext">Options</div>
                </label>
                <label className="containerSA">
                  <input
                    type="checkbox"
                    id="boxChangePortfolios"
                    name="CFD"
                    checked={CFD || false}
                    onChange={(e) => handleCheckedEvents(e)}
                  />
                  <span className="checkmarkSA checkmark-col1-2"></span>
                  <div className="porttext">CFD</div>
                </label>
              </div>
            </div>
          </div>
          {/*           {innerhtml === "Save" && (
            <button
              id="portmanageintbutton"
              onClick={async () => {
                history.push("/Integrations");
              }}
            >
              Manage Integration
            </button>
          )} */}
          <div
            className="portfoliosColorWrapper"
            onClick={() => {
              !ismaster &&
                propsIn.setshowUpgradeModal({
                  show: true,
                  featureSelect: "Colored portfolios",
                  blur: false,
                  tierText: "master",
                });
            }}
          >
            <input
              type="color"
              id="headColorWrapper"
              name="head"
              disabled={!ismaster}
              value={colorState}
              onChange={handleColorChange}
            />
            <label id="headColorWrapperText">Portfolio Color</label>
            {ismaster ? (
              <label className="containerSA nocolormove">
                <input
                  type="checkbox"
                  id="boxChangePortfolios"
                  name="colorcheckbox"
                  disabled={!ismaster}
                  checked={colorCheck || false}
                  onChange={(e) => {
                    if (!colorCheck) {
                      setcolorCheck(true);
                      setColorState("");
                    } else {
                      setColorState(
                        innerhtml === "Create" ? "" : colorSetting || ""
                      );
                      setcolorCheck(false);
                    }
                  }}
                />
                <span className="checkmarkSA checkmark-col1-2"></span>
                <div className="porttext4488">No color</div>
              </label>
            ) : (
              tierDiv2
            )}
          </div>

          {innerhtml === "Create" && (
            <label className="containerSA loaddemodatacheckboxtop">
              <input
                type="checkbox"
                id="boxChangePortfolios"
                name="loadDemoData"
                checked={loadDemoData || false}
                onChange={(e) => handleLoadDemoData(e)}
              />
              <span className="checkmarkSA checkmark-col1-2"></span>
              <div className="porttext44">Load Demo Data</div>
            </label>
          )}
          {innerhtml === "Save" &&
            (loadDemoDataSetting ? (
              loadDemoDataSetting === "bad" ? (
                <div id="porttext445a">
                  Upgrade to allow 50+ entries & load demo data
                </div>
              ) : (
                <div id="porttext445">
                  <div className="deletedemodataheader1">
                    <div className="deletedemodatasubheader1">Demo Data</div>
                    <button
                      className="deletedemodatabutton2p deletedemodatabutton2adjust"
                      onClick={() => {
                        deleteTrades();
                      }}
                    >
                      <div style={{ marginTop: "-2px" }}>{"Delete"}</div>
                    </button>
                  </div>
                </div>
              )
            ) : (
              <div id="porttext445">
                <div className="deletedemodataheader1">
                  <div className="deletedemodatasubheader1">Demo Data</div>
                  <button
                    id="portmanageintbutton2"
                    onClick={async () => await handleSaveDemoData()}
                  >
                    Load
                  </button>
                </div>{" "}
              </div>
            ))}
          <button
            id="portnobutton"
            style={{
              top:
                innerhtml === "Create"
                  ? width > 481
                    ? "298px"
                    : "276px"
                  : width > 481
                  ? "318px"
                  : "286px",
            }}
            onClick={async () => {
              seteditPortState(false);
              setCheckedItems({
                Options: false,
                Stocks: false,
                Futures: false,
                Forex: false,
                Crypto: false,
                CFD: false,
              });
              setcreatePortfolioName("");
              setloadDemoData(false);
              // yes this is supposed to run twice
              await setInitialPortStyles(chosenPortfolio2);
              await setInitialPortStyles(chosenPortfolio2);
              localStorage.setItem("accDropdownState", "create");
              let tempchosenPorts = createdPortfolioAll.slice();
              const allPortNames = tempchosenPorts.map((port) => port.name);
              validationTop(chosenPortfolio2, allPortNames);
              forceUpdate();
            }}
          >
            Cancel
          </button>
          <button
            id="portaddbutton"
            style={{
              top:
                innerhtml === "Create"
                  ? width > 481
                    ? "298px"
                    : "276px"
                  : width > 481
                  ? "318px"
                  : "286px",
            }}
            onClick={async () => {
              await postPortfolios();
            }}
            disabled={isDisabled}
          >
            {innerhtml}
          </button>
        </UnorderedList>
      ) : (
        <UnorderedList id="portfolio-dropdown" className="portfolio-dropdown">
          <button
            disabled={createdisabled}
            className="createnewportfoliobutton"
            key={
              "CreatePortfoliohjkfgkhjgjkfkjhgoliarujghoirughoierughossiogueghoig"
            }
            onClick={(e) => {
              setColorState("");
              const featureSelect = {
                show: true,
                featureSelect: "2 portfolios",
                blur: false,
              };
              const featureSelectMaster = {
                show: true,
                featureSelect: "5 portfolios",
                blur: false,
                tierText: "master",
              };
              const featureSelectultimate = {
                show: true,
                featureSelect: "10 portfolios",
                blur: false,
                tierText: "ultimate",
              };
              // Tiering
              if (tier === "free") {
                if (createdportINITPre.length < 2) {
                  addnewportfolio("Create");
                } else {
                  props.setSubscriptionModalOpen(featureSelect);
                  props.setshowUpgradeModal(featureSelect);
                }
              } else if (tier === "pro" && active) {
                if (createdportINITPre.length < 3) {
                  addnewportfolio("Create");
                } else {
                  props.setSubscriptionModalOpen(featureSelectMaster);
                  props.setshowUpgradeModal(featureSelectMaster);
                }
              } else if (tier === "oldpro" && active) {
                if (createdportINITPre.length < 6) {
                  addnewportfolio("Create");
                } else {
                  props.setSubscriptionModalOpen(featureSelectMaster);
                  props.setshowUpgradeModal(featureSelectMaster);
                }
              } else if (tier === "master" && active) {
                if (createdportINITPre.length < 6) {
                  addnewportfolio("Create");
                } else {
                  props.setSubscriptionModalOpen(featureSelectultimate);
                  props.setshowUpgradeModal(featureSelectultimate);
                }
              } else if (tier === "ultimate" && active) {
                if (createdportINITPre.length < 11) {
                  addnewportfolio("Create");
                } else {
                }
              } else if (tier === "admin" && active) {
                addnewportfolio("Create");
              } else {
                if (createdportINITPre.length < 2) {
                  addnewportfolio("Create");
                } else {
                  props.setSubscriptionModalOpen(featureSelect);
                  props.setshowUpgradeModal(featureSelect);
                }
              }
            }}
          >
            <img className="small-icons-OC7" src={newportfolio} alt="0" />

            <div className="small-icons-OC7-sub">Create a New Portfolio</div>
          </button>
          <div className="choose-portfolios">
            {displayPortfolios(createdPortfolioAll)}{" "}
          </div>
          <PopoverStickOnHover
            placement="bottom"
            delay={300}
            xAdjust={18}
            yAdjust={0}
            keepOpen={false}
            component={
              <div style={{ fontSize: "1.1em", color: "#fa798b" }}>
                Discard Changes
              </div>
            }
          >
            <button
              className="accdropdowncancelbutton"
              id="accdropdowncancelbutton"
              onClick={() => cancelFunction()}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </PopoverStickOnHover>

          <PopoverStickOnHover
            placement="bottom"
            delay={300}
            xAdjust={18}
            yAdjust={0}
            keepOpen={false}
            component={
              <div style={{ fontSize: "1.1em", color: "#55f8a6" }}>
                Apply Changes
              </div>
            }
          >
            <button
              className="accdropdownapplybutton"
              id="accdropdownapplybutton"
              aria-describedby="tooltip"
              onClick={async () => await applyFunction()}
            >
              <FontAwesomeIcon icon={faCheck} />
            </button>
          </PopoverStickOnHover>
        </UnorderedList>
      )}
    </div>
  );
};

PortfolioDropdown.defaultProps = {
  userId: "",
};

PortfolioDropdown.propTypes = {
  userId: PropTypes.string,
};

export default PortfolioDropdown;
