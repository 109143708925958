import { v4 as uuidv4 } from "uuid";
import profitLossCalculation from "./../../../functions/profitLossCalculation";
import getPointValue from "../../../functions/getFuturesPointValue";
import { montharr, weekday } from "./../../../arrays/weekandmonth";
import {
  getEndDateTime,
  getStartDateTime,
} from "./../../../functions/getStartorEndDateTime";
import {
  filterByID,
  filterByIDWebullOption,
} from "./../../../functions/filterByID";
import { csvToArray, csvToArrayFromFills } from "./../csvToArray";
const crypto = require("crypto");

function generateHash(inputString) {
  return crypto.createHash("sha256").update(inputString).digest("hex");
}

function parseOptionString(optionString) {
  // Extract components using a regular expression
  const regex = /^(\w{1,6})(\d{6})([CP])(\d{8})$/;
  const match = optionString.match(regex);

  if (!match) {
    throw new Error("Invalid option string format");
  }

  // Destructure the matched components
  const [, symbol, date, type, strikeRaw] = match;

  // Parse and format the expiration date
  const year = `20${date.slice(0, 2)}`;
  const month = date.slice(2, 4);
  const day = date.slice(4, 6);
  const expirationDate = `${month}/${day}/${year}`;

  // Determine option type (C for Call, P for Put)
  const optionType = type === "C" ? "Call" : "Put";

  // Parse the strike price by inserting a decimal before the last three digits
  const strikePrice = parseFloat(
    `${strikeRaw.slice(0, -3)}.${strikeRaw.slice(-3)}`
  );

  // Return the result as an object
  return {
    symbol,
    expirationDate,
    type: optionType,
    strikePrice,
  };
}

export const webull = (
  readerEvent,
  temptrades,
  settemptrades,
  csvToArray,
  userId,
  portfolio,
  settrades,
  buttonFile,
  setinvalidFile,
  pnlmethod,
  entries
) => {
  var content = readerEvent.target.result; // =====> this is the content!

  let tradeArray = csvToArray(content);
  tradeArray.pop(); // removes headers from incoming file
  if (!readerEvent) {
    setinvalidFile(true);
    return;
  }
  settemptrades(tradeArray);
  let trade = "";
  let trades = [...entries];
  let fullTradearray = [];

  if (
    !temptrades ||
    temptrades === "" ||
    temptrades === undefined ||
    temptrades === null
  ) {
  } else {
    if (buttonFile === "Options Orders") {
      temptrades.forEach((trade) => {
        fullTradearray.push({ ...trade, tradeType: "Stocks" }); // push Options Orders
      });
      tradeArray.forEach((trade) => {
        fullTradearray.push({ ...trade, tradeType: "Options" }); // push Orders
      });
    } else if (buttonFile === "Orders") {
      tradeArray.forEach((trade) => {
        fullTradearray.push({ ...trade, tradeType: "Stocks" }); // push Options Orders
      });
      temptrades.forEach((trade) => {
        fullTradearray.push({ ...trade, tradeType: "Options" }); // push Orders
      });
    }
    fullTradearray.sort(function (a, b) {
      var c = new Date(!a["Filled Time"] ? a.Timestamp : a["Filled Time"]);
      var d = new Date(!b["Filled Time"] ? b.Timestamp : b["Filled Time"]);
      return c - d;
    });
    const verified = 0; // could be 1 in the future
    for (let i = 0, j = fullTradearray.length; i < j; i++) {
      if (
        fullTradearray[i]["Contract"] === "Fund Transaction" ||
        fullTradearray[i]["Currency"] === "Fund Transaction"
      ) {
      } else {
        if (fullTradearray[i]["tradeType"] === "Stocks") {
          const myString = String(
            fullTradearray[i]["Symbol"] +
              fullTradearray[i]["Filled"] +
              fullTradearray[i]["Filled Time"] +
              Number(fullTradearray[i]["Price"].replace(/^@/, ""))
          );

          const hashValue = generateHash(myString);
          let mutableTrades = entries.slice(); // array of user trades that can be edited
          // Filter only those trades that share at least one hash with 'trade'
          const foundTrade = mutableTrades.find((trade) =>
            trade.entry.hashes?.includes(hashValue)
          );

          // look for open trades in "trades" (not the current iteration!)
          // that matches the symbol
          let openTradesPre = [...trades];
          let openTradesPre2 = [...trades];
          let openTradesMatch = openTradesPre.filter((trade) =>
            filterByID(trade, false, fullTradearray[i]["Symbol"])
          );
          let openTradesNOTMatch = openTradesPre2.filter((trade) =>
            filterByID(trade, true, fullTradearray[i]["Symbol"])
          );
          // --------------------------

          // ---------- Determine if it's an opening or closing entry -----------

          // if the order was canceled or rejected dont do anything
          // also skip "Trade Paired" Cash entries - not needed
          if (
            fullTradearray[i]["Status"] === "Cancelled" ||
            Number(fullTradearray[i]["Filled"]) === 0
          ) {
            // Don't do anything here
          } else {
            let startDateTime = new Date(fullTradearray[i]["Filled Time"]);
            //let timestamp = new Date(fullTradearray[i]["Timestamp"]);
            let orderType =
              fullTradearray[i]["Side"] === "Buy" ? "Long" : "Short";
            //let delta = fullTradearray[i]["Delta"];

            let entryexecution = {
              id: uuidv4(),
              lotSize: Number(fullTradearray[i]["Filled"]),
              entryPrice: Number(fullTradearray[i]["Price"].replace(/^@/, "")),
              startDateTime: startDateTime,
              expectedEntry: "",
              strikePrice: "",
              expirationDate: "",
              legType: "",
            };
            const openTrade = openTradesMatch[0];
            const opentradeOrderType = openTrade?.entry.orderType;
            if (!openTradesMatch.length) {
              trade = {
                entryId: uuidv4(),
                entry: {
                  pictures: ["N/A"],
                  symbol: {
                    symbols: [fullTradearray[i]["Symbol"]],
                    pointValue: 0,
                  },
                  strategy: "",
                  selectedConfidence: "",
                  selectedEmotion: "",
                  selectedPhysical: "",
                  selectedMarket: "",
                  selectedTimeframe: "",
                  selectedMistake: "",
                  selectedPortfolio: portfolio.name,
                  selectedPortfolioType: "Stocks",
                  orderType: orderType,
                  orderNumber: "",
                  dayOfWeek: weekday[startDateTime.getDay()],
                  monthOfYear: montharr[startDateTime.getMonth()],
                  stopLoss: "",
                  takeProfit: "",
                  profitLoss: 0,
                  commissions: 0,
                  fees: 0,
                  maxAdEx: "",
                  maxFavEx: "",
                  comments: "",
                  multiExecution: [entryexecution],
                  exitExecution: [],
                  startDateTime: startDateTime,
                  endDateTime: "",
                  tags: "",
                  verifiedLevel: verified,
                  hashes: [hashValue],
                  idLinks: [],
                },
              };

              if (foundTrade) {
                // do nothing
              } else {
                trades.push(trade);
                openTradesPre = trades;
                openTradesPre2 = trades;
              }
            } else {
              if (orderType !== opentradeOrderType) {
                // -------- Add the exit execution, and calculate profit/loss, fees, and start & end date time stuff -------
                var endDateTime = new Date(fullTradearray[i]["Filled Time"]);
                let exitexecution = {
                  id: uuidv4(),
                  exitLotSize: Number(fullTradearray[i]["Filled"]),
                  exitPrice: Number(
                    fullTradearray[i]["Price"].replace(/^@/, "")
                  ),
                  endDateTime: endDateTime,
                  expectedExit: "",
                  exitstrikePrice: "",
                  exitexpirationDate: "",
                  exitlegType: "",
                  equityComponents: [],
                  exercised: "",
                };
                const openTrade = openTradesMatch[0];
                const openTradeEntry = openTrade?.entry;

                // -------- Add the exit execution, and calculate profit/loss, fees, and start & end date time stuff -------
                const opentrademultiExecution = openTradeEntry.multiExecution;
                const opentradeOrderType = openTradeEntry.orderType;
                const opentradeSymbol = openTradeEntry.symbol;
                const opentradeFees = openTradeEntry.fees;

                const opentradesType = openTradeEntry.selectedPortfolioType;
                const newExitExecution = [
                  ...openTradeEntry.exitExecution,
                  exitexecution,
                ];
                // calculate absolute start date time

                let startDateTimez = getStartDateTime(opentrademultiExecution);
                let endDateTimez = getEndDateTime(newExitExecution);
                const entry = Object.assign({}, openTradeEntry, {
                  exitExecution: newExitExecution,
                  profitLoss: Number(
                    profitLossCalculation(
                      opentrademultiExecution,
                      newExitExecution,
                      opentradeOrderType,
                      opentradesType,
                      opentradeSymbol.pointValue,
                      false,
                      pnlmethod
                    )
                  ),
                  hashes: [...openTradeEntry.hashes, hashValue],
                  endDateTime: endDateTimez,
                  startDateTime: startDateTimez,
                });
                const closedTrade = Object.assign({}, openTrade, {
                  entry: entry,
                });

                if (foundTrade) {
                  // do nothing
                } else {
                  openTradesNOTMatch.push(closedTrade);
                  trades = openTradesNOTMatch;
                  openTradesPre = openTradesNOTMatch;
                  openTradesPre2 = openTradesNOTMatch;
                }
              } else {
                // Add new opening executions
                const openTrade = openTradesMatch[0];
                const openTradeEntry = openTrade?.entry;
                const opentradeFeesin = openTradesMatch[0].entry.fees;
                const entry = Object.assign({}, openTradeEntry, {
                  multiExecution: [
                    ...openTradesMatch[0].entry.multiExecution,
                    entryexecution,
                  ],
                  hashes: [...openTradeEntry.hashes, hashValue],
                  /*                 fees: opentradeFeesin + getFees(fullTradearray, i), */
                });
                const closedTrade = Object.assign({}, openTrade, {
                  entry: entry,
                });

                if (foundTrade) {
                  // do nothing
                } else {
                  openTradesNOTMatch.push(closedTrade);
                  trades = openTradesNOTMatch;
                  openTradesPre = openTradesNOTMatch;
                  openTradesPre2 = openTradesNOTMatch;
                }
              }
            }
          }
        } else if (fullTradearray[i]["tradeType"] === "Options") {
          const parsedString = parseOptionString(fullTradearray[i]["Symbol"]);
          const myString = String(
            fullTradearray[i]["Symbol"] +
              fullTradearray[i]["Filled"] +
              fullTradearray[i]["Filled Time"] +
              Number(fullTradearray[i]["Price"].replace(/^@/, ""))
          );
          const hashValue = generateHash(myString);
          let mutableTrades = entries.slice(); // array of user trades that can be edited
          // Filter only those trades that share at least one hash with 'trade'
          const foundTrade = mutableTrades.find((trade) =>
            trade.entry.hashes?.includes(hashValue)
          );

          // look for open trades in "trades" (not the current iteration!)
          // that matches the symbol
          let openTradesPre = [...trades];
          let openTradesPre2 = [...trades];
          /*           const lastVarIn =
            rhType === "option"
              ? legs
              : {
                  strikePrice: strikePrice,
                  expirationDate: expirationDate,
                  legType: optionTypeF,
                }; */
          let orderType =
            fullTradearray[i]["Side"] === "Buy" ? "Long" : "Short";
          const lastVarIn = [
            {
              strikePrice: parsedString.strikePrice,
              expirationDate: parsedString.expirationDate,
              legType: parsedString.type,
              side: fullTradearray[i]["Side"],
            },
          ];
          let openTradesMatch = openTradesPre.filter((trade) =>
            filterByIDWebullOption(
              trade,
              false,
              parsedString.symbol,
              "Option",
              lastVarIn
            )
          );

          let openTradesNOTMatch = openTradesPre2.filter((trade) =>
            filterByIDWebullOption(
              trade,
              true,
              parsedString.symbol,
              "Option",
              lastVarIn
            )
          );
          // --------------------------

          // ---------- Determine if it's an opening or closing entry -----------

          // if the order was canceled or rejected dont do anything
          // also skip "Trade Paired" Cash entries - not needed
          if (
            fullTradearray[i]["Status"] === "Cancelled" ||
            Number(fullTradearray[i]["Filled"]) === 0 ||
            parsedString.strikePrice === 0
          ) {
            // Don't do anything here
          } else {
            let startDateTime = new Date(fullTradearray[i]["Filled Time"]);
            //let timestamp = new Date(fullTradearray[i]["Timestamp"]);
            //let delta = fullTradearray[i]["Delta"];
            let expirationDatePre = new Date(parsedString.expirationDate);
            const expirationDate = new Date(
              expirationDatePre.getTime() +
                expirationDatePre.getTimezoneOffset() * 60000
            ); //this converts the "YYYY-MM-DD" string to the correct date time object

            let entryexecution = {
              id: uuidv4(),
              lotSize: Number(fullTradearray[i]["Filled"]),
              entryPrice: Number(fullTradearray[i]["Price"].replace(/^@/, "")),
              startDateTime: startDateTime,
              expectedEntry: "",
              strikePrice: parsedString.strikePrice,
              expirationDate: expirationDate,
              legType: orderType + " " + parsedString.type,
            };
            let optionTypeF;
            if (parsedString.type === "Call") {
              optionTypeF = orderType === "Long" ? "Long Call" : "Short Call";
            } else {
              optionTypeF = orderType === "Long" ? "Long Put" : "Short Put";
            }

            const openTrade = openTradesMatch[0];
            const opentradeOrderType = openTrade?.entry.orderType;
            if (!openTradesMatch.length) {
              trade = {
                entryId: uuidv4(),
                entry: {
                  pictures: ["N/A"],
                  symbol: {
                    symbols: [parsedString.symbol],
                    pointValue: 0,
                  },
                  strategy: "",
                  selectedConfidence: "",
                  selectedEmotion: "",
                  selectedPhysical: "",
                  selectedMarket: "",
                  selectedTimeframe: "",
                  selectedMistake: "",
                  selectedPortfolio: portfolio.name,
                  selectedPortfolioType: "Options",
                  orderType: optionTypeF,
                  orderNumber: "",
                  dayOfWeek: weekday[startDateTime.getDay()],
                  monthOfYear: montharr[startDateTime.getMonth()],
                  stopLoss: "",
                  takeProfit: "",
                  profitLoss: 0,
                  commissions: 0,
                  fees: 0,
                  maxAdEx: "",
                  maxFavEx: "",
                  comments: "",
                  multiExecution: [entryexecution],
                  exitExecution: [],
                  startDateTime: startDateTime,
                  endDateTime: "",
                  tags: "",
                  verifiedLevel: verified,
                  hashes: [hashValue],
                  idLinks: [],
                },
              };

              if (foundTrade) {
                // do nothing
              } else {
                trades.push(trade);
                openTradesPre = trades;
                openTradesPre2 = trades;
              }
            } else {
              if (orderType !== opentradeOrderType) {
                // -------- Add the exit execution, and calculate profit/loss, fees, and start & end date time stuff -------
                var endDateTime = new Date(fullTradearray[i]["Filled Time"]);
                let exitexecution = {
                  id: uuidv4(),
                  exitLotSize: Number(fullTradearray[i]["Filled"]),
                  exitPrice: Number(
                    fullTradearray[i]["Price"].replace(/^@/, "")
                  ),
                  endDateTime: endDateTime,
                  expectedExit: "",
                  exitstrikePrice: "",
                  exitexpirationDate: "",
                  exitlegType: "",
                  equityComponents: [],
                  exercised: "",
                };
                const openTrade = openTradesMatch[0];
                const openTradeEntry = openTrade?.entry;

                // -------- Add the exit execution, and calculate profit/loss, fees, and start & end date time stuff -------
                const opentrademultiExecution = openTradeEntry.multiExecution;
                const opentradeOrderType = openTradeEntry.orderType;
                const opentradeSymbol = openTradeEntry.symbol;
                const opentradeFees = openTradeEntry.fees;

                const opentradesType = openTradeEntry.selectedPortfolioType;
                const newExitExecution = [
                  ...openTradeEntry.exitExecution,
                  exitexecution,
                ];
                // calculate absolute start date time

                let startDateTimez = getStartDateTime(opentrademultiExecution);
                let endDateTimez = getEndDateTime(newExitExecution);
                const entry = Object.assign({}, openTradeEntry, {
                  exitExecution: newExitExecution,
                  profitLoss: Number(
                    profitLossCalculation(
                      opentrademultiExecution,
                      newExitExecution,
                      opentradeOrderType,
                      "Options",
                      opentradeSymbol.pointValue,
                      false,
                      pnlmethod
                    )
                  ),
                  hashes: [...openTradeEntry.hashes, hashValue],
                  endDateTime: endDateTimez,
                  startDateTime: startDateTimez,
                });
                const closedTrade = Object.assign({}, openTrade, {
                  entry: entry,
                });

                if (foundTrade) {
                  // do nothing
                } else {
                  openTradesNOTMatch.push(closedTrade);
                  trades = openTradesNOTMatch;
                  openTradesPre = openTradesNOTMatch;
                  openTradesPre2 = openTradesNOTMatch;
                }
              } else {
                // Add new opening executions
                const openTrade = openTradesMatch[0];
                const openTradeEntry = openTrade?.entry;
                const opentradeFeesin = openTradesMatch[0].entry.fees;
                const entry = Object.assign({}, openTradeEntry, {
                  multiExecution: [
                    ...openTradesMatch[0].entry.multiExecution,
                    entryexecution,
                  ],
                  hashes: [...openTradeEntry.hashes, hashValue],
                  /*                 fees: opentradeFeesin + getFees(fullTradearray, i), */
                });
                const closedTrade = Object.assign({}, openTrade, {
                  entry: entry,
                });

                if (foundTrade) {
                  // do nothing
                } else {
                  openTradesNOTMatch.push(closedTrade);
                  trades = openTradesNOTMatch;
                  openTradesPre = openTradesNOTMatch;
                  openTradesPre2 = openTradesNOTMatch;
                }
              }
            }
          }
        }
      }
    }
  }
  settrades(trades);
};
export default webull;
